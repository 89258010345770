import { useState} from 'react';
import axios from 'axios'
import { PagePrompt } from "../../configs/PagePrompt";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import close from '../../assets/img/toolsImage/close.png'
import './mymemory.css'
import SetupCookies from '../../tools/setupCookies';

interface MymemoryProps{
    username?: string
    waitinfoFunc:Function
    setinfoFunc:Function
    setloginCheckappFunc:Function
}
let ErrorList = ["我无法回答您的提问"]
const Mymemory: React.FC<MymemoryProps> = (props) => {    
    const [memoryresult,setMemoryresult] = useState<any>([{question:"没有记录",reply:""}])
    const [memorysearch,setMemorysearch] = useState<string>()
    let HttpBody_init= {'username': props.username, uuid:SetupCookies("uuid","","get"),session_id:SetupCookies("session_id","","get")}
    function memorysearchFunc(){
        if(memorysearch && memorysearch !== ""){
            let HttpBody={...HttpBody_init,question:memorysearch}
            props.waitinfoFunc(true,30000)
            axios.post(`${PagePrompt.url}/llm/mymemory`,HttpBody).then(
            response => {
                props.waitinfoFunc(false)
                if(response.data.message === '未登录'){
                    props.setinfoFunc("为了您账户的安全，请尝试重新登陆后操作。","2000")
                    props.setloginCheckappFunc(false,false)
                    return false
                }
                else if(response.data.errno===0){
                    setMemoryresult(response.data.data)
                }else{
                    setMemoryresult([{question:"没有记录",reply:""}])
                }
                },
                async error => {
                    console.log(error.message.errono)
                }
                ).catch(function (error){console.log(error)})
        }
        
    }
    function deletethismemory(item:any,index:number){
        if(item.question !== "没有记录" && item.question !== "没有记录'\n"){
            let HttpBody={...HttpBody_init,question:item.question,reply:item.reply}
            axios.post(`${PagePrompt.url}/llm/deletemymemory`,HttpBody).then(
                response => {
                    if(response.data.errno===0){
                        if(response.data.errno === 0){

                            let ll = [...memoryresult]
                            ll.splice(index, 1)
                            setMemoryresult(ll)
                        }
                    }
                    },
                    async error => {
                        console.log(error.message.errono)
                    }
                    ).catch(function (error){console.log(error)})
        }
    }
    async function memoryoptimize(){
        let deleteList:any[] = []
        let deleteQ:any[] = []
        ErrorList.forEach(async element => {
            let HttpBody={...HttpBody_init,question:element}
            props.waitinfoFunc(true,30000)
            axios.post(`${PagePrompt.url}/llm/mymemory`,HttpBody).then(
            async response => {
                props.waitinfoFunc(false)
                if(response.data.message === '未登录'){
                    props.setinfoFunc("为了您账户的安全，请尝试重新登陆后操作。","2000")
                    props.setloginCheckappFunc(false,false)
                    return false
                }
                else if(response.data.errno===0){
                    [deleteList,deleteQ] = listFilter(response.data.data,deleteList,deleteQ)
                    setMemoryresult(deleteList)
                }else{
                    setMemoryresult([{question:"没有相关记录",reply:""}])
                }
                },
                async error => {
                    console.log(error.message.errono)
                }
                ).catch(function (error){console.log(error)})
        });
    }
    function listFilter(searchresult:any,deleteList:any,deleteQ:any){
        searchresult.forEach((element:any) => {
            if(!deleteQ.includes(element.question)){
                let includeError = 0
                ErrorList.forEach(elem => {
                    if(element.reply.indexOf(elem) !== -1){includeError = 1}
                    if(element.reply.length < 10){includeError = 1}
                });
                if(includeError == 1){
                    deleteList.push(element)
                    deleteQ.push(element.question)
                }
            }
        });
        return [deleteList,deleteQ]
    }
    function deleteAll(){
        if(memoryresult.length > 0 && memoryresult[0].question !== "没有记录"){
            let HttpBody={...HttpBody_init,question:memoryresult,reply:""}
            axios.post(`${PagePrompt.url}/llm/deletemymemory`,HttpBody).then(
                response => {
                    if(response.data.errno===0){
                        setMemoryresult([{question:"没有记录",reply:""}])
                    }
                    },
                    async error => {
                        console.log(error.message.errono)
                    }
                    ).catch(function (error){console.log(error)})
        }

    }

    return( 
        <div className='mymemory'>
            <div id='memory_search_control'>
                <div className='memory_search'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">关键词</InputGroup.Text>
                        <Form.Control
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={memorysearch}
                        onChange={(e) =>setMemorysearch(e.target.value)} 
                        />
                    </InputGroup>
                </div>
                <div className='memory_control'>
                    <Button className='memory_control_but'  variant="outline-info" onClick={() => memorysearchFunc()} >搜索</Button>
                    <Button  className='memory_control_but' variant="outline-info" onClick={() => memoryoptimize()} >优化</Button>
                    <Button  className='memory_control_but' variant="outline-info" onClick={() => deleteAll()} >请空相关记忆</Button>
                </div>
            </div>

            <table className='memory_result'>
                {(memoryresult as any).map((item:any,index:number) =>{
                    return <tr className='memory_items'>
                                <th  className='memory_left'>{index}</th>
                                <th  className='memory_left_content'>
                                    <div>{item.question}</div>
                                    <div>{item.reply}</div>
                                </th>
                                <th className='memory_right'><img src={close} onClick={()=>deletethismemory(item,index)} className="rounded me-2" width="20" alt="" /></th>
                            </tr>
                })
                }
            </table>
        </div> 
    )
}
export default Mymemory