import openaiAPI from "../../src/APILibs/openai.json";
import azureaiAPI  from "../../src/APILibs/azureai.json";
import glm7bAPI from "../../src/APILibs/glm7b.json";
import baichuanAPI from "../../src/APILibs/baichuan.json";
import llama2API from "../../src/APILibs/llama2.json";
import mistralAPI from "../../src/APILibs/mistral.json";
import qwenAPI from "../../src/APILibs/qwen.json";
import zhipuai from "../../src/APILibs/zhipuai.json";
// import geminiAPI from "../../../src/APILibs/gemini.json";
import prompt_txt from '../../src/APILibs/prompt_txt.json';
import prompt_doc from '../../src/APILibs/prompt_doc.json'; 
import turbo_sdxlAPI from "../../src/APILibs/turbo_SDXL2.json";
import turboimg2img from "../../src/APILibs/turboimg2img.json";
import openaitxt2img from "../../src/APILibs/openaitxt2img.json";
import openaiimg2txt from "../../src/APILibs/openaiimg2txt.json";
import openaiimg2img from "../../src/APILibs/openaiimg2img.json";
import qwentxt2img from "../../src/APILibs/qwentxt2img.json";
import zhipuaitxt2img from "../../src/APILibs/zhiputxt2img.json";
import zhipuaiimg2txt from "../../src/APILibs/zhipuimg2txt.json";
import stabilityaitxt2img from "../../src/APILibs/stabilityaitxt2img.json";
import stabilityaiimg2img from "../../src/APILibs/stabilityaiimg2img.json";
import stabilityaimask from "../../src/APILibs/stabilityaimask.json";
import stabilityaipixel from "../../src/APILibs/stabilityaipixel.json";

// zapier apis
import zapier from '../../src/APILibs/zapier/zapier.json';
import emailservice from '../../src/APILibs/zapier/emailservice.json';
import customtrigger from '../../src/APILibs/zapier/customtrigger.json';
import senttomyemail from '../../src/APILibs/service/senttomyemail.json';
import filegenerator from '../../src/APILibs/service/filegenerator.json';
import googlemap from '../../src/APILibs/service/googlemap.json';
import translation from '../../src/APILibs/service/translation.json';
import txtreader from '../../src/APILibs/service/txtreader.json';
import txtreaderpytt from '../../src/APILibs/service/txtreaderpytt.json';
import foldermanager from '../../src/APILibs/service/foldermanager.json';
import newsgoogle from '../../src/APILibs/service/newsgoogle.json';
import topnews from '../../src/APILibs/service/topnews.json';
import juhenews from '../../src/APILibs/service/juhenews.json';
import gmailservice from '../../src/APILibs/service/gmailservice.json';
import saveworkflow from '../../src/APILibs/tools/saveworkflow.json'
import getworkflow from '../../src/APILibs/tools/getworkflow.json'
import meteomatics from '../../src/APILibs/service/meteomatics.json';
import voicerecorder from '../../src/APILibs/service/voicerecorder.json';
import writingtools from '../../src/APILibs/service/writingtools.json';
import polishing from '../../src/APILibs/service/polishing.json';
import writing from '../../src/APILibs/service/writing.json';
import wikipedia from '../../src/APILibs/service/wikipedia.json';
import  dbconnection  from "../../src/APILibs/service/dbconnection.json";
import notion from '../../src/APILibs/notion/notion.json';
import notiondb from '../../src/APILibs/notion/notion_db.json';
import notionblock from '../../src/APILibs/notion/notion_block.json';
import notionpage from '../../src/APILibs/notion/notion_page.json';
import article from '../../src/APILibs/service/article.json';

export const PagePrompt: PageP= {
    LoginTips:"您还没有登陆",
    // url:"http://localhost:8000",
    url:"https://agentlet.com:8443",
    // 我们的微信平台为，我们的app平台为
    LLMList:['Baichuan-13B (百川智能)','GLM-7B (清华大学)','Llama2-13B',"Mistral AI",'ChatGpt (OpenAI)',"Azure AI",'Qwen (通义千问)',"智谱AI(GLM-4)",
    
    "Turbo SDXL (LoRA Stable Diffusion XL)","Stability AI","ChatGpt (dall-e-3)",'通义千问',"智谱AI","Turbo SDXL","ChatGpt (dall-e-2)","Stability AI (v1-6)","Stability AI (pixel)","Stability AI (mask)","ChatGpt (dall-e-2)2","智谱AI(GLM-4V)"],
    LLMlibs :{"GLM-7B (清华大学)":glm7bAPI,"Baichuan-13B (百川智能)":baichuanAPI,"ChatGpt (OpenAI)":openaiAPI,"Qwen (通义千问)":qwenAPI,"智谱AI(GLM-4)":zhipuai,
        "Llama2-13B":llama2API,"Mistral AI":mistralAPI,"Azure AI":azureaiAPI,
        "Turbo SDXL (LoRA Stable Diffusion XL)":turbo_sdxlAPI, "Stability AI":stabilityaitxt2img,"ChatGpt (dall-e-3)":openaitxt2img,'通义千问':qwentxt2img,"智谱AI":zhipuaitxt2img,
        "ChatGpt (dall-e-2)":openaiimg2img,"Stability AI (v1-6)":stabilityaiimg2img,"Stability AI (pixel)":stabilityaipixel,"Stability AI (mask)":stabilityaimask,
        "Turbo SDXL":turboimg2img,"ChatGpt (dall-e-2)2":openaiimg2txt,"智谱AI(GLM-4V)":zhipuaiimg2txt,
    },
    
    APILibs:{
        "openaiAPI":openaiAPI,"glm7bAPI":glm7bAPI,"qwenAPI":qwenAPI,"llama2API":llama2API,"turbo_sdxlAPI":turbo_sdxlAPI,"Turbo SDXL":turboimg2img,"mistralAPI":mistralAPI,"baichuanAPI":baichuanAPI,"zhipuaiAPI":zhipuai,"azureaiAPI":azureaiAPI,

        "prompt_txt":prompt_txt,"prompt_doc":prompt_doc,"zapier":zapier,"emailservice":emailservice,"customtrigger":customtrigger,
        
        "senttomyemail":senttomyemail, "filegenerator":filegenerator,"translation":translation,"googlemap":googlemap,
        "txtreader": txtreader,"txtreaderpytt":txtreaderpytt, "foldermanager":foldermanager,"newsgoogle":newsgoogle,"topnews":topnews,"gmailservice":gmailservice, "saveworkflow":saveworkflow, "getworkflow":getworkflow,
        "juhenews":juhenews,"meteomatics":meteomatics,"voicerecorder":voicerecorder,"writingtools":writingtools,"writing":writing,"polishing":polishing,"wikipedia":wikipedia, "dbconnection":dbconnection,
        "notion":notion,"notiondb":notiondb,"notionblock":notionblock,"notionpage":notionpage,"article":article,

    },
    gpt_name_Libs:{"GLM-7B (清华大学)":"glm7b_","Baichuan-13B (百川智能)":"baichuan_","ChatGpt (OpenAI)":"openai_","Qwen (通义千问)":"qwen_",
    "Turbo SDXL (LoRA Stable Diffusion XL)":"turbo_sdxl_","Llama2-13B":"llama2_","Mistral AI":"mistral_","ChatGpt (dall-e-3)":"openai_","ChatGpt (dall-e-2)":"openai_","ChatGpt (dall-e-2)2":"openai_",'通义千问':"qwen_","智谱AI":"zhipu_","智谱AI(GLM-4)":"zhipu_","智谱AI(GLM-4V)":"zhipu_","Stability AI":"stabilityai_","Stability AI (v1-6)":"stabilityai_","Stability AI (pixel)":"stabilityai_","Stability AI (mask)":"stabilityai_"},


    cleanupCoookiesList:["temperature","max_tokens","openai_organization","prompt_txt","prompt_doc","gpt_4","memory_ST","qwen_model","img_num","memory_LT","cfg_scale","chunk_size","chunk_overlap","saved_doc"
    ,"embedding_model_selection","disable_embedding","enable_embedding","vdb_name","denoising_strength","negative_prompt","sampler_name","prompt_selection","role_setting",

    "emailservice","zapier_emailservice","emailtosent","cc","title","content","description","filename","language_selection","searchcontent","negativecontent","advancesearch","targetlanguage","searchlocation","searchtime","searchdate","newsgooglesearch"
    ,"numnews","myfiles","zapier_customtrigger","customtrigger","zapier_customaction","vectordb","attachement","voice_selection","addcustomdata","top_p","numnews","autodrive","topnewssearch","gmailuser","clientid","clientsecret","refreshtoken",
    "recipients","gmailcc","gmailtitle","gmailcontent","gmailattachement","serial_number","wf_author","wf_name","wf_icon","wf_description","wf_share","autodrive","mylocation","locationsearch","locationmark",
    "saved_pic","variation","mask","wf_icon","max_tokens","mylocation","locationsearch","locationmark","selectionListAll","typenews","numpages","mylocation","locationsearch","locationmark","juhenewssearch"
    ,"agenttype","repetition_penalty","port","serial_number","meteomatics_username","meteomatics_password","meteomatics_mylocation","meteomatics_locations","meteomatics_date","meteomatics_timeduration","meteomatics_parameters","autodrive","language",
    "saved_pic","width","height","mask","image_strength","text_prompts_weight","steps","seed","cfg_scale","style_preset","sampler","azureai_api_key","endpoint","engine","propertyList","startdate","zhipu_model","zhipuai_api_key",
    "notion_block_id","notiondb","notionblock","notionpage","notion_api_key","max_num","notion_page_id","pageupdate","newpage","notion_page_id","pageupdate","newpage","notion_db_id","dbget","dbsearch","writing_selection","polishing_selection","tool_selection","prompt_name","writing","polishing"
    ,"search_content","language","vdb_selection","ckpt","agent_name","llms_election","url_article",
    "memory_img","styles","subseed","restore_faces","tiling","n_iter","batch_size","enable_hr","hr_scale","hr_second_pass_steps","hr_resize_x","hr_resize_y","article_selection"],

    cleanupAPICoookies:["emailservice","zapier_emailservice","emailtosent","cc","title","content","description","filename","language_selection","searchcontent","negativecontent","advancesearch","targetlanguage","searchlocation","searchtime","searchdate","newsgooglesearch"
    ,"numnews","myfiles","zapier_customtrigger","customtrigger","zapier_customaction","vectordb","attachement","voice_selection","addcustomdata","top_p","tianapi_api_key","numnews","autodrive","topnewssearch","gmailuser","clientid","clientsecret","refreshtoken","qwen_api_key",
    "recipients","gmailcc","gmailtitle","gmailcontent","gmailattachement","serial_number","wf_author","wf_name","wf_icon","wf_description","wf_share","autodrive","juhe_api_key","typenews","numpages","gmap_api_key","mylocation","locationsearch","locationmark","juhenewssearch",
    "openai_api_key","saved_pic","variation","mask","wf_icon","max_tokens","gmap_api_key","mylocation","locationsearch","locationmark","selectionListAll","agenttype","repetition_penalty","port","serial_number",
    "meteomatics_username","meteomatics_password","meteomatics_mylocation","meteomatics_locations","meteomatics_date","meteomatics_timeduration","meteomatics_parameters","autodrive","language","saved_pic","stabilityai_api_key","width","height",
    "image_strength","text_prompts_weight","steps","seed","cfg_scale","style_preset","sampler","negative_prompt","azureai_api_key","endpoint","engine","propertyList","startdate","zhipu_model","zhipuai_api_key","ckpt","llms_election",
    "notion_block_id","notiondb","notionblock","notionpage","notion_api_key","max_num","notion_page_id","pageupdate","newpage","notion_page_id","pageupdate","newpage","notion_db_id","dbget","dbsearch","writing_selection","polishing_selection","tool_selection","prompt_name","writing","polishing"
    ,"search_content","language","vdb_selection","memory_img","styles","subseed","restore_faces","tiling","n_iter","batch_size","enable_hr","hr_scale","hr_second_pass_steps","hr_resize_x","hr_resize_y","agent_name","article_selection","url_article"],
    
    zapierCookiesList:["emailservice","zapier_emailservice","emailtosent","cc","title","content","description","zapier_customtrigger","customtrigger","zapier_customaction","addcustomdata"],
    senttomyemailCookiesList:["title","content","attachement"],
    filegeneratorCookiesList:["content","filename","myfiles"],
    googlemapCookiesList:["gmap_api_key","mylocation","locationsearch","locationmark","autodrive","selectionListAll"],
    translationCookiesList:["content","language_selection","autodrive"],
    txtreaderCookiesList:["content","voice_selection"],
    txtreaderpyttCookiesList:["content","voice_selection"],
    foldermanagerCookiesList:["saved_doc","vectordb"],
    newsgoogleCookiesList:["searchcontent","negativecontent","advancesearch","targetlanguage","searchlocation","searchtime","searchdate","numnews","autodrive","autodrive","newsgooglesearch"],
    topnewsCookiesList:["tianapi_api_key","numnews","autodrive","topnewssearch"],
    gmailserviceCookiesList:["gmailuser","clientid","clientsecret","refreshtoken","recipients","gmailcc","gmailtitle","gmailcontent","gmailattachement"],
    saveworkflowCookiesList:["serial_number","wf_author","wf_name","wf_icon","wf_description","wf_share"],
    getworkflowCookiesList:["serial_number"],
    juhenewsCookiesList:["juhe_api_key","typenews","numpages","autodrive","prompt_selection","juhenewssearch"],
    writingtoolsCookiesList:["writing","polishing","writing_selection","polishing_selection","tool_selection","prompt_name"],
   
    wikipediaCookiesList:["search_content","language","prompt_txt"],
    dbconnectionCookiesList:["vdb_selection"],
    openaiimg2imgCookiesList:["openai_api_key","saved_pic","variation","mask","wf_icon"], 
    openaitxt2imgCookiesList:["openai_api_key","saved_pic"],
    openaiimg2txtCookiesList:["openai_api_key","saved_pic","max_tokens","wf_icon"],
    qwentxt2imgCookiesList:["qwen_api_key","saved_pic"], 
    zhipuaitxt2imgCookiesList:["zhipuai_api_key","saved_pic"],  
    stabilityaitxt2imgCookiesList:["stabilityai_api_key","saved_pic","steps","width","height","seed","cfg_scale","style_preset","negative_prompt"], 
    stabilityaiimg2imgCookiesList:["stabilityai_api_key","saved_pic","image_strength","steps","width","height","seed","cfg_scale","style_preset","negative_prompt"], 
    stabilityaipixelCookiesList:["stabilityai_api_key","saved_pic","width","height"], 
    stabilityaimaskCookiesList:["stabilityai_api_key","saved_pic","mask","image_strength","text_prompts_weight","steps","seed","cfg_scale","style_preset","sampler","negative_prompt"], 

    articleCookiesList:["article_selection","url_article"],
    meteomaticsCookiesList:["meteomatics_username","meteomatics_password","meteomatics_mylocation","meteomatics_locations","meteomatics_date","meteomatics_timeduration","meteomatics_parameters","autodrive","language"], 
    notionCookiesList:["notiondb","notionblock","notionpage","max_num","pageupdate","newpage","pageupdate","newpage","dbget","dbsearch"],//"propertyList"
    
    newswebsupportList:["千家网","科学网","cnBeta","中财网","观察者","证券之星","新华网","中工网","中国经济网","cq.chinanews.com.cn",
    "上海证券报","China Daily","中国新闻网","新京报","Glossy","中华网","新浪","央视网","大公网","中国网",
    "Wenxuecity","澎湃新闻","Sohu","南方网","人民网","CNN","ABC News","CNBC","Business Insider","Fortune",
    "焦点新闻","FX168财经","美国之音中文网 - VOA Mandarin","politics.people.com.cn","中国教育在线","万维读者网","证券时报","第一财经","新闻"
    ,"黑龙江日报","华商网","凤凰网","四川在线","深圳新闻网","上海东方网","广州日报大洋网"],

    infoList:['* 该平台为粒子智算的LLM和Agent网页测试平台','* 我们将根据唯一的用户名来存储每位用户的账户数据和LLM设置，用户的API数据默认为用户本地保存，取得用户授权后，可保存于本服务器云端。','* 本平台支持Agent定制服务，AIPC定制，企业达大模型部署等服务，请联系我们或者给我们留言。','* 谢谢您的关注和支持，祝您拥有美好的一天。'],
    statisticsList:[{'平台容易使用':50},{'提升工作效率和质量':50},{'完成搜索和文本查找任务':50},{'完成写作初稿和总结提炼任务':50},{'提高质量和创造力':50},{'有助于专注时间':50},{'完成电子邮件任务':50},{'会一直使用':50},{'会推荐给周围朋友':50}],
    AgentList:[[['*添加agent方式：输入"添加谷歌新闻",或点击谷歌新闻, 在流程图中查看','谷歌新闻','谷歌地图','天气预报','中华网新闻',"聚合数据新闻","维基百科搜索"],[],['谷歌翻译','文本阅读gTTS','文本阅读pytt',"论文分析"],['Zapier','谷歌邮箱','Notion'],[],[]],
                ['*添加agent方式：点击图标, 在流程图中查看',]],
    stopIndexDict:{"ChatGpt (OpenAI)":9,"GLM-7B (清华大学)":8,"Baichuan-13B (百川智能)":8,"Qwen (通义千问)":9,"Llama2-13B":8,"Mistral AI":8,"Azure AI":12,
                "Turbo SDXL (LoRA Stable Diffusion XL)":21, "Turbo SDXL":7,"ChatGpt (dall-e-3)":0,"ChatGpt (dall-e-2)":0,"ChatGpt (dall-e-2)2":0,'通义千问':0,
                "translation":3,"saveworkflow":6,"foldermanager":0, "filegenerator":2,"googlemap":4,"newsgoogle":7,"gmailservice":8,"topnews":7,"senttomyemail":2,"txtreader":1,"txtreaderpytt":1,"voicerecorder":0,"writingtools":3,"wikipedia":3,"dbconnection":0,
                "zapier":2,"zapier_emailservice":5,"zapier_customtrigger":2,"zapier_customaction":0,"meteomatics":7, "juhenews":7,"Stability AI":7,"Stability AI (v1-6)":8,"Stability AI (pixel)":3,"Stability AI (mask)":10,"智谱AI":0,"智谱AI(GLM-4)":7,"智谱AI(GLM-4V)":4,
                "Notion":5,"notiondb":3,"notionblock":3,"notionpage":3,"article":3},
    ISO_639code:{"南非荷兰语":"af",
                "阿尔巴尼亚语":"sq",
                "阿姆哈拉语":"am",
                "阿拉伯语":"ar",
                "亚美尼亚文":"hy",
                "阿萨姆语":"as",
                "艾马拉语":"ay",
                "阿塞拜疆语":"az",
                "班巴拉语":	"bm",
                "巴斯克语":	"eu",
                "白俄罗斯语":"be",
                "孟加拉文":	"bn",
                "博杰普尔语":	"bho",
                "波斯尼亚语":	"bs",
                "保加利亚语":	"bg",
                "加泰罗尼亚语":	"ca",
                "宿务语":	"ceb",
                "中文（简体）":	"zh-CN",
                "中文（繁体）":	"zh-TW",
                "科西嘉语":	"co",
                "克罗地亚语":"hr",
                "捷克语":	"cs",
                "丹麦语":	"da",
                "迪维希语":	"dv",
                "多格来语":	"doi",
                "荷兰语":	"nl",
                "英语":	"en",
                "世界语":	"eo",
                "爱沙尼亚语":	"et",
                "埃维语":	"ee",
                "菲律宾语（塔加拉语）":	"fil",
                "芬兰语":	"fi",
                "法语":	"fr",
                "弗里斯兰语":	"fy",
                "加利西亚语":	"gl",
                "格鲁吉亚语":	"ka",
                "德语":	"de",
                "希腊文":	"el",
                "瓜拉尼人":	"gn",
                "古吉拉特文":	"gu",
                "海地克里奥尔语":	"ht",
                "豪萨语":	"ha",
                "夏威夷语":	"haw",
                "希伯来语":	"he",
                "印地语":	"hi",
                "苗语":	"hmn",
                "匈牙利语":	"hu",
                "冰岛语":	"is",
                "伊博语":	"ig",
                "伊洛卡诺语":	"ilo",
                "印度尼西亚语":	"id",
                "爱尔兰语":	"ga",
                "意大利语":	"it",
                "日语":	"ja",
                "爪哇语":	"jv",
                "卡纳达文":	"kn",
                "哈萨克语":	"kk",
                "高棉语":	"km",
                "卢旺达语":	"rw",
                "贡根语":	"gom",
                "韩语":	"ko",
                "克里奥尔语":	"kri",
                "库尔德语":	"ku",
                "库尔德语（索拉尼）":	"ckb",
                "吉尔吉斯语":	"ky",
                "老挝语":	"lo",
                "拉丁文":	"la",
                "拉脱维亚语":	"lv",
                "林格拉语":	"ln",
                "立陶宛语":	"lt",
                "卢干达语":	"lg",
                "卢森堡语":	"lb",
                "马其顿语":	"mk",
                "迈蒂利语":	"mai",
                "马尔加什语":	"mg",
                "马来语":	"ms",
                "马拉雅拉姆文":	"ml",
                "马耳他语":	"mt",
                "毛利语":	"mi",
                "马拉地语":	"mr",
                "梅泰语（曼尼普尔语）":	"mni-Mtei",
                "米佐语":	"lus",
                "蒙古文":	"mn",
                "缅甸语":	"my",
                "尼泊尔语":	"ne",
                "挪威语":	"no",
                "尼杨扎语（齐切瓦语）":	"ny",
                "奥里亚语（奥里亚）": "or",
                "奥罗莫语":	"om",
                "普什图语":	"ps",
                "波斯语":	"fa",
                "波兰语":	"pl",
                "葡萄牙语":	"pt",
                "旁遮普语":	"pa",
                "克丘亚语":	"qu",
                "罗马尼亚语":	"ro",
                "俄语":	"ru",
                "萨摩亚语":	"sm",
                "梵语":	"sa",
                "苏格兰盖尔语":	"gd",
                "塞佩蒂语":	"nso",
                "塞尔维亚语":	"sr",
                "塞索托语":	"st",
                "修纳语":	"sn",
                "信德语":	"sd",
                "僧伽罗语":	"si",
                "斯洛伐克语":	"sk",
                "斯洛文尼亚语":	"sl",
                "索马里语":	"so",
                "西班牙语":	"es",
                "巽他语":	"su",
                "斯瓦希里语":	"sw",
                "瑞典语":	"sv",
                "塔加路语（菲律宾语）":	"tl",
                "塔吉克语":	"tg",
                "泰米尔语":	"ta",
                "鞑靼语":	"tt",
                "泰卢固语":	"te",
                "泰语":	"th",
                "蒂格尼亚语":	"ti",
                "宗加语":	"ts",
                "土耳其语":	"tr",
                "土库曼语":	"tk",
                "契维语（阿坎语）":	"ak",
                "乌克兰语":	"uk",
                "乌尔都语":	"ur",
                "维吾尔语":	"ug",
                "乌兹别克语":	"uz",
                "越南语":	"vi",
                "威尔士语":	"cy",
                "班图语":	"xh",
                "意第绪语":	"yi",
                "约鲁巴语":	"yo",
                "祖鲁语":	"zu",
                },
    prompt_txt_index:{'ChatGpt (OpenAI)':9,'GLM-7B (清华大学)':8,'Baichuan-13B (百川智能)':8,'Qwen (通义千问)':9,'Llama2-13B':8,"Mistral AI":8,"Turbo SDXL (LoRA Stable Diffusion XL)":0},
    meteo_txt:{"w0":"未知","w1":"晴","w2":"局部多云","w3":"多云","w4":"阴天","w5":"雨","w6":"雨雪/雨夹雪","w7":"雪","w8":"阵雨","w9":"阵雪","w10":"雨夹雪","w11":"轻雾/霭","w12":"浓雾","w13":"冻雨","w14":"雷暴","w15":"毛毛雨","w16":"沙暴",
    "w101":"晴","w102":"局部多云","w103":"多云","w104":"阴天","w105":"雨","w106":"雨雪/雨夹雪","w107":"雪","w108":"阵雨","w109":"阵雪","w110":"雨夹雪","w111":"轻雾/霭","w112":"浓雾","w113":"冻雨","w114":"雷暴","w115":"毛毛雨","w116":"沙暴"},
    rolerprompt:[[["请你扮演一个考古学家","你在中东地区进行过多次重要的考古发掘，拥有 15 年的现场经验。","你擅长研究古代文明与宗教的交融，曾为多部纪录片提供专业见解。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个舞台剧导演。","你曾在百老汇工作了 20 年，导演过多部经典音乐剧。","你擅长通过现代手法重新诠释古典作品，使其更贴近现代观众。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个国际律师。","你在国际法庭上为多国提供法律咨询，具有 15 年的跨国案件处理经验。","你专攻国际人权法，曾成功为多名政治犯辩护。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个古物鉴定师。","你在著名拍卖行工作超过 25 年，鉴定过无数的艺术品和古物。","你擅长亚洲陶瓷艺术，曾为国家博物馆提供鉴定服务。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个神经外科医生。","你在国际知名医院工作了 20 年，进行过上百次脑部手术。","你擅长使用微创技术进行神经外科手术，有多篇相关研究发表在顶级医学期刊。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个航空工程师。","你为某知名航空公司工作，设计过多种商业飞机。","你擅长使用新型材料和技术优化飞机性能，为航空业带来革命。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个旅行作家。","你游历过 100 多个国家，出版了数十本旅行随笔。","你善于从文化和历史的角度描述一个地方，带领读者深入体验。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个花艺师。","你在巴黎接受花艺专业培训，设计过多场国际花展。","你擅长用花朵表达情感，为众多婚礼和场合提供定制花艺。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个电竞选手。","你是某知名游戏的全球冠军，赢得过多次国际大赛。","你在团队合作和策略执行上有独到之处，是队伍中的关键角色。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个宇航员。","你曾两次参与国际空间站任务，总共在太空停留超过 200 天。","你负责过多次太空漫步，为太空科研做出了重要贡献。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个鸟类学家。","你在亚马逊雨林开展鸟类调查，记录了超过 500 种鸟类的生活习性。","你对热带鸟类的迁徙和繁殖有深入的研究。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个飞行员。","你是国际长途航班的资深飞行员，飞行里程超过 1 百万公里。","你擅长在恶劣气候条件下安全驾驶，多次获得表彰。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个室内设计师。","你拥有 25 年的设计经验，曾为多位名人设计豪宅。","你擅长融合现代与传统元素，创造出宁静而高雅的空间。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个项目经理。","你在 IT 行业管理了超过 50 个跨国项目，有 15 年的项目管理经验。","你擅长高效协调团队资源，确保项目按时按质完成。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个财务分析师。","你为一家全球 500 强企业工作，负责年度财务预测与分析。","你对市场趋势有独到见解，为公司决策提供关键数据支持。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个人事经理。","你拥有 20 年的人力资源管理经验，为多家跨国公司选拔和培养了高层领导。","你熟悉各种招聘策略和员工培训方案。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个销售经理。","你在医疗设备行业销售了 20 年，是多次销售冠军。","你擅长建立客户关系和谈判，带领团队多次完成销售目标。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个数据分析师。","你在电商平台工作，利用数据驱动的策略提升了销售额 30%。","你精通 Python 和 R 语言，擅长进行数据挖掘和预测。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个小说家。","你已出版了 5 部畅销小说，其中两部被改编为电影。","你擅长心理悬疑题材，能深入挖掘人性的复杂面。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个新闻记者。","你在全球性新闻机构工作了 15 年，多次报道重大国际事件。","你有出色的采访技巧和敏锐的新闻嗅觉。","请根据这个身份，为我撰写一篇文案。"],
    ["请你扮演一个食品评论家。","你为数十家美食杂志和博客写过文章，是美食界的权威人士。","你有敏锐的味蕾，能准确描述食物的味道和质感。","请根据这个身份，为我撰写一篇文案。"]
    ],
    [[" Please play the role of an archaeologist.","You've conducted several major archaeological excavations in the Middle East and have 15 years of field experience." , "You specialize in the study of the intersection of ancient civilizations and religions, and have provided expert insights for several documentaries." , "Please write a piece of copy for me based on this identity."],
    [" Please play the role of a stage director." , "You worked on Broadway for 20 years and have directed several classic musicals." , "You specialize in reinterpreting classical works through modern techniques to make them more relevant to modern audiences." , "Please write a copy for me based on this identity."],
    [" Please play the role of an international lawyer." , "You have 15 years of experience handling transnational cases by providing legal advice to multiple countries before international tribunals." , "You specialize in international human rights law and have successfully defended a number of political prisoners." , "Please write a copy for me in this capacity."],
    [" Please play the role of an antiquities appraiser." ,"You've worked at a prestigious auction house for over 25 years and have appraised countless works of art and antiquities." , "You specialize in Asian ceramic art and have provided appraisal services to national museums." , "Please write a copy for me based on this identity."],
    [" Please play the role of a neurosurgeon." , "You have worked for 20 years in internationally recognized hospitals and have performed hundreds of brain surgeries." , "You specialize in neurosurgery using minimally invasive techniques and have several related studies published in top medical journals." , "Please write a copy for me based on this identity."],
    [" Please play the role of an aeronautical engineer." , "You work for a well-known airline and have designed a variety of commercial airplanes." , "You specialize in using new materials and technologies to optimize aircraft performance and revolutionize the aviation industry." , "Please write a copy for me based on this identity."],
    [" Please play the role of a travel writer." ,"You've traveled to more than 100 countries and published dozens of travel essays." , "You're good at describing a place from a cultural and historical point of view and taking the reader deeper into the experience." , "Write me a piece of copy based on this identity."],
    [" Please play the role of a florist." , "You trained professionally in floristry in Paris and have designed several international flower shows." , "You specialize in expressing emotions with flowers and have provided custom floral arrangements for numerous weddings and occasions." , "Please write a copy for me based on this identity."],
    [" Please play the role of a video game player." , "You are the global champion of a well-known game and have won several international competitions." , "You are unique in teamwork and strategy execution and are a key player in the team." , "Please write a copy for me based on this identity."],
    [" Please play the role of an astronaut.", "You have participated in two missions to the International Space Station, spending a total of more than 200 days in space." , "You have been responsible for many spacewalks and have made significant contributions to space research." , "Please write a copy for me based on this identity."],
    [" Please play the role of an ornithologist." , "You have conducted bird surveys in the Amazon rainforest and documented the habits of over 500 species of birds." , "You have conducted in-depth research on the migration and reproduction of tropical birds." , "Please write a piece of copy for me based on this identity."],
    [" Please play the role of a pilot.", "You're a veteran pilot of long-haul international flights, with over 1 million kilometers flown." , "You specialize in safe driving in adverse weather conditions and have received numerous awards." , "Please write a copy for me based on this identity."],
    [" Please play the role of an interior designer." , "You have 25 years of design experience and have designed luxury homes for several celebrities." , "You specialize in blending modern and traditional elements to create serene and elegant spaces." , "Please write a copy for me based on this identity."],
    ["Please play the role of a project manager." , "You have managed over 50 multinational projects in the IT industry and have 15 years of project management experience." , "You specialize in efficiently coordinating team resources to ensure that projects are completed on time and on quality." , "In this capacity, please write a copy for me."],
    [" Please play the role of a financial analyst." , "You work for a global Fortune 500 company and are responsible for annual financial forecasting and analysis." , "You have insights into market trends and provide key data to support company decisions." , "In this capacity, please write a copy for me."],
    [" Please play the role of a human resources manager." , "You have 20 years of experience in human resource management and have selected and developed senior leaders for several multinational companies." , "You are familiar with various recruitment strategies and employee training programs." , "Please write a copy for me based on this identity."],
    [" Please play the role of a sales manager." , "You've been selling in the medical device industry for 20 years and are a multiple sales champion." , "You excel at building customer relationships and negotiating, and have led your team to multiple sales targets." , "Please write a copy for me based on this identity."],
    [" Please play the role of a data analyst." , "You work for an e-commerce platform and have boosted sales by 30% using data-driven strategies." , "You are fluent in Python and R and specialize in data mining and forecasting." , "Please write a copy for me based on this identity."],
    [" Please play the role of a novelist." , "You have published five best-selling novels, two of which have been adapted into movies." , "You specialize in psychological suspense and can dig deep into the complexity of human nature." , "Please write a copy for me based on this identity."],
    [" Please play the role of a journalist." ,"You've worked for a global news organization for 15 years and have covered major international events." ,"You have excellent interviewing skills and a keen sense of journalism." , "Please write a copy for me in this capacity."],
    [" Please play the role of a food critic." , "You've written for dozens of food magazines and blogs and are an authority on food." , "You have keen taste buds and can accurately describe the flavors and textures of food." , "Please write a piece of copy for me based on this identity."],
    ]
    ]
};
 
interface PageP {
    LoginTips:string,
    url:string,
    LLMList:Array<string>,
    cleanupCoookiesList:Array<string>,
    cleanupAPICoookies:Array<string>,
    infoList:Array<string>,
    zapierCookiesList:Array<string>,
    senttomyemailCookiesList:Array<string>,
    filegeneratorCookiesList:Array<string>,
    googlemapCookiesList:Array<string>,
    translationCookiesList:Array<string>,
    txtreaderCookiesList:Array<string>,
    txtreaderpyttCookiesList:Array<string>,
    foldermanagerCookiesList:Array<string>,
    newsgoogleCookiesList:Array<string>,
    topnewsCookiesList:Array<string>,
    gmailserviceCookiesList:Array<string>,
    saveworkflowCookiesList:Array<string>,
    getworkflowCookiesList:Array<string>,
    juhenewsCookiesList:Array<string>,
    writingtoolsCookiesList:Array<string>,
    articleCookiesList:Array<string>,
    wikipediaCookiesList:Array<string>,
    dbconnectionCookiesList:Array<string>,
    openaiimg2imgCookiesList:Array<string>,
    openaitxt2imgCookiesList:Array<string>,
    openaiimg2txtCookiesList:Array<string>,
    qwentxt2imgCookiesList:Array<string>,
    zhipuaitxt2imgCookiesList:Array<string>,
    stabilityaitxt2imgCookiesList:Array<string>,
    stabilityaiimg2imgCookiesList:Array<string>,
    stabilityaipixelCookiesList:Array<string>,
    stabilityaimaskCookiesList:Array<string>,
    meteomaticsCookiesList:Array<string>,
    notionCookiesList:Array<string>,
    newswebsupportList:Array<string>,
    statisticsList:Array<object>,
    AgentList:Array<any>,
    stopIndexDict:{[key:string]:number},
    ISO_639code:{[key:string]:string},
    prompt_txt_index:{[key:string]:number},
    LLMlibs:{[key:string]:any},
    APILibs:{[key:string]:any},
    gpt_name_Libs:{[key:string]:string},
    meteo_txt:{[key:string]:string},
    rolerprompt:Array<any>,
}


