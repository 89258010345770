import React from "react";
import './Popup.css'
import close from '../../src/assets/img/toolsImage/close.png'

interface DownLImgBoxProps{
    dialogWidth?:string
    trigger_img?:boolean
    setTrigger_img:Function
    src_img:string
    height_l:string
    index:number
}

const DownLImgBox: React.FC<DownLImgBoxProps> = (props) => {
    return (props.trigger_img)?(
        <div className='popup-img'>
            <div className='popup-inner-img'>
                <div className='popup-close'><img src={close} onClick={()=>props.setTrigger_img(false,props.index)} className="rounded me-2" width="20" alt="" /></div>
                <img className='popup-body-img' src={props.src_img} style={{width:props.height_l}}  alt="" /> 
            </div>
        </div>
    ): <div></div>;
}
    
    
export default DownLImgBox;