import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import MsgBox from "../../tools/msgBox";
import './chatBox.css'
import axios from 'axios'
import { PagePrompt } from "../../configs/PagePrompt";
import { WorkflowPrompt } from "../../configs/flowPrompt";
import microphone from "../../assets/img/toolsImage/microphone.png"
import stop from "../../assets/img/toolsImage/stop.png"


import SetupCookies from '../../tools/setupCookies';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { WorkflowPlan, WorkflowExecute} from "../../hooks/workflowPlan";


// import Gemini_txt_prompts from "../../tools/gemini/gemini";
export interface ChatBoxProps {
  count?:Number,
  username?:string,
  loginCheckapp:boolean,
  setloginCheckappFunc:Function
  selectedLLM:string,
  selectparsList:[],
  parsconfirm:boolean,
  setparsconfirm:Function,
  enable_memory_ST:any,
  workFlowAdd_on: Function,
  boxwidth:number,
  fillin_txt:any,
  fillin_file:any,
  generatemsgBox:any,
  setGeneratemsgBox:Function,
  setinfoFunc:Function,
  waitinfoFunc:Function,
  workFlowAddList:any,
  autodrivePars:any,
  newscontent:[],
  microphoneTrigger:number, 
  setMicrophoneTrigger:Function

}
var inputHistory=new Array(0);
var STmemoryList=new Array(0);
var LLMparslist = new Array(0);
var LLMparslist_h = new Array(0);
let APIdata: Object;

var inputHistory_index=0;
const LLMlibs :{[key:string]:any} = PagePrompt.LLMlibs
class ChatBox extends React.Component <ChatBoxProps, object>  {
  constructor(props:any) {
    super(props);
    const currentDate = new Date();
    this.state = {
      content: "",
      list: [
      {'sender':'LLM_Agent','message':'您好，请问有什么需要我服务？','date':currentDate}] ,
      username: props.username || "小D",
      wf: props.generatemsgBox,
      sent_message_h:2.5,
      increase_or_decrease:0,
      autodrivePars:props.autodrivePars,
      Myworkflow:[],
      newscontent:props.newscontent
    };
    this.handleCountChange = this.handleCountChange.bind(this);
 }

  // componentDidMount() {
  //   console.log("trigger")
  //   console.log((this.state as any).content)
  //       // if((this.state as any).content !== ''){
  //       //   this.handleClick()
  //       // }
  // }

  // componentWillReceiveProps(props:any) {
  //   if(this.props.generatemsgBox !== ""){
  //     this.generatereplymsgbox("LLM_Agent",this.props.generatemsgBox,[])
  //     this.props.setGeneratemsgBox("")
  //   }
  // } 
  

  resize_inputBox() {
    if((this.state as any).increase_or_decrease === 0){
      this.setState({
        ...this.state,
        sent_message_h:(this.state as any).sent_message_h*2,
        increase_or_decrease: (this.state as any).sent_message_h*2 >= 10? 1:0
      });
      
    }else{
      this.setState({
        ...this.state,
        sent_message_h:(this.state as any).sent_message_h/2,
        increase_or_decrease: (this.state as any).sent_message_h/2 <= 2.5? 0:1
      });
    }

  }

  static getDerivedStateFromProps(props:any, state:any) {
    if(props.generatemsgBox !== state.wf){
      if(props.generatemsgBox !== ""){
        let currentDate = new Date()
        let msgItem
        if(props.generatemsgBox.slice(0,9) === "username:"){ //case microphne 
          // msgItem={'sender':props.username,'message':props.generatemsgBox.slice(9,props.generatemsgBox.length),'date':currentDate,'button':[]};
          
          return {content:props.generatemsgBox.slice(9,props.generatemsgBox.length),
                  wf: props.generatemsgBox,
                  };
        }
        // else if(props.generatemsgBox.slice(0,9) === "username:"){ // case autodrive 
        //   msgItem={'sender':props.username,'message':props.generatemsgBox.slice(9,props.generatemsgBox.length),'date':currentDate,'button':[]};
        //   props.setGeneratemsgBox("")
        //   return {content:"",
        //           list: [...(state as any).list,msgItem],
        //           wf: props.generatemsgBox,
        //           };
        // }
        else{
          msgItem={'sender':"LLM_Agent",'message':props.generatemsgBox,'date':currentDate,'button':[]};
          props.setGeneratemsgBox("")
          return {content:"",
                  list: [...(state as any).list,msgItem],
                  wf: props.generatemsgBox,
                  };
        }
      }else{return {wf: props.generatemsgBox};}
      
    }else if(props.autodrivePars !== state.autodrivePars){
      return {Myworkflow: props.autodrivePars? state.Myworkflow:[],
              autodrivePars:props.autodrivePars};
    }
    else if(props.newscontent !== state.newscontent){
      return {newscontent:props.newscontent}
    }
    else{
      return null
    }
  }


  handleCountChange(message:string) {
    this.setState({
      content:message
    });
  }

  handleClick() {
    this.sentMsg();
    let txt = (this.state as any).content.toLowerCase()
    WorkflowPrompt.keywords.forEach((elem,index) => {
      if(txt.indexOf(elem) !== -1){
        this.props.workFlowAdd_on(WorkflowPrompt.response[index])
      }
    });
  }

  generatereplymsgbox(sender:string,message:string,button:string[]){
    let currentDate = new Date()
    const msgItem={'sender':sender,'message':(message || " "),'date':currentDate,'button':button};
    this.setState({
      ...this.state,
      content:"",
      list: [...(this.state as any).list,msgItem]
    });
  }

  async HttpPost_msg(url:string,HttpBody:{},items:any = ""){
      if(this.props.selectedLLM ===  "Turbo SDXL (LoRA Stable Diffusion XL)"){
        this.props.waitinfoFunc(true,120000)
      }else{this.props.waitinfoFunc(true)}
      
      return await axios.post(`${PagePrompt.url}/llm/${url}`,HttpBody).then(
        async response => {
          
          this.props.waitinfoFunc(false)
          if(response.data.message === '未登录'){
            this.props.setinfoFunc("为了您账户的安全，请尝试重新登陆后操作。","2000")
            this.props.setloginCheckappFunc(false,false)
            return false
          }
          else if(url === "sendmsg"){
            
            if(typeof items === "string" && items.substring(0,15).includes("content_before:")){
              this.generatereplymsgbox(response.data.data.sender,items.substring(15,items.length)+"\n"+response.data.data.message,[])
            }
            else{
              this.generatereplymsgbox(response.data.data.sender,response.data.data.message,[])
              console.log(response.data.data)
            }
            
            if(this.props.enable_memory_ST >0){
              STmemoryList = [
                ...STmemoryList,
                {"role": "user", "content": (HttpBody as any).content.message},
                {"role": "assistant", "content": response.data.data.message},
              ]
              if(STmemoryList.length> 2*this.props.enable_memory_ST){
                STmemoryList.splice(0,2);
              }
            }            
          }
          return response.data.data.message
        },
        async error => {
          this.props.waitinfoFunc(false)
          console.log(error.message.errono)
          return
        }
      ).catch(function (error){console.log(error);return})
    
  }
  
  
  getLLMpars(selectedLLM:string){
    LLMparslist_h=[]
    LLMparslist=[]
    let code = 0
    if(PagePrompt.LLMList.includes(selectedLLM)){    
      ((APIdata as any).APIpars).forEach( async (element:any,index:number) => { 
        let getcookies 
        getcookies=SetupCookies(element.parsName,"","get")
        
        if(getcookies && (Object.values(this.props.selectparsList[index])[0] === true  || this.props.workFlowAddList.includes("getworkflow"))  ){
          if(element.rule !== "" && typeof(element.rule) === "object"){
            if(getcookies< element.rule[0]|| getcookies>element.rule[1]){
              this.generatereplymsgbox("LLM_Agent",`请输入正确数值，${element.parName_ch}范围为${element.rule[0]}-${element.rule[1]}`,["help"])
              code = -1
            }else{
              LLMparslist.push(getcookies) 
              LLMparslist_h.push(getcookies)
            }
          }
          else if(element.rule !== "" && typeof(element.rule) === "string"){
            if(!RegExp(element.rule).test(getcookies)){
              this.generatereplymsgbox("LLM_Agent","请输入正确的密钥",["help"])
              code = -1
            }else{
              LLMparslist.push(getcookies) 
              LLMparslist_h.push(getcookies)
            }
          }else{
            LLMparslist.push(getcookies) 
            LLMparslist_h.push(getcookies)
          }
                 
        }else if(Object.values(this.props.selectparsList[index])[0] === true){
          LLMparslist.push("true")
          LLMparslist_h.push("true")
        }else if(getcookies && ["prompt_selection","saved_pic","mask","variation"].includes(element.parsName)){
          LLMparslist.push(getcookies);LLMparslist_h.push(getcookies) }
        else{
          LLMparslist.push("")
          if((APIdata as any).defaultPras[index] === ""){
            //返回缺少必须参数
            this.generatereplymsgbox("LLM_Agent","请输入"+element.parsName,["help"])
            code = -1
          }
          else{LLMparslist_h.push((APIdata as any).defaultPras[index])}
          
        }
      });
      
    }
    return code
  }

  checkselectpars(List:[],parsconfirm:boolean) {
    //make sure each selected pars in different group
    let retrunmsg=""
    if (PagePrompt.LLMList.includes(this.props.selectedLLM)){    
      let dupicateList:any[]=[];
      let sublist:string[]=[];
      let subindex:number = -1;
      let passby = 0;
      ((APIdata as any).APIpars).forEach((element:any,index:number )=> {
        if(Object.values(List[index])[0] === true && element.group !== subindex ){
          sublist=[Object.keys(List[index])[0]]
          subindex = element.group
          passby =1
        }else if(Object.values(List[index])[0] === true){
          sublist.push(Object.keys(List[index])[0]) 
          passby =1
        }
        else if (element.group !== subindex){
          if (sublist.length > 1){
            dupicateList.push(sublist)
          }
          sublist=[]
        }else{
          subindex = element.group
          sublist=[]
        }

        if (sublist.length > 1 && (APIdata as any).APIpars.length <= index+1){
          dupicateList.push(sublist)
        }
        // check format/ required APIpars_list true 
        if(Object.values(this.props.selectparsList[index])[0] === true){
          let getcookies = SetupCookies(element.parsName,"","get")
          // document.getElementById('item_input'+ element.parsName)?.innerHTML getattributes not working
          if(element.rule !== ""){
            if(getcookies){
              if(typeof(element.rule) === "object"){
                if(getcookies< element.rule[0]|| getcookies>element.rule[1]){
                  if(retrunmsg === ""){retrunmsg += `(${(APIdata as any).APIname}): \n`}
                  retrunmsg +=`请输入正确数值, ${element.parName_ch},范围为${element.rule[0]}-${element.rule[1]}, 然后点击确认按钮[${index}] \n`
                }
              }
              else if( typeof(element.rule) === "string"){
                if(!RegExp(element.rule).test(getcookies)){
                  if(retrunmsg === ""){retrunmsg += `(${(APIdata as any).APIname}): \n`}
                  retrunmsg +=`请输入正确的密钥, 然后点击确认按钮[${index}] \n`
                }
              }
            }else{
              if(retrunmsg === ""){retrunmsg += `(${(APIdata as any).APIname}): \n`}
              retrunmsg += `请输入: ${element.parName_ch}, 然后点击确认按钮[${index}] \n`
            }
          }else{
            if(!getcookies){
              if(retrunmsg === ""){retrunmsg += `(${(APIdata as any).APIname}): \n`}
              retrunmsg += `请输入: ${element.parName_ch}, 然后点击确认按钮[${index}] \n`
            }
          }
          
        }

      });


      if(dupicateList.length>0 || retrunmsg !== ""){
        dupicateList.forEach((element:any) => {
          retrunmsg+='请在 ' + element + ' 中选择其一 \n'
        });
        return [1,retrunmsg]
      }
      else{
        
        // confirm pars settement
        let confirmmsg = `请确认您勾选的参数设置信息(${(APIdata as any).APIname}): \n`
        
        if(passby === 1){
          List.forEach((item,index)=>{
            if (Object.values(item)[0] === true){
              confirmmsg +=  (APIdata as any).APIpars[index].parName_ch+`[${index}]`+" : " + (SetupCookies(Object.keys(item)[0],"","get")?  SetupCookies(Object.keys(item)[0],"","get"):'')   + "\n"
            }
          })
          return [2,confirmmsg]
        }
        else{return [0,""]}
        
      }
      
    }
    
    return [0,""]
  } 

  didsendMsg(){
    let currentDate = new Date()
    
    const msgItem={'sender':(this.state as any).username,'message':(this.state as any).content,'date':currentDate,"button":[]};
    this.setState({
        ...this.state,
        content:"",
        list: [...(this.state as any).list,msgItem]
      });
  }

  async excute(Myworkflow:any,props2:object,msgItem_h:any,HttpBody:any){
    let output = ""
    let props1:{[key:string]:any} = props2
    for (const element of Myworkflow) {
      if(element.url  !== "llm/sendmsg"){   
        props1["obj"] = element
        props1["output"] = output
        this.props.waitinfoFunc(true,"100000")
        await WorkflowExecute(props1).then(result =>{
          this.props.waitinfoFunc(false)
          if(result){
            this.generatereplymsgbox(result[0],result[1],[])
          }
        })
      }
      else{
        if(output !== ""){
              HttpBody = {...HttpBody,'content':output}
          }
          output = await this.HttpPost_msg("sendmsg",HttpBody,msgItem_h).then( async result =>
          {
            if(result){
                return result
            }
          }
        )
        
      }

    }
  }
  
  async sentMsgNext(){
    let HttpBody_init= {'username':(this.state as any).username,uuid:SetupCookies("uuid","","get"),session_id:SetupCookies("session_id","","get")}
    
    let currentDate = new Date()
    let code = this.getLLMpars(this.props.selectedLLM)
    let memory_Http :any
    if(code === 0){
      const msgItem_h={'sender':(this.state as any).username,'message':(this.state as any).content,'date':currentDate};
      this.didsendMsg()
      let HttpBody: {[key:string]:any}={}
      if([ "Turbo SDXL (LoRA Stable Diffusion XL)",'Turbo SDXL'].includes(this.props.selectedLLM) ){
        LLMparslist_h.push(Math.floor(Math.random() * 1000000).toString().padStart(6, '0'))
      }else{
        let ST_index = 6
        if(["ChatGpt (OpenAI)","Qwen (通义千问)"].includes(this.props.selectedLLM)){ ST_index = 7}
        else if(["Azure AI"].includes(this.props.selectedLLM)){ST_index = 9}

        if(this.props.enable_memory_ST >0 && (Object.values((this.props.selectparsList as any)[ST_index])[0] === true) || this.props.workFlowAddList.includes("getworkflow") ){memory_Http=STmemoryList}else{memory_Http=[]}
      }

      HttpBody = {...HttpBody_init,'content':msgItem_h,'updatetime':currentDate, selectedLLM:this.props.selectedLLM, LLMpars:LLMparslist_h, memory_ST:memory_Http};
      if(this.props.selectedLLM){
        if((this.state as any).autodrivePars && this.props.selectedLLM !== "Turbo SDXL (LoRA Stable Diffusion XL)"){
          if((this.state as any).Myworkflow.length === 0 ){ 
            let props1={HttpBody_init:HttpBody_init,autodrivePars:(this.state as any).autodrivePars,HttpBody:HttpBody}
            let Myworkflow = WorkflowPlan(props1)
            this.setState({
              ...this.state,
              Myworkflow:Myworkflow,
            });
            // didupdate run multiple times
            let autodriveParskeys = Object.keys((this.state as any).autodrivePars)
            if(!autodriveParskeys.includes("txtreader")){
              let props2={HttpBody_init:HttpBody_init,input:HttpBody.content.message,setinfoFunc:this.props.setinfoFunc}
              this.excute(Myworkflow,props2,msgItem_h,HttpBody)
            }
            
          }
          else{ 
            let props2={HttpBody_init:HttpBody_init,input:HttpBody.content.message,setinfoFunc:this.props.setinfoFunc}
            this.excute((this.state as any).Myworkflow,props2,msgItem_h,HttpBody)
          }
          

        }
        else if(!['智谱AI',"智谱AI(GLM-4V)","智谱AI(GLM-4)",'Stability AI',"Stability AI (v1-6)","Stability AI (pixel)","Stability AI (mask)",'通义千问',"ChatGpt (dall-e-3)","ChatGpt (dall-e-2)","ChatGpt (dall-e-2)2"].includes(this.props.selectedLLM)){
          
          if((this.props.workFlowAddList as any).includes("newsgoogle") || (this.props.workFlowAddList as any).includes("topnews")){
            let newsgooglesearch = SetupCookies("newsgooglesearch","","get")
            let topnewssearch = SetupCookies("topnewssearch","","get")
            if((newsgooglesearch !== undefined && (this.props.workFlowAddList as any).includes("newsgoogle") )||( topnewssearch !== undefined  && (this.props.workFlowAddList as any).includes("topnews"))){
              if((this.state as any).newscontent.length>0){

                let TXTprompt = "__news__"
                let list:any
                if((this.props.workFlowAddList as any).includes("newsgoogle")){
                  list = [newsgooglesearch]
                  if(typeof newsgooglesearch === "string"){list = newsgooglesearch.split(",")}
                }else{
                  list = [topnewssearch]
                  if(typeof newsgooglesearch === "string"){list = topnewssearch.split(",")}
                }
                
                list.forEach((elem:any) => {
                  let i:number = typeof elem === "string" ? parseInt(elem):elem;
                  TXTprompt += `新闻标题: ${(this.state as any).newscontent[i][0]} \n  新闻内容: ${(this.state as any).newscontent[i][1]} \n\n`;
                  
                });
                let newLLMpars = HttpBody.LLMpars
                let prompt_txt_index:any = (PagePrompt.prompt_txt_index as any)[this.props.selectedLLM]
  
                newLLMpars[prompt_txt_index] = TXTprompt
                HttpBody = { ...HttpBody, "LLMpars":newLLMpars}
                this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)
              }else{
                this.props.setinfoFunc("请重新尝试","2000")
              }
            }else{this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)}
          }else if((this.props.workFlowAddList as any).includes("writingtools") && ((this.props.workFlowAddList as any).includes("polishing") || (this.props.workFlowAddList as any).includes("writing"))){

            
            let language = SetupCookies("language","","get")
            let polishing_selection
            
            if(!language){language = 0}
            if((this.props.workFlowAddList as any).includes("polishing")){
              let polishing_content = document.getElementById('item_inputpolishing_content')?.innerHTML
              
              if(polishing_content && polishing_content.trim().length>0)
              {
                
                if(polishing_content.substring(0,6).includes("你的任务是") || polishing_content.substring(0,8).includes("Your job")){
                    polishing_selection = polishing_content
                    
                }else{
                    polishing_selection = (language === 0?"你的任务是":"Your job:")+polishing_content
                }
              }else
              {polishing_selection = SetupCookies("polishing_selection","","get")}
              
              
              
              if(!polishing_selection) {polishing_selection=0}
            }else{
              let polishing_content = document.getElementById('item_inputwriting_content')?.innerHTML
              
              
              if(polishing_content && polishing_content.trim().length>0)
              {
                polishing_selection = polishing_content
              }else
              { let n = SetupCookies("writing_selection","","get")
                if(!n){n=0}
                polishing_selection = PagePrompt.rolerprompt[language][n].join("")
              }
              
              
            }
            
            HttpBody["LLMpars"][PagePrompt.stopIndexDict[this.props.selectedLLM]] = [language,polishing_selection]
            this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)
          }else if((this.props.workFlowAddList as any).includes("wikipedia") && SetupCookies("prompt_txt","","get")){
            HttpBody["LLMpars"][PagePrompt.stopIndexDict[this.props.selectedLLM]] = "eb1_wikipedia.txt"
            this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)
          }else if((this.props.workFlowAddList as any).includes("dbconnection")){
            let vdb_selection =  SetupCookies("vdb_selection","","get")
            if(vdb_selection){
             
              document.getElementById('item_inputvdb_selection')?.setAttribute("value",msgItem_h.message)
              document.getElementById('flowbox_items_butvdb_selection')?.click() 
              
            }else{
             
              HttpBody["LLMpars"][PagePrompt.stopIndexDict[this.props.selectedLLM]] = "loadprompt.txt"
              this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)

            }
            
          }else if((this.props.workFlowAddList as any).includes("article")){
            let eb1_article = SetupCookies("article_selection","","get")
            if(eb1_article){
              if(eb1_article.includes(",")){
                let L = eb1_article.split(",")
                L.forEach((elm:string) => {
                  HttpBody["LLMpars"][PagePrompt.stopIndexDict[this.props.selectedLLM]] = "article"+elm
                  this.HttpPost_msg("sendmsg",HttpBody,"content_before:标题为："+elm)
                });
                
              }else{
                HttpBody["LLMpars"][PagePrompt.stopIndexDict[this.props.selectedLLM]] = "article"+eb1_article
                this.HttpPost_msg("sendmsg",HttpBody,"content_before:标题为："+eb1_article)
              }
              
            }

            
          }
          else{

            this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)
          }
          
        }else{
     
          this.HttpPost_msg("sendmsg",HttpBody,msgItem_h)
        }

      }
      
    }
    
  }

  relpyMsg_type0(msg:string | number){
    //repeat case
    this.generatereplymsgbox("LLM_Agent",msg.toString(),["help"])
    //add help
  }
  relpyMsg_type1(msg:string | number){
    this.generatereplymsgbox("LLM_Agent",msg.toString(),["confirm","help"])
    //add help and confirm
  }
  microphoneControl(){
    if(this.props.loginCheckapp){
      if(this.props.microphoneTrigger === 0){this.props.workFlowAdd_on("voicerecorder")}

      if(this.props.microphoneTrigger!==2){
        this.props.setMicrophoneTrigger(this.props.microphoneTrigger+1)
      }
      else{
        this.props.setMicrophoneTrigger(1);
      }
    }else{alert(PagePrompt.LoginTips)}
    
  }

  sentMsg(){
    inputHistory.push((this.state as any).content)
    inputHistory_index = inputHistory.length-1
    if(this.props.loginCheckapp){
      if(this.props.parsconfirm === false && this.props.selectparsList.length >0){
        APIdata = LLMlibs[this.props.selectedLLM]
        let result = this.checkselectpars(this.props.selectparsList,this.props.parsconfirm)
        if(result[0] === 0){//send direct
          this.sentMsgNext()
        }else if (result[0] === 1 )//repeat case
        { 
          this.didsendMsg()
          this.relpyMsg_type0(result[1])
        }else{
          this.didsendMsg()
          this.relpyMsg_type1(result[1])
        }
      }
      else{
        APIdata = LLMlibs[this.props.selectedLLM]
        this.sentMsgNext()} //send direct
      
    }else if(!this.props.selectedLLM || this.props.selectedLLM === undefined){
      this.props.setinfoFunc("请先选择LLM模型",2000)
    } 
    else(alert(PagePrompt.LoginTips))
    
  }

  updateScoll(){
    var element  = document.getElementById("card_text_body");
    element?.scrollIntoView({ behavior: "smooth" , block: "end"});
    element?.scrollTo({
      top: element?.scrollHeight-50,
      behavior: "smooth"
    });
    
  }

  // componentDidMount() {

  // }

  componentDidUpdate() {
    this.updateScoll();
    if((this.state as any).content !== "" && this.props.microphoneTrigger === 1 ) //&& !(this.state as any).autodrivePars
      {
        this.handleClick()
      }
  }
 

  _handleKeyDownchatBox = (e:any) => {
    if (e.key === 'Enter' && e.metaKey === true) {
      this.handleClick()
    }
    else if(e.key === 'ArrowUp' ){
      if(inputHistory_index >= 0){
        this.setState({
          content: inputHistory[inputHistory_index]
        });
        inputHistory_index = inputHistory_index - 1
      }else(
        this.setState({
          content: ''
        })
      )
    }else if(e.key === 'ArrowDown'){
      if(inputHistory_index <= inputHistory.length-1){
        
        this.setState({
          content: inputHistory[inputHistory_index]
        });
        inputHistory_index = inputHistory_index + 1
      }else(
        this.setState({
          content: ''
        })
      )
    }
  }

  render() {
      return (
      <div className="d-flex justify-content-around ">
      <Card className="card_chatBox">  
        <Card.Body style={{  width: this.props.boxwidth.toString()+'rem', height:'80%', transition:".3s"}}>
          <Card.Title>聊天</Card.Title>
          <Card.Text id="card_text_body" style={{ height: (this.state as any).sent_message_h === 2.5?'90%': (this.state as any).sent_message_h === 5? "83%": '69%' }}> 
            {(this.state as any).list.map((item:any,index:any) => {
                return (
                  <MsgBox sender = {item.sender} message={item.message} date = {item.date} button={item.button} setparsconfirm={this.props.setparsconfirm} 
                  boxwidth={this.props.boxwidth} fillin_txt={this.props.fillin_txt} fillin_file={this.props.fillin_file} workFlowAdd_on={this.props.workFlowAdd_on} loginCheckapp={this.props.loginCheckapp}
                  setinfoFunc= {this.props.setinfoFunc} username={this.props.username} workFlowAddList={this.props.workFlowAddList}/>
                )
              })}
          </Card.Text>
          <div id="sent_message" style={{height:(this.state as any).sent_message_h+'rem'}}>
            <button className='fold_contrl' type="button" id="fold_contrl_chatBox" onClick={() => {this.resize_inputBox()}}>{(this.state as any).increase_or_decrease ===0?  "⇱" : "⇲" }</button> 
            <textarea rows={1} //height:'2.5rem'
            className='input_chatBox'
            style={{ width: (this.props.boxwidth - 12).toString()+'rem',height:(this.state as any).sent_message_h+'rem'}} //width: '29rem'
            onChange={e => this.handleCountChange(e.target.value)}
            onKeyDown={e =>this._handleKeyDownchatBox(e)}
            value={(this.state as any).content}
            
            ></textarea>
            {'  '}
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Command+Entre</Tooltip>}
            ><Button id="chatbox_sentmsg" variant="primary" onClick={() => this.handleClick()} >发送</Button></OverlayTrigger>
            <img src={this.props.microphoneTrigger ===2?stop: microphone} id="microphone" style={{backgroundColor:this.props.microphoneTrigger===0?"white":"rgb(199, 242, 234)"}} width="100" alt="" onClick={e =>this.microphoneControl()}/>
          </div>
        </Card.Body>
      </Card>
      </div>
      )
    
    
  }
  
}



export default ChatBox