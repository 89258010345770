import React from 'react';
import './newsBox.css'


interface NewsBoxProps{
    indexnum:number,
    include: number,
    title:string,
    source:string,
    url:string,
}

const NewsBox: React.FC<NewsBoxProps> = (props) => {
    return (
        <div className='newsbox'>
            <div className='newsbox-title'> <a href={props.url}  target="_blank" rel="noopener noreferrer">{props.title}</a> </div>
            <div className={`newsbox-source fontcolor-${props.include}`}>{props.source}</div>
        </div>
    )
}
export default NewsBox