import React,{  useState }  from "react";
import "./guide.css"
import Image from 'react-bootstrap/Image';
import { IconPrompt } from "../../configs/iconPrompyt";

interface GuideProps{
    loginCheckapp:boolean
}

const IMG_Libs_guide = IconPrompt.IMG_Libs_guide
const Guide: React.FC<GuideProps> = (props) => {
    const [gdfoldcotrol, setgdfoldcotrol] = useState("∵")
    const [gdindex, setgdindex] = useState<number>(0)
    const [displayloadWF, setdisplayloadWF] = useState<any>(false)

    const guideContent = [
                        <div>
                            <h5 className='subtitle'>❖  &nbsp; 第一步：点击注册</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>点击右上⻆[登陆]，点击[现在注册]。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["rg_step1"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第二步：填写注册信息</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>填写注册信息，点击[Register]。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["rg_step2"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第三步：登陆</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>填写登陆信息，点击[Login]。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["rg_step3"]}/> 
                                </li>
                            </ol>
                        </div>,
                        <div>
                            <h5 className='subtitle'>❖  &nbsp; 第一步：点击注册</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>点击右上⻆[登陆]，点击[现在注册]。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["rg_step1"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第二步：点击邮箱验证</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>点击[邮箱验证]。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["email_step2"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第三步：输入邮箱信息</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>填写邮箱信息，点击[OK]。</h6></li>
                                <li>
                                <Image className='guide_s_img' src={IMG_Libs_guide["email_step3"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第四步：输入验证码</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>请打开您填写的邮箱，查看邮件中的6位验证码，填写验证码信息，点击[OK]。</h6></li>
                                <li>
                                <Image className='guide_s_img' src={IMG_Libs_guide["email_step4"]}/> 
                                </li>
                            </ol>
                        </div>,
                        <div>
                        <h5 className='subtitle'>❖  &nbsp; 第一步：点击忘记密码</h5>
                        <ol className="guide_li">
                            <li> <h6 className='subcontent'>点击右上⻆[登陆]，点击[忘记密码]。</h6></li>
                            <li>
                            <Image className='guide_s_img' src={IMG_Libs_guide["rsPWD_step1"]}/> 
                            </li>
                        </ol>
                        <h5 className='subtitle'>❖  &nbsp; 第二步：填写注册邮箱信息</h5>
                        <ol className="guide_li">
                            <li> <h6 className='subcontent'>在新打开的页面中，填写您的注册邮箱，点击[获取验证码]。</h6></li>
                            <li>
                            <Image className='guide_m_img' src={IMG_Libs_guide["rsPWD_step2"]}/> 
                            </li>
                        </ol>
                        <h5 className='subtitle'>❖  &nbsp; 第三步：填写新的密码</h5>
                        <ol className="guide_li">
                            <li> <h6 className='subcontent'>请打开您填写的邮箱，查看邮件中的6位验证码，填写验证码以及新的密码，点击[确认]。</h6></li>
                            <li>
                            <Image className='guide_m_img' src={IMG_Libs_guide["rsPWD_step3"]}/> 
                            </li>
                        </ol>
                        </div>,
                        <div><h5 className='subtitle'>❖  &nbsp; 第一步：选择LLM模型</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>点击右上⻆[登陆]，注册新的账户，或者使用已注册账户。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["step1"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第二步：选择LLM模型</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>点击左边菜单栏中第一个图标[选择LLM模型]，选择[开源大模型]，选择 Baichuan-13B (百川智能)。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["step2"]}/> 
                                </li>
                            </ol>
                            
                            <h5 className='subtitle'>❖  &nbsp; 第三步：加载Token</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>点击流程图中 灯泡图标，输入Token, 点击确认按钮✔， 点击[确认]，出现提示"加载成功"。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["step3"]}/> 
                                </li>
                            </ol>
                            <h5 className='subtitle'>❖  &nbsp; 第四步：提问</h5>
                            <ol className="guide_li">
                                <li> <h6 className='subcontent'>在聊天栏中输入问题，点击发送。</h6></li>
                                <li>
                                <Image className='guide_r_img' src={IMG_Libs_guide["step4"]}/> 
                                </li>
                            </ol></div>,
                             
                             ]

    function gdfoldFunc(opt:number){
        setgdindex(opt)
        setgdfoldcotrol(gdfoldcotrol === "∵"?"∴":"∵")
        setdisplayloadWF(gdfoldcotrol === "∵"? true:false)
    }
    return (
        
     
            <div className="guide_box">
                <div className="guide_manue">
                     <div className="guide_title" style={{width: "200px",backgroundColor:gdindex===0?"white":"#cbf3ee"}}  onClick={(e) => gdfoldFunc(0)}>
                        <h5 className="topic4">注册账户/登陆</h5>
                        <button className='gdfold_contrl' type="button" onClick={(e) => gdfoldFunc(0)}>{gdindex===0?gdfoldcotrol:"∵"}</button>
                    </div>
                    <div className="guide_title" style={{width: "200px",backgroundColor:gdindex===1?"white":"#cbf3ee"}}  onClick={(e) => gdfoldFunc(1)}>
                        <h5 className="topic4">邮箱认证</h5>
                        <button className='gdfold_contrl' type="button" onClick={(e) => gdfoldFunc(1)}>{gdindex===1?gdfoldcotrol:"∵"}</button>
                    </div>
                    <div className="guide_title" style={{width: "200px",backgroundColor:gdindex===2?"white":"#cbf3ee"}}  onClick={(e) => gdfoldFunc(2)}>
                        <h5 className="topic4">修改密码</h5>
                        <button className='gdfold_contrl' type="button" onClick={(e) => gdfoldFunc(2)}>{gdindex===2?gdfoldcotrol:"∵"}</button>
                    </div>
                    <div className="guide_title" style={{width: "200px",backgroundColor:gdindex===3?"white":"#cbf3ee"}}  onClick={(e) => gdfoldFunc(3)}>
                        <h5 className="topic4">加载流程图</h5>
                        <button className='gdfold_contrl' type="button" onClick={(e) => gdfoldFunc(3)}>{gdindex===3?gdfoldcotrol:"∵"}</button>
                    </div>
                    
                    
                    
                </div>
                
                <div className="guide_content" style={{display:displayloadWF?"inline-block":"none"}}>
                    {guideContent[gdindex]}
                </div>
            </div>
            
           
        
        
    )
    
    
}

export default Guide