import React  from "react";
import email from  "../../assets/img/LoginImage/email.png";
import Image from 'react-bootstrap/Image';
import phone from "../../assets/img/About/phone.png"
import web from "../../assets/img/About/web.png"
import location from "../../assets/img/About/location.png"
import "./about.css"

interface FrontpageProps{
    username?:string
    loginCheckapp:boolean
    selectedLLM:string
}
const Aboutpage: React.FC<FrontpageProps> = (props) => {
    return (
        <div id="aboutpage">
            <div id='about_box'>
                <Image id='about_icon' src={location}   />
                <div id='about_content'>
                    <h6>Room 404, Engineering Building No.2, </h6>
                    <h6> Higher Education Mega-Center,</h6> 
                    <h6> Guangdong University of Technology</h6>
                </div>
                
            </div>

            <div id='about_box'>
                <Image id='about_icon' src={phone}   />
                <div id='about_content'>
                    <h6>+86 20 3932 2436 </h6>
                </div>
                
            </div>

            <div id='about_box'>
                <Image id='about_icon' src={email}   />
                <div id='about_content'>
                    <h6>find_lab@163.com </h6>
                </div>
                
            </div>

            <div id='about_box'>
                <Image id='about_icon' src={web}   />
                <div id='about_content'>
                    <h6><a href="http://www.find-lab.com">http://www.find-lab.com</a> </h6>
                </div>
                
            </div>
        </div>
        
    )
    
    
}
Aboutpage.defaultProps={
    username:"游客",
    selectedLLM:"API设置"
}
export default Aboutpage