import React,{useState,useEffect}  from "react";
import "./vdbmanager.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { IconPrompt } from "../../configs/iconPrompyt";
import SetupCookies from '../../tools/setupCookies';
import {UseURLLoader} from '../../hooks/useURLLoader';
interface VdbmanagerProps{
    loginCheckapp:boolean
    username:string
    uuid:string
    setinfoFunc:Function
    waitinfoFunc:Function
}
const Vdbmanager: React.FC <VdbmanagerProps>= (props) => {
    const [myvdbs, setMyvdbs] = useState<any>(<div></div> );
    const [selectedvdb, setSelectedvdb] = useState<any>(null);
    const [source, setSource] = useState<any>(null);
    const [metadatasource, setMetadatasource] = useState<any[]>([]);
    const [metadataid, setMetadataid] = useState<any>(null);
    const [doccontent, setDoccontent] = useState<any>(false);
    const [vdbcontent, setVdbcontent] = useState<any>("");



    let HttpBody_init= {'username': props.username, uuid:props.uuid,session_id:SetupCookies("session_id","","get")}
    async function getvdbList(){
        let props1={url:"llm/getsavedprompts",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init},parsName:"vdbmanager"}
        props.waitinfoFunc(true,"20000")
        await UseURLLoader(props1).then(result =>
            {   
                props.waitinfoFunc(false)
                if(typeof result !== 'undefined'){
                    setMyvdbs( <div className="selectedvdb">
                            <h6>选择以下向量数据库，点击确认。</h6>
                            <Form >
                                {result[0].map((item:string,index:number) =>{
                                    return <div>
                                            <Form.Check
                                                key={item}
                                                label={item}
                                                value={0}
                                                name="group1"
                                                type= 'radio'
                                                style={{textAlign:"left",width:"100%",margin:"0 auto"}}
                                                onChange={e => setSelectedvdb(item)}
                                            />
                                        </div>       
                                })
                                }
                            </Form>
                        </div>)
                }
                
            }
        )
    }

    function getvdbsource(opt:number = 0){
        if(selectedvdb){
            let props1={url:"llm/getvdbmetadata",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init,selectedvdb:selectedvdb,metadataid:opt,vdbcontent:""}}
            props.waitinfoFunc(true,"30000")
            UseURLLoader(props1).then(result =>
                {   
                    props.waitinfoFunc(false)
                    if(typeof result !== 'undefined'){
                        
                        let metadata = JSON.parse(result.data.replaceAll("'",'"')) 
                        setSource(metadata)
                    }
                    
                }
            )
        }else{
            props.setinfoFunc("请先选择一个向量数据库，然后点击确认",2000)
        }
        
    }

    

    function getvdbcontent(metadataid:number){
        setMetadataid(metadataid)
        if(selectedvdb){
            let props1={url:"llm/getvdbcontent",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init,selectedvdb:selectedvdb,metadataid:metadataid,vdbcontent:""}}
            props.waitinfoFunc(true,"20000")
            UseURLLoader(props1).then(result =>
                {   
                    props.waitinfoFunc(false)
                    if(typeof result !== 'undefined'){
                     
                        setDoccontent(true)
                        setVdbcontent(result.data)
                    }
                    
                }
            )
        }

    }

    function delvdbcontent(){
        if(metadataid){
            let props1={url:"llm/delvdbcontent",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init,selectedvdb:selectedvdb,metadataid:metadataid,vdbcontent:""}}
            props.waitinfoFunc(true,"20000")
            UseURLLoader(props1).then((result:any) =>
                {   
                    props.waitinfoFunc(false)
                    if(typeof result !== 'undefined'){
                        if(result.data === "ok"){
                            let prevState = { ...source }
                            prevState.ids.splice(metadatasource[1], 1)
                            prevState.metadatas.splice(metadatasource[1], 1)
                            setSource(prevState)
                        }
                        else{props.setinfoFunc("删除失败，请重新尝试",2000)}
                    }
                    
                }
            )
        }else{
            props.setinfoFunc("请先在左边选择删除的数据。",2000)
        }
    }

    function modifyvdbcontent(){
        if(vdbcontent && vdbcontent.trim().length > 0){
            let props1={url:"llm/modifyvdbcontent",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init,selectedvdb:selectedvdb,metadataid:metadataid,vdbcontent:vdbcontent}}
            props.waitinfoFunc(true,"20000")
            UseURLLoader(props1).then(result =>
                {   
                    props.waitinfoFunc(false)
                    if(typeof result !== 'undefined'){
                        if(result.data === "ok"){props.setinfoFunc("更新成功。",2000)}
                        else{props.setinfoFunc("更新失败，请重新尝试",2000)}
                    }
                    
                }
            )
        }else{
            props.setinfoFunc("内容不能为空。",2000)
        }

    }
    function pageControl(index:number,total:number,opt:string){
        if(total> 20){
            if (opt === "before" && Math.ceil(index/20) -1> 0){
                getvdbsource(Math.ceil(index/20) -2)
            }else if(opt === "next" && index <  total){
                getvdbsource(Math.ceil(index/20))
            }
        }
        
    }

    function modifyvdbmetadata(){
       
        if(metadatasource[0] && metadatasource[0].trim().length > 0){
            let props1={url:"llm/modifyvdbmetadata",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init,selectedvdb:selectedvdb,metadataid:metadataid,vdbcontent:metadatasource[0]}}
            props.waitinfoFunc(true,"20000")
            UseURLLoader(props1).then(result =>
                {   
                    props.waitinfoFunc(false)
                    if(typeof result !== 'undefined'){
                        if(result.data === "ok"){
                            props.setinfoFunc("更新成功。",2000)
                            let prevState = { ...source }
                            prevState.metadatas[metadatasource[1]]["source"] = metadatasource[0]
                            setSource(prevState)
                            
                        }
                        else{props.setinfoFunc("更新失败，请重新尝试",2000)}
                    }
                    
                }
            )
        }else{
            props.setinfoFunc("内容不能为空。",2000)
        }

    }

   
    return (props.loginCheckapp)?(
    <div className="vdbmanager">
        <div>
            <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => {getvdbList()}} >我的向量数据库</Button>
            <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => {getvdbsource()}} >确认</Button>
            <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => {window.close()}} >关闭</Button>
        </div>
        {myvdbs}
        <div className="box3">
            {source? <div className="box3_selection" style={{width:window.innerWidth*0.4,display:source?"inline-block":"none"}}>
                <h6>点击查看内容：</h6>
                <Form style={{margin:"0 auto",marginLeft:"5px",marginRight:"5px",width:"fit-content"}}>
                    {source.metadatas.map((item:any,index:number) =>{
                        return <div >
                                <Form.Check
                                    key={item.source}
                                    label={item.source}
                                    value={0}
                                    name="group1"
                                    type= 'radio'
                                    style={{textAlign:"left",width:"fit-content"}}
                                    onChange={e => {getvdbcontent(source.ids[index]);setMetadatasource([item.source,index])}}
                                />
                            </div>       
                    })
                    }
                </Form>
               
                <div style={{margin: "0px auto",width:"100%",textAlign:"center",marginTop:"10px"}}>
                    <h6 style={{width:"fit-content",display:"inline-block",marginRight:"20px"}}>当前/总共：{ (Math.ceil(source.index/20) -1)*20 } - {source.index}/{source.total}</h6>
                    <Button className='but_only' style={{marginRight: "10px",height:"30px", fontSize:"13px"}} variant="outline-info" onClick={() =>  pageControl(source.index,source.total,"before")} >上一页</Button>
                    <Button className='but_only' style={{marginRight: "10px",height:"30px", fontSize:"13px"}} variant="outline-info" onClick={() =>  pageControl(source.index,source.total,"next")} >下一页</Button>
                </div>
                <div style={{margin: "0px auto",width:"100%",textAlign:"center",marginTop:"10px"}}>
                    <input className={'metadata_source'} type="text" name={'metadata_source'} value={metadatasource[0]} onChange={e => setMetadatasource([e.target.value,metadatasource[1]])} />
                    <Button className='but_only' style={{marginRight: "10px"}} variant="outline-info" onClick={() =>  modifyvdbmetadata()} >更新</Button>
                </div>
            </div>
            : <div></div>
            }
            

            <div className="box3_content" style={{width:window.innerWidth*0.5,display:doccontent?"inline-block":"none"}}>
                <textarea  className='vdb_content' style={{width:"100%"}} value={vdbcontent} onChange={(e) => setVdbcontent(e.target.value)} ></textarea>
                <div style={{margin: "0px auto",width:"100%",textAlign:"center"}}>
                    <Button className='but_only' style={{marginRight: "10px"}} variant="outline-info" onClick={() => modifyvdbcontent()} >更新</Button>
                    <Button className='but_only'  style={{marginRight: "10px"}} variant="outline-info" onClick={() => {delvdbcontent()}} >删除</Button>
                </div>
            </div> 
            
           
            
        </div>
        
    </div>

    ):<div></div>
    
    
    
}

export default Vdbmanager