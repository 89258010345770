import React from 'react';
import './Popup.css'
import close from '../../src/assets/img/toolsImage/close.png'
interface PopupProps{
    dialogWidth?:string
    title?: string
    // onOk?: Function
    // sureText?:string
    // onCancle?: Function
    // cancelText?:string
    trigger?:boolean
    children: React.ReactNode;
    setTrigger:any
}

const Popup: React.FC<PopupProps> = (props) => {
    function close_Popup(obj:any){
        if( obj.className === "popup"){props.setTrigger(false)}
    }
    return (props.trigger)?(
        <div className='popup' onClick={(e)=>close_Popup(e.target)}>
            <div className='popup-inner' style={{ width: props.dialogWidth}}>
                <div className='popup-inner-header'>
                    <div className='popup-header-left'>{props.title}</div>
                    <div className='popup-header-right'><img src={close} onClick={()=>props.setTrigger(false)} className="rounded me-2" width="20" alt="" /></div>
                </div>
                {props.children}
                
            </div>
        </div>
    ): <div></div>;
        
    
}
export default Popup