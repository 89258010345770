import double_click from '../assets/img/toolsImage/double-click.png'
import save_icon from "../assets/img/toolsImage/save_icon4.png"
import bulb from "../assets/img/toolsImage/bulb.png"

import check0 from '../assets/img/toolsImage/check0.png'
import check2 from '../assets/img/toolsImage/check2.png'
import check3 from '../assets/img/toolsImage/check3.png'
import BotIMG from '../assets/img/toolsImage/BotIMG.png'
import ClientIMG from '../assets/img/toolsImage/ClientIMG.png'

import robot from '../assets/img/menuImage/robot.png'
import  info  from '../assets/img/menuImage/info.png';
import message from '../assets/img/menuImage/message.png'
import saved from '../assets/img/menuImage/saved.png';
import notification from '../assets/img/menuImage/notification.png';
import about from '../assets/img/menuImage/about.png';
import teacup from '../assets/img/menuImage/teacup.png';
import flow from '../assets/img/menuImage/flow.png';
import tool from '../assets/img/menuImage/tool.png'
import close from '../assets/img/toolsImage/close.png'
import add_icon from '../assets/img/toolsImage/add_icon.png'
import check1 from '../assets/img/toolsImage/check1.png'
import admin from '../assets/img/menuImage/admin.png'
import mymemory from '../assets/img/menuImage/mymemory.png'
import new_msg from '../assets/img/toolsImage/new-message.png'
import open_msg from '../assets/img/toolsImage/open-envelope.png'
import pen from '../assets/img/toolsImage/pen.png'
import eraser from '../assets/img/toolsImage/eraser.png'
import tick_mark from '../assets/img/toolsImage/tick-mark.png'
import reset from '../assets/img/toolsImage/reset.png'
import refresh from '../assets/img/toolsImage/refresh.png'
import click2 from '../assets/img/toolsImage/click2.gif'

import zapier_img from '../assets/img/apiIcon/zapier.png'
import gmail_img from '../assets/img/apiIcon/gmail.png'
import googlemap from '../assets/img/apiIcon/googlemap.png'
import notion_img from '../assets/img/apiIcon/notion.png'
import zhong_img from '../assets/img/apiIcon/zhong.png'
import writing from '../assets/img/apiIcon/writing.png'
import paw from '../assets/img/LoginImage/paw.png'
import googlenews from '../assets/img/apiIcon/googlenews.png'
import googletranslate from '../assets/img/apiIcon/googletranslate.png'
import juhe from '../assets/img/apiIcon/juhe.png'
import txtreader from '../assets/img/apiIcon/txtreader.png'
import meteomatics from '../assets/img/apiIcon/meteomatics.png'
import voicerecorder from '../assets/img/apiIcon/microphoneAPI.png'
import wikipedia from '../assets/img/apiIcon/wikipedia.png'
import article from '../assets/img/apiIcon/article.png'

import openai from '../assets/img/apiIcon/openai.png'
import azureai from '../assets/img/apiIcon/azureai.png'
import glm7b from '../assets/img/apiIcon/glm7b.png'
// import gemini from '../../assets/img/apiIcon/gemini.png'
import qwen from '../assets/img/apiIcon/qwen.png'
import zhipu from '../assets/img/apiIcon/zhipuai.png'
import stabilityai from '../assets/img/apiIcon/stabilityai.jpg'
import llama from '../assets/img/apiIcon/llama.png'
import mistral from '../assets/img/apiIcon/mistral.png'
import workflow from '../assets/img/apiIcon/workflow.png'
import baichuan from '../assets/img/apiIcon/baichuan.png'

import lizhi from '../assets/img/lizhi.png'

import precip_1h from '../assets/img/meteo/precip_1h.png'
import sunrise from '../assets/img/meteo/sunrise.png'
import sunset from '../assets/img/meteo/sunset.png'
import t_2m from '../assets/img/meteo/t_2m.png'
import uv from '../assets/img/meteo/uv.png'
import weather_symbol_24h from '../assets/img/meteo/weather_symbol_24h.png'
import wind_speed_10 from '../assets/img/meteo/wind_speed_10.png'
import w0 from '../assets/img/meteo/0.png'
import w1 from '../assets/img/meteo/1.png'
import w2 from '../assets/img/meteo/2.png'
import w3 from '../assets/img/meteo/3.png'
import w4 from '../assets/img/meteo/4.png'
import w5 from '../assets/img/meteo/5.png'
import w6 from '../assets/img/meteo/6.png'
import w7 from '../assets/img/meteo/7.png'
import w8 from '../assets/img/meteo/8.png'
import w9 from '../assets/img/meteo/9.png'
import w10 from '../assets/img/meteo/10.png'
import w11 from '../assets/img/meteo/11.png'
import w12 from '../assets/img/meteo/12.png'
import w13 from '../assets/img/meteo/13.png'
import w14 from '../assets/img/meteo/14.png'
import w15 from '../assets/img/meteo/15.png'
import w16 from '../assets/img/meteo/16.png'
import w101 from '../assets/img/meteo/101.png'
import w102 from '../assets/img/meteo/102.png'
import w103 from '../assets/img/meteo/103.png'
import w108 from '../assets/img/meteo/108.png'
import w109 from '../assets/img/meteo/109.png'
import w110 from '../assets/img/meteo/110.png'
import w111 from '../assets/img/meteo/111.png'

import step1 from '../assets/img/guide/step1.png'
import step2 from '../assets/img/guide/step2.png'
import step3 from '../assets/img/guide/step3.png'
import step4 from '../assets/img/guide/step4.png'
import email_step2 from '../assets/img/guide/email_step2.png'
import email_step3 from '../assets/img/guide/email_step3.png'
import email_step4 from '../assets/img/guide/email_step4.png'
import rg_step1 from '../assets/img/guide/rg_step1.png'
import rg_step2 from '../assets/img/guide/rg_step2.png'
import rg_step3 from '../assets/img/guide/rg_step3.png'
import rsPWD_step1 from '../assets/img/guide/rsPWD_step1.png'
import rsPWD_step2 from '../assets/img/guide/rsPWD_step2.png'
import rsPWD_step3 from '../assets/img/guide/rsPWD_step3.png'


export const IconPrompt : IconP = {
    lizhi:lizhi,
    IMG_Libs:{check0:check0,check2:check2,check3:check3,check1:check1,
        double_click:double_click,save_icon:save_icon,bulb:bulb,
        BotIMG:BotIMG,ClientIMG:ClientIMG,add_icon:add_icon,refresh:refresh,click2:click2,
    },
    IMG_Libs_menu:{robot:robot,info:info,message:message,
        saved:saved,about:about,tool:tool,close:close,add_icon:add_icon,flow:flow,notification:notification,teacup:teacup,
        check1:check1,admin:admin,mymemory:mymemory,new_msg:new_msg,open_msg:open_msg,
        pen:pen,tick_mark:tick_mark,eraser:eraser,reset:reset,
    },
    IMG_Libs_apis:{zapier_img:zapier_img,gmail_img:gmail_img,notion_img:notion_img,
        zhong_img:zhong_img,paw:paw,googlenews:googlenews,googletranslate:googletranslate,googlemap:googlemap,juhe:juhe,writing:writing,
        txtreader:txtreader,meteomatics:meteomatics,voicerecorder:voicerecorder,wikipedia:wikipedia,article:article
        
    },
    IMG_Libs_llm:{openai:openai,glm7b:glm7b,qwen:qwen,zhipu:zhipu,stabilityai:stabilityai,llama:llama,mistral:mistral,workflow:workflow,
        baichuan:baichuan,azureai:azureai,
    },
    IMG_Libs_meteo:{precip_1h:precip_1h,sunrise:sunrise,sunset:sunset,t_2m:t_2m,uv:uv,weather_symbol_24h:weather_symbol_24h,wind_speed_10m:wind_speed_10},
    weather_symbol:{w0:w0,w1:w1,w2:w2,w3:w3,w4:w4,w5:w5,w6:w6,w7:w7,w8:w8,w9:w9,w10:w10,w11:w11,w12:w12,w13:w13,w14:w14,w15:w15,w16:w16
        ,w101:w101,w102:w102,w103:w103,w104:w4,w105:w5,w106:w6,w107:w7,w108:w108,w109:w109,w110:w110,w111:w111,w112:w12,w113:w13,w114:w14,w115:w15,w116:w16},
    
    IMG_Libs_guide:{step1:step1,step2:step2,step3:step3,step4:step4,email_step2:email_step2,email_step3:email_step3,email_step4:email_step4,
        rg_step1:rg_step1,rg_step2:rg_step2,rg_step3:rg_step3,rsPWD_step1:rsPWD_step1,rsPWD_step2:rsPWD_step2,rsPWD_step3:rsPWD_step3},    
    };

interface IconP {
    lizhi:any,
    IMG_Libs:{[key:string]:any},
    IMG_Libs_menu:{[key:string]:any},
    IMG_Libs_apis:{[key:string]:any},
    IMG_Libs_llm:{[key:string]:any},
    IMG_Libs_meteo:{[key:string]:any},
    weather_symbol:{[key:string]:any},
    IMG_Libs_guide:{[key:string]:any},
}
