import React,{ useState }  from "react";
import Button from 'react-bootstrap/Button';
import {UseURLLoader} from '../hooks/useURLLoader';
import Form from 'react-bootstrap/Form';
interface ChangePwdProps{
    setinfoFunc:Function,
    waitinfoFunc:Function,
}

const ChangePwd: React.FC<ChangePwdProps> = (props:any) => {
    const [email, setEmail] = useState<string>();
    const [username_get, setUsername_get] = useState<any>();
    const [password, setPassword] = useState<string>();
    const [verifycode, setVerifycode] = useState<number>();
    const [confirmpassword, setconfirmpassword] = useState<string>();
    const [alertinfo, setalertinfo] = useState<boolean>(false);
    const [switchphone, setswitchphone] = useState<boolean>(false);
    
    async function confirmchange(){
        if(password!=confirmpassword){
            setconfirmpassword('')
            setalertinfo(true)
        }else{
            setalertinfo(false)
            
            let HttpBody={email:email,password:password,verifycode:verifycode}
            let props1={url:`user/changepwd`,HttpBody:HttpBody,setinfoFunc:props.setinfoFunc}
            await UseURLLoader(props1).then(result =>
               
                {
                    if(result){
                        props.setinfoFunc(result)
                        window.close()
                        
                    }else{
                        setUsername_get(null)
                    }
                }
            )
            
        }
        
    }



    async function getverifyCode(){
        if(email && email.trim().length>0){
            let HttpBody = {email:email,request:"sendverifyCode"};
            let props1={url:`user/emailservice`,HttpBody:HttpBody,setinfoFunc:props.setinfoFunc}
            props.waitinfoFunc(true,30000)
            await UseURLLoader(props1).then(result =>
                {
                    props.waitinfoFunc(false)
                    if(result){
                        props.setinfoFunc("验证码已发送成功。")
                        setUsername_get(result)
                    }else{
                        props.setinfoFunc("您的邮箱尚未注册账户，请核对后重新尝试，或者注册新的账户。")
                        setUsername_get(null)
                    }
                }
            )
        }
        
    }
    async function getverifyCode2(){
        props.setinfoFunc("手机验证功能暂未开放，请期待网站更新。")
    }

    return (
        <div>
            <div style={{display:switchphone?"none":"inline-block"}}>
                <div style={{display:"inline-block", width:"100px"}}>
                    <Form.Check // prettier-ignore
                                type="switch"
                                className='switch_item'
                                id={"switchphone"}
                                label={switchphone?"手机":"邮箱"}
                                onClick={(e) => setswitchphone(true)}
                                checked={switchphone}
                            />
                </div>
                
                <input style={{width:"400px",marginRight:"20px",marginTop:"20px",marginBottom:"20px"}} type="email" value={email} onChange={(e:any)=>setEmail(e.target.value)} placeholder="请输入您注册的邮箱地址，然后点击获取验证码"/>
                <Button  variant="outline-primary" onClick={() => getverifyCode()}>获取验证码</Button>
            </div>
            <div style={{display:switchphone?"inline-block":"none"}}>
                <div style={{display:"inline-block", width:"100px"}}><Form.Check // prettier-ignore
                                type="switch"
                                className='switch_item'
                                id={"switchphone"}
                                label={switchphone?"手机":"邮箱"}
                                onClick={(e) => setswitchphone(false)}
                                checked={switchphone}
                            /></div>
                <input style={{width:"400px",marginRight:"20px",marginTop:"20px",marginBottom:"20px"}} type="email" value={email} onChange={(e:any)=>setEmail(e.target.value)} placeholder="请输入您注册的手机号，然后点击获取验证码"/>
                <Button  variant="outline-primary" onClick={() => getverifyCode2()}>获取验证码</Button>
            </div>
            <div style={{display:username_get?"block":"none"}}>
                <div><p className="writing_title" style={{width:"120px"}}>用户名 : </p>{username_get}</div>
                <div><p className="writing_title" style={{width:"120px"}}>验证码 : </p> <input style={{width:"400px",marginRight:"20px",marginTop:"20px"}} type="number" value={verifycode} onChange={(e:any)=>setVerifycode(e.target.value)} placeholder="请输入您的验证码" maxLength={6}/></div>
                <div><p className="writing_title" style={{width:"120px"}}>输入新密码 : </p> <input style={{width:"400px",marginRight:"20px",marginTop:"20px"}} type="text" value={password} onChange={(e:any)=>setPassword(e.target.value)} placeholder="请输入您的新密码"/></div>
                <div><p className="writing_title" style={{width:"120px"}}>再次输入密码 : </p> <input style={{width:"400px",marginRight:"20px",marginTop:"20px"}} type="text" value={confirmpassword} onChange={(e:any)=>setconfirmpassword(e.target.value)} placeholder="请确认您的新密码"/></div>
                
                <div style={{display:alertinfo?"block":"none"}} >密码不匹配，请再次输入您的密码</div>
                <Button style={{marginTop:"20px"}} variant="outline-primary" onClick={() => confirmchange()}>确认</Button>
            </div>

        </div>
       
    )
    
    
}

export default ChangePwd