import React ,{useState} from "react";
import ChatBox from "../../compoments/chatBox/chatBox";
import WorkFlow from "../../compoments/workFlow/workFlow";
interface MainProps{
    username?:string
    loginCheckapp:boolean
    setloginCheckappFunc:Function
    selectedLLM:string
    setinfoFunc:Function
    workFlowAddList:any
    setworkFlowAddList:Function,
    workFlowAdd_on:Function,
    cleanupAPICookies:Function,
    waitinfoFunc:Function,
    enable_memory_ST:any,
    setenable_memory_ST:Function
}
const Main: React.FC<MainProps> = (props) => {
    const [selectparsList, setSelectparsList] = useState<any>([])
    const [parsconfirm,setparsconfirm]=useState(false)
    
    const [fillin_txt,setFillin_txt]= useState<any>({}) //1 文字，
    const [fillin_file,setFillin_file]= useState<any>({}) //2文件
    const [boxwidth,setBoxwidth]=useState(70)
    const [generatemsgBox, setGeneratemsgBox] = useState<any>("")
    const [autodrivePars, setAutodrivePars] = useState<any>()
    const [newscontent, setNewscontent] = useState<any>([])
    const [microphoneTrigger, setMicrophoneTrigger] = useState<number>(0)
    
    function setSelectparsListFunc(item:[]){
        if ( item.length>0){
            setSelectparsList(item)
        }
    }

    return (
        <div>
            {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta> */}
            {/* http://1.12.246.213:45641/  width={document.documentElement.clientWidth} height={document.documentElement.clientHeight} */} 
            {/* {props.selectedLLM === "Turbo SDXL (LoRA Stable Diffusion XL)"?
            <iframe className="main_iframe" src="http://1.12.246.213:45641/" title="SDXL_iframe"></iframe> 
            :
            <div className="d-flex justify-content-around" id="main">
                <ChatBox username={props.username} loginCheckapp={props.loginCheckapp} selectedLLM={props.selectedLLM} selectparsList ={selectparsList} parsconfirm = {parsconfirm} setparsconfirm = {setparsconfirm} enable_memory_ST={enable_memory_ST} 
                workFlowAdd_on={props.workFlowAdd_on} boxwidth={boxwidth} fillin_txt={fillin_txt} fillin_file={fillin_file} generatemsgBox={generatemsgBox} setGeneratemsgBox={setGeneratemsgBox} setinfoFunc={props.setinfoFunc} waitinfoFunc={props.waitinfoFunc} workFlowAddList={props.workFlowAddList}
                autodrivePars={autodrivePars} newscontent={newscontent} microphoneTrigger={microphoneTrigger} setMicrophoneTrigger={setMicrophoneTrigger}  setloginCheckappFunc={props.setloginCheckappFunc}></ChatBox>
                <WorkFlow selectedLLM={props.selectedLLM} username = {props.username} setSelectparsListFunc ={setSelectparsListFunc } selectparsList ={selectparsList} setparsconfirm = {setparsconfirm} loginCheckapp = {props.loginCheckapp} setenable_memory_ST={setenable_memory_ST}
                 workFlowAddList={props.workFlowAddList} setworkFlowAddList={props.setworkFlowAddList} workFlowAdd_on={props.workFlowAdd_on} setinfoFunc={props.setinfoFunc} cleanupAPICookies = {props.cleanupAPICookies} boxwidth={boxwidth} setBoxwidth={setBoxwidth}
                 setFillin_txt={setFillin_txt} fillin_txt={fillin_txt} fillin_file={fillin_file} setFillin_file={setFillin_file} setGeneratemsgBox={setGeneratemsgBox} setAutodrivePars={setAutodrivePars} setNewscontent={setNewscontent} newscontent={newscontent} waitinfoFunc={props.waitinfoFunc} 
                 microphoneTrigger={microphoneTrigger} setMicrophoneTrigger={setMicrophoneTrigger}></WorkFlow>
            </div>
            }    */}
            <div className="d-flex justify-content-around" id="main">
                <ChatBox username={props.username} loginCheckapp={props.loginCheckapp} selectedLLM={props.selectedLLM} selectparsList ={selectparsList} parsconfirm = {parsconfirm} setparsconfirm = {setparsconfirm} enable_memory_ST={props.enable_memory_ST} 
                workFlowAdd_on={props.workFlowAdd_on} boxwidth={boxwidth} fillin_txt={fillin_txt} fillin_file={fillin_file} generatemsgBox={generatemsgBox} setGeneratemsgBox={setGeneratemsgBox} setinfoFunc={props.setinfoFunc} waitinfoFunc={props.waitinfoFunc} workFlowAddList={props.workFlowAddList}
                autodrivePars={autodrivePars} newscontent={newscontent} microphoneTrigger={microphoneTrigger} setMicrophoneTrigger={setMicrophoneTrigger}  setloginCheckappFunc={props.setloginCheckappFunc}></ChatBox>
                <WorkFlow selectedLLM={props.selectedLLM} username = {props.username} setSelectparsListFunc ={setSelectparsListFunc } selectparsList ={selectparsList} setparsconfirm = {setparsconfirm} loginCheckapp = {props.loginCheckapp} setenable_memory_ST={props.setenable_memory_ST}
                 workFlowAddList={props.workFlowAddList} setworkFlowAddList={props.setworkFlowAddList} workFlowAdd_on={props.workFlowAdd_on} setinfoFunc={props.setinfoFunc} cleanupAPICookies = {props.cleanupAPICookies} boxwidth={boxwidth} setBoxwidth={setBoxwidth}
                 setFillin_txt={setFillin_txt} fillin_txt={fillin_txt} fillin_file={fillin_file} setFillin_file={setFillin_file} setGeneratemsgBox={setGeneratemsgBox} setAutodrivePars={setAutodrivePars} setNewscontent={setNewscontent} newscontent={newscontent} waitinfoFunc={props.waitinfoFunc} 
                 microphoneTrigger={microphoneTrigger} setMicrophoneTrigger={setMicrophoneTrigger}></WorkFlow>
            </div>
        </div>    

    )
    
    
}
Main.defaultProps={
    username:"游客",
    selectedLLM:"API设置"
}
export default Main 