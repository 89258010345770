import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { start } from "repl";
export interface DatepickerProps {
  buts:any;
  start:any;
  end:any;
  setCookies_Pars:Function;
  cookies_name:string
  }
class Datepicker extends React.Component <DatepickerProps>  {
  constructor(props:any) {
    super(props);
    this.state = {
      show: false,
      date: moment(),
      dateE: moment(),
      index:0,
    };
  }
  
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (index:number) => {
    this.setState({ show: true , index:index});
  };
  handleChangeDate = (date:any, event:any) => {
    let newdate = moment(date)
    let newdateE = (this.state as any).dateE
    if((this.state as any).index === 0){
      if(date<this.props.start){
        newdate=moment(this.props.start)
      }else if((this.state as any).dateE.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") && date>(this.state as any).dateE.toDate()){
        newdate=(this.state as any).dateE
      }else if(date>this.props.end){
        newdate = moment(this.props.end)
        newdateE = moment(this.props.end)
      }
      this.setState({
        date:newdate,
        dataE:newdateE
      });
      
      let cookies = this.props.setCookies_Pars(this.props.cookies_name,"","get")
      if(cookies){
        let cookiesL = cookies.split(",")
        cookiesL[0] = newdate.format("YYYY-MM-DD").toString()
        cookies = cookiesL.join(",")
      }else{
        if(newdate.format("YYYY-MM-DD").toString() > (this.state as any).dateE.format("YYYY-MM-DD")){
          cookies = `${newdate.format("YYYY-MM-DD").toString()},${newdate.format("YYYY-MM-DD").toString()}`
        }else{
          cookies = `${newdate.format("YYYY-MM-DD").toString()},${(this.state as any).dateE.format("YYYY-MM-DD").toString()}`
        }
        
      }
      this.props.setCookies_Pars(this.props.cookies_name,cookies,"new")
    }else{
      if(date<(this.state as any).date.toDate()){newdate=(this.state as any).date
      }else if(date>this.props.end){newdate=moment(this.props.end)}
      this.setState({
        dateE:newdate
      });
      let cookies = this.props.setCookies_Pars(this.props.cookies_name,"","get")
      if(cookies){
        let cookiesL = cookies.split(",")
        cookiesL[1] = newdate.format("YYYY-MM-DD").toString()
        cookies = cookiesL.join(",")
      }else{
        cookies = `${(this.state as any).date.format("YYYY-MM-DD").toString()},${newdate.format("YYYY-MM-DD").toString()}`
      }
      this.props.setCookies_Pars(this.props.cookies_name,cookies,"new")
    }

  };
  

  render() {
    return (
      <div className="Datepicker">
        {this.props.buts.length ===1?
        <div className="Datepicker_box">
          <Button variant="outline-info"  id='but_only' onClick={()=>this.handleShow(0)}>{this.props.buts[0]}</Button>
          <div className="Datepicker_txt">{(this.state as any).date.toDate().toString()}</div>
        </div>
        :
        <div className="Datepicker_box">
          {this.props.buts.map((x:any,index:number) => {
            return <div>
              <Button variant="outline-info" id='but_only' onClick={()=>this.handleShow(index)}>{x}</Button>
              {index === 0?<div className="Datepicker_txt">{(this.state as any).date.format("YYYY-MM-DD")}</div>:<div className="Datepicker_txt">{(this.state as any).dateE.format("YYYY-MM-DD")}</div>}

            </div>

          })}
          
        </div>

        }
        
        

        <Modal show={(this.state as any).show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>请选择日期</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <DatePicker
            //   selected={this.state.date}
              selected={(this.state as any).index===0? (this.state as any).date.toDate():(this.state as any).dateE.toDate()}
              onChange={this.handleChangeDate}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default Datepicker;