import axios from 'axios';
import { PagePrompt } from "../configs/PagePrompt";
import SetupCookies from "./setupCookies";


const LoginCheck = (username:string,uuid:string,setloginCheckappFunc:any) => {
    if(username && uuid)
    {
        axios.post(`${PagePrompt.url}/user/logincheck`,{username:username,uuid:uuid,session_id:SetupCookies("session_id","","get")}).then(
            response => {
                if(response.data.errno === 0){
                    setloginCheckappFunc(true,(response.data.data.usertype === "S"))
                }
                
            },
            async error => {
                console.log(error.message.errono)
                setloginCheckappFunc(false,false)
                
            }
        ).catch(function (error){console.log(error)})
  
    }else{
        setloginCheckappFunc(false,false)
    }
    
}

  
export default LoginCheck;


