import React,{useState,useEffect} from "react";
import { AudioRecorder, useAudioRecorder  } from 'react-audio-voice-recorder';
import axios from "axios";
import { PagePrompt } from "../configs/PagePrompt";

interface VoiceRecorderProps{
    username:string
    trigger_micro?:number
    setCookies_Pars:Function
    setGeneratemsgBox:Function
    setMicrophoneTrigger:Function,
    waitinfoFunc:Function,
}
const VoiceRecorder: React.FC<VoiceRecorderProps> = (props) => {
    const [trigger_recorder, setTrigger_recorder] = useState(props.trigger_micro);
    const recorderControls = useAudioRecorder()
    const audio = document.createElement("audio");
    audio.setAttribute("id", "popup-audio");
    const addAudioElement = (blob:any) => {
        const url = URL.createObjectURL(blob);
        audio.src = url;
        audio.controls = true;
        if(document.getElementById("popup-audio")){document.getElementById("popup-audio")?.remove()}
        document.getElementById("popup-voice")?.appendChild(audio);

        const formData = new FormData();
        formData.append('file',blob,'myvoice.webm');
        let language = props.setCookies_Pars("language","","get")
        if (!language){language = "zh"}
        axios.post(`${PagePrompt.url}/msg/mp3?&username=${props.username}&language=${language}`, formData).then((result) => {
            if(result.status === 200){
                result =  result.data.message
                props.setGeneratemsgBox(`username:${result}`)
            }
        });
    };

    useEffect(()=>{
        if(trigger_recorder !== props.trigger_micro){
            if(props.trigger_micro === 2){
                // let audioPlayer: HTMLVideoElement = document.getElementById("popup-audio")?document.getElementById("popup-audio"):document.createElement("audio")
                const audioPlayer = document.getElementById("popup-audio2") as HTMLVideoElement;
                if(audioPlayer){audioPlayer.pause()}

                {/* document.getElementById("popup-audio")?.pause() */}
                recorderControls.startRecording()
            }
            else if(props.trigger_micro === 1){recorderControls.stopRecording()}
            setTrigger_recorder(props.trigger_micro)

        }
    },[props.trigger_micro]) 


    useEffect(()=>{
        if(recorderControls.isRecording){props.waitinfoFunc(true,"240000","正在录音中。。。")
        }else{props.waitinfoFunc(false)}
    },[recorderControls.isRecording]) 

    return (
        <div className='popup-voice' id="popup-voice">
            <AudioRecorder 
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                downloadFileExtension="mp3"
            />
        </div>
    );
}
    
VoiceRecorder.defaultProps={
    trigger_micro:0
}
export default VoiceRecorder;