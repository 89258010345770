import React from 'react';
import { PagePrompt } from "../configs/PagePrompt";

export interface AudioPlayerProps {
   filepath:string
   username:string
  }
 

class AudioPlayer extends React.Component <AudioPlayerProps,any> {
  constructor(props:any) {
    super(props);
    this.state = {
        filepath:props.filepath,
    };
  }


  static getDerivedStateFromProps(props:any, state:any) {
    if(props.filepath !== state.filepath){
      if(props.filepath !== "" && props.filepath !== null){
        try{
          const audio = document.createElement("audio");
          audio.setAttribute("id", "popup-audio2");
          audio.src = `${PagePrompt.url}/${props.username}/${props.filepath}`;
          audio.controls = true;
          audio.autoplay = true;
          if(document.getElementById("popup-audio2")){document.getElementById("popup-audio2")?.remove()}
          document.getElementById("popup-audio-box")?.appendChild(audio);
          return {
            filepath:props.filepath,
            };
          
        }
        catch(err) {
          console.log(err)
          return null
        }
      }
      else{
        return null
      }   
    }
    else{
      return null
    }    
  }

  render() {
    return (
      <div className='popup-audio-box' id="popup-audio-box"></div>
    )
  }

  
}

export default AudioPlayer