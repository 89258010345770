export const WorkflowPrompt: WorkflowP = {
    keywords:['添加zapier','启动zapier',
    '添加gmail','启动gmail','添加谷歌邮箱','启动谷歌邮箱', '添加谷歌翻译','启动谷歌翻译','添加谷歌新闻','启动谷歌新闻',
    '添加中华新闻','启动中华新闻','添加聚合数据','启动聚合数据','添加聚合新闻','启动聚合新闻', 
    '添加天气预报','启动天气预报',
    '添加文本阅读','启动文本阅读','添加文本阅读g','添加文本阅读g','添加文本阅读p','启动文本阅读p'],
    response:['zapier','zapier',
    "gmailservice","gmailservice","gmailservice", "gmailservice", "translation","translation","newsgoogle","newsgoogle",
    'topnews','topnews',"translation", "juhenews","juhenews","juhenews","juhenews",
    "meteomatics","meteomatics",
    "txtreader","txtreader","txtreader","txtreader","txtreaderpytt","txtreaderpytt"],
    
};

interface WorkflowP {
    keywords:Array<string>,
    response:Array<string>,
}
