import {UseURLLoader} from '../hooks/useURLLoader';
import { PagePrompt } from "../configs/PagePrompt";
export const WorkflowPlan = (props:any) =>{
    let Myworkflow_start: any[] = []
    let Myworkflow_end: any[] = []
    let Myworkflow : any[]
    if(props.autodrivePars){
        Object.entries(props.autodrivePars).map( ([key,value]) => {
            if(key === "translation"){
                if((value as any).port === "模型输出,模型输入"){
                    let content_translation = (value as any).content === "default" ?"": (value as any).content
                    let selectedlanguage =  (value as any).language_selection
                    if(selectedlanguage === undefined){selectedlanguage="中文（简体）"}
                    let HttpBody_translation = {...props.HttpBody_init,content:content_translation,selectedlanguage:selectedlanguage};
                    let task = {url:`api/translation`,HttpBody:HttpBody_translation}
                    Myworkflow_start.push(task)
    
                    // let detect_lang = langdetect.detect(input)
                    HttpBody_translation = {...props.HttpBody_init,content:content_translation,selectedlanguage:""};
                    task = {url:`api/translation`,HttpBody:HttpBody_translation}
                    Myworkflow_end.push(task)
                }
                else if((value as any).port === "模型输入"){ 
                    let content_translation = (value as any).content === "default" ?"": (value as any).content
                    let selectedlanguage =  (value as any).language_selection
                    if(selectedlanguage === undefined){selectedlanguage="中文（简体）"}
                    let HttpBody_translation = {...props.HttpBody_init,content:content_translation,selectedlanguage:selectedlanguage};
                    let task = {url:`api/translation`,HttpBody:HttpBody_translation}
                    Myworkflow_start.push(task)
    
                    }
                else{
                    let content_translation = (value as any).content === "default" ?"": (value as any).content
                    let HttpBody_translation = {...props.HttpBody_init,content:content_translation,selectedlanguage:""};
                    let task = {url:`api/translation`,HttpBody:HttpBody_translation}
                    Myworkflow_end.push(task)
                }
            }else if(key === "newsgoogle" || key === "topnews"){
                
            }else if(key === "txtreader"){
                let content_txtreader = (value as any).content === "default" ?"": (value as any).content
                let HttpBody = {...props.HttpBody_init,content:content_txtreader,voiceselection:(value as any).voice_selection,opt:(value as any).opt};
                let task={url:`api/reader`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                Myworkflow_end.push(task)
            }
            return []
        });
    
        let task = {url:`llm/sendmsg`,HttpBody:props.HttpBody}
        Myworkflow_start.push(task)
        if(Myworkflow_end.length !== 0){
            Myworkflow =  Myworkflow_start.concat(Myworkflow_end)
        }else{
            Myworkflow = Myworkflow_start
        }
    
        return Myworkflow
        
    }
    else{return []}
    
    
}

// 执行
// props.obj props.output props.setinfoFunc props.HttpBody_init props.input
export const WorkflowExecute = async (props:any) =>{
    // Myworkflow = planFunc()
    let output = props.output
    
    let HttpBody_translation
    let detect_lang = props.obj.HttpBody.selectedlanguage

    let content = props.obj.HttpBody.content ===  "default" ? "":props.obj.HttpBody.content
    if(props.obj.url  === "api/translation"){
        if (props.obj.HttpBody.selectedlanguage === ""){
            let props1={url:"api/lang",setinfoFunc:props.setinfoFunc,HttpBody:{...props.HttpBody_init,input:props.input}}
            detect_lang =  await UseURLLoader(props1).then(result =>
                {
                    if(result){
                        return result
                    }
                }
            )
            // langdetect.detect(props.input)
        }
        if(output!== undefined && output !== ""){content +=  output
        }else{content +=  props.input}

        HttpBody_translation = {...props.obj.HttpBody,content:content,selectedlanguage:detect_lang};
        let props1={url:`api/translation`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody_translation}
        output = await UseURLLoader(props1).then(result =>
            {
                if(result){
                    
                    if(result.indexOf("(原文)") !== -1){
                        result = ""
                    }
                    return result
                    
                }
            }
        )

        if(output !== ""){
            //输出结果
            // props.generatereplymsgbox("google翻译",output,[])
            return ["google翻译",output]
        }else{return}
    }else if(props.obj.url  === "api/reader"){
        content = output
        let HttpBody_txtreader = {...props.obj.HttpBody,content:content};
        let username = HttpBody_txtreader.username
        let props1={url:`api/reader`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody_txtreader}
        output = await UseURLLoader(props1).then(result =>
            {
                if(result){
                    const audio = document.createElement("audio");
                    audio.setAttribute("id", "popup-audio2");
                    audio.src = `${PagePrompt.url}/${username}/${result}`;
                    audio.controls = true;
                    audio.autoplay = true;
                    if(document.getElementById("popup-audio2")){document.getElementById("popup-audio2")?.remove()}
                    document.getElementById("popup-audio-box")?.appendChild(audio);
                    // if(result.indexOf("(原文)") !== -1){
                    //     result = ""
                    // }
                    return result
                    
                }
            }
        )
    }
        
    
}


// export default WorkflowPlan,WorkflowExecute
// async function sendMSG(HttpBody:any){
    //     return await axios.post(`${PagePrompt.url}/llm/sendmsg`,HttpBody).then(
    //         async response => {
    //             return response.data.data.message         
    //         },
    //         async error => {
    //           console.log(error.message.errono)
    //           return
    //         }
    //       ).catch(function (error){console.log(error);return})
    // }