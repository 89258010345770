import brain_b from "../../assets/img/frontpage/brain_c.png"
import photo10 from "../../assets/img/frontpage/photo10.png"
import photo9 from "../../assets/img/frontpage/photo9.png"
import photo8 from "../../assets/img/frontpage/photo8.png"
import photo7 from "../../assets/img/frontpage/photo7.png"
import photo6 from "../../assets/img/frontpage/photo6.png"
import photo5 from "../../assets/img/frontpage/photo5.png"
import photo4 from "../../assets/img/frontpage/photo4.png"
import photo2 from "../../assets/img/frontpage/photo2.png"
import photo1 from "../../assets/img/frontpage/photo1.png"
import photo0 from "../../assets/img/frontpage/photo0.png"
import photo3 from "../../assets/img/frontpage/photo3.png"
import photo11 from "../../assets/img/frontpage/photo11.png"
import photo12 from "../../assets/img/frontpage/photo12.png"
import photo13 from "../../assets/img/frontpage/photo13.png"
import networkdots from "../../assets/img/frontpage/networkdots.png"
import cat_logo from '../../assets/img/frontpage/cat_logo.png'
import React  from "react";
import "./frontpage.css"
interface FrontpageProps{
    username?:string
    loginCheckapp:boolean
    selectedLLM:string
}
const Frontpage: React.FC<FrontpageProps> = (props) => {
    return (
        <div id="front-page">
            <div className="background_layer0"><img src={networkdots} alt="" /></div>
            <div className="background_layer"><img src={brain_b} alt="" style={{width:"500px"}} /></div>
            <div id="photo_layer">
                <img id="photo_1"  src={photo1} className="small-photo-size3" alt=""  />
                <img id="photo_2" src={photo2} className="small-photo-size2" alt=""  />
                <img id="photo_0" src={photo0} className="small-photo-size3" alt=""  />
                <img id="photo_4" src={photo4} className="small-photo-size2" alt=""  />
                <img id="photo_5" src={photo5} className="small-photo-size1" alt=""  />
                <img id="photo_6" src={photo6} className="small-photo-size1" alt=""  />
                <img id="photo_7" src={photo7} className="small-photo-size1" alt=""  />
                <img id="photo_8" src={photo8} className="small-photo-size1" alt=""  />
                <img id="photo_9" src={photo9} className="small-photo-size2" alt=""  />
                <img id="photo_10" src={photo10} className="small-photo-size3" alt=""  />
                <img id="photo_3" src={photo3} className="small-photo-size2" alt=""  />
                <img id="photo_11" src={photo11} className="small-photo-size1" alt=""  />
                <img id="photo_12" src={photo12} className="small-photo-size1" alt=""  />
                <img id="photo_13" src={cat_logo} className="small-photo-size3" alt=""  />
                <img id="photo_14" src={photo13} className="small-photo-size3" alt=""  />
                </div>
            <div id="topic_layer">
                <h2 className="topic1">粒子智算</h2>
                <h5 className="topic2">让AI助力您的学习 • 工作 • 生活</h5>
                <h6 className="topic3"> ― AI Agent (智能体工场)</h6>

            </div>
        </div>
        
    )
    
    
}
Frontpage.defaultProps={
    username:"游客",
    selectedLLM:"API设置"
}
export default Frontpage