import { useState,useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import './admin.css'
import axios from 'axios'
import Popup from '../../tools/Popup';
import { PagePrompt } from "../../configs/PagePrompt";
import SetupCookies from '../../tools/setupCookies';
interface AdminProps{
    username?: string
    userList?: Array<any>
    msgList?: Array<any>
}

const userTable=["id",'用户名','密码','电话','邮箱','用户类别','Token（日）','最后使用日期','Token（月）','创建时间','更新时间','Api_list','History_list','Message_list','Workflow_list','Vote_list']
const Admin: React.FC<AdminProps> = (props) => {      
    const [userList, setuserList] = useState(props.userList || []);
    const [msgList, setmsgList] = useState(props.msgList || []);
    const [userPopup,setuserPopup] = useState(false)
    const [messagePopup,setmessagePopup] = useState(false)
    const [userdata,setuserdata] = useState(Object)
    const [userdataList,setuserdataList]=useState([])
    const [userdataListIndex,setuserdataListIndex]=useState(0)
    const [showusermsg,setshowusermsg] = useState<string | undefined>()
    const [replymsgS,setreplymsgS] = useState<string | undefined>()
    const [msgIndex,setmsgIndex]=useState({})
    const [replyIndex,setreplyIndex]=useState<number>(0)
    let HttpBody_init= {'username': props.username, uuid:SetupCookies("uuid","","get"),session_id:SetupCookies("session_id","","get")}
    

    useEffect(() => { //监控props.userList状态变化
        setuserList(props.userList|| [])
        setmsgList(props.msgList || [])
    }, [props.userList,props.msgList]);
    

    let showreplyList = (msgList as any).map((obj:any,index:number) => (
        <tr>
        <th>{obj.message_id}</th>
        <th>{obj.username}</th>
        <th style={{width:"800px", maxWidth: "800px", textAlign:"left"}}>{obj.title}</th>
        <th> 
        <Button  variant="outline-primary" onClick={() => replymsgcheck(obj.message_id,index)} >回复</Button>
        </th>
        </tr>
    ))

    
    function HttpPost_user(url:string,HttpBody:Object){
        axios.post(`${PagePrompt.url}/user/${url}`,HttpBody).then(
            response => {
                if (url === "id"){
                    setuserdata(response.data.data)
                    setuserdataList(Object.values(response.data.data))
                    setuserPopup(true)
                }else if (url === "usermodify"){
                    delete (HttpBody as any).username
                    for (const [key, value] of Object.entries(HttpBody)) {
                        if((props.userList as any)[userdataListIndex][key]){(props.userList as any)[userdataListIndex][key] = value}
                    }
                    setuserPopup(false)
                }
            },
            async error => {
                console.log(error.message.errono)
            }
        ).catch(function (error){console.log(error)})
    }
    function  searchuser(user_id:number,index:number){
        setuserdataListIndex(index)
        HttpPost_user('id',{user_id:user_id})
    
    }
    function changesetList(list:any,setlist:any,value:any,index:number){
        const nextlist = list.map((c:any, i:number) => {
            if (i === index) {
              // Increment the clicked counter
              return value;
            } else {
              // The rest haven't changed
              return c;
            }
          });
          setlist((nextlist as any));
    }

    function deletesetList(list:any,setlist:any,index:number){
        const nextlist = list
        nextlist.splice(index,1)
        setlist((nextlist as any));
    }

    function handleAdminUserInfoChange(value:any,index:number){
        changesetList(userdataList,setuserdataList,value,index)
    }
    
    let HttpBody={}
    function modifyuserinfo(){
        
        let keys = Object.keys(userdata)
        let values = Object.values(userdata)
        
        for(var i=3; i< userdataList.length; i++){
            if((values[i] || userdataList[i]) && userdataList[i] !== values[i]){
                HttpBody={...HttpBody, [keys[i]]:userdataList[i]}
            }
        }
       
        if(Object.values(HttpBody).length >0){ 
            HttpBody={...HttpBody,username:userdataList[1]}
            HttpPost_user('usermodify',HttpBody)
        }
            
        
    }

    function HttpPost_msg(url:string,HttpBody:Object){
        axios.post(`${PagePrompt.url}/msg/${url}`,HttpBody).then(
            response => {
                if(url === 'getmsg'){
                    let meg = "标题:"+response.data.data[0].title+"\n\n"
                    JSON.parse(response.data.data[0].content).map((opt:any) => ( 
                        meg+=  `${opt.sender} :  (${opt.date}) \n  ${opt.msg}  \n \n `
                    ))
                    setshowusermsg(meg)
                    setmessagePopup(true)
                    setmsgIndex({message_id:(HttpBody as any).message_id,content:JSON.parse(response.data.data[0].content)})
                }else if(url === "renewmsg"){
                    setreplymsgS("")
                    deletesetList(msgList,setmsgList,replyIndex)
                    setmessagePopup(false)
                }
                
            },
            async error => {
                console.log(error.message.errono)
            }
        ).catch(function (error){console.log(error)})
    }
    function replymsgcheck(msg_id:number,index:number){
        let HttpBody = {...HttpBody_init,message_id:msg_id}
        HttpPost_msg("getmsg",HttpBody)
        setreplyIndex(index)
    }
    function replymsg(){
        const date = new Date()
        let dateTime = date.toISOString().split(".")[0]
        let msg = [...(msgIndex as any).content, {sender:props.username,msg:replymsgS,date:dateTime}]
        let HttpBody={...HttpBody_init,content:msg,message_id:(msgIndex as any).message_id,replycode:1}
        HttpPost_msg("renewmsg",HttpBody)
    }

    return(
        <div className='admin'>
        <Tabs
        defaultActiveKey="user_info"
        id="justify-tab-example"
        className="mb-3"
        justify
        >
            <Tab eventKey="user_info" title="用户信息列表" >
            <table id='admin-table'>
                    <tr>
                    <th> 用户ID</th>
                    <th> 用户名</th>
                    <th> 电话</th>
                    <th> 邮箱地址</th>
                    <th> 密码</th>
                    <th> 用户类型</th>
                    <th> Token统计（天）</th>
                    <th> 最后使用日期</th>
                    <th> ...</th>
                    </tr>
                {(userList as any).map((obj:object,index:number) => (
                    <tr>
                    {Object.values(obj).map((items,i)=>(
                        <th>{items}</th>
                    ))}
                    
                    <th> 
                    <Button  variant="outline-primary" style={{marginRight:"5px"}} onClick={() => searchuser((obj as any).user_id, index)} >查询</Button>
                    {/* <Button  variant="outline-primary" onClick={() => modifyuser((obj as any).user_id)} >修改</Button> */}
                    </th>
                    </tr>
            ))}
                
            </table>
            </Tab>
            <Tab eventKey="message_win" title="信息回复窗口" >
            <table id='admin-table'>
                <tr>
                    <th> 信息ID</th>
                    <th> 用户名</th>
                    <th style={{width:"300px", maxWidth: "300px"}}> 主题</th>
                    <th> ...</th>
                </tr>
                
                {showreplyList}
                
            </table>
            </Tab>
        </Tabs>
        <Popup setTrigger={setuserPopup} 
            trigger = {userPopup}
            dialogWidth = '600px'
            title='查看和修改用户信息'
            >
                <table id='admin-modify-table'>
                    {(userdataList as any).map((obj:any,index:number) => (
                        <tr>
                        <th>{userTable[index]}</th>
                        <th><input type="text" value={userdataList[index]} onChange={(e) =>handleAdminUserInfoChange(e.target.value,index)} style={{width: "320px"}}/></th>
                        </tr>
                    ))}  
                </table>
            
            <Button  variant="outline-primary" onClick={() => modifyuserinfo()} >修改</Button>
            
        </Popup>
        <Popup setTrigger={setmessagePopup} 
            trigger = {messagePopup}
            dialogWidth = '600px'
            title='回复用户留言'
            >
           <textarea  className='Popup-leavemsg' rows={10} readOnly onChange={(e) =>setshowusermsg} value={showusermsg}></textarea> 
           <textarea  className='Popup-leavemsg2' value={replymsgS} placeholder='请输入回复信息' rows={5} onChange={(e) => setreplymsgS(e.target.value)}/> 
            <Button  variant="outline-primary" onClick={() => replymsg()} >发送</Button>
            
        </Popup>
        </div>
        
    )
}





Admin.defaultProps={
    username:"",
    
}
export default Admin