import React,{useState}  from "react";
import "./PromptSpliter.css"
import Button from 'react-bootstrap/Button';
import { IconPrompt } from "../../configs/iconPrompyt";
interface PromptSpliterProps{
    article:string
    PromptSplitertrigger:boolean
    setPromptSplitertrigger:Function
    setinfoFunc:Function
    setsplitorInput:Function
}
const IMG_Libs = IconPrompt.IMG_Libs
var page = 0
var chaptername_index = 0
const PromptSpliter: React.FC <PromptSpliterProps>= (props) => {
    const [chaptername1, setchaptername1] = useState<string>("")
    const [chaptername2, setchaptername2] = useState<string>("")
    const [chaptername3, setchaptername3] = useState<string>("")
    const [readonlychaptername,setreadonlychaptername] = useState<any[]>([false,false,false])
    const [showcontent,setshowcontent] = useState<string>()
    const [splitorinput, setsplitorinput] = useState<string>("")
    



    var num_page = Math.ceil(props.article.length/500)

    function confirmchaptername(opt:number){
        let readonly = [...readonlychaptername]
        readonly[opt-1] = !readonly[opt-1]
        
        if(readonly[opt-1]){
            if(chaptername_index === 2){
                chaptername_index = 0 
                readonly[0] = false
            }else{
                chaptername_index += 1
                readonly[chaptername_index] = false
            }
            
        }else{
            chaptername_index = opt-1
        }
        setreadonlychaptername(readonly)

    }
    function generateTstyle(){
        if((chaptername1 && chaptername1.trim().length>0) && (chaptername2 && chaptername2.trim().length>0) && (chaptername3 && chaptername3.trim().length>0)){
            let ch1 =  Array.from(chaptername1)
            let ch2 =  Array.from(chaptername2)
            let ch3 =  Array.from(chaptername3)
            let start:any[] = []
            let end:any[] = []
            let stopmark = 0
            ch1.forEach((e,index) => {
                if(e === ch2[index] && e === ch3[index] && stopmark === 0){
                    start.push("\\"+e)
                }
                else{
                    stopmark = 1
                }
            });
            stopmark = 0
            ch1.reverse().forEach((e,index) => {
                if(e === ch2.reverse()[index] && e === ch3.reverse()[index] && stopmark === 0){
                    end.push("\\"+e)
                }
                else{ stopmark = 1 }
            });

            if(start.length>0){
                setsplitorinput(start.join("")+"(.+?)"+end.join(""))
            }else{
                setsplitorinput("[err]由于选择的标题并非遵循一定规律，\n 无法生成标题样式。")
                
            }
        }
    }
    function pagecontrol(opt:number){
        if(opt === 0 && page>0){
            page -=1
            setshowcontent(props.article.slice(page*500,(page+1)*500))

        }else if(opt === 1 && page<num_page-1){
            page +=1
            setshowcontent(props.article.slice(page*500,(page+1)*500))
        }
        return page
    }

    function onMouseUpFunc(){
        
        let selObj:any = window.getSelection();
        let article:string = showcontent?showcontent:props.article.slice(0,500)
        if(chaptername_index+1 === 1){
            setchaptername1(article.substring(selObj?.anchorOffset, selObj?.focusOffset))
        }
        else if (chaptername_index+1 === 2){
            setchaptername2(article.substring(selObj?.anchorOffset, selObj?.focusOffset))
        }else{ setchaptername3(article.substring(selObj?.anchorOffset, selObj?.focusOffset))}
        
    }
    function styleconfirm(){
        if(splitorinput !== "" && splitorinput.slice(0,4) !=="[err]"){
            document.getElementById("splitor_input")?.setAttribute("value",splitorinput) 
            props.setsplitorInput(splitorinput)
            props.setPromptSplitertrigger(false)
        }
    }

    function closestyle(obj:any){
        if( obj.id === "PromptSpliter"){ props.setPromptSplitertrigger(false)}
    }
    
   
    return (props.PromptSplitertrigger)?(
        <div id="PromptSpliter" onClick={(e)=>closestyle(e.target)}>
            <div className="PromptSpliter_inner" >
                <h5 className="topic4">❖  &nbsp; 请根据文件内容，标注章节的名称</h5>
                <div className="dbconnection_content" style={{textAlign:"left"}}>
                    <div className="article_box" >
                        <h6>请标注3个章节名称，确保章节的名称在每个章节开头，并遵循一定规律，比如: 第n章，[标题], (标题)...。</h6>
                        <h6> 例如：</h6>
                        <div className="article_content_e"><mark>{"第一章：\n"}</mark>{"内容... \n\n"} <mark>{"第二章：\n"}</mark>{"内容... \n\n"} <mark>{"第三章：\n"}</mark> {"内容... \n"}</div>
                        <div className="article_title_e">{"--> 章节的名称1： 第一章：\n \n\n --> 章节的名称2： 第二章：\n  \n\n --> 章节的名称3： 第三章：\n  \n"}</div>
                        <div className="article_title_e">{"--> 生成的标题样式："} <mark>第(.+?)章：\n</mark> {"\n \n\n\n \n "}</div>
                        <h6 style={{marginTop:"10px"}}>截取方式：用鼠标点击标题左侧，拖拽，到结尾处松开。请将生成的标题样式，点击[确认]。</h6>
                    </div>
                    <br />
                    <div className="article_box" >
                        <h6>文章：<p className="samplespan" onClick={()=>pagecontrol(0)}>上一页</p> &nbsp; <p className="samplespan" onClick={()=>pagecontrol(1)}>下一页</p></h6>
                        <div className="article_content" onMouseUp={(e) => onMouseUpFunc()}>
                            {showcontent?showcontent:props.article.slice(0,500)}
                            </div>
                        <div className="article_title_e" style={{verticalAlign:"top"}}>
                            {" \n\n 章节的名称1：\n"} 
                            <input type="text" id={'item_input_chaptername1'} className='item_input_chaptername' name={"chaptername1"}  value={chaptername1} onChange={(e)=>setchaptername1(e.target.value)} maxLength={50} readOnly={readonlychaptername[0]} style={{backgroundColor:readonlychaptername[0]?"transparent":"white"}}></input>
                            <img src={readonlychaptername[0]?IMG_Libs["check0"]:IMG_Libs["check1"]} style={{marginLeft:"10px"}} className="me-2" width="30" alt="" onClick={()=>confirmchaptername(1)}/>
                            {" \n\n 章节的名称2： \n"} 
                            <input type="text" id={'item_input_chaptername2'} className='item_input_chaptername' name={"chaptername2"}  value={chaptername2} onChange={(e)=>setchaptername2(e.target.value)} maxLength={50} readOnly={readonlychaptername[1]} style={{backgroundColor:readonlychaptername[1]?"transparent":"white"}}></input>
                            <img src={readonlychaptername[1]?IMG_Libs["check0"]:IMG_Libs["check1"]} style={{marginLeft:"10px"}} className="me-2" width="30" alt="" onClick={()=>confirmchaptername(2)}/>
                            {" \n\n 章节的名称3： \n"}
                            <input type="text" id={'item_input_chaptername3'} className='item_input_chaptername' name={"chaptername3"}  value={chaptername3} onChange={(e)=>setchaptername3(e.target.value)} maxLength={50} readOnly={readonlychaptername[2]} style={{backgroundColor:readonlychaptername[2]?"transparent":"white"}}></input>
                            <img src={readonlychaptername[2]?IMG_Libs["check0"]:IMG_Libs["check1"]} style={{marginLeft:"10px"}} className="me-2" width="30" alt="" onClick={()=>confirmchaptername(3)}/>
                            <br />
                      
                            <div className='but_chaptername'><Button id='but' variant="outline-info" onClick={() => generateTstyle()} >生成标题样式</Button>
                            &nbsp;<Button id='but' variant="outline-info" onClick={() => styleconfirm()} >确认</Button></div>
                           
                            <div className='but_chaptername'> <mark>{splitorinput}</mark> </div>
                        </div>
                        
                    </div>
                </div>

        
            </div>
        </div>
    ):
    <div></div>
    
    
}

export default PromptSpliter