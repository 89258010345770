import paw from '../assets/img/LoginImage/paw.png'
import './waitInfo.css'
interface WaitInfoProps{
    message?:string
}
const WaitInfo:  React.FC<WaitInfoProps> = (props) => {
    return(
        <div id='waitinfo'>
            <div className='waitinfo_box'>
                <img src={paw} className="rounded me-2 waitinfo_box_img" width="15" alt="" />
                <div className="waitinfo_box_text">{props.message}</div>
            </div>
        </div>
    )
}
WaitInfo.defaultProps={
    message:"正在努力加载中。。。",
}
export default WaitInfo;