import React,{useState, useEffect}  from "react";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Logo from '../../assets/img/lizhi.png'
import  './header.css'
import { Login } from "../login/Login";
import SetupCookies from "../../tools/setupCookies";

interface HeaderProps{
    loginCheckapp:boolean,
    username?:string
    uuid?:string,
    getChilduser_uuid:Function,
    cleanupCookies:Function
}

const Header: React.FC<HeaderProps> =  (props) => {
    const [LoginPopup,setLoginPopup] = useState(false)
    const [loginCheck, setloginCheck] = useState(false)

    useEffect(() => { //监控props.userList状态变化
        setloginCheck(props.loginCheckapp)
    }, [props.loginCheckapp]);

    function logout(){
        SetupCookies("password","","delete")
        SetupCookies("username","","delete")
        props.cleanupCookies()
        setloginCheck(false)
    }

    const get2Childuser_uuid = (item1:any,item2:any,item3:Boolean,item4:any) =>{
        props.getChilduser_uuid(item1,item2,item3)
        setloginCheck(item4)
      }

    

    return (
        <>
        <Navbar className="bg-body-tertiary pb-0 ml-0">
            <Container id='header_container'>
            <Navbar.Brand href="/home">
            <a href="/"><Image src={Logo}  width="50" className="logo_img"/></a>
            &nbsp;  &nbsp; &nbsp; 粒子智算 · 工场
            </Navbar.Brand>
            {/* style={{display:"inline-block",marginTop:"5px"}} */}
                <div style={{display:(loginCheck? "block":"none")}} onChange={() => setloginCheck}>
                    <div id="header-login-username" style={{right:(180 + (props.username?.length || 0)).toString()+"px"}}><h5>{props.username}</h5></div>
                    <Button id="header-login-but" variant="outline-info" onClick={()=>logout()}>
                    登出
                    </Button>
                </div>
                
                
                <div style={{display:(loginCheck? "none":"block")}} onChange={() => setloginCheck}>
                    <Login 
                    setTrigger={setLoginPopup}
                    trigger = {LoginPopup}
                    get2Childuser_uuid={get2Childuser_uuid}
                    ></Login>
                    
                    <Button id="header-login-but" variant="outline-info" onClick={()=>setLoginPopup(true)}>
                        登陆
                    </Button>
                </div>
                
                
            </Container>
        </Navbar>
        
        </>

    )
}

Header.defaultProps={
    loginCheckapp: false,
    username:"",
    uuid:"",
}
export default Header