import React,{useState,useEffect} from 'react'
import { UseURL } from '../hooks/useURLLoader';
import { GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';
const containerStyle = {
  width: '360px',
  height: '400px'
};

interface GMapProps{
  isMarkerShown:any,
  center:any
  setCookies_Pars:Function
  googleMapsApiKey:string
  modetype:string,
  waitinfoFunc:Function,
  setinfoFunc:Function,
  language:string,
  changeOriginDestination:Function,
  addmark:boolean;
  addmarkFunc:Function,
}


const GMap:  React.FC<GMapProps> = (props) => {
  
  let Cookies_Pars = props.setCookies_Pars("mylocation","","get")
  let locationList = props.setCookies_Pars("locationmark","","get")
  let selectionListAll= props.setCookies_Pars("selectionListAll","","get")
  if(locationList){locationList = locationList.split(",")}
  if(selectionListAll){selectionListAll=selectionListAll.split(",")}
  
  const [map, setMap] = useState<any>(null)
  const [mapL, setMapL] = useState<any>(null)
  const [center,setCenter] = useState<any>(props.center)
  const [markerL,setMarkerL] = useState<any>(null)
  const [markerI,setMarkerI] = useState<any>(null)
  let setmap

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: props.googleMapsApiKey
  })

  const onLoad = React.useCallback(function callback(map:any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    
    map.fitBounds(bounds);
    
    setMap(map)
  }, [])

  

  const onUnmount = React.useCallback(function callback(map:any) {
    setMap(null)
  }, [])

  useEffect(() => {
    
    let language = props.language?props.language:"en"
    function setLocation(obj:any){
      let result
      let type
      let latlng = /^(-)?\d{1,2}.\d{2,14},(-)?\d{1,2}\.\d{2,14}$/;
      if(latlng.test(obj)){
        result = obj
        type = 0
      }
      else{
        result = obj.replaceAll(" ","+")
        type = 1
      }
      return [result,type]
    }

    async function getDistance(ori:string,dest:string){
      let Gmapurl = `https://maps.googleapis.com/maps/api/directions/json?origin=${ori}&destination=${dest}&key=${props.googleMapsApiKey}`
      props.waitinfoFunc(true,30000)
      await UseURL(Gmapurl,props.setinfoFunc).then(result =>
          {
              props.waitinfoFunc(false)
              if(result){
                let distance  = result.distance.text
                let duration = result.duration.text
                return [distance,duration]
              }
          }
      ).catch(function (error){console.log(error);props.waitinfoFunc(false)})
    }
    

    if(props.modetype === "satellite"){
      let [locationpars,type] = setLocation(Cookies_Pars)
      
      setmap = <iframe
        width="350px"
        height="400px"
        // frameborder="0" 
        style={{border:"0"}}
        referrerPolicy='no-referrer-when-downgrade'
        src={`https://www.google.com/maps/embed/v1/place?key=${props.googleMapsApiKey}&q=${locationpars}&maptype=satellite&language=${language}&zoom=18`}
        allowFullScreen
        title={props.modetype}
        >
      </iframe>
      setMapL(setmap)
      
    }else if(props.modetype === "directions"){
      if(locationList ){
        if(locationList.length < 4){
          props.setinfoFunc(`请刷新[添加标记]后，选中起始位置和目的地`,"4000")
        }else{
          setmap = 
          <div>
            <iframe
            width="400px"
            height="500px"
            // frameborder="0" 
            style={{border:"0"}}
            referrerPolicy='no-referrer-when-downgrade'
            src={`https://www.google.com/maps/embed/v1/directions?key=${props.googleMapsApiKey}&origin=${locationList[0]},${locationList[1]}&destination=${locationList[2]},${locationList[3]}&language=${language}`}
            allowFullScreen
            title={props.modetype}
            >
          </iframe>
            <button className='fold_contrl' id='googlemap' type="button" onClick={e=>props.changeOriginDestination(locationList)}>⭥</button> 
          </div>
          
          setMapL(setmap)
          
        }
      }else{props.setinfoFunc(`请刷新[添加标记]后，选中起始位置和目的地`,"4000")}

      
    }else if(props.modetype === "mark"){
      
      let seletDict = []

      if(selectionListAll){

        for(var i=0; i< selectionListAll.length;i=i+2){
          seletDict.push({
            lat: Number(selectionListAll[i]),
            lng: Number(selectionListAll[i+1])
          })
          if(i===0){
            setCenter({
              lat: Number(selectionListAll[i]),
              lng: Number(selectionListAll[i+1])
            })
          }
        }
        setMarkerL(seletDict)
      }else if(center.lat !==  Number(Cookies_Pars.split(",")[0]) || center.lng !==  Number(Cookies_Pars.split(",")[1])){
        setCenter({
            lat: Number(Cookies_Pars.split(",")[0]),
            lng: Number(Cookies_Pars.split(",")[1])
          })
      }
      
    }else if(props.modetype === "place" || Cookies_Pars){
      let [locationpars,type]  = setLocation(Cookies_Pars)
      setmap = <iframe
        width="400px"
        height="500px"
        // frameborder="0" 
        style={{border:"0"}}
        referrerPolicy='no-referrer-when-downgrade'
        src={`https://www.google.com/maps/embed/v1/place?key=${props.googleMapsApiKey}&q=${locationpars}&language=${language}`}
        allowFullScreen
        title={props.modetype}
        >
      </iframe>
      setMapL(setmap)
    }
    else{
      setMapL(null)
    }
  
  }, [props,Cookies_Pars,center]);

  
  const onMapClick= (e:any) => {
    setMarkerI(
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    );
    const latlag=`${e.latLng.lat()},${e.latLng.lng()}`
    props.addmarkFunc(latlag)
  };

  return isLoaded&&props.modetype === "mark" ? (//isLoaded&&
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={e=>onMapClick(e)}
      >
        {markerL? markerL.map((element:any) => {
          return <Marker position={element} />
        }):<Marker position={center} />}
        {markerI?<Marker position={markerI} />: <div></div> }
        <></>
      </GoogleMap> 
    </div>
  ) : <div>{mapL}</div>
}

export default GMap;
    

