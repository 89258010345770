import check2 from '../assets/img/toolsImage/check2.png'

interface ShowInfoProps{
    message:string
    time:string
}
const ShowInfo:  React.FC<ShowInfoProps> = (props) => {
    return(
        <div id='showinfo'>
            <div className='showinfo_box'>
                <img src={check2} className="rounded me-2 showinfo_box_img" width="15" alt="" />
                <div className="showinfo_box_img">{props.message}</div>
            </div>
        </div>
    )
}

export default ShowInfo;