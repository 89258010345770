import React, {  useEffect, useState, useRef }   from "react";
import Card from 'react-bootstrap/Card';
import baichuanAPI from "../../../src/APILibs/baichuan.json";
import SetupCookies from '../../tools/setupCookies';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import FlowBox from "../flowBox/flowBox";
import { PagePrompt } from "../../configs/PagePrompt";
import { IconPrompt } from "../../configs/iconPrompyt";
// import axios from 'axios'
import {UseURLLoader} from '../../hooks/useURLLoader';


interface WorkFlowProps{
    message?:string
    selectedLLM:string
    username?:string
    setSelectparsListFunc:Function,
    selectparsList:[],
    setparsconfirm:Function,
    loginCheckapp:boolean,
    setenable_memory_ST:Function,
    workFlowAddList:string[],
    setworkFlowAddList: Function,
    workFlowAdd_on: Function,
    setinfoFunc:Function,
    cleanupAPICookies:Function,
    boxwidth:number,
    setBoxwidth:Function,
    setFillin_txt:Function,
    setFillin_file:Function,
    fillin_txt:any,
    fillin_file:any,
    setGeneratemsgBox:Function,
    setAutodrivePars:Function,
    newscontent:any,
    setNewscontent:Function,
    waitinfoFunc:Function,
    microphoneTrigger:number,
    setMicrophoneTrigger:Function,
}
var countstep = 0
var gpt_name = ""
const stopIndexDict:{[key:string]:number} = PagePrompt.stopIndexDict
const LLMlibs :{[key:string]:any} = PagePrompt.LLMlibs
const APILibs :{[key:string]:any} = PagePrompt.APILibs
const gpt_name_Libs: {[key:string]:any} = PagePrompt.gpt_name_Libs
const IMG_Libs = IconPrompt.IMG_Libs
const WorkFlow: React.FC<WorkFlowProps> = (props) => {
    const [workFlowAdd,setworkFlowAdd] = useState( <div></div>)
    const [isfold, setIsfold] = useState("<·· ");
    const [refresh_newscontent, setRefresh_newscontent] = useState<any>();

    let HttpBody_init= {'username': props.username, uuid:SetupCookies("uuid","","get"),session_id:SetupCookies("session_id","","get")}


    async function setAutodriveParsFunc(opt:boolean){
        if(opt){
            let parscheck = true;
            let content : {[key: string]: any}= {};
            let alartinfo
            let content_result
            (props.workFlowAddList as any).forEach( async (elem:string,index:number) => {
                if(!["saveworkflow","getworkflow"].includes(elem)){
                    [content_result,parscheck,alartinfo] = HttpBody_parsetup(elem,{},parscheck)
                    content[elem]=content_result
                }
                
                if(["newsgoogle","topnews"].includes(elem)){
                    //生成向量数据库
                    //检查输入框是否为空，
                    let searchcontent = SetupCookies("searchcontent","","get")
                    if(elem === "newsgoogle" &&  searchcontent !== undefined ){
                        if(searchcontent.trim() === ""){
                            alartinfo = "请先设定搜索内容"
                            parscheck = false
                        }
                    }else if(elem === "newsgoogle"){
                        alartinfo = "请先设定搜索内容"
                        parscheck = false
                    }
                    if(parscheck){
                        if(initrefresh_newscontent.current !== undefined){
                            // already have  newsList and Newscontent
                            let HttpBody: {[key:string]:any} = {...HttpBody_init}
                            let newDict: {[key:string]:any} = {}
                            let index = 0
                            for await ( const  elem of (initrefresh_newscontent.current as any) ) {
                                let dict = {"title":elem[0],"content":elem[1],"url":elem[2]}
                                newDict[index]=dict
                                index +=1
            
                            };

                            HttpBody["newDict"] = newDict
                            let props1={url:`llm/newsprompt`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                            props.waitinfoFunc(true)
                            await UseURLLoader(props1).then( async result =>
                                {   
                                    // console.log(result)
                                    if(result){
                                        SetupCookies("prompt_selection",result,"new")
                                        props.waitinfoFunc(false)
                                    }else{
                                        props.setinfoFunc("请重新点击开始搜索，然后开启自动生成模式.","2000")
                                        props.waitinfoFunc(false)
                                    }
                                }
                            )
                        }else{
                             //need search
                        }
 
                    }

                }
                
            })
            if(parscheck){props.setAutodrivePars(content)}
            else{alert(alartinfo)}
        }else{ 
            props.setAutodrivePars(null)
            SetupCookies("prompt_selection","","delete")}
    }

    function setIsHoveredFunc(val:boolean){
        // setIsHovered(val)
        if(val){props.setBoxwidth(45)
        }else{props.setBoxwidth(isfold === "··> "?45:70)}
    }
    function setIsfoldFunc(){
        if(isfold === "<·· "){
            setIsfold("··> ")}
        else{
            setIsfold("<·· ")}
    }
    let workFlowAddinnerHTML= [ <div></div> ]
    function renderworkFlow(){
        let APIdata: any
        gpt_name= gpt_name_Libs[props.selectedLLM]
        countstep=0
        if(props.workFlowAddList.length>0){
            props.workFlowAddList.forEach((item,index)=>{
                countstep+=1
                if(Object.keys(APILibs).slice(7,Object.keys(APILibs).length).indexOf(item) !== -1){
                    APIdata = (APILibs as any)[item]
                    APIdata.APIpars[0].name_in_db = gpt_name+APIdata.APIpars[0].name_in_db
                }
                let dict : {[key: string]: string} ={}
                let dict2 : {[key: string]: string} ={}
                APIdata.APIpars.forEach((elem:any) => {
                    if(["textarea" , "input"].includes(elem.type) && elem.parsName.includes("api_key") === -1 ){ dict[(elem.parName_ch).split("(")[0]] = elem.parsName}
                    else if (elem.type === "button_multiselection") { dict2[(elem.parName_ch).split("(")[0]] = elem.parsName}
                });
                if(Object.keys(dict).length !== 0){
                    props.setFillin_txt({...props.fillin_txt,...dict}) 
                }
                if(Object.keys(dict2).length !== 0){ props.setFillin_file({...props.fillin_file,...dict2})}

                workFlowAddinnerHTML.push(
                <div>
                    <div id="add_on_line">
                        <div>⁝</div>
                        <div>✢</div>
                        <div>⁝</div>
                    </div>
                    
                    <Card.Text>
                        <FlowBox toolName = {APIdata.toolName} APIname = {APIdata.APIname} APIpars={APIdata.APIpars} step={countstep} selectedLLM={props.selectedLLM} checked_default={APIdata.checked_default} workFlowAdd_on={props.workFlowAdd_on} 
                        workFlowDel_one = {workFlowDel_one} filename={item} username = {props.username} APIpars_list = {APIdata.APIpars_list} setSelectparsListFunc={()=>{}} selectparsList={[]} setparsconfirm={()=>{}} loginCheckapp={props.loginCheckapp} 
                        setenable_memory_ST={()=>{}} defaultPras={APIdata.defaultPras} deleteworkflow={deleteworkflow} saveworkflow={saveworkflowFunc} setinfoFunc={props.setinfoFunc} setGeneratemsgBox={props.setGeneratemsgBox} setAutodriveParsFunc={setAutodriveParsFunc}
                        setNewscontent={props.setNewscontent} waitinfoFunc={props.waitinfoFunc} setRefresh_newscontent={setRefresh_newscontent} microphoneTrigger={props.microphoneTrigger} setMicrophoneTrigger={props.setMicrophoneTrigger}></FlowBox>
                    </Card.Text>
                </div>)   
                    
            })
            let finalinnerHTML = 
            <div>
                { (workFlowAddinnerHTML as any).map((elem:any,index:number) =>{
                    return workFlowAddinnerHTML[index]
                })}
            </div>
            setworkFlowAdd(
                finalinnerHTML
            )
        }else(setworkFlowAdd(<div></div> ))
    }

    useEffect(()=>{
        renderworkFlow()
    },[props.workFlowAddList,props.loginCheckapp,props.microphoneTrigger]) 

   

    function workFlowDel_one(parsName:string){
        if(props.workFlowAddList.indexOf(parsName) > -1){
            props.setworkFlowAddList(props.workFlowAddList.filter(item => item !== parsName))
        }
    }

    function deleteworkflow(parsName:string){
        
        if(props.workFlowAddList.indexOf(parsName) > -1){
            if(parsName === "zapier"){
                // props.setworkFlowAddList(props.workFlowAddList.filter(item => item !== parsName))
                let newList =["zapier","emailservice","customtrigger", "customAction"] 
                // APILibs["zapier"].APIpars_list.forEach((elem:any) =>{newList.push(Object.keys(elem)[0])})
                props.setworkFlowAddList(props.workFlowAddList.filter(item => newList.indexOf(item) === -1))
                //cleanup cookies
                props.cleanupAPICookies("zapierCookiesList")
            }else if(parsName === "notion"){
                let newList =["notion","notiondb","notionblock","notionpage"] 
                props.setworkFlowAddList(props.workFlowAddList.filter(item => newList.indexOf(item) === -1))
                //cleanup cookies
                props.cleanupAPICookies("notionCookiesList")
            }else if(parsName === "writingtools"){
                let newList = ["writingtools","writing","polishing"]
                props.setworkFlowAddList(props.workFlowAddList.filter(item => newList.indexOf(item) === -1))
                //cleanup cookies
                props.cleanupAPICookies("writingtoolsCookiesList")
            }
            else if(Object.keys(APILibs).slice(12, Object.keys(APILibs).length).indexOf(parsName) !== -1){
                props.setworkFlowAddList(props.workFlowAddList.filter(item => item !== parsName))
                props.cleanupAPICookies(parsName+"CookiesList")
                
            }
            
            let dictname :  string[] =[]
            let dict2name : string[] =[]
            let APIdata = (APILibs as any)[parsName]
            APIdata.APIpars.forEach((elem:any) => {
                if(["textarea" , "input"].includes(elem.type)){ dictname.push((elem.parName_ch).split("(")[0])}
                else if (elem.type === "button_multiselection") { dict2name.push((elem.parName_ch).split("(")[0])}
            });
            if(dictname.length > 0){
                let copyCarValues= {...props.fillin_txt}
                dictname.forEach(elem =>{
                    if(copyCarValues[elem]){delete copyCarValues[elem]}
                })
                props.setFillin_txt(copyCarValues) 

            }
            if(dict2name.length > 0){ 
                let copyCarValues= {...props.fillin_file}
                dict2name.forEach(elem =>{
                    if(copyCarValues[elem]){delete copyCarValues[elem]}
                })
                props.setFillin_file(copyCarValues)
            }
            if(parsName === "voicerecorder"){props.setMicrophoneTrigger(0)}
        }
    }

    function HttpBody_parsetup(elem:string,HttpBody:any,parscheck:boolean){ 
        let stopIndex = stopIndexDict[elem] ;
        let APIretrive = (APILibs as any)[elem]
        let alartinfo = ""
        
        APIretrive.APIpars_list.forEach((item:any,i:number) =>{
            Object.entries(item).map(([key, value]) => {
                if(APIretrive.APIpars[i].type === "button_txt_renew"){
                    let pars = document.getElementById('hold_space_'+key)?.innerHTML
                    if(pars !== ""){
                        HttpBody["token"] = pars
                    }else{
                        alartinfo += (APIretrive.APIpars[i].parName_ch).split("(")[0]+"不能为空 \n" 
                        parscheck = false
                        
                    }
                }else if(APIretrive.APIpars[i].type === "txt_default"){
                    HttpBody[key] = props.username
                }else if(APIretrive.APIpars[i].type === "textarea"){
                    let pars = document.getElementById('item_input'+key)?.innerHTML
                    if(pars !== ""){
                        HttpBody[key]  = pars
                    }else{
                        if(APIretrive.APIpars[i].required === true){
                            alartinfo += (APIretrive.APIpars[i].parName_ch).split("(")[0]+"不能为空 \n"
                            parscheck = false
                        }else{
                            HttpBody[key]  = APIretrive.defaultPras[i]
                        }
                    }
                }
                else if(i <= stopIndex && key !== undefined){
                
                    let pars = SetupCookies(key,"","get")
                    if(pars && ((typeof pars === "string" && pars.trim().length>0) || typeof pars !== "string")){
                        HttpBody[key] = pars
                    }else{
                        if(APIretrive.APIpars[i].required === true){
                            alartinfo += (APIretrive.APIpars[i].parName_ch).split("(")[0]+"不能为空，请点击确旁边按钮确认 \n"
                            parscheck = false
                        }else{
                            HttpBody[key]  = APIretrive.defaultPras[i]
                        }
                    }
                    
                }
                if(APIretrive.APIname === "txtreader 参数设置"){HttpBody["opt"] = APIretrive.APIpars[3][1] === "audio/wav"?1:0}
                return [HttpBody,parscheck]
            })
        })
        return [HttpBody,parscheck,alartinfo]
    }
    function HttpBody_parsetup_llm(llm:string,HttpBody:any,parscheck:boolean,opt:boolean = false ){ 
        let stopIndex = stopIndexDict[llm] ;
        let APIretrive = LLMlibs[llm];
        let alartinfo = "";
        let HttpBody_pars:any[] = []
        APIretrive.APIpars_list.forEach((item:any,i:number) =>{
            Object.entries(item).map(([key, value]) => {
                if(i <= stopIndex && key !== undefined){
                    let pars = SetupCookies(key,"","get")
                    if(pars && ((typeof pars === "string" && pars.trim().length>0) || typeof pars !== "string")){
                        HttpBody[key] = pars
                        HttpBody_pars.push(pars)
                    }else{
                        if(APIretrive.APIpars[i].required === true){
                            alartinfo += (APIretrive.APIpars[i].parName_ch).split("(")[0]+"不能为空 \n"
                            parscheck = false
                        }else{
                            HttpBody[key]  = APIretrive.defaultPras[i]
                            HttpBody_pars.push(APIretrive.defaultPras[i])
                        }
                    }
                    
                }else if(i ===  APIretrive.APIpars_list.length -1 ){
                    let pars = SetupCookies(key,"","get")
                    if(pars && pars.trim().length>0){
                        HttpBody[key] = pars
                        HttpBody_pars.push(pars)
                    }else{
                        HttpBody[key]  = APIretrive.defaultPras[i]
                        HttpBody_pars.push(APIretrive.defaultPras[i])
                    }
                }
                return [HttpBody,parscheck,HttpBody_pars]
            })
        })
        if(opt){return [HttpBody_pars,parscheck,alartinfo]}else{return [HttpBody,parscheck,alartinfo]}
        
    }

    //强制更新
    const initrefresh_newscontent = useRef();
    useEffect(()=>{
        initrefresh_newscontent.current = refresh_newscontent;
    },[refresh_newscontent])

    async function saveworkflowFunc(parsName:string,arg:any=""){
        let stopIndex:number
        let fileindex:number
        let textareaindex:number
        let parscheck = true
        let alartinfo = ""
        if(parsName === "emailservice"){
            stopIndex = 3
            let HttpBody : {[key: string]: any} = {"username":props.username,"content":document.getElementById('item_inputcontent')?.innerHTML,"description":document.getElementById('item_inputdescription')?.innerHTML,tokenname:Object.keys(APILibs["emailservice"].APIpars_list[0])[0]}
            APILibs["emailservice"].APIpars_list.forEach((elem:any,index:number) =>{
                if(index === 0){
                    let pars = document.getElementById('hold_space_zapier_emailservice')?.innerHTML
                    if(pars !== ""){
                        HttpBody["token"] = pars
                    }else{
                        alert("token不能为空")
                        parscheck = false
                    }
                }
                else if(index <= stopIndex && Object.keys(elem)[0] !== undefined){
                    let pars = SetupCookies(Object.keys(elem)[0],"","get")
                   
                    if(pars && ((typeof pars === "string" && pars.trim().length>0) || typeof pars !== "string")){
                        HttpBody[Object.keys(elem)[0]] = pars
                    }else{
                        HttpBody[Object.keys(elem)[0]] = APILibs["emailservice"].defaultPras[index]
                    }
                    
                }
            })
            if(parscheck){
                // HttpServerRequest("zap/emailservice",HttpBody)
                let props1={url:`zap/emailservice`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            props1.setinfoFunc("保存成功","2000");
                            SetupCookies("zapier_emailservice","","new");
                        }
                    }
                )
        }
        }else if(parsName === "senttomyemail"){
            stopIndex = 0
            textareaindex=1
            fileindex = 2
            let HttpBody : {[key: string]: any}={...HttpBody_init}
            let readytosent = true
            APILibs["senttomyemail"].APIpars_list.forEach((elem:any,index:number) =>{
                let pars = SetupCookies(Object.keys(elem)[0],"","get")
                if(index <= stopIndex && Object.keys(elem)[0] !== undefined){
                    if(pars && pars.trim().length>0){
                        HttpBody[Object.keys(elem)[0]] = pars
                    }else{
                        HttpBody[Object.keys(elem)[0]] = APILibs["senttomyemail"].defaultPras[index]
                    }
                }else if(index === textareaindex){
                    pars = document.getElementById('item_input'+Object.keys(elem)[0])?.innerHTML
                    if(pars && pars.trim().length>0){
                        HttpBody[Object.keys(elem)[0]] = pars
                    }else{
                        readytosent=false
                    }
                }
                else if(index === fileindex){
                    if(pars && pars.trim().length>0){
                        HttpBody[Object.keys(elem)[0]] = pars
                    }else{
                        HttpBody[Object.keys(elem)[0]] = APILibs["senttomyemail"].defaultPras[index]
                    }
                }
                
            })
            if(readytosent){
                // HttpServerRequest("user/emailtome",HttpBody)
                let props1={url:`user/emailtome`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            props1.setinfoFunc(result,"2000");
                        }
                    }
                )
            }
            else{
                props.setinfoFunc("您有未填的信息，请勾选右边图标☑️生效。","3000")
            }
           

        }else if(parsName === "customtrigger"){
            stopIndex = 1
            let HttpBody : {[key: string]: any} = {"username":props.username,"description":document.getElementById('item_inputdescription')?.innerHTML,tokenname:Object.keys(APILibs["customtrigger"].APIpars_list[0])[0]}
            APILibs["customtrigger"].APIpars_list.forEach((elem:any,index:number) =>{
                if(index === 0){
                    let pars = document.getElementById('hold_space_zapier_customtrigger')?.innerHTML
                    if(pars !== ""){
                        HttpBody["token"] = pars
                    }else{
                        alert("token不能为空")
                        parscheck = false
                    }
                }
                else if(index <= stopIndex && Object.keys(elem)[0] !== undefined){
                    let pars = SetupCookies(Object.keys(elem)[0],"","get")
                    if(pars && ((typeof pars === "string" && pars.trim().length>0) || typeof pars === "object")){
                        HttpBody[Object.keys(elem)[0]] = pars
                    }else{
                        HttpBody[Object.keys(elem)[0]] = APILibs["customtrigger"].defaultPras[index]
                    }
                    
                }
            })
            if(parscheck){

                // HttpServerRequest("zap/customtrigger",HttpBody)
                let props1={url:`zap/customtrigger`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            props1.setinfoFunc("保存成功","2000");
                            SetupCookies("zapier_emailservice","","new");
                        }
                    }
                )
            }
        }else if(parsName === "pdf_generator" || parsName === "txt_generator"){
            let content = document.getElementById('item_inputcontent')?.innerHTML
            let filename = SetupCookies("filename","","get")
            if(content && filename){
                let HttpBody : {[key: string]: any} = {...HttpBody_init,"content":content,"filename":filename,"filetype":parsName.split("_")[0]}
                // HttpServerRequest("api/filegenerate",HttpBody)
                let props1={url:"api/filegenerate",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            props1.setinfoFunc(result,"2000");
                        }
                    }
                )
            }else{
                props.setinfoFunc("您有未填的信息，请勾选右边图标☑️生效。","3000")
            }
            
        }else if(parsName === "gmailservice_sent"){
            stopIndex = 6
            let alertinfo = ""
            let HttpBody : {[key: string]: any} = {...HttpBody_init,"gmailcontent":document.getElementById('item_inputgmailcontent')?.innerHTML,"gmailattachement":SetupCookies("gmailattachement","","get")?SetupCookies("gmailattachement","","get"):"default"}
            APILibs["gmailservice"].APIpars_list.forEach((elem:any,index:number) =>{
                if(index <= stopIndex && Object.keys(elem)[0] !== undefined){
                    let pars = SetupCookies(Object.keys(elem)[0],"","get")
                    if(pars && pars.trim().length>0){
                        HttpBody[Object.keys(elem)[0]] = pars
                    }else if(Object.keys(elem)[0] === "gmailcc"){
                        HttpBody[Object.keys(elem)[0]] = "default"
                    }
                    else{
                        parscheck = false
                        alertinfo += APILibs["gmailservice"].APIpars[index].parName_ch.split("(")[0] + ","
                    }
                }
            })
            if(parscheck){
                // HttpServerRequest("api/gmailservice",HttpBody)
                let props1={url:"api/gmailservice",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            props1.setinfoFunc(result,"2000");
                        }
                    }
                )
            }
            else{alert(alertinfo.substring(0,alertinfo.length-1)+"不能为空")}
        }else if(parsName === "saveworkflow"){
            let HttpBody : {[key: string]: any} ;
            HttpBody = {...HttpBody_init,"tokenname":parsName};
            let content : {[key: string]: any}= {};
            let content_result ={};
            // get all workFlowAdd
            
            // check if  api paras
            (props.workFlowAddList as any).forEach((elem:string,index:number) => {
                if(elem === "saveworkflow"){[HttpBody,parscheck,alartinfo] = HttpBody_parsetup(elem,HttpBody,parscheck)}
                else{[content_result,parscheck,alartinfo] = HttpBody_parsetup(elem,{},parscheck)
                    content[elem]=content_result}
                
            });
            if(parscheck){
                // llm paras
                [content_result,parscheck,alartinfo] = HttpBody_parsetup_llm(props.selectedLLM,{},parscheck);
                content[props.selectedLLM]=content_result;
                if(parscheck){
                    
                    HttpBody["content"] = content
                    // console.log(HttpBody)
                    // HttpServerRequest("workflow/save",HttpBody)
                    let props1={url:"workflow/save",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    await UseURLLoader(props1).then(result =>
                        {
                            if(result){
                                props1.setinfoFunc(result,"2000");
                            }
                        }
                    )
                }else{
                    // get llm info
                    alert(alartinfo)
                } 
            }else{
                // get llm info
                alert(alartinfo)
            } 
            
        }else if(parsName === "getworkflow"){
            let HttpBody : {[key: string]: any} ;
            let token = SetupCookies("serial_number","","get") 
            
            if(token && typeof token === "string" && token.trim().length > 20){
                HttpBody = {...HttpBody_init,"token":token};
                let props1={url:`workflow/getworkflow`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then( async (result:any) =>
                    {   

                        if(result){
                            // console.log(result)
                            //setup llm
                            let selectllm =  Object.keys(result)[0]
                            let prompt = "[sw]_" + result.author + "/" + result[selectllm].prompt_selection

                            SetupCookies("prompt_selection",prompt,"new") 
                            for (const [key, value] of Object.entries(result[selectllm])) {
                                if( key !== "prompt_selection" && value !== "default"){
                                    SetupCookies(key,value,"new")
                                }
                                
                                if(key === "memory_ST" && value !== "default"){
                                    props.setenable_memory_ST(value)
                                }
                            }

                            SetupCookies("agent_name",result["agent_name"],"new") 
                            props.setinfoFunc("加载成功","3000")
                            document.getElementById("llms_election_input")?.setAttribute("value",Object.keys(result)[0])

                        }else{props.setinfoFunc("加载失败，请确认token是否正确。","3000")}
                    }
                )
            }else{
                props.setinfoFunc("请先输入流程图序列号，然后回车或者勾选图标☑️确认。","3000")
            }
            
        }else if(parsName === "newsgoogle" || parsName === "topnews" || parsName === "juhenews"){
            //auto ：将新闻分篇发给glm 生成总结，生成向量数据库
            // 一键总结，将新闻分篇发给glm生成总结
            
            // console.log(props.newscontent) //parseInt(i)
            // console.log(initrefresh_newscontent.current)
            if(props.newscontent.length>0 || initrefresh_newscontent.current !== undefined){
                let TXTprompt = "__news__"
                let list:any
                list = initrefresh_newscontent.current !== undefined? initrefresh_newscontent.current:props.newscontent;
                let summaryresult :any[]= [] 
                props.waitinfoFunc(true,10000*((initrefresh_newscontent.current as any).length || 2))
                for await ( const  elem of list) {
                    TXTprompt += `新闻标题: ${elem[0]} \n  新闻内容: ${elem[1]} \n\n`;
                    let HttpBody: {[key:string]:any} = {...HttpBody_init}
                    HttpBody["question"]="请根据新闻内容生成一篇200字的总结。"
                    let pars = baichuanAPI.defaultPras
                    pars[0] = "0.8"
                    pars[1] = "2000"
                    pars[8] = TXTprompt
                    HttpBody["LLMpars"] = pars
                    HttpBody["selectedLLM"] = 'Baichuan-13B (百川智能)'
                    
                    
                    let props1={url:`llm/gensummary`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    await UseURLLoader(props1).then( async result =>
                        {   
                            if(result){
                                result.replace(HttpBody["question"],"");
                                summaryresult.push(await result);
                            }else{summaryresult.push("发生未知错误，请重新尝试");}
                        }
                    )

                };
                props.waitinfoFunc(false)
                // "根据新闻内容生成的200字总结:"
               
                // generate msg box
                let txt = "新闻概述：\n "
                summaryresult.forEach((element,index) => {
                    // txt += `<B>[标题]</B> \n <B>${list[index][0]}</B> \n <B>[内容概要]</B> \n ${element} \n\n`;
                    txt += `[标题] \n ${list[index][0]} \n [内容概要] \n ${element} \n\n`;
                });
                props.setGeneratemsgBox(txt)

            }else{
                props.setinfoFunc("请先输入关键词，点击确认按钮，点击搜索新闻，稍等10s后, 重新尝试","2000")
            }
        
        }else if(parsName.indexOf("checknews") !== -1){
            // initrefresh_newscontent.current
            let apiname = parsName.split("_")[1]
            let pars = SetupCookies(apiname,"","get")
            
            if(pars !== undefined && initrefresh_newscontent.current !== undefined){
                let list:any
                list = initrefresh_newscontent.current !== undefined? initrefresh_newscontent.current:props.newscontent;
                if(typeof pars === "string"){pars = pars.split(",")}
                else{pars = [pars]}

                for await ( const elem of pars) {
                    let TXTprompt = `新闻标题: ${list[parseInt(elem)][0]} \n  新闻内容: ${list[parseInt(elem)][1]} \n\n`;
                    props.setGeneratemsgBox(TXTprompt)
                }
            }else{props.setinfoFunc("请先搜索新闻，稍等10s后, 重新尝试","2000")}


        }else if(parsName === "vdb_selection"){
           
            //get http 
            if(['智谱AI',"智谱AI(GLM-4V)","智谱AI(GLM-4)",'Stability AI',"Stability AI (v1-6)","Stability AI (pixel)","Stability AI (mask)",'通义千问',"ChatGpt (dall-e-3)","ChatGpt (dall-e-2)","ChatGpt (dall-e-2)2"].includes(props.selectedLLM)){
                props.setinfoFunc("该大模型暂不支持链接本地数据库，请在选择LLM模型中更换模型。","2000")
            }
            else{
                let HttpBody: {[key:string]:any} = {...HttpBody_init}
                let pars:any  
                [pars,parscheck,alartinfo] = HttpBody_parsetup_llm(props.selectedLLM,{},true,true);

                if(parscheck){
                    let currentDate = new Date()
                    const msgItem_h={'sender':props.username,'message':arg.question,'date':currentDate};
                    HttpBody["selectedLLM"] = props.selectedLLM
                    if(arg.TXTprompt.length>0){pars[pars.length-1] = "__news__"+arg.TXTprompt}else{pars[pars.length-1] = ""}
                    
                    HttpBody["LLMpars"] = pars
                    HttpBody["memory_ST"] = []
                    HttpBody['content'] = msgItem_h
                    HttpBody['updatetime'] = currentDate

                    
                    let props1={url:`llm/sendmsg`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    await UseURLLoader(props1).then(result =>
                        {
                            props.waitinfoFunc(false)
                            if(result){
                                props.setGeneratemsgBox(result.message)
                            }
                        }
                    )
                }else{
                    // get llm info
                    alert(alartinfo)
                }
                
                
                
                // generat msgbox
            }
        }else if(parsName === "article_summary" || parsName === "article_translation" ){
            let filename = SetupCookies("article_selection","","get") 
            console.log(filename)
            if (filename){
                if(filename.includes(",")){
                    let filenameList = filename.split(",")
                    filenameList.forEach(async (element:string) => {
                        let HttpBody = {...HttpBody_init,"filename":element};
                        let props1={url:`llm/`+parsName,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                        props.waitinfoFunc(true)
                        await UseURLLoader(props1).then( async (result:any) =>
                            {   
                                console.log(result)
                                if(result){
                                    props.waitinfoFunc(false)
                                    props.setGeneratemsgBox(result)
                                }else{props.setinfoFunc("加载失败，请确认是否选择文章。","3000")}
                            }
                        )
                    });
                    
                }else{
                    let HttpBody = {...HttpBody_init,"filename":filename};
                    let props1={url:`llm/`+parsName,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    props.waitinfoFunc(true)
                    await UseURLLoader(props1).then( async (result:any) =>
                        {   
                            if(result){
                                props.waitinfoFunc(false)
                                props.setGeneratemsgBox(result)
                            }else{props.setinfoFunc("加载失败，请确认是否选择文章。","3000")}
                        }
                    )
                }
                
                
            }else{
                props.setinfoFunc("加载失败，请选择文件。","3000")}
            
        }
            
    }


    function updateScoll(){
        if(isfold === "<·· "){
            var element  = document.getElementById("workflowCard");
            element?.scrollIntoView({ behavior: "smooth" , block: "end"});
            element?.scrollTo({
              top: element?.scrollHeight-50,
              behavior: "smooth"
            });
        }
        
        
      }
    function renderworkFlowmain(){
        if( Object.keys(LLMlibs).includes(props.selectedLLM)){
            // console.log(LLMlibs[props.selectedLLM])
            let APIdata = LLMlibs[props.selectedLLM]

            props.setSelectparsListFunc((APIdata as any).APIpars_list)
            
            return (
                <div className="d-flex justify-content-around" onMouseEnter={() => {setIsHoveredFunc(true); updateScoll()}} onDoubleClick={()=>setIsfoldFunc()}
                onMouseLeave={() => setIsHoveredFunc(false)} >
                    <div className="control_but">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">双击改变区域大小</Tooltip>}
                        >
                            <img id="img_double_click" src={IMG_Libs["double_click"]} className="rounded me-2 double_click" width="20" height="20" alt="" />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">双击保存流程图设置</Tooltip>}
                        >
                            <img id="img_double_click" src={IMG_Libs["save_icon"]} className="rounded me-2 double_click" width="20" height="20" alt="" onClick={()=>{props.workFlowAdd_on("saveworkflow"); workFlowDel_one("getworkflow")}}/>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">双击导入流程图</Tooltip>}
                        >
                            <img id="img_double_click" src={IMG_Libs["bulb"]} className="rounded me-2 double_click" width="20" height="20" alt="" onClick={()=>{props.workFlowAdd_on("getworkflow"); workFlowDel_one("saveworkflow")}}/>
                        </OverlayTrigger>
                    </div>
                    
                    
                    
                    <Card style={{ width: (80-props.boxwidth).toString()+'rem', transition:".3s"}} id="workflowCard">
                        <Card.Body>
                        <Card.Title onClick={()=>setIsfoldFunc()} id="isfold_but">{isfold}流程图</Card.Title>
                            {/* <div>{APIdata.toolName}</div> */}
                            <Card.Text>
                                <FlowBox toolName = {(APIdata as any).toolName} APIname = {(APIdata as any).APIname} APIpars={(APIdata as any).APIpars} step={0} selectedLLM={props.selectedLLM} checked_default={(APIdata as any).checked_default} 
                                workFlowAdd_on={props.workFlowAdd_on}  workFlowDel_one = {workFlowDel_one} filename='none' username = {props.username}  APIpars_list = {(APIdata as any).APIpars_list} setSelectparsListFunc={props.setSelectparsListFunc} 
                                selectparsList={props.selectparsList} setparsconfirm={props.setparsconfirm} loginCheckapp={props.loginCheckapp} setenable_memory_ST={props.setenable_memory_ST} defaultPras={[]} deleteworkflow={deleteworkflow} saveworkflow={saveworkflowFunc} 
                                setinfoFunc={props.setinfoFunc} setGeneratemsgBox={props.setGeneratemsgBox} setAutodriveParsFunc={setAutodriveParsFunc} setNewscontent={props.setNewscontent} waitinfoFunc={props.waitinfoFunc} setRefresh_newscontent={setRefresh_newscontent}
                                microphoneTrigger={props.microphoneTrigger} setMicrophoneTrigger={props.setMicrophoneTrigger}></FlowBox>
                            </Card.Text>
                        </Card.Body>
                        <Card.Body id="workFlowAdd_body">
                            {workFlowAdd}
                        </Card.Body>
                        
                    </Card>
                </div>
            )
        }else{
            return <div className="d-flex justify-content-around" onMouseEnter={() => setIsHoveredFunc(true)}
            onMouseLeave={() => setIsHoveredFunc(false)}  >
                    <div className="control_but">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">双击改变区域大小</Tooltip>}
                        >
                            <img id="img_double_click" src={IMG_Libs["double_click"]} className="rounded me-2 double_click" width="20" height="20" alt="" />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">双击保存流程图设置</Tooltip>}
                        >
                            <img id="img_double_click" src={IMG_Libs["save_icon"]} className="rounded me-2 double_click" width="20" height="20" alt="" onClick={()=>{props.workFlowAdd_on("saveworkflow"); workFlowDel_one("getworkflow")}}/>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">双击导入流程图</Tooltip>}
                        >
                            <img id="img_double_click" src={IMG_Libs["bulb"]} className="rounded me-2 double_click" width="20" height="20" alt="" onClick={()=>{props.workFlowAdd_on("getworkflow"); workFlowDel_one("saveworkflow")}}/>
                        </OverlayTrigger>
                    </div>
                    <Card style={{ width: (80-props.boxwidth).toString()+'rem' , transition:".3s"}} id="workflowCard">
                        <Card.Body>
                        <Card.Title  onClick={()=>setIsfoldFunc()} id="isfold_but">{isfold}流程图</Card.Title>
                        </Card.Body>
                    </Card>
                </div>
        }
    }
    return renderworkFlowmain()
}

WorkFlow.defaultProps={
    message:"Calling tool q QQ_send_email: \n QQ_send_emal",
    selectedLLM:"API设置",
    username:"游客"
}
export default WorkFlow