import React, {  useState,useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './flowBox.css'

import Button from 'react-bootstrap/Button';
import FileUpload from '../../tools/fileUpload';
import { PagePrompt } from "../../configs/PagePrompt";
import { IconPrompt } from "../../configs/iconPrompyt";
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import close from '../../assets/img/toolsImage/close.png'
import tick_mark from '../../assets/img/toolsImage/tick-mark.png'
import {UseURLLoader,DownloadIMG,UseURL} from '../../hooks/useURLLoader';
import GMap from '../../tools/googlemap';
import AudioPlayer from '../../tools/AudioPlayer';
import ImgMask from '../../tools/imgMask';
import Datepicker from '../../tools/datepicker';
import VoiceRecorder from '../../tools/voiceRecorder';
import PromptSpliter from '../../pages/PromptSpliter/PromptSpliter';

import Popup from '../../tools/Popup';
import { io } from 'socket.io-client';
import { string } from 'yaml/dist/schema/common/string';
interface ItemsProps{
    APIname:string,
    parsName: string,
    parName_ch:string,
    pars: any,
    group: string,
    name_in_db:string,
    required:string,
    setCookies_Pars:Function,
    type:string,
    workFlowAdd_on:Function,
    workFlowDel_one:Function,
    username:string,
    setselectedFunc : Function,
    setselectedindex:number,
    setparsconfirm:Function,
    loginCheckapp:boolean,
    setchecked_default:Function,
    setenable_memory_ST:Function,
    rule:any,
    APIpars_list:[],
    defaultPras:any,
    checked_default:boolean,
    deleteworkflow:Function,
    saveworkflow:Function,
    setinfoFunc:Function,
    setselected:any, //correct buttonIMG error
    setGeneratemsgBox:Function,
    
    setAutodriveParsFunc:Function,
    setNewscontent:Function,
    waitinfoFunc:Function,
    setRefresh_newscontent:Function,
    microphoneTrigger:number,
    setMicrophoneTrigger:Function,
}
let multiselectionList:any[] =[]
let selectionListAll:any[] =[]
const IMG_Libs = IconPrompt.IMG_Libs
let trigger_mask=false
var socket:any;
const Items: React.FC<ItemsProps> = (props:any) => {
    let Cookies_Pars = props.setCookies_Pars(props.parsName,"","get")
    const [inputVal, setinputVal] = useState<any>(null)
    
    const [subselectoin, setSubselectoin] = useState<any>(props.type === "selectionbox_2layer"? JSON.parse(JSON.stringify(Object.values(props.pars[0])[0])):null)  
    const [butDisabled, setbutDisabled] = useState<any>(props.parsName === "vdb_selection"?null:"添加") 
    const [fileprompt,setfileprompt] = useState(<div></div> )
    
    const [foldcotrol, setfoldcotrol] = useState(props.parsName === "map"?":":props.parsName === "tips"?"∵":'∴')
    const [heightcotrol, setheightcotrol] = useState(props.parsName === "tips"?"20px":'auto')
    const [multiselection, setMultiselection] = useState<any[]>(["无"])
    const [multiselectioncol, setMultiselectioncol] = useState<string[]>([])
    const [httpresponse, setHttpresponse] = useState<any>(Cookies_Pars?Cookies_Pars:"")
    const [key_value_addon, setKey_value_addon] = useState<{[key: string]: any}>({})
    const [addmark,setAddmark] = useState(false)
    const [imgmask, setImgmask] = useState( <div></div> );
    useEffect(() => { 
        // Cookies_Pars = props.setCookies_Pars(props.parsName,"","get")
        if(Cookies_Pars){
            if(props.type === "input"|| props.type === "textarea"|| props.type === "input_s"){
                setinputVal(Cookies_Pars)}
            else if(props.type === "button_prompt"){ 
                setbutDisabled(Cookies_Pars)}
            else if(props.type === "button_txt_renew" || props.type === "button_input"){
                setHttpresponse(Cookies_Pars)
            }
            else if(props.type === "button_pic"){
                try{
                    let item_img =`${PagePrompt.url}/${props.username}/${Cookies_Pars}`
                    if(item_img){
                        setfileprompt(<img src={item_img} className="rounded me-2" width="100" alt="" />)
                    }
                }catch (error){
                    console.log(error)
                }
            }
        }else{
            setinputVal(null)
            
        }
        if(props.type === "range" && Cookies_Pars){setinputVal(Cookies_Pars)
        }else if(props.type === "range"){setinputVal(props.pars)}
        else if(props.type === "selectionbox" && Cookies_Pars){ 
            setinputVal(Cookies_Pars)
        }
        else if(props.type === "multiselection" && Cookies_Pars){
            setMultiselection(Cookies_Pars.split(","))
            multiselectionList=Cookies_Pars.split(",")
        }

        if(props.parsName === "autodrive" && (Cookies_Pars === true  || Cookies_Pars === "true")){ 
            setinputVal(true)
            props.setAutodriveParsFunc(true)
        }else if(props.parsName === "autodrive" ){setinputVal(false);props.setAutodriveParsFunc(false)}

        if(props.type === "check" && (Cookies_Pars === true  || Cookies_Pars === "true")){ 
            setinputVal(true)
        }else if(props.type === "check" ){setinputVal(false)}
        
        if(props.parsName === "vdb_selection" && inputVal === null){setinputVal(props.pars)}
       
    }, [Cookies_Pars,props]); //.type,props.setAutodriveParsFunc()

    // function refreshIMG(){
    //     console.log("refreshIMG()")
    //     try{
                    
    //         let item_img =`${PagePrompt.url}/${props.username}/${Cookies_Pars}`
    //         console.log("refreshIMG()2")
    //         console.log(item_img)
    //         if(item_img){
    //             setfileprompt(<img src={item_img} className="rounded me-2" width="100" alt="" />)
    //         }
    //     }catch (error){
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     // Cookies_Pars = props.setCookies_Pars(props.parsName,"","get")
    //     if(props.type === "check" && (Cookies_Pars === true  || Cookies_Pars === "true")){ 
    //         setinputVal(true)
    //     }else if(props.type === "check" ){setinputVal(false)}
        
    // }, [Cookies_Pars,props.type]);

    let buttonIMG = <div></div>;
    let inputOrBut = <div></div>;
    let TXTprompt =<div></div> 

    let HttpBody_init= {'username': props.username, uuid:props.setCookies_Pars("uuid","","get"),session_id:props.setCookies_Pars("session_id","","get")}

    function setCookies_ParsFunc(inputVal:any){
        if(typeof(inputVal) !== "object"){
            if(["button","input","input_s","range","checkbox","input_but_dict","selectionbox_2layer","selectionbox","button_multiselection",
            "multiselection","multiselection_all","button_oneselection","button_oneselection_pic","button_txt_renew","check","button_input"].includes(props.type)){
                props.setCookies_Pars(props.parsName,inputVal,"new")
            }else if(props.type === "empty"){props.setCookies_Pars(props.parsName,true,"new")}
            
            if(props.parsName === "memory_ST"){
                props.setenable_memory_ST(inputVal)}
        }else{
            if(inputVal !== undefined  && inputVal !== null){
                props.setCookies_Pars(props.parsName,inputVal.join(","),"new")}
        }
    }

    function handleChange(obj:any,required:string){
        if (props.loginCheckapp === true){
            if(obj.getAttribute('src',2) === IMG_Libs["check0"] || obj.getAttribute('src',2) === IMG_Libs["check3"])
            {
                obj.setAttribute('src',IMG_Libs["check2"])
                if(props.checked_default === true){props.setselectedFunc(true,props.setselectedindex)}
                else{props.APIpars_list[props.setselectedindex][Object.keys(props.APIpars_list[props.setselectedindex])[0]] = true}
                if(props.type === "empty"){setCookies_ParsFunc(true)}
            }else if(required === "ture"){
                obj.setAttribute('src',IMG_Libs["check3"])
                if(props.checked_default === true){props.setselectedFunc(false,props.setselectedindex)}
                else{props.APIpars_list[props.setselectedindex][Object.keys(props.APIpars_list[props.setselectedindex])[0]] = true}
                if(props.type === "empty"){setCookies_ParsFunc(true)}
            }else{
                obj.setAttribute('src',IMG_Libs["check0"])
                if(props.checked_default === true){props.setselectedFunc(false,props.setselectedindex)}
                else{props.APIpars_list[props.setselectedindex][Object.keys(props.APIpars_list[props.setselectedindex])[0]] = false}
                if(props.type === "empty"){setCookies_ParsFunc(false)}
            }
            props.setparsconfirm(false)
            
            if(props.type !== "input_but_dict"){ 
                if(inputVal !== undefined && inputVal !== null){
                    setCookies_ParsFunc(inputVal)
                }else if(["input","input_s"].includes(props.type) && (typeof document.getElementById('item_input'+props.parsName)?.getAttribute("value") !== "undefined")){
                    let getinputVal:any = document.getElementById('item_input'+props.parsName)?.getAttribute("value")
                    if(getinputVal.trim().length>0){
                        setCookies_ParsFunc(getinputVal)
                    }
                }
            }else{
                //check key and value is not empty
                let isEmpty = false
                let newDict = {}
                Object.values(key_value_addon).forEach((value) => {
                    if((Object.values(value)[0] as any).trim() === "" || (Object.keys(value)[0].trim() as any) === ""){isEmpty = true}
                    else{
                        newDict = {...newDict,[Object.keys(value)[0]]:Object.values(value)[0]}
                    }
                })
                if(isEmpty){alert("数据(key,value)不能为空白。")}
                else{
                    setCookies_ParsFunc(JSON.stringify(newDict))
                }
            }
            
        }else{
            alert(PagePrompt.LoginTips)}
    }

    function changeStateIcon_uncheked(){
        if(props.required === "ture"){document.getElementById("img_"+props.parsName)?.setAttribute('src',IMG_Libs["check3"])
        }else{document.getElementById("img_"+props.parsName)?.setAttribute('src',IMG_Libs["check0"])}
        props.APIpars_list[props.setselectedindex][Object.keys(props.APIpars_list[props.setselectedindex])[0]] = false
    }
    function changeStateIcon_cheked(){
        document.getElementById("img_"+props.parsName)?.setAttribute('src',IMG_Libs["check2"])
    }

    function setStateBut(obj:any){
        changeStateIcon_uncheked()
        if(props.type === "range"){
            
            if(["searchtime","meteomatics_timeduration","width","height","cfg_scale"].includes(props.parsName) ){setinputVal((obj/100*(props.rule[1]-props.rule[0])).toFixed(0))}
            else{setinputVal((obj/100*(props.rule[1]-props.rule[0])).toFixed(2))}

        }else{
           
            setinputVal(obj)}
        
       if(props.type !== "textarea" && props.type !== "but_only" ){
            if(props.checked_default === true){
            props.setselectedFunc(false,props.setselectedindex)}
       }
       if(["language_selection","selectionbox","meteomatics_username","meteomatics_password","style_preset","sampler","zhipu_model","sampler_name","styles","cfg_scale","filename","ckpt"].includes(props.parsName)){
            setCookies_ParsFunc(obj)
            props.setselectedFunc(true,props.setselectedindex)
        }
        
        if(props.parsName === "voice_selection"){
            let element = props.pars[obj]
            let selection = JSON.parse(JSON.stringify(Object.values(element)[0]));
            setSubselectoin(selection)
        }else if(props.parsName === 'locationsearch' ){
            props.setCookies_Pars(props.name_in_db,obj,"new")
            // double click fold_contrl
            document.getElementById('fold_contrlmap')?.click() 
            
        }else if(["language","tool_selection"].includes(props.parsName)){
            setCookies_ParsFunc(obj)
        }else if(props.parsName === "writing_selection"){
            setCookies_ParsFunc(obj)
            setHttpresponse(obj)
        }
    }

    function _handleKeyDownflowBox (e:any) {
        if (e.key === 'Enter') {
          setCookies_ParsFunc(inputVal)
          changeStateIcon_cheked()
        //   if(props.checked_default === true){
        //   props.setselectedFunc(true,props.setselectedindex)}
        props.setselectedFunc(true,props.setselectedindex)
        }
    }

    function setTrigger_maskFunc(showup:boolean){
        if(trigger_mask !== showup){
            trigger_mask = showup
        }
        setImgmask( <div></div> )
    }

    async function handleClick_item(){
        if (props.loginCheckapp === true){
            if(["prompt_txt","prompt_doc","emailservice","customtrigger","notiondb", "notionpage","notionblock","polishing","writing"].includes(props.parsName) && butDisabled === "添加"){
                //add new workflow text
                props.workFlowAdd_on(props.parsName)
            } 
            else if(["prompt_txt","prompt_doc","emailservice","customtrigger","notiondb", "notionpage","notionblock","polishing","writing"].includes(props.parsName) && butDisabled === "删除"){
                //add new workflow text
                props.workFlowDel_one(props.parsName)
            
            }else if(props.parsName === "zapier_emailservice" || props.parsName === "zapier_customtrigger" ){
                let HttpBody = {...HttpBody_init,tokenname:props.parsName};
                // httpRequest("zap/getnewtoken",HttpBody)
                let props1={url:`zap/getnewtoken`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setHttpresponse(result.token)
                        }
                    }
                )
            }else if(props.parsName === "serial_number"){
                let HttpBody = {...HttpBody_init,tokenname:props.parsName};
                // httpRequest("api/getserialnumber",HttpBody)
                let props1={url:`api/getserialnumber`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setHttpresponse(result.token)
                        }
                    }
                )
            }else if(props.parsName === "wf_name"){
                if(inputVal && inputVal.trim() !== ""){
                    let HttpBody = {...HttpBody_init,wf_name:inputVal};
                    // httpRequest("api/checkname",HttpBody)
                    let props1={url:`api/checkname`,setinfoFunc:props.setinfoFunc,setCookies_ParsFunc:props.setCookies_ParsFunc,HttpBody:HttpBody}
                    await UseURLLoader(props1).then(result =>
                        {
                            if(result){
                                setCookies_ParsFunc(inputVal)
                            }
                        }
                    )
                }
                
            }else if(props.parsName === "mask"){
                if(butDisabled === "添加"){
                    let selectpic = props.setCookies_Pars("saved_pic","","get")
                    if(typeof selectpic !== "undefined"){
                        trigger_mask = !trigger_mask
                        setImgmask(<ImgMask dialogWidth="220px" trigger_mask={trigger_mask} setTrigger_mask={setTrigger_maskFunc} selectpic={selectpic} username={props.username} height_l="600px" ></ImgMask>) 
                    }
                    setbutDisabled("删除")
                }else{setbutDisabled("添加");props.setCookies_Pars(props.parsName,"","new");setfileprompt(<div></div>)}
                
            }else if(props.parsName === "dbsearch"){
                let propertyList = props.setCookies_Pars("propertyList","","get")
                if(inputVal===props.name_in_db[1] ||  (propertyList && propertyList.trim().length !== 0 && propertyList === multiselectioncol.join(","))){
                    let NOTION_DB_ID = props.setCookies_Pars("notion_db_id","","get")
                    if(NOTION_DB_ID && NOTION_DB_ID.trim().length !== 0 ){
                        setinputVal(props.name_in_db[0])
                        notionFunc("dbsearch",multiselection)
                    }else{
                        props.setinfoFunc("请先输入NotionAPI密钥，然后点击确认。","2000")
                    }
                
                }
                else{
                    if(propertyList && propertyList.trim().length !== 0){
                        setinputVal(props.name_in_db[1])
                        let list = propertyList.split(",")
                        setMultiselectioncol(list)
                        setMultiselection([["and",["and",list[0],"contains",""]]])
                    }else{
                        props.setinfoFunc("请先输入Notion数据库ID，然后点击获取数据库信息。","2000")
                    }
                    
                }
                

            }else if([ "pageupdate","newpage"].includes(props.parsName)){
                notionFunc("pageget")

            }
            else if([ "blockupdate","newblock"].includes(props.parsName)){
                notionFunc("blockget")

            }else if(["blocklistupdate"].includes(props.parsName)){
                notionFunc("blocklistget")

            }else if(props.parsName === "vdb_name"){
                let HttpBody = {...HttpBody_init,dbtype:props.name_in_db[2]};
                // httpRequest("api/checkname",HttpBody)
                let props1={url:`llm/checkvdbname`,setinfoFunc:props.setinfoFunc,setCookies_ParsFunc:props.setCookies_ParsFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then((result:any) =>
                    {
                        if(result){
                            

                            trigger_mask = true
                            let pop = <Popup setTrigger={setTrigger_maskFunc} 
                                    trigger = {trigger_mask}
                                    dialogWidth = '550px'
                                    title='LLM 设置'
                                    >
                                        <div className='filter_innercontainner_propoties' style={{width:"80%",marginTop:"10px",marginBottom:"10px"}}>
                                            <Form>
                                                {result.promptList.map((item:string,index:number) =>{
                                                    return  <div>
                                                            <Form.Check
                                                                key={item}
                                                                label={item}
                                                                value={0}
                                                                name="group1"
                                                                type= 'radio'
                                                                className={props.parsName+'multiselection'}
                                                                onChange={e => selectionChange(e.target.checked,item)}
                                                            />
                                                        </div>
                                                                 
                                                })}
                                            </Form>
                                        </div>
                                </Popup>
                            setImgmask(pop)
                            
                            
                            // setCookies_ParsFunc(inputVal)
                        }   
                    }
                )
                
            }
            else if(props.parsName ===  "splitorInput"){
                
                let txt = document.getElementById('item_inputprompt_content')?.innerHTML
                if(multiselectionList[0] && props.name_in_db[2] === "prompt_doc"){
                    
                    let HttpBody = {...HttpBody_init,filename:multiselectionList[0]};
                    let props1={url:`llm/getcontent`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    await UseURLLoader(props1).then(result =>
                        {
                            
                            if(result){

                                setSubselectoin(result)
                                setAddmark(true)
                            }else{
                                props.setinfoFunc("该文件无法打开", 20000)
                            }
                        }
                    )
                }
                else if(txt && txt.trim().length>0 && props.name_in_db[2] === "prompt_txt"){
                    setSubselectoin(txt)
                    setAddmark(true)
                }
                else if(props.name_in_db[2] === "prompt_doc"){{props.setinfoFunc("请先输入prompt内容。","2000")}}
                else if(props.name_in_db[2] === "prompt_txt"){{props.setinfoFunc("请先选择文件。","2000")}}
                else{props.setinfoFunc("发生未知错误，请刷新后重试。","2000")} 
                
            }else if(props.parsName === "url_article"){
                
                if(inputVal && inputVal.includes("https://ieeexplore.ieee.org")){
                    let HttpBody = {...HttpBody_init,"articleUrl":inputVal};
                    let props1={url:`llm/article_download`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    props.waitinfoFunc(true)
                    await UseURLLoader(props1).then( async (result:any) =>
                        {   
                            console.log(result)
                            if(result){
                                props.waitinfoFunc(false)
                                props.setinfoFunc("文章以成功添加到数据库，请点击刷新。","3000")
                            }else{props.setinfoFunc("加载失败，请确认IEEE下载链接是否有效。","3000")}
                        }
                    )
                }else{
                    props.setinfoFunc("请输入有效的IEEE下载链接地址，例如：https://ieeexplore.ieee.org/document/12345，或者：https://ieeexplore.ieee.org/stamp/stamp.jsp?tp=&arnumber=12345","3000")
                }

            }

            if(!["zapier_emailservice","zapier_customtrigger","mask"].includes(props.parsName) ){
                if(butDisabled === "添加"){
                    setbutDisabled("删除")
                    if(props.parsName === "emailservice" || props.parsName === "customtrigger"){props.setCookies_Pars(props.parsName,"删除","new")}
                }else{
                    setbutDisabled("添加")
                    if(props.parsName === "emailservice" || props.parsName === "customtrigger"){props.setCookies_Pars(props.parsName,"添加","new")}
                }
            }
            
        }else{
            alert(PagePrompt.LoginTips)}
    }

    async function handleClick_chat(){
        if (props.loginCheckapp === true){
            if(props.parsName === "translation_but"){
                //http translation
                let content = document.getElementById('item_input'+props.name_in_db)?.innerHTML
                let selectedlanguage =  props.setCookies_Pars("language_selection","","get")
                if (content){
                    if(selectedlanguage === undefined){selectedlanguage="中文（简体）"}
                    let HttpBody = {...HttpBody_init,content:content,selectedlanguage:selectedlanguage};
                    // httpRequest("api/translation",HttpBody)
                    let props1={url:`api/translation`,setinfoFunc:props.setinfoFunc,setGeneratemsgBox:props.setGeneratemsgBox,HttpBody:HttpBody}
                    await UseURLLoader(props1).then(result =>
                        {
                            if(result){
                                if(result.indexOf("(原文)") !== -1){result = result.substring(4,result.length)}
                                setHttpresponse(result)
                                props1.setGeneratemsgBox(result)
                            }
                        }
                    )
                }
                else{
                    alert("文本内容不能为空白")
                }
                
            }else if(props.parsName === "mylocation"){
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(async function (position) {
                        //get location info
                        let YOUR_API_KEY = props.setCookies_Pars(props.name_in_db,"","get") 
                        props.setCookies_Pars(props.parsName,`${position.coords.latitude},${position.coords.longitude}`,"new")
                        if(YOUR_API_KEY && YOUR_API_KEY!==""){
                            let Gmapurl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${YOUR_API_KEY}`
                            props.waitinfoFunc(true,30000)
                            await UseURL(Gmapurl,props.setinfoFunc).then(result =>
                                {
                                    props.waitinfoFunc(false)
                                    if(result){
                                        let resultinfo = `坐标：${position.coords.latitude},${position.coords.longitude} \n地址：${result.formatted_address}`
                                        setHttpresponse(resultinfo)
                                    }
                                }
                            ).catch(function (error){console.log(error);props.waitinfoFunc(false)})
                        }else{
                            props.setinfoFunc("请输入有效的api密钥。","2000")
                        }
                    
                    });
                  } else {
                    props.setinfoFunc("Geolocation is not available in your browser.您的浏览器不支持地理位置定位，请允许相关设置。","4000")
                  }
            }else if(props.parsName === "map"){
                if(!addmark){
                    setAddmark(true)
                    setinputVal("mark")}
                else{setAddmark(false)}
                
            }else if(props.parsName === "meteomatics_mylocation"){
                let searchlocation = props.setCookies_Pars(props.name_in_db,"","get")
                
                if(searchlocation){
                    searchlocation=searchlocation.split(",")
                    searchlocation.forEach(async (locatioelem:any) => {
                        locatioelem=locatioelem.replace("\n","")
                        let targtaddress = locatioelem
                        let list = targtaddress.split(" ")

                        let HttpBody0 = {...HttpBody_init};
                        let stopindex = PagePrompt.stopIndexDict["meteomatics"]
                        let HttpBody2 = setHttpBody(HttpBody0,stopindex)
                        HttpBody2 = {...HttpBody2,meteomatics_mylocation:`${list[list.length - 2]}, ${list[list.length - 1]}`};

                        props.waitinfoFunc(true,30000)
                        let Url = "api/meteomatics"
                        let props2={url: Url,setinfoFunc:props.setinfoFunc,setGeneratemsgBox:props.setGeneratemsgBox,HttpBody:HttpBody2}
                        await UseURLLoader(props2).then(async result =>
                            {    
                                props.waitinfoFunc(false)  
                                if(result){
                                    let meteoresult: {[key: string]: any} = {}
                                    result.forEach((elem:any) => {
                                        let Parsname = elem.parameter
                                        elem.coordinates[0].dates.forEach((element:any) => {
                                            meteoresult[element.date]= {...meteoresult[element.date],[Parsname]:element.value}
                                        });
                                    });
                                    
                                    meteoresult = {...meteoresult,"address":targtaddress}
                                    props.setGeneratemsgBox(meteoresult)

                                    return meteoresult
                                }

                            }
                        ).catch(function (error){console.log(error);props.waitinfoFunc(false)})
                    });
                }
                else{
                    if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(async function (position) {
                            let targtaddress = `${position.coords.latitude},${position.coords.longitude}`
                            let HttpBody = {...HttpBody_init,location:`${position.coords.latitude},${position.coords.longitude}`,opt:"check",language:"zh"};
                            let props1={url:`api/geopy`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                            targtaddress = await UseURLLoader(props1).then(async (result) =>
                                {
                                    if(result){
                                        result = result[0].slice(8,result[0].length)
                                        result = result.replace("latlng=","\n")
                                        
                                        setHttpresponse(result)
                                        return result
                                        
                                    }else{
                                        setMultiselection(["无"])
                                    }
                                
                                }
                            )

                            let HttpBody0 = {...HttpBody_init};
                            let stopindex = PagePrompt.stopIndexDict["meteomatics"]
                            let HttpBody2 = setHttpBody(HttpBody0,stopindex)
                            HttpBody2 = {...HttpBody2,meteomatics_mylocation:`${position.coords.latitude}, ${position.coords.longitude}`};

                            props.waitinfoFunc(true,30000)
                            let Url = "api/meteomatics"
                            let props2={url: Url,setinfoFunc:props.setinfoFunc,setGeneratemsgBox:props.setGeneratemsgBox,HttpBody:HttpBody2}
                            await UseURLLoader(props2).then(async result =>
                                {      
                                    props.waitinfoFunc(false) 
                                    if(result){
                                        
                                        let meteoresult: {[key: string]: any}= {}
                                        result.forEach((elem:any) => {
                                            let Parsname = elem.parameter
                                            elem.coordinates[0].dates.forEach((element:any) => {
                                                meteoresult[element.date]= {...meteoresult[element.date],[Parsname]:element.value}
                                            });
                                        })

                                        // meteoresult["location"]:
                                        meteoresult = {...meteoresult,"address":targtaddress}
                                        props.setGeneratemsgBox(meteoresult)

                                        return meteoresult
                                    }

                                }
                            ).catch(function (error){console.log(error);props.waitinfoFunc(false)})
                            
                        })
                    }else{
                        props.setinfoFunc("Geolocation is not available in your browser.您的浏览器不支持地理位置定位，请允许相关设置。","4000")
                    }
                }
                
            }
            else if(props.parsName === "wikipedia_search"){
                
                let search_content = props.setCookies_Pars("search_content","","get")
                if(search_content && search_content.trim().length > 0 ){
                    let language = props.setCookies_Pars("language","","get")
                    if(!language){language="zh"}
                    let HttpBody = {...HttpBody_init,content:search_content,language:language};
                    
                    let props1={url:`api/wikipedia`,setinfoFunc:props.setinfoFunc,setGeneratemsgBox:props.setGeneratemsgBox,HttpBody:HttpBody}
                    await UseURLLoader(props1).then(result =>
                        {
                            if(result){
                                setHttpresponse(result)
                                props.setCookies_Pars("prompt_txt",true,"new")
                            }
                            else{
                                setHttpresponse("")
                                props.setCookies_Pars("prompt_txt",false,"new")
                            }
                        }
                    )
                }else{
                    props.setinfoFunc("请输入搜索词条","4000")
                }
            }
            // setGeneratemsgBox
        }else{
            alert(PagePrompt.LoginTips)}
    }

    async function handleClick_voice(){
        if (props.loginCheckapp === true){
            let content = document.getElementById('item_input'+props.name_in_db[0])?.innerHTML
            let voiceselection =  props.setCookies_Pars("voice_selection","","get")
            if(!voiceselection){
                voiceselection = props.defaultPras[1]
            }
            if (content){
                let opt=0
                if(props.name_in_db[1] === "audio/wav"){opt=1}
                let HttpBody = {...HttpBody_init,content:content,voiceselection:voiceselection,opt:opt};
                let props1={url:`api/reader`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {   
                        if(result){
                            setinputVal(result)
                        }
                    }
                )
            }else{
                alert("文本内容不能为空白")
            }
        }else{
            alert(PagePrompt.LoginTips)}
    }

    function foldFunc(obj:Object,name:string){
        if(props.parsName !== "map"){
            setfoldcotrol(foldcotrol === "∴"?  "∵" : "∴")
            setheightcotrol(heightcotrol === "auto" ? "20px":"auto")
        }else{
            setfoldcotrol(foldcotrol === ":"?  "‥" : ":")
        }
        
    }

    if(["button","input","input_s","range","empty","checkbox","input_but_dict"].includes(props.type)){
        
        if (props.required === false ) {
            buttonIMG = <img id={"img_"+props.parsName} src={IMG_Libs["check0"]} onClick={e => handleChange(e.target,props.required)} className="rounded me-2" width="20" alt="" />;
        } else {
            buttonIMG = <img id={"img_"+props.parsName} src={IMG_Libs["check3"]} onClick={e => handleChange(e.target,props.required)} className="rounded me-2" width="20" alt="" />;
        }
        //修正图像错误
        if((document.getElementById("img_"+props.parsName)?.getAttribute('src') )?.indexOf("check0") === -1 && (props.setselected === false || props.setselected === "false")){
            document.getElementById("img_"+props.parsName)?.setAttribute('src',IMG_Libs["check0"])
        }

    }else if (["button_prompt","button_pic","button_multiselection","multiselection","button_oneselection","button_oneselection_pic","button_txt_renew","button_input","but_multiple","button_txt","button_mp3","selectionbox","selectionbox_2layer","button_notion"].includes(props.type)){
        buttonIMG = <div></div>
    }
    else{
        buttonIMG = <button className='fold_contrl' type="button" id={'fold_contrl'+props.parsName} onClick={(e) => foldFunc(e.target,props.parsName)}>{foldcotrol}</button>  ;
    }
    
    function filepromptFunc(result:string){
        props.setchecked_default(false)
        if(result.length>0){
            setfileprompt(
                <textarea  className='item_input' style={{width:"100%",height: '10em'}} value={result} ></textarea>
            )
        }
    }

    async function saveTXTprompt(router:string){
        if(router === "txtprompt" || router === "docprompt"){
            let HttpdefaultPras: {[key:string]:any} = {}
            if(props.defaultPras){HttpdefaultPras = [...props.defaultPras]; }
             
            let value
            if(router === "txtprompt"){
                value = document.getElementById('item_inputprompt_content')?.innerHTML
            }else if(router === "docprompt" && multiselectionList !== undefined){ //multiselectionList -> doc prompt selection 
                value = multiselectionList[0]
            }
            
            if (props.username && !(value === undefined || value.trim() === "")){   
                (props.APIpars_list).forEach((element:any,index:number) => {
                    let [key,value] = Object.entries(element)[0]
                    if( props.setCookies_Pars(key,"","get") !== undefined){
                        HttpdefaultPras[index] = props.setCookies_Pars(key,"","get")}
                    else if(value === true){
                        // case empty
                        HttpdefaultPras[index] = "true"
                    }else if(key === "vdb_name"){
                        HttpdefaultPras[index] = document.getElementById('item_input'+key)?.getAttribute("value")
                    }
                });   

                // remove the first and the last items
                let HttpBody:{[key:string]:any} = {}
                if(HttpdefaultPras[1] === true){
                    HttpBody = {...HttpBody_init,username:props.username,txtprompt:value,column:"prompt_file",promptPars:HttpdefaultPras};}
                else{
                    if(router === "txtprompt"){HttpBody = {...HttpBody_init,txtprompt:value,column:"prompt_txt",promptPars:HttpdefaultPras,splitorInput:""};}
                    else if(router === "docprompt"){HttpBody = {...HttpBody_init,docprompt:value,column:"prompt_doc",promptPars:HttpdefaultPras,splitorInput:""};}                
                }
                
                if(document.getElementById("item_inputsplitorInput")?.getAttribute("value")){HttpBody["splitorInput"] = document.getElementById("item_inputsplitorInput")?.getAttribute("value")}

                let props1={url:`llm/${router}`,setinfoFunc:props.setinfoFunc,refresh_multiselection:props.refresh_multiselection,setchecked_default:props.setchecked_default,HttpBody:HttpBody}
                props.waitinfoFunc(true,60000)
                await UseURLLoader(props1).then(result =>
                    {
                        props.waitinfoFunc(false)
                        if(result){
                            setMultiselection(result)
                            setMultiselectioncol(result)
                        }
                        
                    }
                )
            }else{
                if(router === "txtprompt"){alert("Prompt内容不能为空")}
                else{alert("请刷新<已上传的文件>, 并且选择其中之一来生成prompt")}
                
            }
        }else if(router === "writingprompt" || router === "polishingprompt"){
            let prompt_name = props.setCookies_Pars("prompt_name","","get")
            if(prompt_name && prompt_name.trim().length>0){
                let language = props.setCookies_Pars("language","","get")
                let polishing_selection:any
                if(!language){language = 0}
                
                if(router === "writingprompt"){
                    let polishing_content = document.getElementById('item_inputwriting_content')?.innerHTML
                    if(polishing_content && polishing_content.trim().length>0)
                    {
                      polishing_selection = polishing_content
                    }else
                    { polishing_selection = props.setCookies_Pars("writing_selection","","get")
                      if(!polishing_selection){polishing_selection=0}
                      
                    }
                }else{
                    let polishing_content = document.getElementById('item_inputpolishing_content')?.innerHTML
                    
                    if(polishing_content && polishing_content.trim().length>0)
                    {
                        if(polishing_content.substring(0,6).includes("你的任务是") || polishing_content.substring(0,8).includes("Your job")){
                            polishing_selection = polishing_content
                        }else{
                            polishing_selection = (language === 0?"你的任务是":"Your job:")+polishing_content
                        }
                      
                    }else
                    {polishing_selection = props.setCookies_Pars("polishing_selection","","get")}
                    if(!polishing_selection){polishing_selection=0}
                    
                }
                
                let HttpBody = {...HttpBody_init,router:router,prompt_name:prompt_name,selection:[language,polishing_selection]};
                let props1={url:`llm/writingprompt`,setinfoFunc:props.setinfoFunc,refresh_multiselection:props.refresh_multiselection,setchecked_default:props.setchecked_default,HttpBody:HttpBody}
                props.waitinfoFunc(true,30000)
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            props.waitinfoFunc(false)
                        }
                        
                    }
                )
            }else{
                props.setinfoFunc("请输入prompt名称。","2000")
            }
            


            
        }
        
            
        
    }

    function handlecheckbox(element:any){
        setCookies_ParsFunc(element)
        changeStateIcon_uncheked()
        setinputVal(element)
    }
    function setHttpBody(HttpBody:object,stopindex:number){
        props.APIpars_list.forEach((element:string,index:number) => {
            if(index <= stopindex){
                let value = props.setCookies_Pars(Object.keys(element)[0],"","get")
                if(value){
                    HttpBody = {...HttpBody,[Object.keys(element)[0]] : value}
                    
                }else{
                    HttpBody = {...HttpBody,[Object.keys(element)[0]] : props.defaultPras[index]}
                }
            }
        });
        return HttpBody
    }
    async function  refresh_multiselection(){
        if (props.username){
            if(props.parsName === "prompt_selection" || props.parsName === "vectordb"){
                let props1={url:"llm/getsavedprompts",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init},parsName:props.parsName}
                await UseURLLoader(props1).then(result =>
                    {   
                        if(typeof result !== 'undefined'){
                            if(result[0]){setMultiselection(result[0])}
                            if(result[1]) {setMultiselectioncol(result[1])}
                        }
                        
                    }
                )
            }else if(props.parsName === "myfiles"){
                let props1={url:"llm/getsavedfile",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init}}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setMultiselection(result)
                            setMultiselectioncol(result)
                        }
                        
                    }
                )
            }else if (props.parsName === "newsgooglesearch" || props.parsName === "topnewssearch" || props.parsName === "juhenewssearch"){
                let HttpBody0 = {...HttpBody_init};
                let stopindex = PagePrompt.stopIndexDict[props.APIname.split(" ")[0]]
                let HttpBody = setHttpBody(HttpBody0,stopindex)
                props.waitinfoFunc(true,30000)
                let Url = "api/googlenews"
                if(props.parsName === "topnewssearch"){Url = "api/topnews"}
                else if(props.parsName === "juhenewssearch"){ Url = "api/juhenews"}
                let props1={url: Url,setinfoFunc:props.setinfoFunc,setGeneratemsgBox:props.setGeneratemsgBox,HttpBody:HttpBody}
                let newsList = await UseURLLoader(props1).then(result =>
                    {      
                        if(result){
                            if(result[0]){setMultiselection(result[0])}
                            if(result[1].length !== 0){props1.setGeneratemsgBox(result[1])}
                            return result[1]
                        }

                    }
                )
                if(newsList !== undefined){
                    let titleList :any[]=[]
                    let urllist:{[key:string]:any} = {}
                
                    if(props.parsName === "newsgooglesearch"){
                        newsList.forEach((value:any) => {if(PagePrompt.newswebsupportList.includes(value[2])){titleList.push(value[1])}})

                        newsList.map((x:any,index:number) => {
                            if(PagePrompt.newswebsupportList.includes(x[2])){
                                urllist[index.toString()+"_"+x[2]]=x[3] //same name
                            }
                            return urllist
                        });
                    }else if (props.parsName === "topnewssearch"){
                        newsList.forEach((value:any) => {titleList.push(value[1])})

                        newsList.map((x:any,index:number) => {
                            urllist[index.toString()+"_"+x[2]]=x[3]
                            return urllist
                        });
                    }else{
                        newsList.forEach((value:any) => {titleList.push(value[1])})

                        newsList.map((x:any,index:number) => {
                            urllist[index.toString()+"_"+x[2]]=x[3]
                            return urllist
                        });
                    }

                    let HttpBody1:any = {...HttpBody_init};
                    HttpBody1["urllist"]= urllist
                    HttpBody1["searchengine"]= props.parsName
                    let props2={url:"api/newscontent",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody1}
                    let newscont = await UseURLLoader(props2).then((result:any) =>
                        {   
                        if(result){
                            return result
                        }
                            
                        }
                    )  
                    let newscontent: any[] =[]
                    titleList.forEach((t,index) => {
                        newscontent.push([t,newscont[index][0],Object.values(urllist)[index]])
                    });
                    props.setNewscontent(newscontent)
                    props.setRefresh_newscontent(newscontent)
                    
                    props.waitinfoFunc(false)
                }
                
            }else if(props.parsName === "article_selection"){
                let props1={url:"llm/getsavedfile?path=1",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init}}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            // console.log(result)
                            setMultiselection(result)
                            setMultiselectioncol(result)
                        }
                        else{
                            // console.log(result)
                        }
                    }
                )
            }

            
        }
        
    }

    function httpRequest_download(url:string,HttpBody:any,filename:string,filetype:string){
        if(filetype === "pdf"){
            axios.post(`${PagePrompt.url}/${url}`,HttpBody,{responseType:'blob'}).then(
                response => {
                    var  blob = new Blob([response.data], {type: "application/pdf"});
                    var newurl = (window.URL ? URL : webkitURL).createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = newurl;
                    a.download = filename
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                },
                async error => {
                    console.log(error.message.errono)
                }
            ).catch(function (error){console.log(error)})
        }else{
            axios.post(`${PagePrompt.url}/${url}`,HttpBody).then(
                response => {
                    var blob = new Blob([response.data]);
                    
                    var newurl = (window.URL ? URL : webkitURL).createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = newurl;
                    a.download = filename
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                },
                async error => {
                    console.log(error.message.errono)
                }
            ).catch(function (error){console.log(error)})
        }
        
    }

    function onConnect2(arg:any) {
    
        props.setinfoFunc("已经成功链接本地数据库，请选择数据库，然后提问。",2000)
        setMultiselection(arg.fileList)
    }
    function onVDBquery(arg:any){
        props.waitinfoFunc(false)
        
        //send msg
        props.saveworkflow(props.parsName,arg)
        setbutDisabled(null)
    }

    async function refresh_oneselection(){
        if (props.username){
            if( props.parsName === "saved_pic" || props.parsName === "wf_icon"){ //"wf_icon"
                let props1={url:"llm/getsavedimg",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init}}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setMultiselection(result)
                            setMultiselectioncol(result)
                        }
                    }
                )
                
            }else if( props.parsName === "file_selection"){
                props.setinfoFunc("请期待网站更新","2000")
            }else if(props.parsName === "vdb_selection" ){
                
                if(multiselectionList[0]){
                    
                    props.waitinfoFunc(true,30000)
                    
                    let get_value = document.getElementById('item_inputvdb_selection')?.getAttribute("value")

                    if(butDisabled && butDisabled.trim().length >0){
                        props.waitinfoFunc(true,100000)
                        socket.emit("loadvdb", { 
                            question:butDisabled,
                            filename:multiselectionList[0],
                        });
                        
                    }else if(get_value && get_value.trim().length >0){
                        props.waitinfoFunc(true,100000)
                        socket.emit("loadvdb", { 
                            question:get_value,
                            filename:multiselectionList[0],
                        });
                    }
                    else{
                        props.setinfoFunc("请输入您的提问。","2000")
                    }

                }else{
                    setinputVal(props.name_in_db)
                    const VDBURL = 'http://localhost:8080';
                    socket = io(VDBURL,{
                        reconnectionDelayMax:10000,
                        query:{"request":"vdbconnection"},
                        extraHeaders: {
                            "Access-Control-Allow-Origin": "*",
                          },
                        transports: ['websocket', 'polling', 'flashsocket'],
                    });
                    socket.on('connectvdb', (arg:any)=>onConnect2(arg));
                    socket.on("responsetxt", (arg:any)=>onVDBquery(arg));

                    socket.off('connectvdb', (arg:any)=>onConnect2(arg));
                    socket.off("responsetxt", (arg:any)=>onVDBquery(arg));
                }
                
                
            }
            else{
                let props1={url:"llm/getsavedfile",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init}}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setMultiselection(result)
                            setMultiselectioncol(result)
                        }
                    }
                )
            }
            
        }
    }
    async function deleteprompt(item:string,col:string,index:number){
        if(props.parsName === "myfiles"){
            let HttpBody = {...HttpBody_init,item:item};
            let props1={url:"llm/deletedoc",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
            await UseURLLoader(props1).then(result =>
                {
                    if(result){
                        refresh_multiselection()
                    }
                }
            )
        }else{
            
            if(props.parsName === "prompt_selection" || props.parsName ==="vectordb"){
                
                if(item.indexOf("_doc") !== -1){col = "prompt_doc"}
                else if(item.indexOf(".txt") !== -1){col = "prompt_file"}
                else{col = "prompt_txt"}
            }else if(props.parsName === "article_selection"){
                col = "article"
            }
            else{
                if(item.indexOf("_doc") !== -1){col = "prompt_doc"}
                else{col = "prompt_file"}
            }
            
            let HttpBody = {...HttpBody_init,col:col,item:item};
            // httpRequest("llm/deleteprompt",HttpBody)
            let props1={url:"llm/deleteprompt",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
            await UseURLLoader(props1).then(result =>
                {
                    if(result){
                        refresh_multiselection()
                    }
                }
            )
        }
        
    }
    async function deletedoc(item:string,col:string,index:number){
        let HttpBody 
        if(props.parsName === "saved_pic"){
            HttpBody = {...HttpBody_init,item:item,col:"uploadimg"};
            setMultiselection(multiselection.filter(i => i !== item))
        }else{
            HttpBody = {...HttpBody_init,item:item,col:"uploadfile"};
        }
        
        // httpRequest("llm/deletedoc",HttpBody)
        let props1={url:"llm/deletedoc",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        await UseURLLoader(props1).then(result =>
            {
                if(result){
                    refresh_oneselection()
                }
            }
        )
    }

    async function renew(parsName:string){
        if(parsName === "zapier_emailservice"){
            let pars = document.getElementById('hold_space_zapier_emailservice')?.innerHTML
            let pars_old = props.setCookies_Pars(props.parsName,"","get")
            if(pars !== ""&& pars_old !== "" && pars_old !== pars){
                let HttpBody = {...HttpBody_init,token:pars,token_old:pars_old};
                // httpRequest("zap/renewtoken",HttpBody)
                let props1={url:`zap/renewtoken`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setCookies_ParsFunc(result)
                        }
                    }
                )
            }else if(pars_old === ""){ alert("请在‘查看流程图设置中’重新加载改设置")}
            else{
                alert("请先获取新的token")
            }
        }else if(parsName === "serial_number"){
            let pars = document.getElementById('hold_space_serial_number')?.innerHTML
            let pars_old = props.setCookies_Pars(props.parsName,"","get")
            if(pars !== ""&& pars_old !== "" && pars_old !== pars){
                let HttpBody = {...HttpBody_init,token:pars,token_old:pars_old};
                let props1={url:`api/renewserialnumber`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        if(result){
                            setCookies_ParsFunc(result)
                        }
                    }
                )

            }else if(pars_old === ""){ alert("请在‘查看流程图设置中’重新加载改设置")}
            else{
                alert("请先获取新的token")
            }
        }
    }

    function multiselectionChange(checked:boolean,item:string){
        multiselectionList =[]
        selectionListAll=[]
        let obj = document.getElementsByClassName(props.parsName+"multiselection");
        Array.from(obj).forEach((element,index)=> {
            if(props.parsName === "newsgooglesearch" || props.parsName === "topnewssearch" || props.parsName === "juhenewssearch"){
                if((element as HTMLInputElement).checked){
                    multiselectionList.push(index)
                }
            }else if(props.parsName === "locationmark"){
                if((element as HTMLInputElement).checked){
                    let latlng = (element as HTMLInputElement).name.split("\n")[1]
                    latlng = latlng.replace(" ",",")
                    multiselectionList.push(latlng)
                    selectionListAll.push(latlng)
                }

                if(multiselectionList.length>2){
                    multiselectionList = multiselectionList.slice(multiselectionList.length-2,multiselectionList.length)
                }
                
                props.setCookies_Pars("selectionListAll",selectionListAll.toString(),"new")
            }else{
                if((element as HTMLInputElement).checked){
                    multiselectionList.push((element as HTMLInputElement).name)
                }
            }
            
        });
        setCookies_ParsFunc(multiselectionList)
        
        if(multiselectionList.length > 0 && props.parsName !== "myfiles"){
            props.setselectedFunc(true,props.setselectedindex)
        }
        if(props.parsName === "locationmark" && checked){
            let latlng = item.split("\n")[1]
            latlng = latlng.replace(" ",",")
            props.setCookies_Pars(props.name_in_db[1],latlng,"new")
            document.getElementById('fold_contrlmap')?.click()
        }else if (props.parsName === "locationmark"){document.getElementById('fold_contrlmap')?.click()}
    }

    function changeOriginDestination(list:any){
        let newList = list.slice(2,4)
        newList.push(list[0])
        newList.push(list[1])
        props.setCookies_Pars('locationmark',newList.toString(),"new")
        document.getElementById('fold_contrlmap')?.click()
      }

    function multiselectionChange2(i:any,item:string){  //multiselectionList.includes(item)
        multiselectionList=[]
        let obj = document.getElementsByClassName(props.parsName+"multiselection");
        Array.from(obj).forEach((element)=> {
            if((element as HTMLInputElement).checked){
                multiselectionList.push((element as HTMLInputElement).name.split("-")[0])
            }
        });
        if(props.parsName === "meteomatics_parameters"){
            if(item === "All"){
                if(i){setinputVal(false);setCookies_ParsFunc(multiselectionList)}
                else{setinputVal(true);setCookies_ParsFunc(item)}
            }else{
                setinputVal(false)
                setCookies_ParsFunc(multiselectionList)
            }
            
        }else{
            setCookies_ParsFunc(multiselectionList)}
        
        
        if(multiselectionList.length > 0 && props.parsName !== "myfiles"){
            props.setselectedFunc(true,props.setselectedindex)
        }
    }
        

    function oneselectionChange(checked:boolean,item:string){
        if(checked){
            
            multiselectionList=[item]
            props.APIpars_list[props.setselectedindex][Object.keys(props.APIpars_list[props.setselectedindex])[0]] = true
        }
        if(props.name_in_db === "wf_icon"){
            props.setCookies_Pars(props.name_in_db,props.username + "/" +multiselectionList[0],"new")
        }
        else{setCookies_ParsFunc(multiselectionList[0])}
        
        
    }
    function selectionChange(checked:boolean,item:string){
        if(checked){
            if(props.parsName === "vdb_name"){
                setinputVal(item)
                setCookies_ParsFunc(item)
            }
        }

    }
    
    function checkplaceholder(obj:any){
        setinputVal(obj.placeholder)
        obj?.setAttribute("placeholder","")
    }

    function downloadfile(obj:any){
        if(obj === "saved_pic"){
            let filename = props.setCookies_Pars(obj,"","get")
            DownloadIMG(filename,props.username)
        }
        else if(typeof obj === "object"){
            let filenameList: string [] =[]
            obj.forEach((element:string ) => {
                if(props.setCookies_Pars(element,"","get")){
                    filenameList = filenameList.concat(props.setCookies_Pars(element,"","get").split(','))
                }
            });
            if(filenameList.length > 0 ){
                filenameList.forEach((element:string) => {
                    if(element.split('.')[1] === 'png' || element.split('.')[1] === 'jpg'){
                        DownloadIMG(element,props.username)
                    }else{
                        let HttpBody = {...HttpBody_init,"filename":element};
                        httpRequest_download("api/download",HttpBody,element,element.split('.')[1])
                    }
                    
                });
            }
        }
        else{
            if(props.setCookies_Pars(obj,"","get")){
                let filenameList = props.setCookies_Pars(obj,"","get").split(',')
                filenameList.forEach((element:string) => {
                    let HttpBody = {...HttpBody_init,"filename":element};
                    httpRequest_download("api/download",HttpBody,element,element.split('.')[1])
                });
            }else{props.setinfoFunc("请先选择要下载的文件","2000")}
        }
        
    }

    function handleAdd_item(obj:any){
        let l=Object.keys(key_value_addon).length
        if(obj === "addcustomdata"){//(key,value) key_value_addon
            setKey_value_addon({...key_value_addon,[l.toString()]:{"":""}})
        }
    }
    function setKey_value_addon_But(obj:any,opt:string,key_index:string){       
        if(opt === "key"){
            setKey_value_addon(
                {...key_value_addon,[key_index]:{[obj]:Object.values(key_value_addon[key_index])[0]}}
            )
        }
        else if(opt === "value"){
            setKey_value_addon(
                {...key_value_addon,[key_index]:{[Object.keys(key_value_addon[key_index])[0]]:obj}}
            )
        }
    }
    function switchFunc(obj:any){

        if(inputVal === null){
            setCookies_ParsFunc(true)
            setinputVal(true);
            if(props.parsName ===  "autodrive"){props.setAutodriveParsFunc(true)}
        }else{
            setCookies_ParsFunc(!inputVal)
            setinputVal(!inputVal);
            if(props.parsName ===  "autodrive"){props.setAutodriveParsFunc(!inputVal)}
            
        }
    }
    function switchMapFunc(obj:string){
        if(obj !== inputVal){setinputVal(obj)}
    }
    function switchLangFunc(obj:string){
        if(obj !== subselectoin){setSubselectoin(obj)}
    }
    async function test(){
        let urllist=
            {
                '0_每日看点快看': 'https://mini.eastday.com/mobile/240202104133998512955.html',
            }
        let HttpBody1:any = {...HttpBody_init};
        HttpBody1["urllist"]= urllist
        HttpBody1["searchengine"]= props.parsName
        let props2={url:"api/newscontent",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody1}
        props.waitinfoFunc(true,30000)
        await UseURLLoader(props2).then((result:any) =>
            {   
                // console.log(result)
            }
        )   
    }
    async function addLocation(obj:string,opt:string,latlng:string=""){
        
        if(obj === "locationmark" || opt === "del"){
        
            let location = inputVal
            if(opt === "del"){location = obj}
            let YOUR_API_KEY = props.setCookies_Pars(props.name_in_db[0],"","get")
            
            let HttpBody = {...HttpBody_init,location:location,opt:opt,YOUR_API_KEY:YOUR_API_KEY};
            if((YOUR_API_KEY && location) || ["get","del"].includes(opt) ){

                if( ["get","del"].includes(opt) ||(opt ==="add" && location.replace(" ","") !== "")){

                    let props1={url:`api/googlemap`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                    props.waitinfoFunc(true,30000)
                    await UseURLLoader(props1).then((result) =>
                        {
                            props.waitinfoFunc(false)
                            if(result){
                                if(result.length>0){
                                    let multiselect:any[] = []
                                    result.forEach((element:string) => {
                                        element = element.replace("latlng","")
                                        let elem = element.split("=")
                                        multiselect.push(elem[1]+"\n"+elem[2])
                                    });
                                    setMultiselection(multiselect) 
                                }else{
                                    setMultiselection(["无"])
                                }
                            }
                        }
                    )
                }
                
            }
            else{
                props.setinfoFunc("请先输入有效的API密钥","2000")
            }
        }else if(obj === "meteomatics_locations" || opt === "del"){
            let location = inputVal
            if(opt === "del"){location = obj}
            let language = props.setCookies_Pars(props.name_in_db[0],"","get")
            if(!language){language="zh"}
            let HttpBody = {...HttpBody_init,location:location,opt:opt,language:language};
            
            if( ["get","del"].includes(opt) ||(opt ==="add" && location.replace(" ","") !== "")){
                props.waitinfoFunc(true,30000)
                let props1={url:`api/geopy`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then((result) =>
                    {
                        props.waitinfoFunc(false)
                        if(result){
                            if(result.length>0){
                                let multiselect:any[] = []
                                result.forEach((element:string) => {
                                    element = element.replace("latlng","")
                                    let elem = element.split("=")
                                    multiselect.push(elem[1]+"\n"+elem[2])
                                });
                                setMultiselection(multiselect) 
                            }else{
                                setMultiselection(["无"])
                            }
                        }
                    }
                )
            }
            
        }
        else if(opt === "save"){
            let HttpBody = {...HttpBody_init,location:obj,opt:opt,YOUR_API_KEY:""};
            let props1={url:`api/googlemap`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
            await UseURLLoader(props1).then((result) =>
                {
                    if(result){
                        setfileprompt( <div></div> )
                        let selectionListAll= props.setCookies_Pars("selectionListAll","","get")
                        if(selectionListAll){selectionListAll+=`,${latlng}`;props.setCookies_Pars("selectionListAll",selectionListAll,"new")}
                        else{props.setCookies_Pars("selectionListAll",latlng,"new")}
                        document.getElementById('fold_contrlmap')?.click()
                    }
                }
            )
        }
        
        
    }

    async function addmarkFunc(latlag:string){
        //get location info
        let YOUR_API_KEY = props.setCookies_Pars("gmap_api_key","","get") 
        
        if(YOUR_API_KEY && YOUR_API_KEY!==""){
            let Gmapurl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlag}&key=${YOUR_API_KEY}`
            props.waitinfoFunc(true,30000)
            await UseURL(Gmapurl,props.setinfoFunc).then(result =>
                {
                    props.waitinfoFunc(false)
                    if(result){
                        let resultinfo = `坐标：${latlag} \n地址：${result.formatted_address}`
                        let addlocation = `address=${result.formatted_address.replaceAll(",","")} latlng=${latlag.replaceAll(","," ")}`
                        let file = <div className='addmark'>
                            <div className='addmark_content'>{resultinfo}</div>
                            <img src={IMG_Libs.add_icon} onClick={()=>addLocation(addlocation,"save",latlag)} className="rounded me-2 addmark_img" width="15" alt="" />
                        </div> 
                        setfileprompt(file)
                    }
                }
            ).catch(function (error){console.log(error);props.waitinfoFunc(false)})
        }else{
            props.setinfoFunc("请输入有效的api密钥。","2000")
        }
        
        
    }

    async function notionFunc(Notionreqs:string,NOTION_KEYWORDS:any="",NOTION_BLOCK_ID:any=""){
        let YOUR_API_KEY = props.setCookies_Pars("notion_api_key","","get") 
        let NOTION_DB_ID = props.setCookies_Pars("notion_db_id","","get") 
        let NOTION_PAGE_ID = props.setCookies_Pars("notion_page_id","","get") 
        
        if(NOTION_BLOCK_ID === ""){NOTION_BLOCK_ID = props.setCookies_Pars("notion_block_id","","get") }
        
        let max_num = props.setCookies_Pars("max_num","","get") 
        if(!max_num){max_num = 100}
        if(YOUR_API_KEY){
            if(["dbsearch"].includes(Notionreqs) && NOTION_DB_ID === undefined){
                props.setinfoFunc("请输入有效的NOTION_DB_ID。","2000")
            }else if([ "pageget","newpage","pageupdate"].includes(Notionreqs) && NOTION_DB_ID === undefined){
                props.setinfoFunc("请输入有效的NOTION_PAGE_ID。","2000")
            }else if([ "blockget","blocklistget"].includes(Notionreqs) && NOTION_BLOCK_ID === undefined){
                props.setinfoFunc("请输入有效的NOTION_BLOCK_ID。","2000")
            }
            else
            {
                let HttpBody = {...HttpBody_init,NOTION_API_KEY:YOUR_API_KEY,NOTION_FUNCTION:Notionreqs,NOTION_KEYWORDS:NOTION_KEYWORDS,NOTION_DB_ID:NOTION_DB_ID,NOTION_PAGE_ID:NOTION_PAGE_ID,NOTION_BLOCK_ID:NOTION_BLOCK_ID };
                props.waitinfoFunc(true,30000)
                let props1={url:`api/notion`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
                await UseURLLoader(props1).then(result =>
                    {
                        props.waitinfoFunc(false)
                        if(result){

                            if(typeof result.message !== "undefined"){

                                // setHttpresponse(result.token)
                                props.setGeneratemsgBox(result.message)
                            }
                            else{
                                let msg:any
                                if(Notionreqs === "listallusers"){

                                    msg = ""
                                    result.results.forEach((elem:any) => {
                                        msg = msg + JSON.stringify(elem).replaceAll('","','", \n"').slice(1,msg.length-1) + "\n\n"
                                    });
                                }else if(Notionreqs === "dbsearch"){
                                    msg = ""
         
                                    result.results.forEach((elem:any) => {
                                        msg +=  "创建时间: " + elem.created_time + "\n"
                                        msg +=  "链接: " + elem.url + "\n"
                                        Object.entries(elem.properties).forEach(([key,value]:any,index:number) => {
                                            if(index<max_num){
                                                if(value.type === "checkbox"){
                                                    msg +=  key +": "+ value[value.type] + "\n"
                                                }
                                                else if(value.type === "date"){
                                                    msg +=  key +": "+ value[value.type]["start"] + "\n"
                                                }else if(value.type === "number"){
                                                    msg +=  key +": "+ value[value.type] + "\n"
                                                }
                                                else if(value[value.type][0] !== undefined){
                                                    msg +=  key +": "+ value[value.type][0]["plain_text"] + "\n"
                                                }
                                            }
                                            
                                            
                                        });
                            
                                        msg += "\n\n"
              
                                    });
                                    

                                }else if( Notionreqs === "pageget" || Notionreqs === "newpage"){
                                    msg = ""
                                    let propertyList:{[key:string]:any}= {}
                                    msg +=  "创建时间: " + result.created_time + "\n"
                                    msg +=  "链接: " + result.url + "\n"
                                    let initselection = ""
                                    Object.entries(result.properties).forEach(([key,value]:any,index:number) => {
                                        if(index<max_num){
                                            if(initselection === ""){initselection = `${key}(${value.type})`}
                                            
                                            if(value.type === "checkbox"){
                                                msg +=  key +": "+ value[value.type] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type]
                                            }
                                            else if(value.type === "date"){
                                                msg +=  key +": "+ value[value.type]["start"] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type]["start"] 
                                            }else if(value.type === "number"){
                                                msg +=  key +": "+ value[value.type] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type]
                                            }
                                            else if(value[value.type][0] !== undefined){
                                                msg +=  key +": "+ value[value.type][0]["plain_text"] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type][0]["plain_text"]
                                            }
                                        }
                                        
                                    });
                        
                                    msg += "\n\n"
                                    if(props.parsName === "newpage" && Notionreqs === "pageget" ){msg = "请在右边流程图中根据提示信息，填写相应内容，然后点击新建按钮。"}
  
                                    setKey_value_addon(propertyList)
                                    setMultiselection([[initselection,propertyList[initselection]]])
                                    setSubselectoin(result.properties)
                                }else if( Notionreqs === "pageupdate"){

                                    msg = ""
                                    let propertyList:{[key:string]:any}= {}
                                    msg +=  "创建时间: " + result.created_time + "\n"
                                    msg +=  "链接: " + result.url + "\n"
                                    let initselection = ""
                                    Object.entries(result.properties).forEach(([key,value]:any,index:number) => {
                                        if(index<max_num){
                                            if(initselection === ""){initselection = `${key}(${value.type})`}
                                            
                                            if(value.type === "checkbox"){
                                                msg +=  key +": "+ value[value.type] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type]
                                            }
                                            else if(value.type === "date"){
                                                msg +=  key +": "+ value[value.type]["start"] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type]["start"] 
                                            }else if(value.type === "number"){
                                                msg +=  key +": "+ value[value.type] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type]
                                            }
                                            else if(value[value.type][0] !== undefined){
                                                msg +=  key +": "+ value[value.type][0]["plain_text"] + "\n"
                                                propertyList[`${key}(${value.type})`]=value[value.type][0]["plain_text"]
                                            }
                                        }
                                        
                                    });
                        
                                    msg += "\n\n"
                                }else if( [ "blockget","blocklistget","addblock"].includes(Notionreqs)){
  
                                    msg = ""
                                    if(result.type === "block" ){
                                        if(result.results.length >0){
                                            let resultList=result.results
                                            let paragraphList:any[] = []
                                            let paragraphList2 :any[] = []
                                            resultList.forEach((element:any,index:number) => {
                                                if(index<max_num){
                                                    if(element.paragraph.rich_text.length>0){
                                                        msg += "作者: " + element.created_by.object + "\n"
                                                        msg += "block_id: " + element.id + "\n"
                                                        msg += "创建时间: " + element.created_time + "\n"
                                                        msg += "内容:\n"+ element.paragraph.rich_text[0].plain_text  + "\n"
                                                        msg +="\n\n"
                                                        paragraphList.push(element.paragraph.rich_text[0].plain_text)
                                                        paragraphList2.push([element.id,element.paragraph])
                                                    }
                                                }
                                            });
                                            if( paragraphList.length >0){
                                                setMultiselection(paragraphList)
                                                setinputVal([0,paragraphList[0]])
                                                setMultiselectioncol(paragraphList2)
                                            }
                                        }else{
                                            msg = "尚未查询到相关数据，请尝试点击获取List。"
                                        }
                                    }else if(result.type === "paragraph"){
                                        
                                        if(Object.keys(result.paragraph).length >0){
                                            let paragraphList:any[] = []
                                            msg += "作者: " + result.created_by.object + "\n"
                                            msg += "block_id: " + result.id + "\n"
                                            msg += "创建时间: " + result.created_time + "\n"
                                            msg += "内容:\n"+ result.paragraph.rich_text[0].plain_text  + "\n"
                                            msg +="\n\n"
                                            paragraphList.push(result.paragraph.rich_text[0].plain_text)

                                            
                                            if( paragraphList.length >0){
                                                setMultiselection(paragraphList)
                                                setinputVal([0,paragraphList[0]])
                                                setMultiselectioncol([result.id,result.paragraph])
                                            }
                                        }else{
                                            msg = "尚未查询到相关数据，请尝试点击获取List。"
                                        }
                                    }
                                    else{
                                        msg = "请核对Notion block ID"
                                    }
                                    
                                   
                                }else if([ "blocklistupdate","blockupdate"].includes(Notionreqs)){
                                    msg += "作者: " + result.created_by.object + "\n"
                                    msg += "block_id: " + result.id + "\n"
                                    msg += "创建时间: " + result.created_time + "\n"
                                    msg += "内容:\n"+ result.paragraph.rich_text[0].plain_text  + "\n"
                                    msg +="\n\n"

                                    let newlist:any[] = [...multiselectioncol]
                                    if(Notionreqs === "blocklistupdate"){
                                        multiselectioncol.forEach((elem:any,index:number) => {
                                            if(elem[0] === result.id){
                                                newlist[index][1] = result.paragraph}
                                        })
                                    }else{
                                        newlist[1] = result.paragraph
                                    }
                                    
                                    setMultiselectioncol(newlist)
                                }else if(Notionreqs === "delblock"){
                                    msg = ""
                                    props.setinfoFunc("删除成功","2000")
                                    setMultiselection([])
                                    setMultiselectioncol([])
                                }
                                else{
                                    msg = ""

                                    msg += "id: " + result.id + "\n"
                                    msg += "created_time: " + result.created_time + "\n"
                                    msg += "title: " + result.title[0].plain_text + "\n"
                                    msg += "url: " + result.url + "\n"
                                    msg += "properties:"
                                    let propertyList = ""
                                    Object.entries(result.properties).forEach(([key,value]:any) => {
                                        msg = msg + key + ", "
                                        propertyList += `${key}(${value.type}),`
                                    });
                                    props.setCookies_Pars("propertyList",propertyList,"new") 
                                    
                                }
                                
                                props.setGeneratemsgBox(msg)
                                
                            }
                            
                        }
                    }
                )
            }
            
        }else{
            props.setinfoFunc("请输入有效的api密钥。","2000")
        }
        

    }
    function notion_filterFunc(value:any,opt:any,opt1:any,indexname:any){

        let newlist = [...multiselection]
        if(indexname === "values"){
            newlist[opt][opt1][3] = value
        }else if(indexname === "outcontainner"){
            newlist[opt][0] = value
        }else if(indexname === "innercontainner"){
            newlist[opt][opt1][0] = value
        }else if(indexname === "propoties"){
            newlist[opt][opt1][1] = value
        }else if(indexname === "conditions"){
            newlist[opt][opt1][2] = value
        }else if(indexname === "but0"){
            newlist.push(["and",["and",value,"contains",""]])
        }else if(indexname === "but1"){
            newlist[opt].push(["and",value,"contains",""])
        }else if(indexname === "but0-del"){
            newlist.splice(opt, 1)
        }else if(indexname === "but1-del"){
            newlist[opt].splice(opt1, 1)
        }else if(indexname === "pagepropoties"){
            newlist[opt][0] = value
            newlist[opt][1] = key_value_addon[value]
        }else if(indexname === "input"){
            newlist[opt][1] = value
            let newentities = {...key_value_addon}
            newentities[newlist[opt][0]] =  value
            setKey_value_addon(newentities)
        }
        else if(indexname === "but3"){
            let [key,value] = Object.entries(key_value_addon)[0]
            newlist.push([key,value])
        }else if(indexname === "but_del3"){
            newlist.splice(opt, 1)
        }else if(indexname === "paragraph"){
            setinputVal([value,multiselection[value]])
            

        }else if(indexname ==="paragraphinput"){
            let inputv = [...inputVal]
            inputv[1]=value
            setinputVal(inputv)
            newlist[inputVal[0]] = value
        }else if(indexname === "delblock"){
            if(value === "blockupdate"){
                let NOTION_BLOCK_ID = multiselectioncol[0]
                notionFunc("delblock","",NOTION_BLOCK_ID)
            }
            else{
                let NOTION_BLOCK_ID = multiselectioncol[inputVal[0]][0]
                notionFunc("delblock","",NOTION_BLOCK_ID)
            }
        }
        else{
            let NOTION_KEYWORDS:{[key:string]:any} = {...subselectoin}
            let valuetype = indexname.split("(")[1].split(")")[0]
            if(valuetype === "checkbox"){
                NOTION_KEYWORDS[indexname.split("(")[0]][valuetype] = value
            }else if(valuetype === "number"){NOTION_KEYWORDS[indexname.split("(")[0]][valuetype] = Number(value)}
            else if(valuetype === "date"){
                NOTION_KEYWORDS[indexname.split("(")[0]][valuetype]["start"] = value
            }
            else{
                NOTION_KEYWORDS[indexname.split("(")[0]][valuetype][0]["plain_text"] = value
                NOTION_KEYWORDS[indexname.split("(")[0]][valuetype][0]["text"]["content"] = value
                
            }
            setSubselectoin(NOTION_KEYWORDS)

        }
        setMultiselection(newlist)
    }
    async function notion_update(Notionreqs:any,INIT_KEYWORDS:any){
        let NOTION_KEYWORDS:{[key:string]:any} = {}
        INIT_KEYWORDS.forEach((elem:any) => {
            let value = subselectoin[elem[0].split("(")[0]]
            
            if(value.type === "checkbox" ){
                value[value.type] = Boolean(elem[1])
            }else if(value.type === "number"){
                value[value.type] = Number(elem[1])
            }
            else if(value.type === "date"){
                value[value.type]["start"] = elem[1]
            }
            else if(value[value.type][0] !== undefined){
                value[value.type][0]["plain_text"] = elem[1]
                value[value.type][0]["text"]["content"] = elem[1]
                
            }
            NOTION_KEYWORDS[elem[0].split("(")[0]]=value
        });
        
        notionFunc(Notionreqs,NOTION_KEYWORDS)
    }

    async function notion_blockupdate(Notionreqs:any,value:any="") {

        if(Notionreqs === "addblock"){
            let index= inputVal[0]
            let NOTION_KEYWORDS:any = multiselectioncol[index][1]

            if(httpresponse && httpresponse.trim().length>0){
                NOTION_KEYWORDS.rich_text[0].plain_text =httpresponse
                NOTION_KEYWORDS.rich_text[0].text.content = httpresponse
                notionFunc("addblock",NOTION_KEYWORDS)

            }else{
                props.setinfoFunc("请输入内容","2000")
            }
        }else if(Notionreqs === "cancle"){
            setAddmark(false)
        }
        else if(props.parsName === "blocklistupdate"){
            multiselectioncol.forEach((elem:any,index:number) => {
                if(elem[1].rich_text[0].plain_text !== multiselection[index]){
                    let NOTION_KEYWORDS = elem[1]
                    let NOTION_BLOCK_ID = elem[0]
                    NOTION_KEYWORDS.rich_text[0].plain_text = multiselection[index]
                    NOTION_KEYWORDS.rich_text[0].text.content = multiselection[index]
                    notionFunc(Notionreqs,NOTION_KEYWORDS,NOTION_BLOCK_ID)
                }
            });
        }
        else if(props.parsName === "blockupdate"){
            let NOTION_KEYWORDS:any = multiselectioncol[1]
            let NOTION_BLOCK_ID = multiselectioncol[0]
            if(NOTION_KEYWORDS.rich_text[0].plain_text !== multiselection[0]){
                NOTION_KEYWORDS.rich_text[0].plain_text = multiselection[0]
                NOTION_KEYWORDS.rich_text[0].text.content = multiselection[0]
                notionFunc(Notionreqs,NOTION_KEYWORDS,NOTION_BLOCK_ID)
            }
            
        }
        
    }

    function openwindows(opt:any){
        window.open(opt, "_blank")
    }
    function closesocket(){
        try{socket.close()}catch(error){}
    }

    
    if(props.type === "input"){ 
        inputOrBut = <input type="text" id={'item_input'+props.parsName} className='item_input' name={props.parsName} placeholder={props.pars} value={inputVal} onChange={(e)=>setStateBut(e.target.value)} onKeyDown={e =>_handleKeyDownflowBox(e)} maxLength={105}></input>
        TXTprompt = <div></div>
    }else if(props.type === "input_s"){
        inputOrBut =<div className='item_input_s_div'><input id={'item_input'+props.parsName} className='item_input_s' type="number" name={props.parsName} placeholder={props.pars} value={inputVal} onChange={(e)=>setStateBut(e.target.value)} onKeyDown={e =>_handleKeyDownflowBox(e)} maxLength={10}></input></div> 
        TXTprompt = <div></div>
    }else if(props.type === "textarea"){
        inputOrBut = <textarea id={'item_input'+props.parsName} className='item_input' style={{width:"100%",height: '10em'}} name={props.parsName} placeholder={""} value={inputVal} onClick={(e)=>{props.setchecked_default(false)}} onChange={(e)=>{e.target.placeholder === ""? setStateBut(e.target.value):checkplaceholder(e.target)}} /> //inputVal
        // TXTprompt=<Button variant="outline-info" onClick={() => saveTXTprompt()} >保存</Button>
        TXTprompt = <div></div>
    }
    else if(props.type === "button_upload")
    {   
        inputOrBut = <FileUpload filepromptFunc ={filepromptFunc} username={props.username} name_in_db={props.name_in_db} setchecked_default={props.setchecked_default} setCookies_Pars={props.setCookies_Pars} refresh_oneselection={refresh_oneselection} setinfoFunc={props.setinfoFunc}/>
        TXTprompt = <div></div>
    }
    else if(props.type === "range"){
        inputOrBut =
        <div>
            <Form.Label id='platform_poll_lable1' >{props.rule[0]}</Form.Label>
            <Form.Range id={'range_'+props.parsName} style={{width:"60%"}} value={inputVal*100/(props.rule[1]-props.rule[0])} onChange={e => setStateBut(parseInt(e.target.value))}/>
            <Form.Label id='platform_poll_lable1' >{props.rule[1]}</Form.Label>
            {inputVal}
        </div>
        TXTprompt = <div></div>
    }else if(props.type === "selectionbox"){
        if( ["styles","sampler_name","style_preset","sampler",'zhipu_model',"language_selection","ckpt"].includes(props.parsName)){
           
            inputOrBut = <div>
                <Form.Select id={"Form_option1_"+ props.parsName} aria-label="Default select example" size="sm" onChange={e => setStateBut(e.target.value)} value={inputVal} >
                    {(props.pars as any).map((element:any,index:number) => {
                        return <option key={"Form_option1_"+element} value={element}>{element}</option>
                    })}
                </Form.Select>
            </div>
        }else if( ["writing_selection"].includes(props.parsName)){
            let language = props.setCookies_Pars("language","","get")
            if(!language){language = 0}
            inputOrBut = <div>
                <Form.Select id={"Form_option1_"+ props.parsName} aria-label="Default select example" size="sm" onChange={e => setStateBut(e.target.value)} value={inputVal? inputVal:props.pars[0].split("-")[0]} >
                    {(props.pars as any).map((element:any,index:number) => {
                        return <option key={"Form_option1_"+element.split("-")[0]} value={element.split("-")[0]}>{element.split("-")[1]}</option>
                    })}

                </Form.Select>
                {
                    httpresponse?
                    <div className="filter_innercontainner_co1">
                        <div className="writing_txt"><p className="writing_title">职业 </p>{PagePrompt.rolerprompt[language][httpresponse][0]}</div>
                        <div className="writing_txt"><p className="writing_title">资历 </p>{PagePrompt.rolerprompt[language][httpresponse][1]}</div>
                        <div className="writing_txt"><p className="writing_title">专长 </p>{PagePrompt.rolerprompt[language][httpresponse][2]}</div>
                        <div className="writing_txt"><p className="writing_title">任务 </p>{PagePrompt.rolerprompt[language][httpresponse][3]}</div>
                    </div>:
                    <div></div>
                }
                
            </div>
        }
        else{
            inputOrBut = <div>
                <Form.Select id={"Form_option1_"+ props.parsName} aria-label="Default select example" size="sm" onChange={e => setStateBut(e.target.value)} value={inputVal? inputVal:props.pars[0].split("-")[0]} >
                    {(props.pars as any).map((element:any,index:number) => {
                        return <option key={"Form_option1_"+element.split("-")[0]} value={element.split("-")[0]}>{element.split("-").slice(1,element.length).join("-")}</option>
                    })}

                </Form.Select>
            </div>
        }
        
        TXTprompt = <div></div>
    }else if(props.type === "selectionbox_2layer"){
        
        inputOrBut = <div>
                    <Form.Select aria-label="Default select example" size="sm" onChange={e => {setStateBut(e.target.value)}}>
                        {(props.pars as any).map((element:any,index:number) => {
                            return <option key={"Form_option_"+Object.keys(element)[0]} value={index} >{Object.keys(element)[0]}</option>
                        })}

                    </Form.Select>
                    </div>
        TXTprompt = <Form>
        <div key={`inline-radio`} className="mt-2 layer_option">
        {Object.entries(subselectoin).map(([key,value]) => {
            return (<Form.Check
            inline  
            key={key}
            label={key}
            name="group1"
            type='radio'
            id={`inline-radio-${key}`}
            onChange={() => handlecheckbox(value)}
            
            /> )
        })}
        </div>
        
        </Form>     
    }
    else if(props.type === "empty"){
        inputOrBut = <div></div>
        TXTprompt = <div></div>
    }
    else if(props.type === "checkbox"){
        inputOrBut = 
        <div>
            <Form>
                <div key={`inline-radio`} className="mb-3">
                {(props.pars as any).map((element:any,index:number) => {
                    return (<Form.Check
                    inline
                    key={index}
                    label={element}
                    name="group1"
                    type='radio'
                    id={`inline-radio-${index}`}
                    onChange={() => handlecheckbox(element)}
                    /> )
                })}
                </div>
                
            </Form>
        </div>
        TXTprompt = <div></div>
    }
    else if(props.type === "but_only"){
        if(["txtprompt","docprompt","writingprompt","polishingprompt"].includes(props.parsName) ){
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => saveTXTprompt(props.parsName)} >{props.pars}</Button>
        }else if (props.parsName === "delete_wf"){
            // delete workflow
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => props.deleteworkflow(props.name_in_db)} >{props.pars}</Button>
        }else if(props.parsName === "save_wf" ){
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db)} >{props.pars}</Button>
        }
        else if(props.parsName === "but_test" ){
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => test()} >{props.pars}</Button>
        }
        else if([ "listallusers","dbget"].includes(props.parsName )){
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => notionFunc(props.parsName)} >{props.parName_ch}</Button>
        }else if([ "db_connect"].includes(props.parsName )){
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => openwindows('/dbconnection')} >{props.parName_ch}</Button>
        }else if( "vdbmanager" === props.parsName){
            inputOrBut = <Button className='but_only' variant="outline-info" onClick={() => openwindows('/vdbmanager')} >{props.parName_ch}</Button>
        }
        else{
            inputOrBut = <div></div>
        }
        
        TXTprompt= <div></div>
    }else if(props.type === "but_multiple"){
        inputOrBut =<div>
            {props.pars.map((elem:string,index:number) => {
            if(elem === "发送至我的邮箱" || elem === "保存流程图" || elem === "发送" || elem === "确认")
            {return <Button className='but_multiple' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db[index])} >{elem}</Button>}
            else if(elem ==="删除" || elem ==="关闭"){
                return <Button className='but_multiple' variant="outline-info" onClick={() => {props.deleteworkflow(props.name_in_db[index]);closesocket()}} >{elem}</Button>}
            else if(elem ==="下载"){return <Button className='but_multiple' variant="outline-info" onClick={() => downloadfile(props.name_in_db[index])} >{elem}</Button>}
            else if(elem === "生成PDF" || elem === "生成TXT"){return <Button className='but_multiple' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db[index])} >{elem}</Button>}
            else if(elem === "一键总结"){return <Button className='but_multiple' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db[index])} >{elem}</Button>}
            else if(elem === "查看"){return <Button className='but_multiple' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db[index])} >{elem}</Button>}
            else if(elem === "总结"){return <Button className='but_multiple' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db[index])} >{elem}</Button>}
            else if(elem === "翻译"){return <Button className='but_multiple' variant="outline-info" onClick={() => props.saveworkflow(props.name_in_db[index])} >{elem}</Button>}
            else{return <div></div>}
             })}
        </div>
        
        TXTprompt= <div></div>
    }
    else if(props.type === "button_multiselection" || props.type === "button_oneselection"){

        let refreshFunc = props.type === "button_multiselection"? refresh_multiselection: refresh_oneselection
        let changeFunc = props.type === "button_multiselection"? multiselectionChange : oneselectionChange
        let deletepromptFunc = props.type === "button_multiselection"? deleteprompt:deletedoc
        inputOrBut = <Button className='flowbox_items_but'  id={'flowbox_items_but'+props.parsName} variant="outline-info" onClick={() => refreshFunc()} >{props.parsName === "vdb_selection"? inputVal:props.pars}</Button>
        if(multiselection[0] === "无"){
            TXTprompt=<div></div>
        }else{
            if(props.type === "button_multiselection"){
                if(props.parsName === "newsgooglesearch" || props.parsName === "topnewssearch" || props.parsName === "juhenewssearch"){
                    TXTprompt=<div  className='height_small'>
                        {multiselection.map((item,index) =>{
                                return <div className='multiselection_contain'>
                                            <div className='multiselection_checkbox'>
                                                <input className={props.parsName+'multiselection prompt_left multiselection_prompt' } type="checkbox" name={item} value={index} onChange={e => changeFunc(e.target.checked,item)} />
                                                <label className={`prompt_content fontcolor-${index%2}`}>{item}</label><br></br>
                                            </div>
                                        </div>                 
                            })
                        }
                    </div>
                }else{
                    TXTprompt=<div>
                        {multiselection.map((item,index) =>{
                            return <div className='multiselection_contain'>
                                        <div className='multiselection_checkbox'>
                                            <input className={props.parsName+'multiselection prompt_left multiselection_prompt'} type="checkbox" name={item} value={index} onChange={e => changeFunc(e.target.checked,item)} />
                                            <label className='prompt_content'>{item}</label><br></br>
                                        </div>
                                        <div className='multiselection_but'> <img src={close} onClick={()=>deletepromptFunc(item,multiselectioncol[index],index)} className="rounded me-2" width="15" alt="" /></div>
                                    </div>                 
                            })
                        }
                    </div>
                }
                
            }else{
                
                if(props.parsName === "vdb_selection"){
                    TXTprompt=<div style={{ position:"relative",top:"-10px"}}>
                        <div>
                            <input type="text" id={'item_input'+props.parsName} style={{display:"inline-block", marginBottom:"5px"}} className='item_input' name={props.parsName} placeholder="请选择数据库，输入您的提问，然后点击加载"  value={butDisabled} onChange={(e)=>setbutDisabled(e.target.value)}></input>
                        </div>
                        <Form>
                            {multiselection.map((item,index) =>{
                                return <div className='multiselection_contain'>
                                    <div className='multiselection_checkbox'>
                                        <Form.Check
                                            key={item}
                                            label={item}
                                            value={0}
                                            name="group1"
                                            type= 'radio'
                                            className={props.parsName+'multiselection multiselection_prompt'}
                                            onChange={e => changeFunc(e.target.checked,item)}
                                        />
                                    </div>
                                </div>               
                            })
                            }
                        </Form>
                    </div>
                }else{
                    TXTprompt=<div>
                        <Form>
                            {multiselection.map((item,index) =>{
                                return <div className='multiselection_contain'>
                                    <div className='multiselection_checkbox'>
                                        <Form.Check
                                            key={item}
                                            label={item}
                                            value={0}
                                            name="group1"
                                            type= 'radio'
                                            className={props.parsName+'multiselection multiselection_prompt'}
                                            onChange={e => changeFunc(e.target.checked,item)}
                                        />
                                    </div>
                                    <div className='multiselection_but'> <img src={close} onClick={()=>deletepromptFunc(item,multiselectioncol[index],index)} className="rounded me-2" width="15" alt="" /></div>
                                </div>               
                            })
                            }
                        </Form>
                    </div>
                }
                
            }
            
        }
        
    }else if (props.type === "multiselection"){
        let value = new Array(props.pars.length).fill(false);
        if(Cookies_Pars !== undefined){
            props.pars.forEach((element:any,index:number) => {
                if(Cookies_Pars.indexOf(element) !== -1){value[index] = true}
            });
        }
        
        inputOrBut = <div></div>
        if(props.parsName === "port"){
            TXTprompt=<div className='multiselection_contain'>
                    {props.pars.map((item:string,index:number) =>{
                            return  <div className='multiselection_checkbox2'>
                                        <input className = {props.parsName +'multiselection prompt_left2 multiselection_prompt'} type="checkbox" name={item} value={index} onChange={() => multiselectionChange2(index,item)} defaultChecked={value[index]}/> 
                                        <label className='prompt_content2'>{item}</label><br></br>
                                    </div>                   
                        })
                    }
                </div>
        }else{
            TXTprompt=<div className='multiselection_contain'>
            {props.pars.map((item:string,index:number) =>{
                    return  <div className='multiselection_checkbox2'>
                                <input className = {props.parsName +'multiselection prompt_left2 multiselection_prompt'} type="checkbox" name={item} value={index} onChange={() => multiselectionChange2(index,item)} defaultChecked={value[index]}/> 
                                <label className='prompt_content2'>{item.split("-")[1]}</label><br></br>
                            </div>                   
                })
            }
        </div>
        }
        
    }else if(props.type === "multiselection_all"){
        let value = new Array(props.pars.length).fill(false);
        if(Cookies_Pars !== undefined){
            props.pars.forEach((element:any,index:number) => {
                if(Cookies_Pars.indexOf(element) !== -1){value[index] = true}
            });
        }
        //"All-全部"
        inputOrBut =<Form.Check
                            inline
                            label="All"
                            name="All"
                            type={'radio'}
                            id={`inline-radio-2`}
                            checked={inputVal}
                            onClick={(e) => multiselectionChange2(inputVal,"All")}
                        />

        TXTprompt=<div className='multiselection_contain'>
                    {props.pars.map((item:string,index:number) =>{
                        return  <div className='multiselection_checkbox2'>
                                    <input className = {props.parsName +'multiselection prompt_left2 multiselection_prompt'} type="checkbox" name={item} value={index} onChange={() => multiselectionChange2(index,item)} disabled={inputVal}/> 
                                    <label className='prompt_content2'>{item.split("-")[1]}</label><br></br>
                                </div>                   
                        })
                    }
                </div>
    }
    else if(props.type === "input_multiselection"){
        inputOrBut = <div>
            <input type="text" id={'item_input'+props.parsName} className='item_input_m' name={props.parsName} placeholder={props.pars} value={inputVal} onChange={(e)=>setStateBut(e.target.value)} onKeyDown={e =>_handleKeyDownflowBox(e)} maxLength={105}></input>
            <img src={IMG_Libs.add_icon} onClick={()=>addLocation(props.parsName,"add")} className="rounded me-2 img_s" width="15" alt="" />
            <img src={IMG_Libs.refresh} onClick={()=>addLocation(props.parsName,"get")} className="rounded me-2 img_s" width="15" alt="" />
        </div>
        
        if(multiselection[0] !== "无"){
            TXTprompt= 
            <div className='multiselection_contain'>
                        {multiselection.map((item,index) =>{
                            return <div className='multiselection_contain'>
                                        <div className='multiselection_checkbox'>
                                            <input className={props.parsName+'multiselection prompt_left multiselection_prompt'} type="checkbox" name={item} value={index} onChange={e => multiselectionChange(e.target.checked,item)} />
                                            <label className='prompt_content'>{item}</label><br></br>
                                        </div>
                                        <div className='multiselection_but'> <img src={close} onClick={()=>addLocation(item,"del")} className="rounded me-2" width="15" alt="" /></div>
                                    </div>                 
                            })
                        }
            </div>
        }else{
            TXTprompt= <div></div>
        }
    
    }
    else if (props.type === "button_oneselection_pic"){
        inputOrBut = <Button id='flowbox_items_but' variant="outline-info" onClick={() =>refresh_oneselection()} >{props.pars}</Button>
        if(multiselection[0] === "无"){
            TXTprompt=<div></div>
        }else{
            TXTprompt=<div className='button_oneselection_pic_container'>
                    <Form>
                        {multiselection.map((item,index) =>{ 
                            try{
                                let item_img = `${PagePrompt.url}/${props.username}/${item}`                    
                                return  <div className='multiselection_checkbox_img'>
                                        <Form.Check
                                            key={item}
                                            label={<img src={item_img} className="me-2" width="50" alt=""/>}
                                            value={0}
                                            name="group1"
                                            type= 'radio'
                                            className={props.parsName+'multiselection'}
                                            onChange={e => oneselectionChange(e.target.checked,item)}
                                        />
                                        <div className='multiselection_but_img'> <img src={close} onClick={()=>deletedoc(item,multiselectioncol[index],index)} className="rounded me-2" width="15" alt="" /></div>
                                        
                                    </div>
                            }catch (error){
                                return <div></div>
                            }
      
                        })
                        }
                    </Form>
                </div>
        }
    }
    else if(props.type === "button_txt_renew"){
        inputOrBut = <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">获取新的token，添加设置后保存生效</Tooltip>}
                    >
                    <div id='flowbox_items'> <Button id='flowbox_items_but' variant="outline-info" onClick={() => handleClick_item()} >{(props.name_in_db).split("_")[1]?(props.name_in_db).split("_")[1]:props.name_in_db}</Button></div>
                    </OverlayTrigger>
        
        TXTprompt = <div>
             <div className='hold_space' id={'hold_space_'+props.parsName}>{httpresponse}</div>
             <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">替换原有token，设置不变</Tooltip>}
            >
             <div className='hold_space_but'><button className='fold_contrl' onClick={() => renew(props.parsName)}>↻</button></div>
            </OverlayTrigger>
        </div>
    }else if(props.type === "button_input"){
        inputOrBut = <div id='flowbox_items'> <Button id='flowbox_items_but' variant="outline-info" onClick={() => handleClick_item()} >{props.name_in_db[0]}</Button></div>
        
        TXTprompt = <div>
                        <input type="text" id={'item_input'+props.parsName} className='item_input' name={props.parsName} placeholder={props.name_in_db[1]} value={inputVal} onChange={(e)=>setStateBut(e.target.value)} maxLength={100}></input>
                    </div>
    }
    else if(props.type === "button_txt"){
        
        if(props.parsName === "wikipedia_search"){
            inputOrBut = <div>
                    <Button id='but_only' variant="outline-info" onClick={() => handleClick_chat()} >{props.pars}</Button>
                    <Button id='but_only' variant="outline-info" onClick={() => {props.setGeneratemsgBox(httpresponse)}} style={{visibility:(httpresponse.trim().length>0)?"visible":"hidden",marginLeft:"10px"}}>查看结果</Button>
            </div>
           
            TXTprompt = <div></div>
        }else{
            inputOrBut = <Button id='but_only' variant="outline-info" onClick={() => handleClick_chat()} >{props.pars}</Button>
            TXTprompt = <div className='hold_space' id={'hold_space_'+props.parsName}>{httpresponse}</div>
        }
       
    }else if(props.type === "button_mp3"){
        inputOrBut = <Button id='but_only' variant="outline-info" onClick={() => handleClick_voice()} >{props.pars}</Button>
        // TXTprompt = <div className='hold_space' id={'hold_space_'+props.parsName}>{httpresponse}</div>
        TXTprompt = <AudioPlayer filepath={inputVal} username={props.username}></AudioPlayer>
    }
    else if(props.type === "input_but_dict"){
        inputOrBut = <Button id='flowbox_items_but_nob' variant="outline-info" onClick={() => handleAdd_item(props.parsName)} >{props.pars}</Button>
        TXTprompt = <div className='input_but_dict'>
            {Object.entries(key_value_addon).map(([key, value]) => {
                return ( <div>
                            <input type="text"  className='item_input_key' name="key" placeholder='key' value={Object.keys(value)[0] as any} onChange={(e)=>setKey_value_addon_But(e.target.value,"key",key)} maxLength={30}></input>
                            <input type="text"  className='item_input_value' name="value" placeholder="value" value={Object.values(value)[0] as any} onChange={(e)=>setKey_value_addon_But(e.target.value,"value",key)} maxLength={100}></input>
                        </div>
                         )
            })}
        </div>
    }else if(props.type === "txt_only"){
        inputOrBut = <div></div>
        TXTprompt = <div className='txt_only'>
            {(Object.values(props.pars) as any).map((elem:any,index:number) => {
                
                let text1 = "∗ "+ (Object.keys(props.pars)[index] as any) + "\n"
                if(elem === ""){ return <div className='notsupport_list'>{text1}</div>}
                else if(typeof elem === "string"){
                    text1+= "   "+ elem;
                    if((Object.keys(props.pars)[index] as any).indexOf("删除") === -1){ return <div className='support_list'>{text1}</div>}
                    else{return <div className='notsupport_list'>{text1}</div>}
                    
                }else{//list
                    if(props.name_in_db !== "newsgoogle"){
                        elem.forEach((sub_elem:string) => {
                            text1+= "   "+sub_elem + "\n"
                        });
                        return <div className='support_list'>{text1}</div>
                    }
                    else{
                        elem.forEach((sub_elem:string) => {
                            text1+=" • " + sub_elem
                        });
                        return <div className='support_list'>{text1}</div>
                    }
                }
                
            })}

        </div>
    }else if(props.type === "txt_default"){
        inputOrBut = <div></div>
        let text
        if(props.parsName === "tips"){text = props.parName_ch + " : "}
        else{text = props.parName_ch + " : "} //"txt_default",
        
        TXTprompt = <div>{text}</div>

        if(props.parsName === "llms_election"){
            let placeholder =  props.setCookies_Pars(props.parsName,"","get")
            TXTprompt = <div> 
                {text}
                <input id={props.parsName+'_input'} className='filter_input_values' type="string" style={{border:'0',textAlign:"center"}} placeholder={placeholder} name={props.parsName} value={""} readOnly></input>
                </div>


        }else{
            TXTprompt = <div>{text}</div>

        }
        
    }
    else if(props.type === "check"){
        inputOrBut = <div className='switch'>
                        <Form.Check // prettier-ignore
                            type="switch"
                            className='switch_item'
                            id={props.parsName +"_switch"}
                            label={props.parName_ch}
                            onClick={(e) => switchFunc(e.target)}
                            checked={inputVal}
                        />
                    </div>
        TXTprompt= <div></div>
    }else if(props.type === "button_pic"){    // button_pic 
        inputOrBut = <div id='flowbox_items' > <Button id='flowbox_items_but' variant="outline-info"  onClick={() => handleClick_item()}>{butDisabled}</Button></div>
        TXTprompt = <div></div>
    }else if(props.type ==="map"){
        inputOrBut = <div className='map_box'>
            <form className="audio-controls" action="#">
                <label>地图</label>
                <select className='audio-controls-speed' name="mapselection" id="mapselection" value={inputVal} onChange={(e) => switchMapFunc(e.target.value)}>
                    <option value={"place"} selected>地图</option>
                    <option value={"satellite"}>卫星地图</option>
                    <option value={"directions"}>导航</option>
                    <option value={"mark"}>显示标记</option>
                </select>
            </form>
            <form className="audio-controls" action="#">
                <label>语言</label>
                <select className='audio-controls-speed' name="languages" id="language" value={subselectoin} onChange={(e) => switchLangFunc(e.target.value)}>
                    <option value={"zh-CN"}>中文(简)</option>
                    <option value={"zh-CN"}>中文(繁)</option>
                    <option value={"en"} selected>英文</option>
                    <option value={"fr"}>法语</option>
                    <option value={"ja"}>日语</option>
                    <option value={"ko"}>韩语</option>
                </select>
            </form>
            <div className="audio-controls-but">
                 <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip" >在地图中标记位置</Tooltip>}
                    >
                    <div id='flowbox_items'><Button id='but_only2' variant="outline-info" onClick={() => handleClick_chat()} >⌖</Button> </div>
                </OverlayTrigger>
            </div>
        </div>
        let mylocation = props.setCookies_Pars(props.name_in_db,"","get")
        let googleMapsApiKey = props.setCookies_Pars("gmap_api_key","","get")
        if(mylocation){
            let center = {
                lat: Number(mylocation.split(",")[0]),
                lng: Number(mylocation.split(",")[1])
            };
            TXTprompt = <GMap googleMapsApiKey={googleMapsApiKey} isMarkerShown={true} center={center} setCookies_Pars={props.setCookies_Pars} modetype={inputVal} waitinfoFunc={props.waitinfoFunc} 
            setinfoFunc={props.setinfoFunc} language={subselectoin} changeOriginDestination={changeOriginDestination} addmark={addmark} addmarkFunc={addmarkFunc}></GMap>
        }else{
            TXTprompt = <div></div>
        }
    }else if(props.type ==="calendar"){
        const today = new Date();
        inputOrBut = <Datepicker buts = {props.name_in_db} start={new Date(today.setDate(today.getDate() - 7))} end={new Date(today.setDate(today.getDate() + 14))} setCookies_Pars={props.setCookies_Pars} cookies_name={props.parsName}></Datepicker> 
        TXTprompt = <div></div>
    }else if(props.type ==="button_voice"){
        inputOrBut = <VoiceRecorder username={props.username} setCookies_Pars={props.setCookies_Pars} setGeneratemsgBox={props.setGeneratemsgBox} trigger_micro={props.microphoneTrigger} setMicrophoneTrigger={props.setMicrophoneTrigger} waitinfoFunc={props.waitinfoFunc}></VoiceRecorder>
        TXTprompt = <div></div>
    }else if(props.type === "button_notion"){
        inputOrBut = <div id='flowbox_items'> <Button id='flowbox_items_but' variant="outline-info" onClick={() => handleClick_item()} >{inputVal===props.name_in_db[1]?props.name_in_db[1]:props.name_in_db[0]}</Button></div>
        if(multiselectioncol.length !== 0 &&  props.parsName === "dbsearch"){
            
            TXTprompt = <div>
                            {multiselection.map((element0:any,index0:number) => {
                                let elementlength = element0.length-1
                                let newarray = Array.from({length: elementlength}, (v, k) => k+1)

                                return <div>
                                    <div className='filter_outcontainner'>
                                        {index0 === 0?
                                        <div className='filter_outcontainner_form'>
                                            <Form.Select id={"Form_option0_andor"} aria-label="Default select example" size="sm" onChange={e => notion_filterFunc(e.target.value,index0,-1,"outcontainner")} >
                                                <option key={"and"} value="and">and</option>
                                                <option key={"or"} value="or">or</option>
                                            </Form.Select>
                                        </div>:<div className='filter_outcontainner_form'>{multiselection[0][0]}</div>
                                        }
                                        <div className='filter_outcontainner_del'>
                                        {index0 !== 0?<button className='filter_but_del1' type="button" onClick={(e) => notion_filterFunc("",index0,-1,"but0-del")}>-</button>:<div></div>}
                                        </div>

                                    </div>

                                    <div className='filter_innercontainner'>
                                        {newarray.map((i) => {
                                            return <div className='filter_innercontainner' id={'filter_innercontainner_'+i.toString()}>
                                                
                                                {
                                                    i !== 1?
                                                        <div className='filter_innercontainner_where'>{element0[1][0]} &nbsp; &nbsp;</div>
                                                    :
                                                        <div className='filter_innercontainner_andor'>
                                                            <Form.Select id={"Form_option1_andor"} aria-label="Default select example" size="sm" onChange={e => notion_filterFunc(e.target.value,index0,i,"innercontainner")} >
                                                                <option key={"and"} value="and">and</option>
                                                                <option key={"or"} value="or">or</option>
                                                            </Form.Select>
                                                        </div>
                                                    
                                                }
                                                
                                                
                                                
                                                <div className='filter_innercontainner_propoties'>
                                                <Form.Select id={"Form_option1_propoties"} aria-label="Default select example" size="sm" onChange={e => notion_filterFunc(e.target.value,index0,i,"propoties")} >
                                                    {multiselectioncol.map((element,index) => {
                                                        return <option key={element} value={element}>{element.split("(")[0]}</option>
                                                    })}
                                                </Form.Select>
                            
                                                </div>
                                        
                                                <div className='filter_innercontainner_conditions'>
                                                    <Form.Select id={"Form_option1_conditions"} aria-label="Default select example" size="sm" onChange={e => notion_filterFunc(e.target.value,index0,i,"conditions")} >
                                                        <option key={"Form_option0"} value="contains">包含</option>
                                                        <option key={"Form_option1"} value="does_not_contain">不包含</option>
                                                        <option key={"Form_option2"} value="equals">等于</option>
                                                        <option key={"Form_option3"} value="does_not_equal">不等于</option>
                                                        <option key={"Form_option4"} value="greater_than">大于</option>
                                                        <option key={"Form_option5"} value="greater_than_or_equal_to">大于或等于</option>
                                                        <option key={"Form_option6"} value="less_than">小于</option>
                                                        <option key={"Form_option7"} value="less_than_or_equal_to">小于或等于</option>
                                                        <option key={"Form_option8"} value="is_empty">空</option>
                                                        <option key={"Form_option9"} value="is_not_empty">不为空</option>
                                                    </Form.Select>
                                                </div>
                                                <div  className='filter_innercontainner_values'>
                                                    <input id={'filter_input_values'} className='filter_input_values' type="string" name={props.parsName} value={multiselection[index0][i][3]} onChange={(e) => notion_filterFunc(e.target.value,index0,i,"values")}></input>
                                                    
                                                </div>
                                                <div className='filter_innercontainner_del'>
                                                    {i!==0?<button className='filter_but_del' type="button" onClick={(e) => notion_filterFunc("",index0,i,"but1-del")}>-</button>: <div></div>}
                                                </div>
                                                
                                                
                                            </div>

                                        })
                                        }
                                        

                                    </div>
                                    <button className='filter_but1' type="button" onClick={(e) => notion_filterFunc(multiselectioncol[0],index0,-1,"but1")}>+添加filter</button>
                                </div>
                                
                                
                            })
                            }
                            <button className='filter_but0' type="button" onClick={(e) => notion_filterFunc(multiselectioncol[0],-1,-1,"but0")}>+添加filter</button>
                        </div>
        
        } 
        else if( multiselection.length > 0 && multiselection[0] !== "无" && props.parsName === "pageupdate"){
            TXTprompt = <div  className='filter_outercontainner'>
                {
                    multiselection.map((element,index) => {
                        return <div className='filter_innercontainner_co1'>
                                <div className='filter_innercontainner_propoties'>
                                    <Form.Select id={"Form_option1_propoties"} aria-label="Default select example" size="sm" onChange={e => notion_filterFunc(e.target.value,index,-1,"pagepropoties")} >
                                        {Object.entries(key_value_addon).map(([key,value]) => {
                                            return <option key={key} value={key}>{key.split("(")[0]}</option>
                                        })}
                                    </Form.Select>
                                </div>
                                <div className="filter_innercontainner_del">
                                    {index === 0?<div></div>:<button className='filter_but0' type="button" onClick={(e) => notion_filterFunc(element,index,-1,"but_del3")}>-删除</button>}
                                </div>
                                
                                <div className="filter_innercontainner_input">
                                    <textarea  className='item_input' style={{width:"97%",maxHeight: '10em'}} value={element[1]} onChange={e => notion_filterFunc(e.target.value,index,-1,"input")}></textarea>
                                </div>
                            </div>
                        
                    })
                }
                <div className="filter_innercontainner_but">
                    <button className='filter_but3' type="button" onClick={(e) => notion_filterFunc("",-1,-1,"but3")}>+添加</button>
                    <button className='filter_but3' type="button" onClick={(e) => notion_update(props.parsName,multiselection)}>更新</button>
                </div>
               
                
            </div>
        
        }else if(multiselection.length > 0 && multiselection[0] !== "无" && props.parsName === "newpage"){
            TXTprompt = <div  className='filter_outercontainner'>
                {
                    Object.entries(key_value_addon).map(([key,value]) => {
                        let propertiestype = key.split("(")[1].split(")")[0]
                        return <div className='filter_innercontainner_co1'>
                                {propertiestype ===  "checkbox"? 
                                <div className="filter_innercontainner_date">
                                    <div className="filter_innercontainner_title1">{key.split("(")[0]}</div>
                                    <input type="checkbox" id="filter_checkbox" name={key.split("(")[0]} value="" onChange={e => notion_filterFunc(e.target.checked,-1,-1,key)}></input>
                                </div>
                                
                                :propertiestype ===  "date"? 
                                <div className="filter_innercontainner_date"> 
                                    <div className="filter_innercontainner_title1">{key.split("(")[0]}</div>
                                    <input type="date" id="filter_date" name={key.split("(")[0]}   onChange={e => notion_filterFunc(e.target.value,-1,-1,key)}/>
                                </div>
                                
                                :propertiestype ===  "number"? 
                                <div className="filter_innercontainner_date"> 
                                    <div className="filter_innercontainner_title1">{key.split("(")[0]}</div>
                                    <input type="number" id="filter_number" name={key.split("(")[0]} placeholder={value} onChange={e => notion_filterFunc(e.target.value,-1,-1,key)}/>
                                </div>
                                :<div className="filter_innercontainner_input"> 
                                    <div className="filter_innercontainner_title">{key.split("(")[0]}</div>
                                    <textarea  className='item_input' style={{width:"97%",maxHeight: '10em'}} placeholder={value} onChange={e => notion_filterFunc(e.target.value,-1,-1,key)}></textarea>
                                </div>
                                }
                                
                                
                            </div>
                        
                    })
                }
                <div className="filter_innercontainner_but2">
                <button className='filter_but3' type="button" onClick={(e) => notionFunc(props.parsName,subselectoin)}>新建</button>
                </div>
                
                
               
                
            </div>
        }else if(multiselection.length > 0 && multiselection[0] !== "无" && ["blocklistupdate","blockupdate"].includes(props.parsName)){
            TXTprompt = <div  className='filter_outercontainner'>
                <div className='filter_innercontainner_propoties'>
                    {multiselection.length>1?
                        <Form.Select id={"Form_option1_propoties"} aria-label="Default select example" size="sm" onChange={e => notion_filterFunc(e.target.value,-1,-1,"paragraph")} >
                            {multiselection.map((item,index) => {
                                return  <option key={index} value={index}>{"block-"+index}</option>
                                
                            })}
                        </Form.Select>
                        :
                        <div></div>
                    }
                    
                </div>
                <div className="filter_innercontainner_input"><textarea  className='item_input' style={{width:"97%",maxHeight: '10em'}} value={(!inputVal || typeof inputVal[1]===undefined )?null:inputVal[1]} onChange={e => notion_filterFunc(e.target.value,-1,-1,"paragraphinput")}></textarea></div>
                
                <div className="filter_innercontainner_but">
                {multiselection.length>1?
                    <button className='filter_but4' type="button" onClick={(e) => setAddmark(true)}>+新建</button>
                    : <div className='filter_but4_emty'> &nbsp; </div>
                }
                    <button className='filter_but_del' type="button" onClick={(e) => notion_filterFunc(props.parsName,-1,-1,"delblock")}>-删除</button>
                    <button className='filter_but4' type="button" onClick={(e) => notion_blockupdate(props.parsName)}>更新</button>
                </div>
                {addmark?
                    <div className="filter_innercontainner_addblock">
                        <textarea id='addblock_input' className='item_input' style={{width:"97%",maxHeight: '10em'}} value={httpresponse} onChange={(e) => setHttpresponse(e.target.value)}></textarea>
                        <div className="filter_innercontainner_but5">
                            <div className="but5"><img src={tick_mark} onClick={()=>notion_blockupdate("addblock")} className="rounded me-2" width="15" alt="" /></div>
                            <div className="but5"><img src={close} onClick={()=>notion_blockupdate("cancle")} className="rounded me-2" width="15" alt="" />   </div>
                        </div>
                    </div>
                    :
                    <div></div>
                }

            </div>
        }
        
        else{
            TXTprompt = <div></div>
        }
        
    }
    else{    // button_prompt 
        inputOrBut = <div id='flowbox_items'> <Button id='flowbox_items_but' variant="outline-info"  onClick={() => handleClick_item()}>{butDisabled}</Button></div>
        TXTprompt = <div></div>
    }
    return(
        <Card id={'items-cart'+props.APIname.split(" ")[0]+"_"+props.setselectedindex} className='items-cart' style={{ width: '22.5rem', height:'auto', margin:'auto'}}>
            <Card.Text className='item_text_body' id={'cardtext_'+props.parsName} style={{ margin:'auto',overflow:'hidden',height:heightcotrol}}> 
                {!["but_only", "but_multiple","button_txt","button_mp3","check","txt_default","calendar","button_voice"].includes(props.type)?
                    <div>
                        <div id='item_left'>{props.parName_ch === ""? props.parsName:props.parName_ch} </div>
                        <div id='item_right'>
                            {buttonIMG}
                        </div>
                    </div>
                    : <div></div>
                }
                {inputOrBut}
                {TXTprompt}
                {fileprompt}
                {imgmask}
            </Card.Text>
            <PromptSpliter article = {subselectoin?subselectoin:""} PromptSplitertrigger={addmark} setPromptSplitertrigger={setAddmark} setsplitorInput={setinputVal} setinfoFunc={props.setinfoFunc}/>
        </Card>
    )
    
}



Items.defaultProps={
    parsName: "API_KEY",
    pars: "参数值1",
    name_in_db:"QQ_API_KEY",
    required:"false"
}

export default Items