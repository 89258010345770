import axios from "axios";
import React from "react";
import { PagePrompt } from "../configs/PagePrompt";
import './fileUpload.css'
import SetupCookies from '../tools/setupCookies';
export interface FileUploadProps {
    filepromptFunc:Function
	username:string
	name_in_db:string
	setchecked_default:Function
	setCookies_Pars:Function
	refresh_oneselection:Function
	setinfoFunc:Function
  }
const headers = {
	'Content-Type': 'text/html;charset=utf-8',
  }
class FileUpload extends React.Component <FileUploadProps>  {
    constructor(props:any) {
        super(props);
        this.state = {
            // Initially, no file is selected
            selectedFile: null,
        };
    }
	
    // On file select (from the pop up)
	onFileChange = (event:any) => {
		// Update the state
		this.setState({
			selectedFile: event.target.files[0],
		});
		this.props.setchecked_default(false)
	};
	
	// On file upload (click the upload button)
	onFileUpload = () => {
		
		try{
			// Create an object of formData
			const formData = new FormData();
			// Update the formData object
			formData.append(
				"avatar",
				(this.state as any).selectedFile,
				encodeURIComponent((this.state as any).selectedFile.name),
			);

			// Details of the uploaded file
			// console.log((this.state as any).selectedFile);

			// Request made to the backend api
			// Send formData object
		
			if ((this.state as any).selectedFile.size <= 80000000 ){
				axios.post(`${PagePrompt.url}/llm/upload?llm=${this.props.name_in_db}&username=${this.props.username}&id=${SetupCookies("uuid","","get") || "none"}`,formData).then(
					response => {
						if(response.data.errno === 0){
							if(this.props.name_in_db !== "wf_icon"){this.props.filepromptFunc(response.data.data.message)}
							else{ SetupCookies(this.props.name_in_db,response.data.data.message,"new")}
							
							this.props.refresh_oneselection()
							this.props.setinfoFunc("文件上传成功，请点击刷新/获取。","2000")
							// this.props.setchecked_default(true)
							// this.props.setCookies_Pars("prompt_doc",response.data.data.data)
							// refresh saved file
						}
						else{this.props.filepromptFunc("")}
					},
					async error => {
						console.log(error.message.errono)
					}
				).catch(function (error){console.log(error)})
			}else{
				alert("请确认上传文件不大于10M")
			}
		}catch(error){
			document.getElementById("file_upload_but")?.setAttribute("disabled","disabled")
		}
        

	};

	// File content to be displayed after
	// file upload is complete
	fileData = () => {
		if ((this.state as any).selectedFile) {
			if ( ["text/plain","application/pdf","text/csv","application/json"].includes((this.state as any).selectedFile.type)
			|| (this.props.name_in_db === "wf_icon" && ["image/png","image/jpeg"].includes((this.state as any).selectedFile.type) )) {
				document.getElementById("file_upload_but")?.removeAttribute("disabled")
				return (
					<div>
						<h6>文件信息:</h6>
						<p className="uploadfile_info">
							文件名称:{" "}
							{(this.state as any).selectedFile.name}
						</p>

						<p className="uploadfile_info">
							文件类型:{" "}
							{(this.state as any).selectedFile.type}
						</p>
						<p className="uploadfile_info">
							文件大小:{" "}
							{(((this.state as any).selectedFile.size)/1000).toFixed(2).toString()+'KB'}
						</p>
					</div>
				);
			}
			else{document.getElementById("file_upload_but")?.setAttribute("disabled","disabled")}
			
		} else {
			document.getElementById("file_upload_but")?.setAttribute("disabled","disabled")
			return (
				<div>
				</div>
			);
		}
	};

	render() {
		return (
			<div>
				<div>
					<input
						type="file"
                        name="avatar"
						onChange={this.onFileChange}
						id="upload_imput"
					/>
					<button onClick={this.onFileUpload} id="file_upload_but" >
						开始上传
					</button>
				</div>
				{this.fileData()}
			</div>
		);
	}
}
// disabled={(this.state as any).butdisable || true}
export default FileUpload;