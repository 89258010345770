import React, { useState,useRef , useEffect} from "react";
import './Popup.css'
import { IconPrompt } from "../configs/iconPrompyt";
import { fromEvent } from "rxjs";
import { useObservable, useSubscription } from "observable-hooks";
import { filter, map, scan, switchMap, takeUntil } from "rxjs/operators";
import "./imgMask.css";
import axios from "axios";
import { PagePrompt } from "../configs/PagePrompt";
import SetupCookies from '../tools/setupCookies';
interface ImgMaskProps{
    dialogWidth?:string
    trigger_mask?:boolean
    setTrigger_mask:Function
    height_l:string
    username:string
    selectpic:string
    
}
let prevx:number, prevy:number

var image = new Image();

let px ;
var mouseup = false;
// let count = true
const ImgMask: React.FC<ImgMaskProps> = (props) => {

    const [activetool,setActivetool] = useState(-1) // 0 eraser, 1 pen
    const [radius,setradius] = useState("10")
    const [rangevisibility,setRangevisibility] = useState(false)
    // const [canvasUrl,setCanvasUrl] = useState<any>([])
    // const [mouseup,setMouseup] = useState(false)
    let requestimg = `${PagePrompt.url}/${props.username}/${props.selectpic}`;
    image.src = requestimg;
    image.crossOrigin="anonymous";
    // px = image.width/600
    // px = image.height/500 > px?  image.height/500: px
    px = 1;

    function mouseupFunc(){
        if(mouseup === false){
            const canvas = canvasRef.current;
            mouseup=true;
            // setCanvasUrl((url:any) => {
            //     url.push(canvas.toDataURL())
            //     return url
            // })
        }
        // setMouseup(true)
        
    }
    // function mousedownFunc(){
        // if(mouseup === true){mouseup=false}
        // setMouseup(false)

    // }

    function add_mask(){
        // console.log(activetool)
        if(activetool !== 0){setActivetool(0);setRangevisibility(true)}else{setActivetool(-1);setRangevisibility(false)}

    }
    function add_pen(){
        // console.log(activetool)
        setRangevisibility(false)
        if(activetool !== 1){setActivetool(1)}else{setActivetool(-1)}
    }
    function saveIMG(){
        const canvas = canvasRef.current;
        
        // canvas.toBlob((blob:any)=>{
        //     const a =document.createElement('a');
        //     a.setAttribute('download', 'CanvasAsImage.png');
        //     a.href = URL.createObjectURL(blob)
        //     a.click()
        //     a.remove()
        // })

        // let dataURL = canvas.toDataURL('image/png');
        // console.log(dataURL)
        // http upload
        canvas.toBlob((blob:any)=>{
            const formData = new FormData();
            // Update the formData object
            let filename = Math.floor(Math.random() * 5).toString()+ "add_mask.png"
            formData.append(
                'avatar',
                blob,
                filename,
            );
            axios.post(`${PagePrompt.url}/llm/upload?llm=mask&username=${props.username}&id=${SetupCookies("uuid","","get") || "none"}`,formData).then(
                response => {
                    if(response.data.errno === 0){
                        SetupCookies("mask",filename,"new")
                        props.setTrigger_mask(false)
                        
                    }
                    
                },
                async error => {
                    console.log(error.message.errono)
                }
            ).catch(function (error){console.log(error)})
        }, "image/png")
    }

    function resetIMG(){
        // console.log("reset")
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.reset()
        
        image.onload = () => {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        };

        // setCanvasUrl((canvasUrl:any) => {
        //     canvasUrl.pop()
        //     return canvasUrl
        // })
    }
    

    const canvasRef = useRef<any>(null);
    
    const o$ = useObservable(
        input$ =>
          input$.pipe(
            filter(([canvasRef]) => !!canvasRef),
            map(([canvasRef]) => canvasRef.current),
            switchMap(canvas =>
              fromEvent(canvas, "mousedown").pipe(
                switchMap(() =>
                  fromEvent(canvas, "mousemove").pipe(
                    map((event:any) => ({ x: event.offsetX, y: event.offsetY })),
                    scan((acc, { x, y }) => {
                      if (acc) {
                        const { x: prevX, y: prevY } = acc;
                        prevx=prevX; prevy=prevY;
                        return { prevX, prevY, x, y };
                      }
    
                      return { x, y };
                    }),
                    takeUntil(fromEvent(canvas, "mouseup")),
                  )
                )
              )
            )
          ),
        [canvasRef]
    );
    
    useSubscription(o$, ({ x, y }) => { //prevX, prevY,
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        // console.log("abc123")
        if(activetool === 0){ //eraser
            ctx.save()
            ctx.beginPath()
            ctx.arc(x,y,radius,0,2*Math.PI);
            ctx.clip()
            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.restore();
            // console.log(count)
            return
        }else if(activetool === 1){ //pen
            ctx.beginPath();
            if (!mouseup && prevx && prevy) {ctx.moveTo(prevx, prevy);};
            if(mouseup === true){mouseup=false}
            ctx.lineTo(x, y);
            ctx.stroke();
            // console.log(count)
            return
        }
        
    });

    useEffect(() =>
    {   
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        image.onload = () => {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
        
    }, [canvasRef])

    function close_imgMask(obj:any){
        if( obj.className === "popup-img2"){props.setTrigger_mask(false)}
    }

    return (props.trigger_mask)?(
        <div className='popup-img2' onClick={e => close_imgMask(e.target)}>
            <div className='popup-inner-img2'>
                {/* <div className='popup-close'><img src={IconPrompt.IMG_Libs_menu.close} onClick={()=>props.setTrigger_mask(false)} className="rounded me-2" width="20" alt="" /></div> */}
                <div className='popup-eraser'><img id="popup-eraser" src={IconPrompt.IMG_Libs_menu.eraser} onClick={add_mask} className="rounded me-2" width="20" alt="" style={{backgroundColor:activetool===0?'rgb(226, 224, 181)':'rgba(220, 245, 242, 0.31)'}}/></div>
                <div className='popup-pen'><img id="popup-pen" src={IconPrompt.IMG_Libs_menu.pen} onClick={add_pen} className="rounded me-2" width="20" alt="" style={{backgroundColor:activetool===1?'rgb(226, 224, 181)':'rgba(220, 245, 242, 0.31)'}} /></div>
                <div className='popup-save'><img  src={IconPrompt.IMG_Libs_menu.tick_mark} onClick={saveIMG} className="rounded me-2" width="20" alt="" /></div>
                <div className='popup-reset'><img  src={IconPrompt.IMG_Libs_menu.reset} onClick={resetIMG} className="rounded me-2" width="20" alt="" /></div>
                <div className='popup-range' style={{visibility:!rangevisibility?"hidden":"visible"}}><input id="popup-range-input" type="range" min="0" max="80" value={radius} step="1" onChange={(e) => setradius(e.target.value)}/></div> 
                {/* <img className='popup-body-img' src={requestimg} style={{width:props.height_l}}  alt="" />  onChange={setradius()} */}
                <canvas ref={canvasRef} width={image.width/px} height={image.height/px} 
                onMouseUp={e => mouseupFunc()} />
            </div>
        </div>
    ): <div></div>;
}
// onMouseDown={e => mousedownFunc()}
    
export default ImgMask;