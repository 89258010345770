import {Cookies } from 'react-cookie';

const cookies = new Cookies()

const SetupCookies = (cookiesname:string,cookiesvalue:any,option:string) => {
    if(option === 'new'){
        cookies.set(cookiesname, cookiesvalue)
        return option
    }else if(option === 'get'){
        return cookies.get(cookiesname)
    }else if(option==='delete'){
        cookies.remove(cookiesname)
        return option
    }
   
}

  
export default SetupCookies;