import axios from "axios";
import { PagePrompt } from "../configs/PagePrompt";

export const UseURLLoader = async (props:any) =>{
    return await axios.post(`${PagePrompt.url}/${props.url}`,props.HttpBody).then(
        async response => {
            // console.log(response.data);
            if(response.data.message === '未登录'){
                props.setinfoFunc("为了您账户的安全，请尝试重新登陆后操作。","2000")
                return false
            }
            else{

                if(props.url === "llm/getsavedfile" || props.url === "llm/getsavedfile?path=1" || props.url === "llm/getsavedimg"){
                    
                    if(response.data.data.fileList.length>0){
                        return response.data.data.fileList
                        // props.urlsetMultiselection(response.data.data.fileList)
                        // props.urlsetMultiselectioncol(response.data.data.fileList)
                        // props.setMultiselection(response.data.data.fileList)
                        // props.setMultiselectioncol(response.data.data.fileList)
                    }else{
                        // props.setMultiselection(["无"])
                        // console.log(response.data)
                        props.setinfoFunc("Opps! 您尚未上传任何文件。","2000")
                        return ["无"]
                    }
                }
                else if(props.url === "llm/deleteprompt" || props.url === "llm/deletedoc"){
                    if(response.data.message === "ok"){
                        // props.refresh_multiselection()
                        props.setinfoFunc("已成功删除","2000")
                        return true
                    }
                }
                else if(["llm/txtprompt","llm/docprompt","llm/writingprompt"].includes(props.url)){
                    if(response.data.errno === 0){
                        props.setchecked_default(true)
                        props.setinfoFunc("Prompt已生成，请刷新 [选择Prompt]","2000")
                        return true
                    }else{
                        // props.setMultiselection(["无"])
                        props.setinfoFunc(response.data.message,"2000")
                        return false
                    }
                }
                else if(props.url === "llm/getsavedprompts"){
                    if(response.data.data.promptList.length>0){
                        if(props.parsName === "vectordb"){
                            //keep file only  cause download
                            let newpromptList :string[]= []
                            let newcolumnList :string[]= []
                            response.data.data.promptList.forEach((element:string,index:number) => {
                                if(element.indexOf(".txt") !== -1){
                                    newpromptList.push(element)
                                    newcolumnList.push(response.data.data.columnList[index])
                                }
                            });
                            if(newpromptList.length>0){
                                return [newpromptList,newcolumnList]
                                // props.setMultiselection(newpromptList)
                                // props.setMultiselectioncol(newcolumnList)
                            }
                        }else if(props.parsName === "vdbmanager"){
                            let newpromptList :string[]= []
                            let newcolumnList :string[]= []
                            response.data.data.promptList.forEach((element:string,index:number) => {
                                if(element.indexOf(".txt") === -1){
                                    newpromptList.push(element)
                                    newcolumnList.push(response.data.data.columnList[index])
                                }
                            });
                            if(newpromptList.length>0){
                                return [newpromptList,newcolumnList]
                                // props.setMultiselection(newpromptList)
                                // props.setMultiselectioncol(newcolumnList)
                            }
                        }
                        else{
                            return [response.data.data.promptList,response.data.data.columnList]
                            // props.setMultiselection(response.data.data.promptList)
                            // props.setMultiselectioncol(response.data.data.columnList)
                        }

                    }else{
                        // props.setMultiselection(["无"])
                        props.setinfoFunc("您尚未添加prompt","2000")
                        return [["无"],[]]
                    }
                }
                else if(props.url === "zap/renewtoken" || props.url === "api/renewserialnumber")
                {
                    if(response.data.errno === 0){
                        props.setinfoFunc(response.data.message,"2000")
                        if(props.url === "zap/renewtoken"){return document.getElementById('hold_space_zapier_emailservice')?.innerHTML}
                        else{return document.getElementById('hold_space_serial_number')?.innerHTML}
                        // props.setCookies_ParsFunc(document.getElementById('hold_space_zapier_emailservice')?.innerHTML)
                        // props.setCookies_ParsFunc(document.getElementById('hold_space_serial_number')?.innerHTML)
                    }else{
                        props.setinfoFunc("请重新尝试","2000")
                        return
                    }
                }
                else if(props.url === "api/checkname"){
                    if(response.data.errno === 0){
                        // props.setCookies_ParsFunc(inputVal)
                        
                        props.setinfoFunc("设置成功","2000")
                        return true
                    }else{props.setinfoFunc(response.data.message,"2000")}
                    
                }
                else if(props.url === "api/googlenews" || props.url === "api/topnews"  || props.url === "api/juhenews"){
                    if(response.data.errno === 0){
                        if(response.data.data.length > 0 ){
                            let newlist = response.data.data
                            let Multiselectionvalue:string[] =[]
                            if(props.url === "api/googlenews"){
                                newlist.forEach((element:any) => {
                                    if(PagePrompt.newswebsupportList.includes(element[2])){
                                        Multiselectionvalue.push(element[0]+"-"+element[1]+"("+element[2]+")")
                                    }
                                });
                            }else{
                                newlist.forEach((element:any) => {
                                    Multiselectionvalue.push(element[0]+"-"+element[1]+"("+element[2]+")")
                                });
                            }
                            
                            // props.setGeneratemsgBox(response.data.data)
                            if(Multiselectionvalue.length > 0){
                                return [Multiselectionvalue,response.data.data]
                                // props.setMultiselection(Multiselectionvalue)
                            }else{

                                // props.setMultiselection(["无"])
                                props.setinfoFunc("尚未找到LLM支持的新闻源，请期待网站的持续更新。","2000")
                                return [["无"],response.data.data]
                            }
                        }else{
                            // props.setMultiselection(["无"])
                            props.setinfoFunc("未搜到相关新闻，请尝试其他搜索关键词","2000")
                            return [["无"],[]]
                        }
                    }else{
                        // props.setMultiselection(["无"])
                        props.setinfoFunc(response.data.message,"2000")
                        return [["无"],[]]
                    }
                }
                else if(["msg/leavemsg","msg/getmsglist","msg/getmsg","msg/renewmsg","msg/getmyvote","msg/admin",
                        "workflow/delete","workflow/get","workflow/getworkflow",
                        `llm/sendmsg`,"llm/checkvdbname","llm/getvdbmetadata","llm/getvdbcontent","llm/modifyvdbcontent","llm/modifyvdbmetadata",
                        "llm/delvdbcontent",
                        "zap/getnewtoken","zap/emailservice","zap/customtrigger",
                        "api/getserialnumber","api/newscontent","api/googlemap","api/geopy","api/meteomatics","api/notion"].includes(props.url)){
                    if(response.data.errno === 0){
                        // console.log(response.data)
                        return response.data.data
                    }else{
                        props.setinfoFunc("请重新尝试","2000")
                    }
                }
                else if( ["msg/vote","user/emailtome","llm/gensummary","llm/newsprompt",`llm/getcontent`,`llm/article_download`,`llm/article_summary`,`llm/article_translation`,
                    "workflow/update?pars=description","workflow/save","workflow/get" ,
                    "user/verifycode","user/emailservice","user/changepwd",
                    "api/translation","api/filegenerate","api/gmailservice" ,"api/lang","api/reader","api/wikipedia"].includes(props.url)){
                    if(response.data.errno === 0){
                        return response.data.message
                    }else{
                        props.setinfoFunc("请重新尝试","2000")
                    }
                }
            }
            
        },
        async error => {
            console.log(error.message.errono)
            return false
        }
    ).catch(function (error){console.log(error);return false})

    
}


export const DownloadIMG = (filename:string,username:string) =>{
    axios.get(`${PagePrompt.url}/${username}/${filename}`, { responseType: 'blob' }).then(response => {
        var file = new Blob([response.data],
            { type: "image/*" }  //image/*
          );
        var element = document.createElement("a");
        element.href = (window.URL ? URL : webkitURL).createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element)
        element.click();
        document.body.removeChild(element)
    });
    return []
}

export const UseURL = async (url:any,setinfoFunc:any) =>{
    return await axios.get(url).then(
        async response => {
            // console.log(response.data)
            if(response.data.status === "OK"){
                if(url.includes("directions")){
                    return response.data.routes[0].legs[0]
                }else{
                    return response.data.results[0]
                }
                
            }
            else{
                setinfoFunc(response.data.error_message,"2000")
                return false
            }
            },
            async error => {
                console.log(error.message.errono)
                return false
            }
        ).catch(function (error){console.log(error);return false})
    
}

// export default UseURLLoader