import React  from "react";
import  './footer.css'
interface FooterProps{
    message?:string
}
const Footer: React.FC<FooterProps> = (props) => {
    return <h6 style={{color:"grey"}}>{props.message}</h6>
}
Footer.defaultProps={
    message:"© 2023-2024 粒子智算 · 工场(Agentlet) & F.I.N.D Lab. All Rights Reserved"
}
export default Footer