import React from 'react';
import './MeteoBox.css'
import { IconPrompt } from "../configs/iconPrompyt";
import { PagePrompt } from "../configs/PagePrompt";
interface MeteoBoxProps{
    parsvalue:object,
    // location:string,
    date:string
 
}
const IMG_Libs_meteo = IconPrompt.IMG_Libs_meteo
const weather_symbol = IconPrompt.weather_symbol
const meteo_txt = PagePrompt.meteo_txt
const MeteoBox: React.FC<MeteoBoxProps> = (props) => {
    let dateT = props.date.split(":")[0];
    dateT = dateT.replace("T"," ")
    
    return (
        
        <div className='meteobox dateT'>
            <div>{`日期: ${dateT}时`}</div>
           {Object.entries(props.parsvalue).map(([key, value]) => {
                let unit = key.split(":")[1];
                if(["sql","idx"].includes(unit)){unit = ""};
                if(["sunrise:sql","sunset:sql"].includes(key)){value=value.split("T")[1];value=value.replace("Z","")}
                let valueO = value
                if(["weather_symbol_24h:idx"].includes(key)){
                    value=meteo_txt[`w${value}`]}
                return  (
                            <div className='meteo_content'>
                                <img src={["weather_symbol_24h:idx"].includes(key)?weather_symbol[`w${valueO}`]:IMG_Libs_meteo[key.split(":")[0]]} className="me-2 meteo_img" width={["weather_symbol_24h:idx"].includes(key)?"60":"18"} alt="" />
                                <div  className='meteo_txt'>{`${value} ${unit}`}</div>
                            </div>
                        )
            })}
        </div>
    )
}
export default MeteoBox