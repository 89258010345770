export const LoginPagePrompt: ILoginPagePrompt = {
  confirmPasswordEmpty: "请再次输入您的密码",
  confirmPasswordWrong: "密码错误，请再次输入",
  lengthOver6: "密码长度必须大于 6",
  messageLoginSuccessful: "登陆成功",
  messageRegisterSuccessful: "注册成功",
  messageUsernameExist: "用户名,电话号码或者邮箱已经被注册",
  messageUsernameNotExist: "用户名或密码错误",
  messageWrongPassword: "密码错误",
  promptCancel: "取消",
  promptKey: "请输入您的邮箱:",
  promptKey2: "请输入您的6位验证码:",
  promptTips1: "请输入有效的邮箱地址",
  promptTips2: "请输入有效的6位验证码",
  promptTips3: "您的邮箱验证成功",
  promptTips4: "您的邮箱验证失败，请重新尝试",
  promptSuccess: "您将收到一封确认邮件，请登陆邮箱验证",
  promptValue: "your.email@xxx.xxx",
  promptValue2: "123456",
  tips: "密码长度需最大于6位数",
  emailTips:"请输入您的邮箱",
  phoneTips:"请输入有效的电话号码",
  verifyTips:"请在10分钟内查看您的邮箱，输入正确的验证码",
  noneTips:"",
  errorTips:"您的操作无效，请重新尝试"
};
interface ILoginPagePrompt {
  confirmPasswordEmpty: string;
  confirmPasswordWrong: string;
  lengthOver6: string;
  messageLoginSuccessful: string;
  messageRegisterSuccessful: string;
  messageUsernameExist: string;
  messageUsernameNotExist: string;
  messageWrongPassword: string;
  promptCancel: string;
  promptKey: string;
  promptKey2: string;
  promptTips1: string;
  promptTips2: string;
  promptSuccess: string;
  promptValue: string;
  promptValue2: string;
  tips: string;
  emailTips:string;
  phoneTips:string;
  verifyTips:string;
  noneTips:string;
  promptTips3:string;
  promptTips4:string;
  errorTips:string;
}
