import React, {  useState,useEffect,useRef } from 'react';
import Toast from 'react-bootstrap/Toast';
import './msgBox.css'
import Button from 'react-bootstrap/Button';
import DownLImgBox from './imgBox';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { PagePrompt } from "../configs/PagePrompt";
import { IconPrompt } from "../configs/iconPrompyt";
import  NewsBox  from './newsBox';
import MeteoBox from './MeteoBox';
import { error } from 'console';
import SetupCookies from '../tools/setupCookies';

interface MsgBoxProps{
    sender?:string
    message?:any
    date?:Date
    button:[],
    setparsconfirm:Function
    boxwidth:number
    fillin_txt:any,
    fillin_file:any,
    workFlowAdd_on:Function,
    loginCheckapp:boolean,
    setinfoFunc:Function,
    username:any,
    workFlowAddList:[]
}
const IMG_Libs = IconPrompt.IMG_Libs
const MsgBox: React.FC<MsgBoxProps> = (props:any) => {
    const currentDate = new Date();
    let times = Math.floor((currentDate.getTime() - (props.date).getTime())/1000)
    const time = caltime(times)
    
    const [showA, setShowA] = useState(true);
    const toggleShowA = () => setShowA(!showA);
    const [showB, setShowB] = useState(true);
    const toggleShowB = () => setShowB(!showB);
    const [showoption, setShowoption] = useState( <div></div> );
    const [textselected, setTextselected] = useState();
    const [msgdynamic, setMsgdynamic] = useState(props.message);
    useEffect(() => { 
        setMsgdynamic(props.message)
    }, [props.message]);
    
    let toollist= <div className='toolist_box'>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">翻译工具</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("translation")}>译</button></OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">发送邮件</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("senttomyemail")}>＠</button></OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">文本阅读</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("txtreader")}>♪</button></OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">地理位置</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("googlemap")}>⌖</button></OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">新闻搜索</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("newsgoogle")}>𝓝</button></OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">撰写工具</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("writingtools")}>✑</button></OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">文件生成</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("filegenerator")}>ᴗ̈</button></OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">连接本地数据库</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("dbconnection")}>⛁</button></OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">文件夹管理</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("foldermanager")}>⊞</button></OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">维基百科搜索</Tooltip>}
        ><button className='toolist_but' onClick={() => addonAPI("wikipedia")}>𝓦</button></OverlayTrigger>
    </div>
    let message_add =<div></div>
    // <hr className="hr hr-blurry" style={{textAlign:"center"}}/>
    
    function  FuncCluster(elm:string) {
        if(elm === 'confirm'){
            var el = document.getElementsByClassName(elm+'_Func_But')
            if(el){
                Array.from(el).forEach(element => {
                    element.setAttribute("hidden","true")
                });
            }
            var el = document.getElementsByClassName('help_Func_But')
            if(el){
                Array.from(el).forEach(element => {
                    element.setAttribute("hidden","true")
                });
            }

            props.setparsconfirm(true)
            

            if(msgdynamic.indexOf("请确认您勾选的参数设置信息") !== -1){
                let list = msgdynamic.split("\n")
                let APIname = list[0].split("(")[1].split(")")[0]
                APIname = APIname.split(" ")[0]
                
                let APIpars = PagePrompt.APILibs[APIname.toLowerCase()+"API"].APIpars
                APIpars.forEach((element:string,index:number) => {
                    if(document.getElementById('items-cart'+APIname+"_"+index)?.className.indexOf(" notice") !== -1)
                    {
                        let classname = document.getElementById('items-cart'+APIname+"_"+index)?.className
                        if(classname){ 
                            document.getElementById('items-cart'+APIname+"_"+index)?.setAttribute("class", classname?.slice(0,classname.length-6));
                            document.getElementById('img_'+APIpars[index].parsName)?.setAttribute("src", IMG_Libs.check2);
                        }
                       
                    }
                })
            }
            


        }else{//help
            if(msgdynamic.indexOf("请确认您勾选的参数设置信息") !== -1 || msgdynamic.indexOf("请输入") !== -1){ //|| msgdynamic.indexOf("请输入:") !== -1
                let list = msgdynamic.split("\n")
                let APIname: string
                APIname = list[0].split("(")[1].split(")")[0]
                APIname = APIname.split(" ")[0]
                
                let APIpars = PagePrompt.APILibs[APIname.toLowerCase()+"API"].APIpars
                list.shift()
                list.forEach((element:string) => {
                    if(element !== ""){
                        let index = element.split("[")[1].split("]")[0]
                        let classname = document.getElementById('items-cart'+APIname+"_"+index)?.className
                        if(classname && classname.indexOf("notice") === -1){
                            document.getElementById('items-cart'+APIname+"_"+index)?.setAttribute("class", classname+" notice");
                            document.getElementById('img_'+APIpars[Number(index)].parsName)?.setAttribute("src", IMG_Libs.click2);
                        }
                    
                    }
                });
            }
            
        }
        
        
    }
    function addonAPI(apiname:string){
        if(props.loginCheckapp &&  ["senttomyemail","filegenerator","googlemap","translation","txtreader","foldermanager","newsgoogle","writingtools","wikipedia","dbconnection"].includes(apiname)){
            props.workFlowAdd_on(apiname)
        }
       
        else if(props.loginCheckapp && apiname ==="meemail"){
            props.setinfoFunc("正在开发中，请期待网站更新","2000")
        }
        else{props.setinfoFunc("您还没有登陆","2000")}
    }
    if((props.button || []).length > 0 ){
        message_add = 
            <div style={{textAlign:"center"}}>
                <hr className="hr hr-blurry" style={{textAlign:"center"}}/>
                {(props.button).map((element:string) => (
                    <Button className={element+'_Func_But'} variant="outline-info btn-sm" style={{marginLeft:"20px",marginRight:"20px"}} onClick={(e) => FuncCluster(element)} >{element === "help"? "帮 助" : '确 认'}</Button>
                ))}
            </div>
    }
    if(props.sender === 'LLM_Agent' && typeof(props.message) === "string"){

    }
    const [trigger_img, setTrigger_img] = useState(new Array(typeof(props.message) === "string" ? 0 :props.message.length).fill(false));

    function setTrigger_imgFun(showup:boolean,index_find:number){
        if(trigger_img[index_find] !== showup){
            setTrigger_img(trigger_img.map((item,index) => {
                if (index === index_find) {
                  // 创建包含变更的*新*对象
                  return showup;
                } else {
                  // 没有变更
                  return item;
                }
              }));
        }
    }
    //强制更新
    const inittextselected = useRef();
    useEffect(()=>{
        inittextselected.current = textselected;
    },[textselected])

    function onMouseUpFunc(){
        
        let selObj = window.getSelection();
        setTextselected(props.message.substring(selObj?.anchorOffset, selObj?.focusOffset))
        navigator.clipboard.writeText(props.message.substring(selObj?.anchorOffset, selObj?.focusOffset));
        let anchorS = Math.min(selObj?.anchorOffset as any, selObj?.focusOffset  as any)
        let anchorE =  Math.max(selObj?.anchorOffset  as any, selObj?.focusOffset  as any)
        let s 
        if(anchorE === props.message.length){
            s = <p>{props.message.substring(0, anchorS)} <span style={{background:"#faf4b7"}}> {props.message.substring(anchorS, anchorE)} </span></p>
        }else if(anchorS === 0){
            s = <p> <span style={{background:"#faf4b7"}}> {props.message.substring(anchorS, anchorE)} </span> {props.message.substring(anchorE,props.message.length)}</p>
        }else{
            s = <p>{props.message.substring(0, anchorS)} <span style={{background:"#faf4b7"}}> {props.message.substring(anchorS, anchorE)} </span> {props.message.substring(anchorE,props.message.length)}</p>
        }
        setMsgdynamic(s)
    }
    function setDoubleClickFunc(){
        if (window.getSelection()?.empty) {  // Chrome
            window.getSelection()?.empty();
        } else if (window.getSelection()?.removeAllRanges) {  // Firefox
            window.getSelection()?.removeAllRanges();
        }
        setMsgdynamic(<div>{props.message}</div> )
    }

    function fillinFunc(items:any){
        if(inittextselected.current !== "" && inittextselected.current !==  undefined){
            
            if(items ==="content"){ //textarea
                let txyOld2 = document.getElementById('item_input'+items)?.innerHTML
                document.getElementById('item_input'+items)?.setAttribute("placeholder",txyOld2+inittextselected.current)
            }
            else{
                let txyOld = document.getElementById('item_input'+items)?.getAttribute("value")
                document.getElementById('item_input'+items)?.setAttribute("value",txyOld+inittextselected.current)
            }
            
        }else{
            if(items ==="content"){ //textarea
                let txyOld2 = document.getElementById('item_input'+items)?.innerHTML
                document.getElementById('item_input'+items)?.setAttribute("placeholder",txyOld2+props.message)
            }else{
                let txyOld = document.getElementById('item_input'+items)?.getAttribute("value")
                document.getElementById('item_input'+items)?.setAttribute("value",txyOld+props.message)
            }
        }
        
        
    }

    function setIsHoveredFunc_msg(val:boolean){
        if(props.loginCheckapp && val){
            if(typeof(props.message) === "string" && Object.keys(props.fillin_txt).length > 0){
                setShowoption(
                <div className='msg_insert'>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">点击鼠标右键选择文本<br/>(自动复制)</Tooltip>}
                ><p className='msg_insert' >将文本插入：</p></OverlayTrigger>
                    {Object.entries(props.fillin_txt).map(([key, value]) => {
                        return (<button className='msg_insert_but' onClick={() => fillinFunc(value)}>{key}</button>)
                    })
                    }
                </div>)
            }
            
            
        }else{setShowoption(<div></div> )}
        
    }


    if (props.sender !== props.username && typeof(props.message) === "string"){ //msg
        let sender_name = props.sender
        let new_name = SetupCookies("agent_name","","get") 
        if(props.workFlowAddList.includes("getworkflow") && new_name && new_name.trim().length !== 0 ) {sender_name = new_name}
        if(props.message.slice(0,8) === "https://" || props.message.slice(0,7) === "http://"){
            return (
                <Toast id="msgBox-left" show={showA} onClose={toggleShowA} style={{width: (props.boxwidth-15).toString()+'rem'}}>
                    <Toast.Body onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}>
                        <div>
                            <img style={{width:"324px",marginBottom:"5px"}} alt='图像生成失败' src={props.message} onClick={() => setTrigger_imgFun(true,0)}/>
                            <DownLImgBox dialogWidth="220px" index={0} trigger_img={trigger_img[0]} setTrigger_img={setTrigger_imgFun} src_img={props.message} height_l="600px"></DownLImgBox> 
                        </div>
                       {showoption}
                    </Toast.Body>
                </Toast>
            )
        }else{
            return (
                <Toast id="msgBox-left" show={showA} onClose={toggleShowA} style={{width: (props.boxwidth-15).toString()+'rem'}}>
                    <Toast.Header className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start">
                            <img src={IMG_Libs["BotIMG"]} className="rounded me-2" width="50" alt="" />
                            <strong style={{marginTop:"15px",marginLeft :"5px",minWidth:"100px"}}>{sender_name}</strong>
                        </div>
                        {toollist}
                        <small className="msgBox-left-time">{time}</small>
                    </Toast.Header>
                    <Toast.Body onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}>
                        <div id='msgBox-txt' onMouseUp={(e) => onMouseUpFunc()} onDoubleClick={()=>setDoubleClickFunc()}>{msgdynamic}</div>
                        {message_add}
                        {showoption}
                    </Toast.Body>
                </Toast>
            )
        }
        
    }
    else if(props.sender !== props.username && props.message[0][3]?.indexOf("https://") !== -1){ //新闻

        return(
            <Toast id="msgBox-left" show={showA} onClose={toggleShowA} style={{width: (props.boxwidth-15).toString()+'rem'}}>
                <Toast.Body style={{display:"inline-block"}} onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}>
                    {(props.message).map((item:any,index:number) => {
                        return (
                            <NewsBox indexnum={parseInt(item[0])} include={PagePrompt.newswebsupportList.includes(item[2])?0:1} title={item[1]} source = {item[2]} url = {item[3]}></NewsBox>
                        )
                    })}
                {showoption}
                </Toast.Body>
            </Toast>
        )
    }
    else if( props.sender !== props.username && props.message instanceof Object && !Array.isArray(props.message)){  //dict meteo props.message instanceof Object

        let timeD = ""
        let spaceindex:any[]=[]
        let targetaddress = props.message.address
        let meteoresult:{[key: string]: any} ={...(props.message as object)} 
        delete meteoresult["address"];
        Object.entries(meteoresult).forEach(([key,value],index) => {
            if(key.split("T")[0] !== timeD){
                if(index !== 0){spaceindex.push(index-1)}          
                timeD = key.split("T")[0]
                
            }
        })
        timeD = ""
        return (
            <Toast id="msgBox-right" onClose={toggleShowB} show={showA} style={{width: (props.boxwidth-15).toString()+'rem'}}>
                <Toast.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <img src={IMG_Libs["BotIMG"]} className="rounded me-2" width="50" alt="" />
                        <strong style={{marginTop:"15px",marginLeft :"5px"}}>{props.sender}</strong>
                    </div>
                    {toollist}
                    <small className="msgBox-left-time">{time}</small>
                </Toast.Header>
                <Toast.Body onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}>
                    <div className='meteocontener'>
                        <div>{`地区: ${targetaddress}`}</div>
                        {Object.entries(meteoresult).map(([key,value],index) => {
                            return  (
                                <div className='meteocontener'>
                                    <MeteoBox  parsvalue={value} date={key}></MeteoBox>
                                    {spaceindex.includes(index)?<div className='meteo_space'> </div>:null}
                                </div>
                               
                            )
                        })}
                    </div>
                    </Toast.Body>
            </Toast>
            )
    }
    
    else if(props.sender !== props.username){ // 图像'LLM_Agent'
        
        return (
            <Toast id="msgBox-left" show={showA} onClose={toggleShowA} style={{width: (props.boxwidth-15).toString()+'rem'}}>
                <Toast.Body onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}>
                    {(props.message).map((item:string,index:number) => {
                        try{
                            
                            let requestimg = `${PagePrompt.url}/${props.username}/${item}` 
                            return (
                                <div style={{display:"inline-block", marginLeft:"10px"}}>
                                    <img style={{width:"324px",marginBottom:"5px"}} alt='图像生成失败' src={requestimg} onClick={() => setTrigger_imgFun(true,index)}/>
                                    <DownLImgBox dialogWidth="220px" index={index} trigger_img={trigger_img[index]} setTrigger_img={setTrigger_imgFun} src_img={requestimg} height_l="600px"></DownLImgBox> 
                                </div>
                            )
                        }catch(err) {
                            console.log(err)
                            return (
                                <div>
                                    图像生成失败
                                </div>
                            )
                        }
                    })}
                   {showoption}
                </Toast.Body>
            </Toast>
        )

        // return (
        //     <Toast id="msgBox-left" show={showA} onClose={toggleShowA} style={{width: (props.boxwidth-15).toString()+'rem'}}>
        //         <Toast.Body onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}>
        //             {(props.message).map((item:string,index:number) => {
        //                 try{
        //                     // let requestimg = require(`/home/jiefeiding/medi/src/assets/img/SDXL/${item}`);
        //                     let requestimg = require(`/Users/jiefeiding/Documents/project/medllm/medi/src/assets/img/SDXL/${item}`)
        //                     return (
        //                         <div>
        //                             <img style={{width:"324px",marginBottom:"5px"}} alt='图像生成失败' src={requestimg} onClick={() => setTrigger_imgFun(true,index)}/>
        //                             <DownLImgBox dialogWidth="220px" index={index} trigger_img={trigger_img[index]} setTrigger_img={setTrigger_imgFun} src_img={requestimg} height_l="600px"></DownLImgBox> 
        //                         </div>
        //                     )
        //                 }catch(err) {
        //                     let b = setTimeout(() =>{
        //                         try {
        //                             // let requestimg = require(`/home/jiefeiding/medi/src/assets/img/SDXL/${item}`);
        //                             let requestimg = require(`/Users/jiefeiding/Documents/project/medllm/medi/src/assets/img/SDXL/${item}`);
        //                             return (
        //                                 <div>
        //                                     <img style={{width:"324px",marginBottom:"5px"}} alt='图像生成失败' src={requestimg} onClick={() => setTrigger_imgFun(true,index)}/>
        //                                     <DownLImgBox dialogWidth="220px" index={index} trigger_img={trigger_img[index]} setTrigger_img={setTrigger_imgFun} src_img={requestimg} height_l="600px"></DownLImgBox> 
        //                                 </div>)
        //                         }
        //                         catch(err) {
        //                             let b2 = setTimeout(() =>{
        //                                 // let requestimg = require(`/home/jiefeiding/medi/src/assets/img/SDXL/${item}`);
        //                                 let requestimg = require(`/Users/jiefeiding/Documents/project/medllm/medi/src/assets/img/SDXL/${item}`)
        //                                 return (
        //                                     <div>
        //                                         <img style={{width:"324px",marginBottom:"5px"}} alt='图像生成失败' src={requestimg} onClick={() => setTrigger_imgFun(true,index)}/>
        //                                         <DownLImgBox dialogWidth="220px" index={index} trigger_img={trigger_img[index]} setTrigger_img={setTrigger_imgFun} src_img={requestimg} height_l="600px"></DownLImgBox> 
        //                                     </div>
        //                                 )
        //                             }, 3000);
        //                             return b2
        //                         }
        //                     }, 2000);
        //                     return b
        //                 }
        //             })}
        //            {showoption}
        //         </Toast.Body>
        //     </Toast>
        // )
    }
    else{
        return (
                <Toast id="msgBox-right" onClose={toggleShowB} show={showB} style={{width: (props.boxwidth-15).toString()+'rem'}}>
                    <Toast.Header>
                        <img src={IMG_Libs["ClientIMG"]} className="rounded me-2" width="50" alt="" />
                        <strong className="me-auto">{props.sender}</strong>
                        <small>{time}</small>
                    </Toast.Header>
                    <Toast.Body onMouseEnter={() => setIsHoveredFunc_msg(true)} onMouseLeave={() => setIsHoveredFunc_msg(false)}><div id='msgBox-txt'>{props.message} {showoption}</div></Toast.Body>
                </Toast>
        )
    }
}

function caltime(times:number){
    const days = Math.floor(times/(24*60*60))
    const hours = Math.floor((times - days*24*60*60)/(60*60))
    const minus = Math.floor((times - days*24*60*60 - hours*60*60)/60)
    // const seconds = Math.floor((times - days*24*60*60 - hours*60*60 - minus*60))
    // return (hours.toString()+'h '+minus.toString()+"m "+seconds.toString() +"s ")
    return (hours.toString()+'h '+minus.toString()+"m ")
}
MsgBox.defaultProps={
    message:"这是消息",
    sender:'LLM_Agent',
    date: new Date()
}
export default MsgBox