import React, {  useState } from 'react';
import Items from './items'
import Toast from 'react-bootstrap/Toast';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './flowBox.css'
import SetupCookies from '../../tools/setupCookies';
import { IconPrompt } from "../../configs/iconPrompyt";

interface FlowBoxProps{
    toolName?:string
    APIname?:string
    checked_default:boolean
    APIpars?:any
    step?:number
    selectedLLM?:string
    workFlowAdd_on:Function
    workFlowDel_one:Function,
    filename:string,
    username?:string,
    APIpars_list:any,
    setSelectparsListFunc:Function,
    selectparsList:[],
    setparsconfirm:Function,
    loginCheckapp:boolean,
    setenable_memory_ST:Function,
    defaultPras:any,
    deleteworkflow:Function,
    saveworkflow:Function,
    setinfoFunc:Function,
    setGeneratemsgBox:Function,
    setAutodriveParsFunc:Function,
    setNewscontent:Function,
    waitinfoFunc:Function,
    setRefresh_newscontent:Function,
    microphoneTrigger:number,
    setMicrophoneTrigger:Function,
}
const IMG_Libs_apis = IconPrompt.IMG_Libs_apis
const IMG_Libs = IconPrompt.IMG_Libs
const IMG_Libs_menu = IconPrompt.IMG_Libs_menu
const IMG_Libs_llm = IconPrompt.IMG_Libs_llm

const FlowBox: React.FC<FlowBoxProps> = (props:any) => {
    const [showA, setShowA] = useState(true);
    const [APIfoldcotrol, setAPIfoldcotrol] = useState('∴')
    const [APIheightcotrol, setAPIheightcotrol] = useState('auto')
    const [checked_default, setchecked_default] = useState(props.checked_default)
    

    
    const toggleShowA = () => setShowA(!showA);
    function setCookies_Pars(name:string,value:string,option:string){
        if(option === "new"){SetupCookies(name,value,"new"); return}
        else{return SetupCookies(name,"","get")}
    }
    function handleImgChange(obj:any){
        if(
            obj.getAttribute('src',2) === IMG_Libs["check0"]){
            obj.setAttribute('src',IMG_Libs_menu["check1"])
            SetupCookies("autodrive","true","new")
            setchecked_default(!checked_default)
            
        }else{
            obj.setAttribute('src',IMG_Libs["check0"])
            SetupCookies("autodrive","false","new")
            setchecked_default(!checked_default)
        }
    }

    function APIfoldFunc(obj:Object,name:string){
        setAPIfoldcotrol(APIfoldcotrol === "∴"?  "∵" : "∴")
        setAPIheightcotrol(APIheightcotrol === "auto" ? "50px":"auto")
    }

    
    function setselectedFunc(value:boolean,index:number){
        if(props.selectparsList.length >0){
            let list = props.selectparsList
            list[index][Object.keys((list as any)[index])[0]] =value
            props.setSelectparsListFunc(list)
        }
    }


    let head_img
    if(props.APIname === "Zapier 参数设置"){head_img=IMG_Libs_apis["zapier_img"]}
    else if(["filegenerator 参数设置","foldermanager 参数设置","getworkflow 参数设置","saveworkflow 参数设置","senttomyemail 参数设置"].includes(props.APIname)){head_img=IconPrompt.lizhi}
    else if(props.APIname === "txtreaderpytt 参数设置"|| props.APIname === "txtreader 参数设置"){head_img=IMG_Libs_apis["txtreader"]}
    else if(props.APIname === "wikipedia 参数设置"){head_img=IMG_Libs_apis["wikipedia"]}
    else if(props.APIname === "gmailservice 参数设置"){head_img=IMG_Libs_apis["gmail_img"]}
    else if(props.APIname === "newsgoogle 参数设置"){head_img=IMG_Libs_apis["googlenews"]}
    else if(props.APIname === "juhenews 参数设置"){head_img=IMG_Libs_apis["juhe"]}
    else if(props.APIname === "topnews 参数设置"){head_img=IMG_Libs_apis["zhong_img"]}
    else if(props.APIname === "translation 参数设置"){head_img=IMG_Libs_apis["googletranslate"]}
    else if(props.APIname === "googlemap 参数设置"){head_img=IMG_Libs_apis["googlemap"]}
    else if(props.APIname === "Notion 参数设置"){head_img=IMG_Libs_apis["notion_img"]}
    else if(props.APIname === "writingtools 参数设置"){head_img=IMG_Libs_apis["writing"]}
    else if(props.APIname === "meteomatics 参数设置"){head_img=IMG_Libs_apis["meteomatics"]}
    else if(props.APIname === "voicerecorder 参数设置"){head_img=IMG_Libs_apis["voicerecorder"]}
    else if(props.APIname === "articletools 参数设置"){head_img=IMG_Libs_apis["article"]}
    else if(props.APIname === "dbconnection 参数设置"){head_img=IconPrompt.lizhi}
    else if (["ChatGpt (OpenAI)","ChatGpt (dall-e-3)","ChatGpt (dall-e-2)","ChatGpt (dall-e-2)2"].includes(props.selectedLLM)){head_img=IMG_Libs_llm["openai"]
    }else if(props.selectedLLM === "GLM-7B (清华大学)"){head_img=IMG_Libs_llm["glm7b"]}
    else if(["智谱AI(GLM-4)","智谱AI","智谱AI(GLM-4V)"].includes(props.selectedLLM) ){head_img=IMG_Libs_llm["zhipu"]}
    // else if(props.selectedLLM ==="Gemini Pro"){head_img=gemini}
    else if(props.selectedLLM === "Baichuan-13B (百川智能)"){head_img=IMG_Libs_llm["baichuan"]}
    else if(["Qwen (通义千问)",'通义千问'].includes(props.selectedLLM)){head_img=IMG_Libs_llm["qwen"]}
    else if(["Turbo SDXL (LoRA Stable Diffusion XL)","Turbo SDXL","Stability AI","Stability AI (v1-6)","Stability AI (pixel)","Stability AI (mask)"].includes(props.selectedLLM)){head_img=IMG_Libs_llm["stabilityai"]}
    else if(props.selectedLLM === "Llama2-13B"){head_img=IMG_Libs_llm["llama"]}
    else if(props.selectedLLM === "Mistral AI"){head_img=IMG_Libs_llm["mistral"]}
    else if(props.selectedLLM === "Azure AI"){head_img=IMG_Libs_llm["azureai"]}
    else{head_img=IMG_Libs_llm["workflow"]}
    
    let mainfold = <button className='fold_contrl' type="button" id={'fold_contrl'+props.APIname} onClick={(e) => APIfoldFunc(e.target,props.APIname)}>{APIfoldcotrol}</button> 

    return(
        <div>
            <Toast id="flowBox" show={showA} onClose={toggleShowA}>
                <Toast.Header closeButton={false}>
                    <img src={head_img} className="rounded me-2" width="60px" height="53px" alt="" />
                    <strong className="me-auto" style={{overflow:"hidden",height:"40px",marginTop:"20px"}}>{props.toolName}</strong>
                    <small style={{overflow:"hidden",height:"40px",marginTop:"25px"}}>Step-{props.step}</small>
                    <div>&nbsp;</div>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Enter键确认输入 <br/> 开启自动生成模式</Tooltip>}
                    >
                        <img style={{overflow:"hidden"}} src={checked_default? IMG_Libs_menu["check1"]:IMG_Libs["check0"]} onClick={e => handleImgChange(e.target)} className="rounded" width="30px" alt="" />
                    </OverlayTrigger>
                    
                </Toast.Header>
                <Toast.Body style={{height:APIheightcotrol, overflow:'hidden'}}>
                    {props.APIpars.map((item:any,index:any) => {
                        if(item.required === true){item.pars = SetupCookies(item.parsName,"","get") || ""}
                    return (
                        <Items 
                        APIname = {props.APIname}
                        parsName = {item.parsName}
                        parName_ch= {item.parName_ch}
                        pars = {item.pars}
                        group = {item.group}
                        name_in_db={item.name_in_db} //from openai
                        required = {item.required}
                        type = {item.type}
                        setCookies_Pars={setCookies_Pars}
                        workFlowAdd_on={props.workFlowAdd_on}
                        workFlowDel_one={props.workFlowDel_one}
                        username = {props.username}
                        setselectedFunc = {setselectedFunc}
                        setselected={Object.values(props.selectparsList[index]?props.selectparsList[index]:{"value":false})[0]}
                        setselectedindex ={index}
                        setparsconfirm = {props.setparsconfirm}
                        loginCheckapp={props.loginCheckapp}
                        setchecked_default = {setchecked_default}
                        setenable_memory_ST = {props.setenable_memory_ST}
                        rule = {item.rule}
                        APIpars_list = {props.APIpars_list}
                        defaultPras ={props.defaultPras}
                        checked_default={props.checked_default}
                        deleteworkflow={props.deleteworkflow}
                        saveworkflow={props.saveworkflow}
                        setinfoFunc={props.setinfoFunc}
                        setGeneratemsgBox={props.setGeneratemsgBox}
                        
                        setAutodriveParsFunc={props.setAutodriveParsFunc}
                        setNewscontent={props.setNewscontent}
                        waitinfoFunc={props.waitinfoFunc}
                        setRefresh_newscontent={props.setRefresh_newscontent}
                        microphoneTrigger={props.microphoneTrigger}
                        setMicrophoneTrigger={props.setMicrophoneTrigger}
                        />
                        )
                    })}
                </Toast.Body>
                {mainfold}
            </Toast>
        </div>
    )
}
FlowBox.defaultProps={
    toolName:"EXAMPLE0",
    APIname:"EXAMPLE",
    step:0,
    selectedLLM:"API设置"
}

export default FlowBox