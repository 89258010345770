import * as React from "react";
import "./login.scss";
import { LoginPagePrompt } from "./LoginPagePrompt";
import { PagePrompt } from "../../configs/PagePrompt";
import close from '../../assets/img/toolsImage/close.png'
import phoneImage from '../../assets/img/LoginImage/phone.png'
import emailImage from '../../assets/img/LoginImage/email.png'
import axios from 'axios'
import SetupCookies from "../../tools/setupCookies";
import {Link} from 'react-router-dom'


export interface ILoginProps {
  setTrigger:any;
  trigger?:boolean;
  get2Childuser_uuid:Function
}

export interface ILoginState {
  /** confirm password */
  confirmPassword: string;
  /** if confirm password wrong ,display something */
  confirmPasswordTips: string;
  /** when user click register or login button, display loading image or not */
  displayLoading: boolean;
  /**
   * if value equal 0, hidden message,
   * if value equal 1, message container move in,
   * if value equal 2, message container move out
   */
  displayMessage: number;
  /** if value is 0 ,form is for login in, if value is 1, that means form is for registering */
  formState: number;
  /** Display user login or registration results as a message */
  message: string;
  /** password */
  password: string;
  /** if password wrong ,display something */
  passwordTips: string;
  phoneTips: string;
  emailTips: string;
  verifyTips: string;
  /** remember password checkbox is selected or not */
  rememberPassword: boolean;
  /** user login or registration results, value equal true, means status success, false means fail */
  result: boolean;
  /** if enter confirm password is empty, this value equal true */
  displayConfirmPasswordTips: boolean;
  /** if enter password is empty, this value equal true */
  displayPasswordTips: boolean;
  /** if enter username is empty, this value equal true */
  displayUsernameTips: boolean;
  /** username */
  username: string;
  /** phone */
  displayPhoneTips: boolean,
  /** verify code */
  displayVerifyTips:boolean,
  /** email */
  displayEmailTips: boolean,
  phone:string;
  email:string;
  httpresponse:string,
  isLoading:boolean,
  err:string,
  httpstate:string,
  verifyCode:string,
}

export class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props)

    this.state = {
      confirmPassword: "",
      confirmPasswordTips: LoginPagePrompt.confirmPasswordEmpty,
      displayConfirmPasswordTips: false,
      displayLoading: false,
      displayMessage: 0,
      displayPasswordTips: false,
      displayUsernameTips: false,
      displayPhoneTips: false,
      displayVerifyTips:false,
      displayEmailTips:false,
      formState: 0,
      message: LoginPagePrompt.messageLoginSuccessful,
      password: "",
      passwordTips: LoginPagePrompt.tips,
      phoneTips: LoginPagePrompt.phoneTips,
      emailTips: LoginPagePrompt.emailTips,
      verifyTips: LoginPagePrompt.verifyTips,
      rememberPassword: false,
      result: true,
      username: "",
      phone:"",
      email:"",
      httpresponse:"",
      isLoading:true,
      err:"",
      httpstate:"",
      verifyCode:"",
    };
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.getUserEmail = this.getUserEmail.bind(this);
    this.handleDisplayLoading = this.handleDisplayLoading.bind(this);
    this.handleDisplayPasswordTips = this.handleDisplayPasswordTips.bind(this);
    this.handleDisplayPhoneTips = this.handleDisplayPhoneTips.bind(this);
    this.handleDisplayVerifyTips = this.handleDisplayVerifyTips.bind(this);
    this.handleDisplayEmailTips = this.handleDisplayEmailTips.bind(this);
    this.handleGoToOtherPage = this.handleGoToOtherPage.bind(this);
    this.handleGoToVerifyPage = this.handleGoToVerifyPage.bind(this);
    this.handleMessageMoveOut = this.handleMessageMoveOut.bind(this);
    this.loginIn = this.loginIn.bind(this);
    this.register = this.register.bind(this);
    this.verify = this.verify.bind(this);
    this.changeVerifyCode = this.changeVerifyCode.bind(this);
    this.resetAllState = this.resetAllState.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.sendPwd = this.sendPwd.bind(this);
    this.sendMSG = this.sendMSG.bind(this);
    this.toggleFormState = this.toggleFormState.bind(this);
    this.toggleRememberPassword = this.toggleRememberPassword.bind(this);
    this.successfulLoginOrRegister = this.successfulLoginOrRegister.bind(this);
  }

  public componentDidMount(){
    this.setState({
      password: SetupCookies("password","","get") || "",
      username: SetupCookies("username","","get")|| "",
    });
  }
  
  /** when component will un mount, reset all state */
  public componentWillUnmount() {
    this.resetAllState();
  }

  updateState(url:string,statecode:string){
    if (url === "login"){
      if(parseInt(statecode) === 0){ 
        this.successfulLoginOrRegister("LoginPagePrompt.messageLoginSuccessful",url);
      }
      else{
       this.handdleErrors("LoginPagePrompt.messageUsernameNotExist")
      }
    }else if (url ==="signup"){
      if(this.state.httpstate ==="0"){this.successfulLoginOrRegister("LoginPagePrompt.messageRegisterSuccessful",url);}
      else{
        this.handdleErrors("LoginPagePrompt.messageUsernameExist")
      }
    }
  }

  showTips(message:string){
    this.setState(
      {
        displayMessage: 1,
        message: message,
        result: true,
      },
      () => {
        setTimeout(() => {
          this.setState({ displayMessage: 0 });
        }, 1800);
      
    })
  }

  HttpPost(url:string,HttpBody:{}){ 
        axios.post(`${PagePrompt.url}/user/${url}`,HttpBody,{withCredentials: true, headers: { 'crossDomain': true }}).then(
            response => {
                if(url ==="emailservice"){
                  console.log("email send")
                  
                }else if(url ==="verifycode"){
                  if(parseInt(response.data.errno) === 0){this.showTips(LoginPagePrompt.promptTips3)}
                  else{this.showTips(LoginPagePrompt.promptTips4)}
                }else if(url ==='signup'){
                   this.resetAllState();
                  if(parseInt(response.data.errno) === 0 ){
                    this.showTips(LoginPagePrompt.messageRegisterSuccessful)

                  }
                  else{this.showTips(LoginPagePrompt.messageUsernameExist)}
                }
                else{
                  //请求成功后通知App更新状态
                  this.setState({isLoading:false,httpresponse:response.data.message,httpstate:response.data.errno})
                  this.updateState(url,response.data.errno)
                  if(parseInt(response.data.errno) === 0){
                    SetupCookies("uuid",response.data.data.uuid,"new")
                    SetupCookies("session_id",response.data.data.session_id,"new")}

                  SetupCookies("username",this.state.username,"new")
                  this.props.get2Childuser_uuid(this.state.username,response.data.data.uuid,(response.data.data.usertype ==="S"),true)
                  
                  if (this.state.rememberPassword){
                    SetupCookies("password",this.state.password,"new")
                  }
                }
                
            },
            async error => {
              this.showTips(LoginPagePrompt.errorTips)
                //请求失败后通知App更新状态
              this.setState({isLoading:false,err:error.message,httpstate:error.errno})
              
            }
        ).catch(function (error){console.log(error)})
  }
  // render
  public render() {
    const loadingImage = require("../../assets/img/LoginImage/loading.png");
    const formStateWords = this.state.formState === 0 ? "登 陆" : "注册账户";
    const loadingClassName = this.state.displayLoading
      ? "login-loading"
      : "login-hiddenLoading";
    return (this.props.trigger)?(
      <div className="login">
        <form className="login-forms">
          <div className='popup-header-right'><img src={close} onClick={()=>this.props.setTrigger(false)} className="rounded me-2" width="20" alt="" /></div>
          <label className="login-state">{formStateWords}</label>
          {/* Username */}
          {this.renderUsername()}
          {/* Password */}
          {this.renderPassword()}
          {/*Confirm Password  */}
          {this.renderConfirmPassword()}
          {/* email */}
          {this.renderEmail()}
          {/* phone */}
          {this.renderPhone()}
          {/* Remember Me and forget password*/}
          {this.renderRememberMeAndForgetPassword()}
          {/* verify email*/}
          {this.renderVerify()}
          {/* submit/loginIn/register */}
          {this.renderSubmitButton()}
          {/* toggle 'register now' and 'login in'  */}
          {this.renderToggleButton()}
        </form>

        {/* loading image */}
        <div className={loadingClassName}>
          <img role="presentation" src={loadingImage} alt="" />
        </div>
        {/* message */}
        {this.renderMessage()}
      </div>
    ): <div></div>;
  }

  /** change and update state of confirm password */
  private changeConfirmPassword(event: { target: { value: any } }) {
    const value = event.target.value;
    this.setState({ displayConfirmPasswordTips: false });
    if (value === "") {
      this.setState({ displayConfirmPasswordTips: true });
    }
    this.setState({ confirmPassword: value });
  }

  /** change and update state of password */
  private changePassword(event: { target: { value: any } }) {
    const value = event.target.value;
    this.setState({ displayPasswordTips: false });
    if (value === "") {
      this.setState({ displayPasswordTips: true });
    }
    this.setState({ password: value });
  }

  /** change and update state of username */
  private changeUsername(event: { target: { value: any } }) {
    const value = event.target.value;
    this.setState({ displayUsernameTips: false });
    if (value === "") {
      this.setState({ displayUsernameTips: true });
    }
    this.setState({ username: value });
  }
  /** change and update state of phone */
  private changePhone(event: { target: { value: any } }) {
    const value = event.target.value;
    this.setState({ displayPhoneTips: false });
    if (value === 0) {
      this.setState({ displayPhoneTips: true });
    }
    this.setState({ phone: value });
  }

  /** change and update state of verify code */
  private  changeVerifyCode(event: { target: { value: any } }) {
    const value = event.target.value;
    this.setState({ displayVerifyTips: false });
    if (value === 0) {
      this.setState({ displayVerifyTips: true });
    }
    this.setState({ verifyCode: value });
  }

  /** change and update state of email */
  private changeEmail(event: { target: { value: any } }) {
    const value = event.target.value;
    this.setState({ displayEmailTips: false });
    if (value === 0) {
      this.setState({ displayEmailTips: true });
    }
    this.setState({ email: value });
  }

  /** a window.prompt , user can input email dress */
  private getUserEmail() {
    const person = prompt(LoginPagePrompt.promptKey, LoginPagePrompt.promptValue);
    if (person === null || person === "" ) {
      return LoginPagePrompt.promptCancel;
    } else if(!this.isEmail(person)){return LoginPagePrompt.promptTips1;}
    else {
      // http 发邮件位置 忘记密码 "sendverifyCode"
      this.setState({ email: person });
      this.HttpPost("emailservice",{email:person,request:"sendverifyCode"})
      return LoginPagePrompt.promptSuccess;
    }
  }

  private getVerifyCode(){
    const verifycode = prompt(LoginPagePrompt.promptKey2, LoginPagePrompt.promptValue2);
    if(verifycode === null || verifycode === ""){
      return LoginPagePrompt.promptCancel;
    }else if( verifycode.length !== 6){
      return LoginPagePrompt.promptTips2;
    }
    else{
      // http 发邮件位置 忘记密码 "sendverifyCode"
      this.HttpPost("verifycode",{email:this.state.email,verifycode:verifycode})
      return LoginPagePrompt.noneTips;
    }
  }

  /** display loading image */
  private handleDisplayLoading(LoginTip: string, results: boolean) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.setState({
          displayLoading: false,
          displayMessage: 1,
          result: results,
        });
        if(LoginTip ==="LoginPagePrompt.messageLoginSuccessful"){
          this.setState({message: LoginPagePrompt.messageLoginSuccessful,});
        }else if (LoginTip ==="LoginPagePrompt.messageUsernameExist")
        {this.setState({message: LoginPagePrompt.messageUsernameExist,});}
        else if (LoginTip ==="LoginPagePrompt.messageUsernameNotExist")
        {this.setState({message: LoginPagePrompt.messageUsernameNotExist,});}
        else if (LoginTip ==="LoginPagePrompt.messageRegisterSuccessful")
        {this.setState({message: LoginPagePrompt.messageRegisterSuccessful,});}
        resolve("done");
      }, 900);
    });
  }

  /** when user input password.length  is less than 6, display password tips */
  private handleDisplayPasswordTips() {
    this.setState({
      displayPasswordTips: true,
      passwordTips: LoginPagePrompt.lengthOver6,
    });
  }
  private handleDisplayPhoneTips() {
    this.setState({
      displayPhoneTips: true,
      phoneTips: LoginPagePrompt.phoneTips,
    });
  }
  private handleDisplayVerifyTips() {
    this.setState({
      displayVerifyTips: true,
      verifyTips: LoginPagePrompt.verifyTips,
    });
  }
  private handleDisplayEmailTips() {
    this.setState({
      displayPasswordTips: true,
      emailTips: LoginPagePrompt.emailTips,
    });
  }
  /** after login/register successful, go to other page */
  private handleGoToOtherPage() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.resetAllState();
      }, 800);
      resolve("");
    });
  }

  private handleGoToVerifyPage() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.resetAllState();
      }, 800);
      resolve("");
    });
  }

  /** make the top message move out */
  private handleMessageMoveOut() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.setState({ displayMessage: 2 });
        resolve("done");
      }, 1500);
    });
  }
  private closepopup(){
    this.props.setTrigger(false)
  }
  isEmail(str:string) {
    var reg =  /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
    return reg.test(str)
  }
  isPhone(str:string){
    var reg =  /^[1][3,4,5,7,8][0-9]{9}$/;
    return reg.test(str)
  }
  /** make sure username and password isn't empty then send request to backend for login in */
  private loginIn() {

    if (this.state.password !== "" && this.state.username !== "") {
      if (this.state.password.length < 6) {
        this.handleDisplayPasswordTips();
      } 
      else {
        this.setState({ displayLoading: true }, async () => {
          await this.HttpPost('login',{username:this.state.username,password:this.state.password})
        });
        
      }
      
      
      // if (
      //   this.state.password === "qwe123" &&
      //   this.state.username === "1"
      // ) {
      //   console.log('here0')
      //   this.setState({ displayLoading: true }, async () => {
      //     this.successfulLoginOrRegister("LoginPagePrompt.messageLoginSuccessful");
      //   });
      // } else if (this.state.username !== "1") {
      //   this.setState({ displayLoading: true }, async () => {
      //     await this.handleDisplayLoading("LoginPagePrompt.messageUsernameNotExist", false);
      //     await this.handleMessageMoveOut();
      //   });
      // } else {
      //   this.setState({ displayLoading: true }, async () => {
      //     await this.handleDisplayLoading("LoginPagePrompt.messageWrongPassword", false);
      //     await this.handleMessageMoveOut();
      //   });
      // }

    } else if (this.state.password === "" || this.state.username === "" || this.state.phone === "" || this.state.email === "") {
      this.setState({
        displayPasswordTips: this.state.password === "",
        displayUsernameTips: this.state.username === "",
        displayPhoneTips: this.state.username === "",
        displayEmailTips: this.state.username === "",
      });
    }
  }

  _handleKeyDown (e:any) {
    this.loginIn()
  }

  /** render confirmPassword */
  private renderConfirmPassword() {
    const confirmPasswordClassName =
      this.state.formState === 0
        ? "login-hidden login-input"
        : "login-input";
    const confirmPasswordImage = require("../../assets/img/LoginImage/password.png");
    const confirmPasswordTipsClassName = this.state.displayConfirmPasswordTips
      ? "login-displayTips"
      : "login-tips";
    return (
      <>
        <div className={confirmPasswordClassName}>
          <input
            type="password"
            name="login-ConfirmPassword"
            id="login-ConfirmPassword"
            placeholder="请确认您的密码"
            value={this.state.confirmPassword}
            onChange={this.changeConfirmPassword}
          />
          <img role="presentation" alt="" src={confirmPasswordImage} />
        </div>
        <div className={confirmPasswordTipsClassName}>
          {this.state.confirmPasswordTips}
        </div>
      </>
    );
  }
  /** render message on top, when user click forget password or login or register, it will display this message */
  private renderMessage() {
    // message className
    const messageClassName =
      this.state.displayMessage === 0
        ? "login-message-container-hidden"
        : this.state.displayMessage === 1
        ? "login-message-container login-message-move-in"
        : "login-message-container login-message-move-out";
    // message icon className
    const messageIconClassName = this.state.result
      ? "login-icon-success"
      : "login-icon-fail";
    return (
      <>
        <div className={messageClassName}>
          {/* icon */}
          <div>
            <span className={messageIconClassName} />
            <span className="login-word-success">
              {this.state.message}
            </span>
          </div>
        </div>
      </>
    );
  }
  /** render password input and icon */
  private renderPassword() {
    const passwordImage = require("../../assets/img/LoginImage/password.png");
    const passwordTipsClassName = this.state.displayPasswordTips
      ? "login-display-passwordTips"
      : "login-hidden-passwordTips";
    return (
      <>
        <div className="login-input">
          <input
            type="password"
            name="login-password"
            id="login-password"
            placeholder="密   码"
            value={this.state.password}
            onChange={this.changePassword}
          />
          <img role="presentation" alt="" src={passwordImage} />
        </div>
        <div className={passwordTipsClassName}>{this.state.passwordTips}</div>
      </>
    );
  }
  /** render remember me and forget password button */
  private renderRememberMeAndForgetPassword() {
    const rememberMeClassName = this.state.rememberPassword
      ? "login-rememberMe-checkImage"
      : "login-rememberMe-unCheckImage";
    const rememberPasswordClassName =
      this.state.formState === 0
        ? "login-rememberMe"
        : "login-hidden";
    return (
      <>
        <div className={rememberPasswordClassName}>
          <p
            role="button"
            onClick={this.toggleRememberPassword}
            className="login-rememberMe-CheckBox"
          >
            <i className={rememberMeClassName} />
            <span className="login-rememberMe-CheckBox-word">
              Remember Me
            </span>
          </p>
          {/* Forgot password */}
          <p>
              <Link className='list-group-item' target="_blank" to='/changepwd'>忘记密码？</Link>
          </p>
        </div>
      </>
    );
  }
  /** render a submit button, when user click this button ,submit the form */
  private renderSubmitButton() {
    if(this.state.formState === 0){
      return (<button
              type="button"
              className="login-button"
              onClick={this.loginIn}
            >
              Login
            </button>)
    }else if(this.state.formState === 1){
      return (
        <button
          type="button"
          className="login-button"
          onClick={this.register}
        >
          Register
        </button>
      )
    }else{
      return (
        <button
          type="button"
          className="login-button"
          onClick={this.verify}
        >
          Verify
        </button>
      )
    }
  }
  /** render toggle button, when user click this button, toggle form state between 'register' and 'login' */
  private renderToggleButton() {
    // toggle formState words
    const toggleFormStateWords =
      this.state.formState === 0 ? "现在注册" : "登 陆";
    return (
      <>
        <p className="login-registerNow">
          <span>Or</span>
          <span role="button" onClick={this.toggleFormState}>
            <a href="/#" onClick={e => e.preventDefault()} role="button">
              {toggleFormStateWords}
            </a>
          </span>
        </p>
      </>
    );
  }
  /** render username input and icon */
  private renderUsername() {
    const usernameImage = require("../../assets/img/LoginImage/username.png");
    const usernameTipClassName = this.state.displayUsernameTips
      ? "login-display-usernameTips"
      : "login-hidden-usernameTips";
    return (
      <>
        <div className="login-input">
          <input
            type="text"
            name="login-username"
            id="login-username"
            placeholder="用户名"
            value={this.state.username}
            onChange={this.changeUsername}
          />
          <img role="presentation" alt="" src={usernameImage} />
        </div>
        <div className={usernameTipClassName}>请输入您的用户名!</div>
      </>
    );
  }

  /** render email input and icon */
  private renderEmail() {
    const emailClassName = this.state.formState === 0
    ? "login-hidden login-input"
    : "login-input";
    const emailTipsClassName = this.state.displayEmailTips
      ? "login-displayTips"
      : "login-tips";

    return (
      <>
        <div className={emailClassName}>
          <input
            type="text"
            name="login-email"
            id="login-email"
            placeholder="邮箱地址"
            value={this.state.email}
            onChange={this.changeEmail}
          />
          <img role="presentation" alt="" src={emailImage} />
        </div>
        <div className={emailTipsClassName}>请输入您的邮箱地址</div>
      </>
    );
  }

  /** render phone input and icon */
  private renderPhone() {
    const phoneClassName = this.state.formState === 0
    ? "login-hidden login-input"
    : "login-input";
    const phoneTipsClassName = this.state.displayPhoneTips
      ? "login-displayTips"
      : "login-tips";

    return (
      <>
        <div className={phoneClassName}>
          <input
            type="text"
            name="login-phone"
            id="login-phone"
            placeholder="手机号码"
            value={this.state.phone.toString()}
            onChange={this.changePhone}
          />
          <img role="presentation" alt="" src={phoneImage} />
        </div>
        <div className={phoneTipsClassName}>请输入您的电话号码</div>
      </>
    );
  }

  /** render email verify and icon */
  private renderVerify() {
    const verifyClassName =
      this.state.formState === 1
        ? "login-rememberMe"
        : "login-hidden";
    return (
      <>
        <div className={verifyClassName}>
          {/* 用户验证 */}
          <p>
            <span role="button" onClick={this.sendEmail}>
              <a href="/#" onClick={e => e.preventDefault()}>
                邮箱验证
              </a>
            </span>
          </p>
          {/* Forgot password */}
          <p>
            <span role="button" onClick={this.sendMSG}>
              <a href="/#" onClick={e => e.preventDefault()}>
                手机验证
              </a>
            </span>
          </p>
        </div>
      </>
    );

  }


  /** make sure username and password isn't empty then send request to backend for register */
  private register() {

    if (
      this.state.confirmPassword !== "" &&
      this.state.password !== "" &&
      this.state.username !== "" &&
      this.state.email !== "" &&
      this.state.phone !== ""
    ) {
      if (this.state.password.length < 6) {
        this.handleDisplayPasswordTips();} 
      else if(this.state.phone.length !== 11){
        this.handleDisplayPhoneTips();}
      else if (this.state.confirmPassword !== this.state.password) {
        this.setState({
          confirmPasswordTips: LoginPagePrompt.confirmPasswordWrong,
          displayConfirmPasswordTips: true,
        });
      } else {
        if (this.state.username === "1") {
          this.setState({ displayLoading: true }, async () => {
            await this.handleDisplayLoading("LoginPagePrompt.messageUsernameExist", false);
            await this.handleMessageMoveOut();
          });
        } else if (this.state.username !== "1") {
          this.setState({ displayLoading: true }, async () => {
            await this.HttpPost('signup',{username:this.state.username,password:this.state.password,phone:this.state.phone,email:this.state.email,usertype:"L1"})
          });
        }
      }
    } else if (
      this.state.confirmPassword === "" ||
      this.state.password === "" ||
      this.state.username === "" ||
      this.state.email === "" ||
      this.state.phone ===""
    ) {
      this.setState({
        displayConfirmPasswordTips: this.state.confirmPassword === "",
        displayPasswordTips: this.state.password === "",
        displayUsernameTips: this.state.username === "",
        displayPhoneTips: this.state.phone === "",
        displayEmailTips: this.state.email === "",
      });
    }
  }
  /** verify received code */
  private verify(){
    if (this.state.verifyCode !== "" ) {
      if (this.state.password.length < 6) {this.handleDisplayVerifyTips();} 
      else{
          console.log("veryfy check")
      }
    }else{
      this.setState({
        displayVerifyTips: this.state.verifyCode === "",
      });
    }
  }
  /** reset all state as initial value */
  private resetAllState() {
    this.setState({
      confirmPassword: "",
      confirmPasswordTips: LoginPagePrompt.confirmPasswordEmpty,
      displayConfirmPasswordTips: false,
      displayLoading: false,
      displayMessage: 0,
      displayPasswordTips: false,
      displayUsernameTips: false,
      formState: 0,
      message: LoginPagePrompt.messageLoginSuccessful,
      password: "",
      passwordTips: LoginPagePrompt.tips,
      rememberPassword: false,
      result: true,
      username: "",
    });
  }

  /** when user forget password,input the email path, then simulate a message: there will be an email */
  private sendEmail() {
    
    this.setState(
      {
        displayMessage: 1,
        message: this.getUserEmail(),
        result: true,
      },
      () => {
        setTimeout(() => {
          // this.setState({ displayMessage: 0 });
          this.setState(
            {
              displayMessage: 1,
              message: this.getVerifyCode(),
              result: true,
            },)
            this.setState({ displayMessage: 0 })
        }, 1800);
      },
    );
  }
  
  private sendPwd(){
    this.showTips("等待系统更新")

  }

  private sendMSG(){
    this.showTips("等待系统更新")
  }

  /**
   * if form as a login form, it will display login successful
   * if form ad a register form, it will display register successful
   */
  private async successfulLoginOrRegister(message: string,clousewin:string) {
    await this.handleDisplayLoading(message, true);
    await this.handleMessageMoveOut();
    if (clousewin ==="signup"){await this.handleGoToVerifyPage();}
    else if (clousewin ==="verify"){await this.handleGoToOtherPage();}
    else if(clousewin ==="login"){await this.closepopup();}
    
  }

  private async handdleErrors(message: string) {
    console.log('handdleErrors')
    await this.handleDisplayLoading(message, true);
    await this.handleMessageMoveOut();
  }
  /** toggle form state of login or register */
  private toggleFormState() {
    const formState = this.state.formState === 0 ? 1 : 0;
    this.setState({
      confirmPassword: "",
      confirmPasswordTips: LoginPagePrompt.confirmPasswordEmpty,
      displayConfirmPasswordTips: false,
      displayLoading: false,
      displayMessage: 0,
      displayPhoneTips: false,
      displayEmailTips: false,
      displayPasswordTips: false,
      displayUsernameTips: false,
      formState,
      message: LoginPagePrompt.messageLoginSuccessful,
      password: "",
      passwordTips: LoginPagePrompt.tips,
      rememberPassword: false,
      result: true,
      username: "",
      phone:"",
      httpresponse:"",
      isLoading:true,
      err:"",
      httpstate:"",
    });
  }

  /** toggle remember password checkbox state of checked */
  private toggleRememberPassword() {
    this.setState({ rememberPassword: !this.state.rememberPassword });
  }
}
