import {useEffect,useState} from 'react';
import './App.css';
import Main from './pages/main/main';
import Footer from './compoments/footer/footer';
import Header from './compoments/header/header';
import Menu from './compoments/menu/menu';
import Mymemory from './pages/Mymemory/mymemory';
import {Route,Routes} from 'react-router-dom'
import Admin from './pages/admin/admin';
import SetupCookies from './tools/setupCookies';
import LoginCheck from './tools/loginCheck';
import Frontpage from './pages/frontpage/frontpage';
import Aboutpage from './pages/About/about';
import { PagePrompt } from './configs/PagePrompt';
import ShowInfo from "./tools/showInfo";
import WaitInfo from './tools/waitInfo';
import ChangePwd from './pages/changePwd';
import Dbconnection from './pages/dbconnection/dbconnection';
// import Browser from './pages/browser/browser';
import Guide from './pages/guide/guide';
import Vdbmanager from './pages/vdbmanager/vdbmanager';

function App() {
  const username = SetupCookies("username","","get")
  const uuid = SetupCookies("uuid","","get")
  const selectedLLM_default=SetupCookies("selectedLLM","","get") 
  
  // let loginCheck

  const [userListP, setuserListP] = useState( []);
  const [msgListP, setmsgListP] = useState( []);
  const [enable_memory_ST,setenable_memory_ST]=useState(0)

  const getChildList = (list1:any,list2:any) =>{
    setuserListP(list1);
    setmsgListP(list2);
  }
  const [user_uuid, setuser_uuid] = useState({username:username,uuid:uuid});
  const [superuser,setsuperuser] = useState(false)
  const [loginCheckapp, setloginCheckapp] = useState(false)
  const [selectedLLM, setselectedLLM] = useState(selectedLLM_default)
  const [info,setinfo] = useState( <div></div> )
  const [workFlowAddList,setworkFlowAddList]= useState<string[]>([])
  
  function workFlowAdd_on(parsName:any,opt:string="none"){ 
    
    if(opt === "saveworkflow"){
      setworkFlowAddList(parsName)
    }else if(opt === "removeworkflow"){
      setworkFlowAddList([])
    }
    else{

      if(typeof parsName === "string"){ // add one
        if(workFlowAddList.indexOf(parsName) === -1){
          //check conflict
          let conflict = PagePrompt.APILibs[parsName].conflict
          let newlist:string[]  = workFlowAddList
          if(conflict.length>0){
            newlist = newlist.filter((elem) => conflict.includes(elem) === false)
          }
          setworkFlowAddList([...newlist,parsName])
        }
      }else{ 
        //已存在的改参数，不存在的全部加上
        let newelem:any[]=[]
        parsName.forEach((elem:string,index:number) => {
          if(workFlowAddList.indexOf(elem) === -1){
            newelem.push(elem)
          }
        });
        setworkFlowAddList(workFlowAddList =>[...workFlowAddList,...newelem])
      
      }
      
    }
   
  }

  function setinfoFunc(message:string,time:string="2000"){
    setinfo(<ShowInfo message={message} time={time}></ShowInfo>) 
    window.setTimeout(function() {
      setinfo( <div></div> ) 
    }, parseInt(time));
  }

  function waitinfoFunc(trigger:boolean,timesetting:number =60000,message:string = "正在努力加载中。。。"){
    if(trigger === true){
      setinfo(<WaitInfo message={message}></WaitInfo>) 
      window.setTimeout(function() {
        setinfo( <div></div> ) 
      }, timesetting);
    }else{
      setinfo(<div></div> ) 
    }
    
  }
  
  const getChilduser_uuid = (item1:any,item2:any,item3:boolean) =>{
    setuser_uuid({username:item1,uuid:item2})
    setsuperuser(item3)
  }

  const setloginCheckappFunc = (item1:boolean,item2:boolean)=>{
    setloginCheckapp(item1)
    setsuperuser(item2)
  }

  const llmselection = (item1:string)=>{
    setselectedLLM(item1)
    SetupCookies("selectedLLM",item1,"new")
  }

  const cleanupCookies = () =>{
    PagePrompt.cleanupCoookiesList.forEach((item)=>{
        SetupCookies(item,"",'delete')
    })
  }

  const cleanupAPICookies = (APIs:string) =>{
    if(APIs === "zapierCookiesList" || APIs === "senttomyemailCookiesList" || APIs === "filegeneratorCookiesList"|| APIs === "translationCookiesList" || APIs === "articleCookiesList" 
    || APIs === "txtreaderCookiesList"|| APIs === "foldermanagerCookiesList"|| APIs === "newsgoogleCookiesList" || APIs === "topnewsCookiesList" || APIs === "gmailserviceCookiesList"
    || APIs === "saveworkflowCookiesList" || APIs ===  "getworkflowCookiesList"|| APIs ===  "openaiimg2imgCookiesList"|| APIs ===  "openaitxt2imgCookiesList"|| APIs ===  "openaiimg2txtCookiesList"
    || APIs ===  "qwentxt2imgCookiesList" || APIs ===  "meteomaticsCookiesList" || APIs ===  "googlemapCookiesList" || APIs ===  "zhipuaitxt2imgCookiesList"){
      PagePrompt[APIs].forEach((item:string)=>{
        SetupCookies(item,"",'delete')
      })
    }
  }

  useEffect(() => { //监控props.userList状态变化
    LoginCheck (user_uuid.username,user_uuid.uuid,setloginCheckappFunc)
    cleanupCookies()
  }, [user_uuid]);
  

  return (
    <div className="App" style={{minHeight:window.innerHeight}}>
      <div className="Page_body" style={{minHeight:window.innerHeight-40}}>
      <Header username={user_uuid.username} uuid={user_uuid.uuid} loginCheckapp={loginCheckapp} getChilduser_uuid={ getChilduser_uuid} cleanupCookies={cleanupCookies}></Header>
        <div>{info}</div>
        <Routes>
          <Route path='/' element={<Frontpage username={user_uuid.username} loginCheckapp={loginCheckapp} selectedLLM={selectedLLM}/>}/>
          <Route path='/about' element={<Aboutpage username={user_uuid.username} loginCheckapp={loginCheckapp} selectedLLM={selectedLLM}/>}/>
          <Route path='/home' element={ 
          <div> <Main username={user_uuid.username} loginCheckapp={loginCheckapp} enable_memory_ST={enable_memory_ST} setenable_memory_ST={setenable_memory_ST} setloginCheckappFunc={setloginCheckappFunc} selectedLLM={selectedLLM} setinfoFunc={setinfoFunc} workFlowAddList={workFlowAddList} setworkFlowAddList={setworkFlowAddList} workFlowAdd_on={workFlowAdd_on} cleanupAPICookies = {cleanupAPICookies} waitinfoFunc={waitinfoFunc}/>
          <menu id="menu"><Menu superuser={superuser || false} username={user_uuid.username} setenable_memory_ST={setenable_memory_ST} workFlowAddList={workFlowAddList} getChildList={getChildList}  loginCheckapp={loginCheckapp} llmselection={llmselection} selectedLLM={selectedLLM} cleanupCookies={cleanupCookies} cleanupAPICookies = {cleanupAPICookies} setinfoFunc={setinfoFunc} workFlowAdd_on={workFlowAdd_on}/></menu></div>}/>
          <Route path='/admin' element={<div><Admin username={user_uuid.username} userList={userListP} msgList={msgListP}/>
          <menu id="menu"><Menu superuser={superuser || false} username={user_uuid.username} setenable_memory_ST={setenable_memory_ST} workFlowAddList={workFlowAddList} getChildList={getChildList}  loginCheckapp={loginCheckapp} llmselection={llmselection} selectedLLM={selectedLLM} cleanupCookies={cleanupCookies} cleanupAPICookies = {cleanupAPICookies} setinfoFunc={setinfoFunc} workFlowAdd_on={workFlowAdd_on}/></menu></div>}/>
          <Route path='/mymemory' element={<div><Mymemory username={user_uuid.username} waitinfoFunc={waitinfoFunc} setinfoFunc={setinfoFunc} setloginCheckappFunc={setloginCheckappFunc}/>
          <menu id="menu"><Menu superuser={superuser || false} username={user_uuid.username} setenable_memory_ST={setenable_memory_ST} workFlowAddList={workFlowAddList} getChildList={getChildList} loginCheckapp={loginCheckapp} llmselection={llmselection} selectedLLM={selectedLLM} cleanupCookies={cleanupCookies} cleanupAPICookies = {cleanupAPICookies} setinfoFunc={setinfoFunc} workFlowAdd_on={workFlowAdd_on}/></menu></div>}/>  
          <Route path='/changepwd' element={<ChangePwd setinfoFunc={setinfoFunc} waitinfoFunc={waitinfoFunc}/>}/>
          <Route path='/dbconnection' element={<Dbconnection username={user_uuid.username} setinfoFunc={setinfoFunc} waitinfoFunc={waitinfoFunc} loginCheckapp={loginCheckapp} uuid={uuid}/>}/>
          {/* <Route path='/browser' element={<Browser loginCheckapp={loginCheckapp} />}/> */}
          <Route path='/guide' element={<Guide loginCheckapp={loginCheckapp} />}/>
          <Route path='/vdbmanager' element={<Vdbmanager loginCheckapp={loginCheckapp} username={user_uuid.username} uuid={user_uuid.uuid} setinfoFunc={setinfoFunc} waitinfoFunc={waitinfoFunc}/>}/>
         
        </Routes>
      </div>
        
      
        <footer id="footer"><Footer/> </footer>   
    </div>
  );
}



export default App;
