import React,{useState}  from "react";
import "./dbconnection.css"
import { io } from 'socket.io-client';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SetupCookies from '../../tools/setupCookies';
import { PagePrompt } from "../../configs/PagePrompt";
import {UseURLLoader} from '../../hooks/useURLLoader';
import baichuanAPI from "../../../src/APILibs/baichuan.json";
import PromptSpliter from "../PromptSpliter/PromptSpliter";

interface DbconnectionProps{
    username:string
    uuid:any
    setinfoFunc:Function
    waitinfoFunc:Function
    loginCheckapp:boolean
    
}
var socket:any;
const Dbconnection: React.FC<DbconnectionProps> = (props) => {
    const [dbfoldcotrol, setdbfoldcotrol] = useState("∵")
    const [dbfoldcotrol2, setdbfoldcotrol2] = useState("∴")
    const [displaycontent, setdisplaycontent] = useState<any>(false)
    const [displaycontent2, setdisplaycontent2] = useState<any>(true)
    const [displaycontent3, setdisplaycontent3] = useState<any>(false)
    const [isConnected, setIsConnected] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [vdbList, setVdbList] = useState<any[]>([]);
    const [maxLength,setmaxLength]=useState<number>(); 
    const [overlapLength,setoverlapLength]=useState<number>();

    
    const [filename, setFilename] = useState<string>();
    const [splitorInput, setsplitorInput] = useState<string>(); 
    
    const [info2, setinfo2] = useState<any>(<div></div> );
    const [advanceC, setAdvanceC] = useState<any>(<div></div> );
    const [vdbname,setvdbname] = useState<string>();
    const [vdbnameE,setvdbnameE] = useState<string>();
    const [vdbfilename, setVdbfilename] = useState<string>();
    const [vdbnameSwitch, setVdbnameSwitch] = useState<boolean>(false);
    const [splitorSwitch, setSplitorSwitch] = useState<boolean>(false);
    const [PromptSplitertrigger,setPromptSplitertrigger] = useState<boolean>(false);
    const [articleA, setarticleA] = useState("")
    

    let HttpBody_init= {'username': props.username, uuid:SetupCookies("uuid","","get"),session_id:SetupCookies("session_id","","get")}
    
    function onConnect() {
        props.setinfoFunc("成功链接本地数据库",2000)
        setIsConnected(true);
      }
  
    function onDisconnect() {
        try{
            socket.close()
            props.setinfoFunc("已断开本地数据库链接",2000)
            setIsConnected(false);
        }catch(error){}
        
      }
  
    // function onFooEvent(value:any) {
    //     setFooEvents(previous => [...previous, value]);
    //   }
    
    function displayList(arg:any){
        if(fileList !== arg.fileList){setFileList(arg.fileList);}
        if(vdbList !== arg.vdbList){setVdbList(arg.vdbList);}
    }
    function setinfoFunc2(arg:any){
        props.setinfoFunc(arg,2000)
        props.waitinfoFunc(false)
        if(arg === "加载成功"){
            setinfo2(<div> 
                文件已成功加载为模型的prompt，请返回聊天页面，进行提问。
                <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => {window.close()}} >确认</Button>
            </div> )
        }else if(arg === "数据库生成成功"){
            setinfo2(<div> 
                文件已成功生成向量数据库，请返回流程图页面，点击链接数据库，然后选择向量数据库名称, 进行数据库加载。
                <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => {window.close()}} >确认</Button>
                </div> )
        }
    }
     
    function socketconnect(opt:any){
        // socket.connected
        const URL = 'http://localhost:8080';
        
        if(opt ===0){
            socket = io(URL,{
                reconnectionDelayMax:10000,
                query:{"request":"dbconnection"},
                extraHeaders: {
                    "Access-Control-Allow-Origin": "*",
                  },
                transports: ['websocket', 'polling', 'flashsocket'],
            });
            socket.on('connect', ()=>onConnect());
            socket.on('disconnect', ()=>onDisconnect());
            socket.on('data', (arg:any) => displayList(arg));
            socket.on('response', (arg:any) => setinfoFunc2(arg));
            socket.on('content', (arg:any) => setinfoFunc5(arg));

            
            // socket.on('foo', onFooEvent);
            socket.off('connect', ()=>onConnect());
            socket.off('disconnect', ()=>onDisconnect());
            socket.off('data', (arg:any) => displayList(arg));
            socket.off('response', (arg:any) => setinfoFunc2(arg));
            socket.off('content', (arg:any) => setinfoFunc5(arg));
            // socket.off('foo', onFooEvent);
        }else{
            try{
                socket.close()
                props.setinfoFunc("已断开本地数据库链接",2000)
            }catch(error){}
            
        }
    }


    
    function dbfoldFunc(opt:number){
        if(opt === 0 ){
            setdbfoldcotrol(dbfoldcotrol === "∵"?"∴":"∵")
            setdisplaycontent(dbfoldcotrol === "∵"? true:false)
        }else{
            setdbfoldcotrol2(dbfoldcotrol2 === "∵"?"∴":"∵")
            setdisplaycontent2(dbfoldcotrol2 === "∵"? true:false)
        }
    }
    function changeFunc2(checked:boolean,item:string){
        
        if(checked && filename !== item){
            setFilename(item)
        }
        
    }
    function changeFunc3(checked:boolean,item:string){
        if(checked && vdbnameE !== item){
            setvdbnameE(item)
        }
        
    }
    function loadprompt(){
        let session_id = SetupCookies("session_id","","get")
        if(props.loginCheckapp && session_id && props.username && props.uuid && filename){
            
            let HttpBody = {'username': props.username,uuid:props.uuid,session_id: session_id}
            socket.emit("loadprompt", { 
                HttpBody:HttpBody,
                filename:filename,
                url:`${PagePrompt.url}/llm/loadprompt`
            });
        }
        
    }

    function generatevdbconfirm(){

        let getvdbname = vdbname?vdbname:document.getElementById('vdbname_input')?.getAttribute("value")
        let getvdbfilename = vdbfilename?vdbfilename:document.getElementById('vdbfilename_input')?.getAttribute("value")
        if(vdbnameSwitch){
            getvdbname = vdbnameE
        }

        

        let getsplitorInput:any = ""
        let getmaxLength:any = 500
        let getoverlapLength:any = 50
        if(!splitorSwitch){
            getsplitorInput= splitorInput?splitorInput:document.getElementById('splitor_input')?.getAttribute("value")
            
        }else{
            getmaxLength= maxLength?maxLength:document.getElementById('item_input_maxLength')?.getAttribute("value")
            getoverlapLength = overlapLength?overlapLength:document.getElementById('item_input_overlapLength')?.getAttribute("value")
        }
        

        console.log(getvdbname,getvdbfilename)
        if((getvdbname && getvdbname.trim().length>0) && filename && (getvdbfilename && getvdbfilename.trim().length>0) ){
            props.waitinfoFunc(true,30000)
            socket.emit("generatevdb", { 
                vdbname:getvdbname,
                filename:filename,
                vdbfilename:getvdbfilename,
                splitorInput:getsplitorInput,
                maxLength:getmaxLength,
                overlapLength:getoverlapLength
            });
        }else{
            props.setinfoFunc("请选择文件，并输入数据库名称。",2000)
        }
        
    }
    
    function getvdbsample(){
        console.log(filename)
        if(filename){
            props.waitinfoFunc(true,30000)
            socket.emit("getfilecontent", { 
                filename:filename
            });
        }else{
            props.setinfoFunc("请选择文件。",2000)
        }
    }
    
    function setinfoFunc4(arg:any){
        props.waitinfoFunc(false)
        
        // let TXTprompt = "__news__" + arg;
        let HttpBody: {[key:string]:any} = {...HttpBody_init}
        HttpBody["question"]="请将以下内容，划分为不同章节："+arg
        let pars = baichuanAPI.defaultPras
        pars[0] = "0.8"
        pars[1] = "2000"
        // pars[8] = TXTprompt
        HttpBody["LLMpars"] = pars
        HttpBody["selectedLLM"] = 'Baichuan-13B (百川智能)'
        
        props.waitinfoFunc(true,30000)
        let props1={url:`llm/gensummary`,setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        UseURLLoader(props1).then( result =>
            {   
                props.waitinfoFunc(false)
                if(result){
                    result.replace(HttpBody["question"],"");
                    setinfo2(<div><h6 style={{whiteSpace:"pre-line",backgroundColor:"#c2d5d3b6",textAlign:"left",marginTop:"10px",marginBottom:"10px"}}> {result}</h6></div> )
                    
                }else{props.setinfoFunc("发生未知错误，请重新尝试。",2000)}
            }
        )

        
    }

    function setinfoFunc5(arg:string){
        props.waitinfoFunc(false)
        setPromptSplitertrigger(true)
        setarticleA(arg)
    }


    function switchFunc2(){
        setVdbnameSwitch(!vdbnameSwitch)
        if(!vdbnameSwitch){selectVDB()}else{
            setAdvanceC(   <div>
                    <div  style={{display:"inline-block",width:"350px"}}><input type="text" id={'vdbname_input'}  className='item_input' name='vdbname_input' placeholder="请输入数据库名称" value={vdbname} onChange={(e)=>setvdbname(e.target.value)} maxLength={50}></input></div>
                    
                </div>
                 )
        }
    }
   
    function switchFunc3(){
        console.log(splitorSwitch)
        setSplitorSwitch(!splitorSwitch)
        if(splitorSwitch){
            setinfo2( <div  style={{display:"inline-block",width:"400px"}}>
                <h6 style={{display:"inline-block"}}>设置划分方式（<p className="samplespan" onClick={getvdbsample}>样式</p> ）：</h6> 
                <input type="text" id={'splitor_input'}  className='item_input' name='splitor_input' placeholder="请根据样式案例，设置您的划分方式。" value={splitorInput} onChange={(e)=>setsplitorInput(e.target.value)} maxLength={50}></input>
                </div>)
        }else{
            setinfo2( <div  style={{display:"inline-block",width:"400px"}}>
                <h6 style={{display:"inline-block"}}>最大长度：</h6> 
                <input style={{marginRight:"5px"}} id={'item_input_maxLength'} className='item_input_s' type="number" name="maxLength" placeholder={"500"} value={maxLength} onChange={(e)=>setmaxLength(Number(e.target.value))} maxLength={5}></input>
                <h6 style={{display:"inline-block"}}>字符</h6> 
                <h6 style={{display:"inline-block", marginLeft:"20px"}}>重叠度：</h6>
                <input style={{marginRight:"5px"}} id={'item_input_overlapLength'} className='item_input_s' type="number" name="overlapLength" placeholder={"50"} value={overlapLength} onChange={(e)=>setoverlapLength(Number(e.target.value))} maxLength={5}></input>
                <h6 style={{display:"inline-block"}}>字符</h6>
                
                </div>)
        }
    }
    function selectVDB(){
        setAdvanceC(<Form >
            {vdbList.map((item,index) =>{
                return <div >
                            <Form.Check
                                key={item}
                                label={item}
                                value={0}
                                name="group1"
                                type= 'radio'
                                style={{textAlign:"justify",width:"fit-content",margin:"0 auto"}}
                                onChange={e => changeFunc3(e.target.checked,item)}
                            />

                    </div>       
            })
            }
        </Form>)
        
    }
    function setdisplaycontentFunc(){
        setdisplaycontent3(true)
        setinfo2( <div  style={{display:"inline-block",width:"400px"}}>
                <h6 style={{display:"inline-block"}}>设置划分方式（<span className="samplespan"  onClick={getvdbsample}>样式</span>）：</h6> 
                <input type="text" id={'splitor_input'}  className='item_input' name='splitor_input' placeholder="请根据样式案例，设置您的划分方式。" value={splitorInput} onChange={(e)=>setsplitorInput(e.target.value)} maxLength={50}></input>
                </div>)
        setAdvanceC(   <div  style={{display:"inline-block",width:"350px"}}><input type="text" id={'vdbname_input'}  className='item_input' name='vdbname_input' placeholder="请输入数据库名称" value={vdbname} onChange={(e)=>setvdbname(e.target.value)} maxLength={50}></input></div> )
    }

    return (
        <div id="dbconnection">
            <div className="dbconnection_tool">
                <div className="dbconnection_title" style={{width: "50%"}}  onClick={(e) => dbfoldFunc(0)}>
                    <h5 className="topic4">工具下载以及安装步骤</h5>
                    <button className='dbfold_contrl' type="button" onClick={(e) => dbfoldFunc(0)}>{dbfoldcotrol}</button>
                </div>
                <div className="dbconnection_content" style={{display:displaycontent?"inline-block":"none"}}>
                    <h5 className='subtitle'>❖  &nbsp; 工具下载</h5>
                    <ol className="dbconnection_li">
                        <li> <h6 className='subcontent'>请点击 <a href={`/bridge_agentlet.zip`} download>下载</a>。</h6></li>
                        <li> <h6 className='subcontent'>Github下载：
                        <p className='command'>( <a  target="_blank"  href={`https://github.com/djweb01/bridge_agentlet/tree/main`}>通过 SSH</a> ) git clone git@github.com:djweb01/bridge_agentlet.git</p>
                        <p className='command'>( <a  target="_blank"  href={`https://github.com/djweb01/bridge_agentlet/tree/main`}>通过 HTTPS</a> ) git clone https://github.com/djweb01/bridge_agentlet.git</p>
                        </h6>
                        </li>
                    </ol>
                    <h5 className='subtitle'>❖  &nbsp; 安装</h5>
                    <ol className="dbconnection_li">
                        <li> <h6 className='subcontent'>下载bridge_agentlet，并将文件解压。</h6> </li>
                        <li> <h6 className='subcontent'>打开Terminal(终端)/CMD（Windows命令行），进入文件夹的位置，例如: <p className='command'>(Mac OS): cd Downloads/bridge_agentlet</p> <p className='command'>(Windows): cd C:\Users\%USERNAME%\Downloads\bridge_agentlet</p></h6></li>
                        {/* </p>  如何打开终端? <a href={`/bridge_agentlet.zip`} download>Windows</a> <a href={`/bridge_agentlet.zip`} download>Mac OS</a></h6> */}
                        <li> <h6 className='subcontent'>安装python（<a  target="_blank"  href={`https://www.python.org/downloads/macos/`}>Mac OS</a>,  <a  target="_blank"  href={`https://www.python.org/downloads/windows/`}>Windows</a>），以及相关依赖。
                        <ul>
                            <li><p className='command'>( <a  target="_blank"  href={`https://pypi.org/project/langchain/`}>Langchain</a> ) pip install langchain</p></li>
                            <li><p className='command'>(<a  target="_blank"  href={`https://pypi.org/project/chromadb/`}>Chroma</a>) sudo pip install chromadb</p></li>
                            <li><p className='command'>(<a  target="_blank"  href={`https://pypi.org/project/transformers/`}>Transformers</a>) pip install transformers</p></li>
                            <li><p className='command'>(<a  target="_blank"  href={`https://pypi.org/project/InstructorEmbedding/`}>InstructorEmbedding</a>) pip install InstructorEmbedding</p></li>
                            </ul>  
                        </h6></li>
                        <li> <h6 className='subcontent'>安装<a  target="_blank"  href={`https://nodejs.org/en/download`}>NodeJS</a></h6></li>
                        <li> <h6 className='subcontent'>安装Nodejs相关依赖，运行： <p className='command'>npm install</p></h6></li>
                    </ol>
                    
                    <h5 className='subtitle'>❖  &nbsp; 运行</h5>
                    <ol className="dbconnection_li">
                        <li> <h6 className='subcontent'>启动程序，运行： <p className='command'>npm start</p>当命令行出现：listening on  https://:::8080 表示运行成功，可以尝试在网页上加载prompt等相关操作。</h6></li>
                        <li> <h6 className='subcontent'>如果遇到运行错误，请根据返回的错误信息 Error: 来解决问题。</h6></li>
                    </ol>
                </div>
            </div>
            <div className="dbconnection_connection">
                <div className="dbconnection_title" onClick={(e) => dbfoldFunc(1)}>
                    <h5 className="topic4">链接本地数据库</h5>
                    <button className='dbfold_contrl2' type="button" onClick={(e) => dbfoldFunc(1)}>{dbfoldcotrol2}</button>
                    </div>
                <div className="dbconnection_content" style={{display:displaycontent2?"inline-block":"none",textAlign:"center"}}>
                    <div>
                        <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() =>socketconnect(0)} >查看本地数据</Button>
                        <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => socketconnect(1)} >取消</Button>
                        
                    </div>
                    
                    
                    <div style={{display:isConnected?"inline-block":"none", marginTop:"20px"}}>
                        <h6>选择以下文件，点击加载prompt，或者生成向量数据库。</h6>
                        <Form >
                            {fileList.map((item,index) =>{
                                return <div >
                                        <Form.Check
                                            key={item}
                                            label={item}
                                            value={0}
                                            name="group1"
                                            type= 'radio'
                                            style={{textAlign:"justify",width:"fit-content",margin:"0 auto"}}
                                            onChange={e => changeFunc2(e.target.checked,item)}
                                        />
                                    </div>       
                            })
                            }
                        </Form>
                        <div style={{marginTop: "20px",marginBottom:"20px"}}>
                            <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => loadprompt()} >加载prompt</Button>
                            <Button className='but_only' style={{marginRight: "20px"}} variant="outline-info" onClick={() => setdisplaycontentFunc()} >生成向量数据库</Button>
                            {/* <Button className='but_only' style={{marginRight: "20px",display:displaycontent3?"inline-block":"none"}} variant="outline-info" onClick={() => getvdbsample()} >转化为推荐样式</Button> */}
                        </div>
                        
                        <div style={{display:displaycontent3?"inline-block":"none",width:"100%",marginBottom:"10px"}}>
                            <h6  className='switch_label'>选择： 新建数据库</h6>
                            <div className='vdbname_switch'>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    className='switch_item'
                                    id={"vdbname_switch"}
                                    // label={props.parName_ch}
                                    onClick={(e) => switchFunc2()}
                                    checked={vdbnameSwitch}
                                />
                            </div>
                            <h6 className='switch_label'>已有数据库</h6>
                            <Button style={{marginLeft:"20px"}} className='but_only' variant="outline-info" onClick={() => generatevdbconfirm()} >确认</Button>
                        </div>
                        
                        <div style={{display:displaycontent3?"inline-block":"none",width:"100%"}}>
                            <div  style={{display:"inline-block",width:"350px"}}><input type="text" id={'vdbfilename_input'}  className='item_input' name='vdbname_input' placeholder="请输入文件名称" value={vdbfilename} onChange={(e)=>setVdbfilename(e.target.value)} maxLength={50}></input></div>
                        </div>
                        {advanceC}
                        <div style={{display:displaycontent3?"inline-block":"none",width:"100%"}}>
                            <div style={{marginTop:"10px"}}>
                                <h6  className='switch_label'>优化数据划分方式： 分章节</h6>
                                <div className='vdbname_switch'>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        className='switch_item'
                                        id={"vdbname_switch"}
                                        // label={props.parName_ch}
                                        onClick={(e) => switchFunc3()}
                                        checked={splitorSwitch}
                                    />
                                </div>
                                <h6 className='switch_label'>固定长度</h6>
                            </div> 
                        </div>
                        {info2}
                        <PromptSpliter article = {articleA} PromptSplitertrigger={PromptSplitertrigger} setPromptSplitertrigger={setPromptSplitertrigger} setsplitorInput = {()=>{}} setinfoFunc={props.setinfoFunc}/>
                    </div>
                </div>
            </div>
            
        </div>
        
    )
    
    
}

export default Dbconnection