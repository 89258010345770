import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import  Col  from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import  './menu.css'
import Popup from '../../tools/Popup';
import { useState,useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {Link} from 'react-router-dom'

import { PagePrompt } from '../../configs/PagePrompt';
import { IconPrompt } from "../../configs/iconPrompyt";
import SetupCookies from '../../tools/setupCookies';
import {UseURLLoader} from '../../hooks/useURLLoader'

interface MenuProps{
    username?:string
    getChildList:Function
    superuser:boolean
    loginCheckapp:boolean
    llmselection:Function
    selectedLLM:string
    cleanupCookies:Function
    setinfoFunc:Function
    workFlowAdd_on:Function
    cleanupAPICookies:Function
    workFlowAddList:string[]
    setenable_memory_ST:Function
}


const LLMList=PagePrompt.LLMList 
const txt2imgList = LLMList.slice(8,13)//
const img2imgList = LLMList.slice(13,18)///
const img2txtList = LLMList.slice(18,20)///
const AgentList=PagePrompt.AgentList
const voteList=[{id:0,name:'平台容易使用',value:50},{id:1,name:'提升工作效率和质量',value:50},{id:2,name:'完成搜索和文本查找任务',value:50},{id:3,name:'完成写作初稿和总结提炼任务',value:50},{id:4,name:'提高质量和创造力',value:50},{id:5,name:'有助于专注时间',value:50},{id:6,name:'完成电子邮件任务',value:50},{id:7,name:'会一直使用',value:50},{id:8,name:'会推荐给周围朋友',value:50}]
const variantList=["success","info","warning","danger","primary","secondary","dark","success","info","warning","danger","primary","secondary","dark"]
const IMG_Libs_menu = IconPrompt.IMG_Libs_menu
const IMG_Libs_apis = IconPrompt.IMG_Libs_apis

const Menu: React.FC<MenuProps> = (props) => {
    
    const [selected, setSelected] = useState(LLMList.indexOf(props.selectedLLM) || 0);  //模型选择
    const [LLMPopup,setLLMPopup] = useState(false)
    const [agentPopup,setAgentPopup] = useState(false)
   
    const [msgPopup,setMsgPopup] = useState(false)
    const [infoPopup,setInfoPopup] = useState(false)
    const [savePopup,setSavePopup] = useState(false)
    const [leavemsg,setleavemsg] = useState({title:"",content:""})
    const [leavenewmsg,setleavenewmsg] = useState<string | undefined>()
    const [showmymsg,setshowmymsg] = useState<string | undefined>()
    const [state_disabled,setstate_disabled] = useState(true)
    const [defaultActiveKey0,setdefaultActiveKey0] = useState("check_message")
    const [leavepollmsg,setleavepollmsg] = useState<string | undefined>()
    
    const [msgList,setmsgList]=useState<any>([]) //has only title
    const [onemsg,setonemsg]=useState<any>()
    const [superuser,setsuperuser]=useState(props.superuser)
 
    
    const [SavedListcategory, setSavedListCategory] = useState<any>([]);
    const [pollValue,setpollValue]=useState(voteList)
    const [statistic,setstatistic] =useState(PagePrompt.statisticsList)
    // const [llmselcttion,setllmselcttion] =useState(llmselct)
    let HttpBody_init= {'username': props.username, uuid:SetupCookies("uuid","","get"),session_id:SetupCookies("session_id","","get")}

    useEffect(() => { //监控props.userList状态变化
        setsuperuser(props.superuser || false)
    }, [props.superuser]);

    async function adminRequest(){
        let props2={url:"msg/admin",setinfoFunc:props.setinfoFunc,getChildList:props.getChildList,HttpBody:{...HttpBody_init}}
        await UseURLLoader(props2).then(result =>
            {       
                props2.getChildList(result.userList,result.Unreplymsg)
            }
        )
    }
    
    async function setMsgPopupFunc(value:boolean){
        //get msg list
        if(props.loginCheckapp){
            let props2={url:"msg/getmsglist",setinfoFunc:props.setinfoFunc,HttpBody:{username:props.username}}
            await UseURLLoader(props2).then(result =>
                {   
                    let newList: any[];
                    (result).forEach((item:any,index:number)=>{
                        let listItem = {id:index, message_id:item.message_id,title:item.title,createtime:(item.createtime).split('T')[0],state:Number(item.reply)}
                        if(newList){newList = [...newList,listItem]}
                        else{newList = [listItem]}
                        setmsgList(newList)
                    })
                    setMsgPopup(true)
                }
            )
        }
        else(alert(PagePrompt.LoginTips))
    }

    async function setInfoPopupFunc(value:boolean){
        //get vote info
        if(props.loginCheckapp){

        let props2={url:"msg/getmyvote",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init}}
        await UseURLLoader(props2).then(result =>
            {   
                try{
                    let newvoteList= voteList
                    for(let i=0;i<voteList.length;i++){
                        (newvoteList[i].value as any) = Object.values(result.data[0])[i] || 0
                    }
                    setpollValue(newvoteList)
        
                    let newvoteList2: any[];
                    newvoteList2 = [{[Object.keys(statistic[0])[0]] : Object.values(result.statistics[0])[0]}]
                    for(let i=1;i<statistic.length;i++){
                        newvoteList2 = [...newvoteList2,{[Object.keys(statistic[i])[0] ]: Object.values(result.statistics[0])[i]}]
                        // setstatistic({...statistic, {[Object.keys(newvoteList2[i])]: Object.values(response.data.data.statistics[0])[i]}})
                    }
                    setstatistic(newvoteList2)
                    setInfoPopup(true)
                }catch(err){
                    setstatistic(PagePrompt.statisticsList)
                    setInfoPopup(true)
                }
            }
        )
        
    
    }else(alert(PagePrompt.LoginTips))
    }
    async function setSavePopupFunc(value:boolean){
        if(props.loginCheckapp){
            setSavePopup(true)
            let props2={url:"workflow/get",setinfoFunc:props.setinfoFunc,HttpBody:{...HttpBody_init}}
            await UseURLLoader(props2).then(result =>
                {   
                    const list: any[] = [];
                    (result).forEach((elem:any,index:number) => {
                        list.push({"id":index,"workflow_id":elem.workflow_id,"content":JSON.parse(elem.content),"description":elem.description,"token":elem.token,tokenname:elem.tokenname,"name":elem.name,"picUrl":elem.picUrl,"share":elem.share,"agenttype":elem.agenttype})
                    });
                    setSavedListCategory(list)
                }
            )
            
        }
        else(alert(PagePrompt.LoginTips))
    }
    async function modifyflowMap(workflow_id:any,description:string,index:number){
        let HttpBody={...HttpBody_init,workflow_id:workflow_id,pars:description}

        let props2={url:"workflow/update?pars=description",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        await UseURLLoader(props2).then(result =>
            {   
                props2.setinfoFunc(result,"2000")
            }
        )  
        
    }
    async function deleteflowMap(workflow_id:any,index:number){
        let HttpBody={...HttpBody_init,workflow_id:workflow_id}
        let props2={url:"workflow/delete",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody,indexpars:index}
        await UseURLLoader(props2).then(result =>
            {   
                handlesetSavedListCategory("",props2.indexpars,'delete')
            }
        )   
    }
    
    async function addflowMap(opt:any){
        //clean Cookies except llm
        await props.cleanupAPICookies("cleanupAPICoookies")
        if(opt.tokenname === "zapier_emailservice"){
            // clouse popup
            let list = Array.from(PagePrompt.APILibs["emailservice"].APIpars_list, (x:any) => Object.keys(x)[0])
            list.pop()
            list.forEach((elem,index) => {
                if(index === 0){
                    SetupCookies(elem,opt.token,"new");
                }else if(index === 5){
                    SetupCookies(elem,opt.description,"new");
                }
                else{
                    if(opt.content[elem] !== undefined){
                        SetupCookies(elem,opt.content[elem],"new");
                    }else{SetupCookies(elem,"","new");}
                }
            });
            let list2 = Array.from(PagePrompt.APILibs["zapier"].APIpars_list, (x:any) => Object.keys(x)[0])
            list2.pop()
            let indexofapp = 0
            await SetupCookies(list2[indexofapp],"删除","new");
        }

        if(opt.tokenname.indexOf("zapier") !== -1){
            //add workflow
            let parsName="zapier_emailservice".split("_")
            await props.workFlowAdd_on(parsName)
            setSavePopup(false)
        }else
        {
            let workFlowAdd:string[]=[]
            workFlowAdd.push("getworkflow")
            SetupCookies("serial_number",opt.token,"new");
            SetupCookies("agent_name",opt.name,"new") 
            
            Object.entries(opt.content).map(([key, value]) => {
                if(PagePrompt.LLMList.includes(key)){ //llm model
                    if(props.selectedLLM === undefined){props.llmselection(key)}
                    //setup best llm
                    SetupCookies("llms_election",key,"new") 
                    
                    Object.entries(value as any).map(([sub_key, sub_value]) => {
                        if(sub_value && sub_value !== "default"){
                            SetupCookies(sub_key,(sub_value as any),"new");
                        }
                        if(sub_key === "memory_ST" && sub_value !== "default"){
                            props.setenable_memory_ST(sub_value )
                        }
                        return []
                    })

                }else{ //apis
                    workFlowAdd.push(key)
                    Object.entries(value as any).map(([sub_key, sub_value]) => {
                        if(sub_value && sub_value !== "default"){
                            SetupCookies(sub_key,(sub_value as any),"new");
                        }
                        return []
                    })
                    
                }
                return [] 
            })
            props.workFlowAdd_on(workFlowAdd,opt.tokenname)
            setSavePopup(false)
        }
        
        
        
    }

    function handlesetSavedListCategory(obj:any,index:number,key:any){ 
        if(key === 'description'){
            setSavedListCategory(
                SavedListcategory.map((item:any) => item.id === index?{
                    ...item,
                    description: obj
                }:{...item})
            );
        }else if(key === 'delete'){
            setSavedListCategory(
                SavedListcategory.filter((item:any) => item.id !== index)
            );
        }
        
      }
      function setpollValuefunc(obj:any,index:number){
        setpollValue(
            pollValue.map((item) => item.id === index?{
                ...item,
                value: Number(obj)
            }:{...item})
        )
      }

    function confirm_change_llm(i:any){
        setSelected(i)
        props.llmselection(LLMList[i])
        props.cleanupCookies()
    }

    function onChangeselect(i:any) {
        if (i !== selected && props.workFlowAddList.length>0){
            
            if(window.confirm("如果更改LLM模型，流程图参数需要重新设置。")){
                confirm_change_llm(i)
                props.workFlowAdd_on("","removeworkflow")
            }
        }else if (i !== selected){
            confirm_change_llm(i)
        }
    }
    
    function setleavemsgfonc(obj:any,keys:any){
        if(keys === "title"){
            setleavemsg({...leavemsg, title:obj})
        }else if (keys === "content"){
            setleavemsg({...leavemsg,content:obj})
        }
        
    }

    async function handleleavemsg(){
        let HttpBody={...HttpBody_init,content:leavemsg.content,title:leavemsg.title,replycode:0}
        let props2={url:"msg/leavemsg",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        await UseURLLoader(props2).then(result =>
            {   
                const date = new Date()
                let dateTime = date.toISOString().split(".")[0]
                let newList=[...msgList,{id:msgList.length, message_id:result,message:[],title:leavemsg.title,createtime:dateTime,state:0}]
                setmsgList(newList)
                setleavemsg({...leavemsg,title:"",content:""})
                setdefaultActiveKey0("check_message")
            }
        )

    }
    async function handlenewleavemsg(){
        const date = new Date()
        let dateTime = date.toISOString().split(".")[0]
        let msg = [...onemsg.content, {sender:props.username,msg:leavenewmsg,date:dateTime}]
        let HttpBody={...HttpBody_init,content:msg,message_id:onemsg.message_id,replycode:0}
        let props2={url:"msg/renewmsg",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        await UseURLLoader(props2).then(result =>
            {   
                let msg = showmymsg
                const date = new Date()
                let dateTime = date.toISOString().split(".")[0]
                msg+=  `${props.username} :  (${dateTime}) \n ${leavenewmsg} \n \n `
                setshowmymsg(msg)
                setleavenewmsg("")
            }
        )
    }
    async function showmsg(obj:any,index:number){
        obj.state = 2
        let newList = msgList
        newList[index].state = 2
        setmsgList(newList)

        let HttpBody={...HttpBody_init,message_id:msgList[index].message_id}
        let props2={url:"msg/getmsg",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        await UseURLLoader(props2).then(result =>
            {   
                setstate_disabled(false) 
                setdefaultActiveKey0("message_content")
                setonemsg({message_id:Object.values(HttpBody)[0],content:JSON.parse(result[0].content)})
                let meg = "标题:"+result[0].title+"\n\n"
                JSON.parse(result[0].content).map((opt:any) => (
                    meg+=  `${opt.sender} :  (${opt.date}) \n ${opt.msg}  \n \n`
                ))
                setshowmymsg(meg)
            }
        )
    }

    async function confirmVote(){
        let HttpBody={...HttpBody_init,message:leavepollmsg}
        let colList={}
        pollValue.forEach((items,index) =>{
            Object.assign(colList, {
                ["col_"+index.toString()]:items.value
            });
        })
        Object.assign(HttpBody, {
            colList:colList
        });
        let props2={url:"msg/vote",setinfoFunc:props.setinfoFunc,HttpBody:HttpBody}
        await UseURLLoader(props2).then(result =>
            {   
                props2.setinfoFunc(result,"2000")
            }
        ) 

    }

    function addworkflow(opt:any){
        if(props.loginCheckapp){
            let parsName
            // || opt === 'Notion' 
            if(opt === 'Zapier'){parsName = opt.toLowerCase()}
            else if( opt === '谷歌邮箱'){parsName = "gmailservice"}
            else if(opt === '中华网新闻'){parsName = "topnews"}
            else if(opt === '谷歌翻译'){parsName = "translation"}
            else if(opt === '谷歌新闻'){parsName = "newsgoogle"}
            else if(opt === '聚合数据新闻'){parsName = "juhenews"}
            else if(opt === '文本阅读gTTS'){parsName = "txtreader"}
            else if(opt === '维基百科搜索'){parsName = "wikipedia"}
            else if(opt === '文本阅读pytt'){parsName = "txtreaderpytt"}
            else if(opt === '谷歌地图'){parsName = "googlemap"}
            else if(opt === '天气预报'){parsName = "meteomatics"}
            else if(opt === 'Notion'){parsName = "notion"}
            else if(opt === '论文分析'){parsName = "article"}
            if(parsName){ 
                props.workFlowAdd_on(parsName)
                setAgentPopup(false)
            }else{
                props.setinfoFunc("期待网站更新","2000")
            }
        }else(alert(PagePrompt.LoginTips))
    }

    function geticon(opt:string){
        let icon
        if(opt === 'Zapier' ){icon = IMG_Libs_apis["zapier_img"]}
        else if(opt === '谷歌邮箱'){icon = IMG_Libs_apis["gmail_img"]}
        else if(opt === 'Notion'){icon = IMG_Libs_apis["notion_img"]}
        else if(opt === '中华网新闻'){icon = IMG_Libs_apis["zhong_img"]}
        else if(opt === '谷歌翻译'){icon = IMG_Libs_apis["googletranslate"]}
        else if(opt === '谷歌新闻'){icon = IMG_Libs_apis["googlenews"]}
        else if(opt === '聚合数据新闻'){icon = IMG_Libs_apis["juhe"]}
        else if(opt === '文本阅读gTTS'){icon = IMG_Libs_apis["txtreader"]}
        else if(opt === '文本阅读pytt'){icon = IMG_Libs_apis["txtreader"]}
        else if(opt === '维基百科搜索'){icon = IMG_Libs_apis["wikipedia"]}
        else if(opt === '谷歌地图'){icon = IMG_Libs_apis["googlemap"]}
        else if(opt === '天气预报'){icon = IMG_Libs_apis["meteomatics"]}
        else if(opt === '论文分析'){icon = IMG_Libs_apis["article"]}
        else{icon = IMG_Libs_apis["paw"]}
        return icon
    }
    function copybord(val:string){
        navigator.clipboard.writeText(val);
    }

      
    let msgstateIMG1= <img src={IMG_Libs_menu["new_msg"]} className="rounded me-2" width="18" alt="" />
    let msgstateIMG2= <img src={IMG_Libs_menu["open_msg"]} className="rounded me-2" width="18" alt="" />;

    return (
        <Nav defaultActiveKey="/home" className="d-inline-flex flex-column" id='menu_container'>
            <Container>
                <Col>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Col xs={6} md={4}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">选择LLM模型</Tooltip>}
                        >
                            <Image id='manu_img' src={IMG_Libs_menu["robot"]}  width="50" onClick={()=>setLLMPopup(true)}/>
                        </OverlayTrigger>
                        <Popup setTrigger={setLLMPopup} 
                            trigger = {LLMPopup}
                            dialogWidth = '550px'
                            title='LLM 设置'
                            >   
                            <Tabs
                            defaultActiveKey="langurage_model"
                            id="justify-tab-example"
                            className="mb-3"
                            justify
                            >
                                <Tab eventKey="langurage_model" title="语言模型" className='tab0'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="api_llm">
                                        <Row className='row0'>
                                            <Col sm={2}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item >
                                                <Nav.Link className='nav_link2' eventKey="opensource_llm">开源大模型</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                <Nav.Link className='nav_link2' eventKey="api_llm">API-大模型</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            </Col>
                                            <Col sm={8}>
                                            <Tab.Content >
                                                <Tab.Pane   className='nav_pane2'  eventKey="opensource_llm">
                                                {LLMList.slice(0,LLMList.length-16).map((opt, index) => (//
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="custom-switch"
                                                        className='custom-switch'
                                                        label = {opt}
                                                        checked={index === selected}
                                                        onChange={() => onChangeselect(index)}
                                                    />
                                                ))} 
                                                </Tab.Pane>
                                                <Tab.Pane  className='nav_pane2' eventKey="api_llm">
                                                {LLMList.slice(LLMList.length-16,LLMList.length-12).map((opt, index) => (//
                                                    <Form.Check // prettier-ignore
                                                        type="switch"
                                                        id="custom-switch"
                                                        className='custom-switch'
                                                        label = {opt}
                                                        checked={index+4 === selected}
                                                        onChange={() => onChangeselect(index+4)}
                                                    />
                                                ))} 
                                                    
                                                </Tab.Pane> 
                                            </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>

                                </Tab>

                                <Tab eventKey="img_model" title="图像生成模型" >
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="txt_img">
                                        <Row className='row0'>
                                            <Col sm={2}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item >
                                                <Nav.Link className='nav_link2' eventKey="txt_img">文字to图像</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                <Nav.Link className='nav_link2' eventKey="img_txt">图像to图像</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                <Nav.Link className='nav_link2' eventKey="img_img">图像to文字</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            </Col>
                                            <Col sm={8}>
                                            <Tab.Content >
                                                <Tab.Pane   className='nav_pane2'  eventKey="txt_img">
                                                    {txt2imgList.map((opt, index) => (
                                                        <Form.Check // prettier-ignore
                                                            type="switch"
                                                            id="custom-switch"
                                                            className='custom-switch2'
                                                            label = {opt}
                                                            checked={index+8 === selected}
                                                            onChange={() => onChangeselect(index+8)}
                                                        />
                                                    ))}
                                                </Tab.Pane>
                                                <Tab.Pane  className='nav_pane2' eventKey="img_txt">
                                                    {img2imgList.map((opt, index) => (
                                                        <Form.Check // prettier-ignore
                                                            type="switch"
                                                            id="custom-switch"
                                                            className='custom-switch2'
                                                            label = {opt}
                                                            checked={index+13 === selected}//
                                                            onChange={() => onChangeselect(index+13)}//
                                                        />
                                                    ))}
                                                    
                                                </Tab.Pane>
                                                <Tab.Pane  className='nav_pane2' eventKey="img_img">
                                                    {img2txtList.map((opt, index) => (
                                                        <Form.Check // prettier-ignore
                                                            type="switch"
                                                            id="custom-switch"
                                                            className='custom-switch2'
                                                            label = {opt}
                                                            checked={index+18 === selected}//
                                                            onChange={() => onChangeselect(index+18)}//
                                                        />
                                                    ))}
                                                    
                                                </Tab.Pane>
                                                
                                                
                                            </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                    
                                </Tab>
                            </Tabs> 
                        </Popup>
                        <br></br>
                    </Col>
                    <Col xs={6} md={4}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">查看Agent</Tooltip>}
                        >
                            <Image id='manu_img' src={IMG_Libs_menu["tool"]} width="50" onClick={()=>setAgentPopup(true)}/>
                        </OverlayTrigger>
                        <Popup setTrigger={setAgentPopup} 
                            trigger = {agentPopup}
                            dialogWidth = '750px'
                            title='Agent Hub'
                            >
                                <Tabs
                                defaultActiveKey="recommended_agent"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                                
                                >
                                    <Tab eventKey="recommended_agent" title="推荐使用" style={{overflow: 'hidden'}}>

                                        <Tab.Container id="left-tabs-example" defaultActiveKey="life">
                                            <Row>
                                                <Col sm={2}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item >
                                                    <Nav.Link className='nav_link' eventKey="life">生活</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="study">学习</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="work">工作</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="literature">文学</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="art">艺术</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="others">其他</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                <Tab.Content >
                                                    <Tab.Pane   className='nav_pane'  eventKey="life">
                                                        <ul>
                                                            {(AgentList[0][0] as any).map((opt:string, index:number) => {
                                                                if(opt[0] === "*"){
                                                                    return <li key={index}>{opt}</li> 
                                                                }else{
                                                                    let icon = geticon(opt)
                                                                    return <Button className='agent_but' variant="outline-primary" style={{backgroundImage:`url(${icon})`, backgroundRepeat:"no-repeat",backgroundSize:"contain", textAlign:"right",paddingRight:"5px"}} onClick={() => addworkflow(opt)} >{opt}</Button>
                                                                }  
                                                            })}
                                                        </ul>
                                                    </Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="food">请期待网站更新</Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="study">
                                                        <ul>
                                                            {(AgentList[0][2] as any).map((opt:string, index:number) => {
                                                                if(opt[0] === "*"){
                                                                    return <li key={index}>{opt}</li> 
                                                                }else{
                                                                    let icon = geticon(opt)
                                                                    return <Button className='agent_but' variant="outline-primary" style={{backgroundImage:`url(${icon})`, backgroundRepeat:"no-repeat",backgroundSize:"contain", textAlign:"right",paddingRight:"5px"}} onClick={() => addworkflow(opt)} >{opt}</Button>
                                                                }  
                                                            })}
                                                        </ul>
                                                    </Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="work">
                                                        <ul>
                                                            {(AgentList[0][3] as any).map((opt:string, index:number) => {
                                                                if(opt[0] === "*"){
                                                                    return <li key={index}>{opt}</li> 
                                                                }else{
                                                                    let icon = geticon(opt)
                                                                    return <Button className='agent_but' variant="outline-primary" style={{backgroundImage:`url(${icon})`, backgroundRepeat:"no-repeat",backgroundSize:"contain", textAlign:"right",paddingRight:"5px"}} onClick={() => addworkflow(opt)} >{opt}</Button>
                                                                }  
                                                            })}
                                                        </ul>
                                                    </Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="art">请期待网站更新</Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="others">请期待网站更新</Tab.Pane>
                                                </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    
                                    </Tab>
                                    <Tab eventKey="share_agent" title="分享" style={{overflow: 'hidden'}} >
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="life">
                                            <Row>
                                                <Col sm={2}>
                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item >
                                                    <Nav.Link className='nav_link' eventKey="life">生活</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="study">学习</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="work">工作</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="literature">文学</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="art">艺术</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                    <Nav.Link className='nav_link' eventKey="others">其他</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                <Tab.Content>
                                                    <Tab.Pane   className='nav_pane'  eventKey="life">
                                                        <ul>
                                                            {AgentList[1].map((opt:any, index:number) => {
                                                                if(opt[0] === "*"){
                                                                    return <li key={index}>{opt}</li> 
                                                                }else{
                                                                    let icon
                                                                    if(opt === 'Zapier' ){icon = IMG_Libs_apis["zapier_img"]}
                                                                    else if(opt === '谷歌邮箱'){icon = IMG_Libs_apis["gmail_img"]}
                                                                    else if(opt === 'Notion'){icon = IMG_Libs_apis["notion_img"]}
                                                                    else if(opt === '中华网新闻'){icon = IMG_Libs_apis["zhong_img"]}
                                                                    else if(opt === '谷歌翻译'){icon = IMG_Libs_apis["googletranslate"]}
                                                                    else if(opt === '谷歌新闻'){icon = IMG_Libs_apis["googlenews"]}
                                                                    else if(opt === '聚合数据新闻'){icon = IMG_Libs_apis["juhe"]}
                                                                    else if(opt === '天气预报'){icon = IMG_Libs_apis["meteomatics"]}
                                                                    else if(opt === '文本阅读gTTS'){icon = IMG_Libs_apis["txtreader"]}
                                                                    else if(opt === '文本阅读pytt'){icon = IMG_Libs_apis["txtreader"]}
                                                                    else if(opt === '维基百科搜索'){icon = IMG_Libs_apis["wikipedia"]}
                                                                    else if(opt === '谷歌地图'){icon = IMG_Libs_apis["googlemap"]}
                                                                    else{icon = IMG_Libs_apis["paw"]}
                                                                    return <Button className='agent_but' variant="outline-primary" style={{backgroundImage:`url(${icon})`, backgroundRepeat:"no-repeat",backgroundSize:"contain", textAlign:"right",paddingRight:"5px"}} onClick={() => addworkflow(opt)} >{opt}</Button>
                                                                }  
                                                            })}
                                                        </ul>
                                                    </Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="food">请期待网站更新</Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="study">请期待网站更新</Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="work">请期待网站更新</Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="art">请期待网站更新</Tab.Pane>
                                                    <Tab.Pane  className='nav_pane' eventKey="others">请期待网站更新</Tab.Pane>
                                                </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Tab>
                                    
                                </Tabs>
                        
                        </Popup>
                        <br></br>
                    </Col>

                    <Col xs={6} md={4}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">查看流程图设置</Tooltip>}
                        >
                            <Image id='manu_img' src={IMG_Libs_menu["flow"]} width="50" onClick={()=>setSavePopupFunc(true)}/>
                        </OverlayTrigger>
                        <Popup setTrigger={setSavePopup} 
                            trigger = {savePopup}
                            dialogWidth = '70%'
                            title='我的自动化办公流程图'
                            >   <div className='Popup-content-box'>
                                    <div className='Popup-content-index'style={{width:'50px'}}>&nbsp;</div>
                                    {/* <input className='Popup-content-left' type="text" style={{width:'20%'}} name='name' value={opt.token}  maxLength={20} onChange={(e) => handlesetSavedListCategory(e.target.value,index,'name')}/>  */}
                                    <div className='Popup-content-left' style={{width:'100px', textAlign:"center"}}> 名称</div>
                                    <div className='Popup-content-left' style={{width:'80px', textAlign:"center"}}> Token</div>
                                    <div className='Popup-content-left' style={{width:'100px', textAlign:"center"}}> Icon</div>
                                    <div className='Popup-content-left' style={{width:'40%', textAlign:"center"}}> 描述</div>
                                    <div className='Popup-content-left' style={{width:'50px', textAlign:"center"}}> 分享</div>
                                    <div className='Popup-content-right' style={{width:'25px',marginRight:"20px"}}>&nbsp; </div>
                                    <div className='Popup-content-right' style={{width:'25px'}}>&nbsp; </div>
                                    <div className='Popup-content-right' style={{width:'25px'}}>&nbsp; </div>
                                </div>
                                {(SavedListcategory || []).map((opt:any, index:number) => {
                                    // console.log("../../assets/img/usericon/"+opt.picUrl) https://
                                    // let item_img = require("../../assets/img/usericon/"+opt.picUrl);
                                    let item_img =  PagePrompt.url + "/images/" + opt.picUrl
                                    return <div className='Popup-content-box'>
                                        <div className='Popup-content-index'style={{width:'50px'}}>{index}</div>
                                        {/* <input className='Popup-content-left' type="text" style={{width:'20%'}} name='name' value={opt.token}  maxLength={20} onChange={(e) => handlesetSavedListCategory(e.target.value,index,'name')}/>  */}
                                        <div className='Popup-content-index'style={{width:'100px', textAlign:"center"}}>{opt.name}</div>
                                        <div className='Popup-content-left' style={{width:'80px', textAlign:"center"}}><button className='but_copy' onClick={()=>copybord(opt.token)}>复制</button></div>
                                        <div className='Popup-content-left' style={{width:'100px', textAlign:"center"}}><img src={item_img} className="me-2" width="60" alt=""/></div>
                                        <div className='Popup-content-left' style={{width:'40%', textAlign:"center"}}> <textarea  className='overflow-setting' value={opt.description} rows={3} onChange={(e) => handlesetSavedListCategory(e.target.value,index,'description')}/></div>
                                        <div className='Popup-content-index' style={{width:'50px', textAlign:"center",verticalAlign:"middle"}}>{opt.share === 1? "是" :"否"}</div>
                                        <div className='Popup-content-right' style={{width:'25px',height:"50px",marginRight:"20px"}}><img src={IMG_Libs_menu["close"]} style={{marginTop:"30px",marginLeft:"5px"}} onClick={()=>deleteflowMap(opt.workflow_id,index)} className="rounded me-2" width="20" alt="" /></div>
                                        <div className='Popup-content-right' style={{width:'25px'}}><img src={IMG_Libs_menu["check1"]} style={{marginTop:"30px",marginLeft:"5px"}} onClick={()=>modifyflowMap(opt.workflow_id,opt.description,index)} className="rounded me-2" width="20" alt="" /></div>
                                        <div className='Popup-content-right' style={{width:'25px'}}><img src={IMG_Libs_menu["add_icon"]} style={{marginTop:"30px",marginLeft:"5px"}} onClick={()=>addflowMap(opt)} className="rounded me-2" width="17" alt="" /></div>
                                    </div>
                                })}
                        </Popup>
                        <br></br>
                    </Col>
                    <Col xs={6} md={4} >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">我的记忆</Tooltip>}
                        >
                            <Link className='list-group-item' to='/mymemory' style={{width:"60px"}} target='_blank'><Image id='manu_img' src={IMG_Libs_menu["mymemory"]}  style={{marginLeft:"5px"}} width="50" /></Link>
                        </OverlayTrigger>
                        <br></br>
                    </Col>
                    <Col xs={6} md={4} >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">操作指南</Tooltip>}
                        >
                            <Link className='list-group-item' style={{width:"60px"}} to='/guide' target='_blank'><Image id='manu_img' style={{marginLeft:"5px"}} src={IMG_Libs_menu["saved"]}  width="50" /></Link>
                        </OverlayTrigger>
                        <br></br>
                    </Col>
                    <Col xs={6} md={4}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">查看留言和回复</Tooltip>}
                        >
                            <Image id='manu_img' src={IMG_Libs_menu["message"]} width="50" style={{marginLeft:"5px"}} onClick={()=>setMsgPopupFunc(true)}/> 
                            {/* onClick={()=>setMsgPopup(true)} */}
                        </OverlayTrigger>
                        <Popup setTrigger={setMsgPopup} 
                            trigger = {msgPopup}
                            dialogWidth = '600px'
                            title='留言和回复'
                            >
                                <Tabs
                                // defaultActiveKey="leave_message"
                                activeKey={defaultActiveKey0}
                                onSelect={(k) => setdefaultActiveKey0(k? k:"leave_message")}
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                                >
                                    <Tab eventKey="leave_message" title="给我们留言" >
                                        <input className='Popup-leavemsg-title' type="text" name='msg-title' value={leavemsg.title} placeholder='请输入您的主题' maxLength={20} onChange={(e) => setleavemsgfonc(e.target.value,'title')}/> 
                                        <textarea  className='Popup-leavemsg' value={leavemsg.content} placeholder='请输入您的留言' rows={10} onChange={(e) =>setleavemsgfonc(e.target.value,'content')}/> 
                                        <Button className='Popup-leavemsg-but' variant="outline-primary" onClick={() => handleleavemsg()} >发送</Button>
                                    </Tab>
                                    <Tab eventKey="check_message" title="查看消息" >
                                        <ol>
                                            {msgList.map((opt:any, index:number) => (
                                                <li className='Popup-leavemsg-li'>
                                                    <div className='Popup-leavemsg-li-left' onClick={() => showmsg(opt,index)}>{opt.title}</div>
                                                    <div className='Popup-leavemsg-li-right'>{opt.state === 1 ? msgstateIMG1: msgstateIMG2}</div>
                                                    <div className='Popup-leavemsg-li-right'>{opt.createtime}</div> 
                                                </li> 
                                                ))}
                                        </ol>
                                        
                                    </Tab>
                                    <Tab id="message_content" eventKey="message_content" title=" " disabled={state_disabled} onChange={(e)=>setstate_disabled}> 
                                        <textarea  className='Popup-leavemsg' rows={10} readOnly onChange={(e) =>setshowmymsg} value={showmymsg}></textarea> 
                                        <textarea  className='Popup-leavemsg2' value={leavenewmsg} placeholder='请输入您新的留言' rows={5} onChange={(e) => setleavenewmsg(e.target.value)}/> 
                                        <Button className='Popup-leavemsg-but' variant="outline-primary" onClick={() => handlenewleavemsg()} >发送</Button>
                                    </Tab>
                                </Tabs>
                                {/* disabled */}
                        </Popup>
                        <br></br>
                    </Col>
                    <Col xs={6} md={4}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">查看通知和投票</Tooltip>}
                        >
                            <Image id='manu_img' src={IMG_Libs_menu["notification"]} width="50" style={{marginLeft:"7px"}} onClick={()=>setInfoPopupFunc(true)}/>
                        </OverlayTrigger>
                        <Popup 
                            setTrigger={setInfoPopup} 
                            trigger = {infoPopup}
                            dialogWidth = '70%'
                            title='平台通知和投票'
                            >
                                <Tabs
                                defaultActiveKey="platform_info"
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                                >
                                <Tab eventKey="platform_info" title="通知" >
                                <ul>
                                    {PagePrompt.infoList.map((opt, index) => (<li style={{textAlign:'left'}}>{opt}</li> ))}
                                </ul>
                                </Tab>
                                <Tab eventKey="platform_poll" title="参与我们的投票" >
                                    <ol id="platform_poll">
                                        {pollValue.map((obj,index) => (
                                            <li style={{textAlign:'center'}}> 
                                                <div>
                                                <Form.Label id='platform_poll_lable0'>{obj.name}</Form.Label>
                                                <Form.Control id='platform_poll_lable3' value = {obj.value} onChange={e => setpollValue}/>
                                                </div>
                                                <div>
                                                <Form.Label id='platform_poll_lable1' >&nbsp;&nbsp;&nbsp;&nbsp;0</Form.Label>
                                                <Form.Range id='platform_poll_range' value={obj.value} onChange={e => setpollValuefunc(e.target.value,index)}/>
                                                <Form.Label id='platform_poll_lable2'>100</Form.Label>
                                                </div>
                                            </li>
                                            ))}
                                            <textarea  className='Popup-leavemsg2' value={leavepollmsg} placeholder='请告诉我们您平时的使用需求，以及改进建议，谢谢' rows={5} onChange={(e) => setleavepollmsg(e.target.value)}/> 
                                            <Button className='Popup-leavemsg-but' variant="outline-primary" onClick={() => confirmVote()} >确认</Button>
                                    </ol>
                                    
                                </Tab>
                                <Tab id="message_content" eventKey="platform_statistics" title="数据统计"> 
                                    <table style={{width:"100%", overflow:'hidden',textAlign:"center"}}>
                                        {statistic.map((obj,index) => (
                                            <tr>
                                            <th id='progressbar_title'> {Object.keys(obj)[0]}</th>
                                            <th> 
                                                <ProgressBar variant={variantList[index]} className='progress' now={Object.values(obj)[0]} label={`${Object.values(obj)[0]}%`}/>
                                            </th>
                                        </tr>
                                    ))}
                                        
                                    </table>
                                    
                                </Tab>
                                </Tabs>
                        </Popup>
                        <br></br>
                    </Col>
                    <Col xs={6} md={4} >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">关于我们</Tooltip>}
                        >
                            <Link className='list-group-item' style={{width:"60px"}} to='/about' target='_blank'><Image id='manu_img' src={IMG_Libs_menu["teacup"]}  width="50" /></Link>
                        </OverlayTrigger>
                        <br></br>
                    </Col>

                    
                    
                    <Col xs={6} md={4} 
                        style={{display:(superuser? "block":"none")}} 
                        onChange={() => setsuperuser}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">用户管理</Tooltip>}
                        >
                            <Link className='list-group-item' style={{width:"60px"}} to='/admin'><Image id='manu_img' style={{marginLeft:"5px"}} src={IMG_Libs_menu["admin"]}  width="50" onClick={()=>adminRequest()}/></Link>
                        </OverlayTrigger>
                    </Col>
                </Col>
            </Container>
        </Nav>
      );
}
   


Menu.defaultProps={
    username:""
}
export default Menu